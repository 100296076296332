import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { ContainerBox, Table, ModalFooter, UploadFileTemplateSorting } from 'components';
import { MODAL_ID, EMPTY_FORMAT_CELL, MODAL_TYPE } from '../../core/utils/constants';
import { RiDeleteBin5Line } from "react-icons/ri";
import { LoadingUpload } from "../../lotties";
import BaseComponent from '../BaseComponent';
import './style.scss';

@inject('commonStore', 'modalStore')
@observer
class ScreenRegistTemplates extends BaseComponent {
  configfilesListsId = null;
  constructor(props) {
    super(props);
    this.configfilesListsId = this.props.match.params?.id;
  }

  componentWillMount = () => {
    const { t, commonStore: { templateSortingStore } } = this.props;
    templateSortingStore.getTemplateSortingTemplatesById(this.configfilesListsId);
  };

  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hide();
  };

  openModalError = () => {
    const { modalStore, t } = this.props;
    const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
    modalStore.show({
      id: MODAL_ID.CONFIRM,
      isOpen: true,
      header: t("template_sorting.upload.cant_upload_warning"),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          {/* The iframe below is used to fix bug in the browser IE11.
          In the screen [Upload], the pdf iframe is overlap all elements. */}
          {isIE && <iframe class="iframe-cover-ar" src="about:blank"></iframe>}
          <div key={"modal-body"} className="modal-body">
            <span>{t("template_sorting.upload.cant_upload_5")}</span>
          </div>
          <ModalFooter
            key={"modal-footer"}
            saveButtonText={t("template_sorting.upload.close")}
            onConfirm={() => {
              this.closeModal();
            }}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: "small",
    });
  };

  handleUploadButton = () => {
    if (document.getElementById("upload-selector")) {
      document.getElementById("upload-selector").click();
    }
  }

  handleAddFile = (file, fileContent) => {
    if (!this.props.commonStore.templateSortingStore.updateFileContent(file)) {
      this.openModalError();
    }
  };

  handleBackButton = () => {
    const { commonStore: { templateSortingStore } } = this.props;
    templateSortingStore.deleteUploadFile();
    this.props.history.push("/templatesorting/edit/" + templateSortingStore.templateSorting.id);
  };

  handleRegistButton = () => {
    const { commonStore: { templateSortingStore } } = this.props;
    templateSortingStore.registTemplates(this.configfilesListsId, this.afterRegist);
  };

  afterRegist = (configfilesListsId) => {
    const { t, commonStore: { templateSortingStore } } = this.props;
    templateSortingStore.getTemplateSortingTemplatesById(configfilesListsId);
  };

  disableRegistButton(){
    const { t, commonStore: { templateSortingStore } } = this.props;
    if (templateSortingStore.uploadingFiles.length === 0) return true;

    let ret = true;
    templateSortingStore.uploadingFiles.map(e => {
      if (e.status === 0) ret = false;
    })
    return ret;
  }
  
  //deleteFile = (fileName, timestamp) => {
  deleteFile = (fileData) => {
    const { modalStore, t, commonStore: { templateSortingStore } } = this.props;
    const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
    modalStore.show({
      id: MODAL_ID.CONFIRM_DELETE,
      isOpen: true,
      header: t("template_sorting.upload.confirmation_delete"),
      onCancel: () => this.closeModal(MODAL_ID.CONFIRM_DELETE),
      children: (
        <div className="text-c">
          {/* The iframe below is used to fix bug in the browser IE11.
          In the screen [Upload], the pdf iframe is overlap all elements. */}
          {isIE && <iframe class="iframe-cover-ar" src="about:blank"></iframe>}
          <div key={"modal-body"} className="modal-body">
            <span>{t("template_sorting.upload.confirm_delete_desc")}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t("template_sorting.upload.remove")}
            onConfirm={() => {
              this.closeModal();
              //templateSortingStore.deleteUploadedFile(encodeURIComponent(fileName), timestamp);
              templateSortingStore.deleteUploadedFile(fileData);
            }}
            cancelButtonText={t("template_sorting.upload.cancel")}
            onCancel={this.closeModal}
          />
        </div>
      ),
      type: MODAL_TYPE.SMALL,
    });
  }

  openComfirmRegist = () => {
    const { modalStore, t, commonStore: { templateSortingStore } } = this.props;
    const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
    modalStore.show({
      id: MODAL_ID.CONFIRM,
      isOpen: true,
      header: t("template_sorting.upload.confirmation"),
      onCancel: () => this.closeModal(MODAL_ID.CONFIRM),
      children: (
        <div className="text-c">
          {/* The iframe below is used to fix bug in the browser IE11.
          In the screen [Upload], the pdf iframe is overlap all elements. */}
          {isIE && <iframe class="iframe-cover-ar" src="about:blank"></iframe>}
          <div key={"modal-body"} className="modal-body">
            <span>{t("template_sorting.upload.confirm_desc")}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t("template_sorting.upload.registupload.execute")}
            onConfirm={() => {
              this.closeModal();
              templateSortingStore.executeCurrentDocument(this.props.history);
            }}
            cancelButtonText={t("upload.cancel")}
            onCancel={this.closeModal}
          />
        </div>
      ),
      type: MODAL_TYPE.SMALL,
    });
  };

  columns = () => {
    const { t, commonStore: { templateSortingStore: { setCurrentUploadFile } } } = this.props;
    return [
      {
        Header: t("template_sorting.upload.no"),
        accessor: "no",
        Cell: ({ row }) => {
          return <div>{(parseInt(row.id) + 1)}</div>;
        },
        width: "6%",
        disableSortBy: true
      },
      {
        Header: t("template_sorting.upload.filename"),
        accessor: "name",
        Cell: ({ row }) => {
          const fileData = row.original.data || row.original;
          return (fileData.status !== -1)
            ? <div className="file-name single-line-row finished-item" onClick={() => setCurrentUploadFile(fileData.id)} title={fileData.templateOriginalFileName} >{fileData.templateOriginalFileName}</div>
            : <div className="file-name single-line-row" title={fileData.templateOriginalFileName}>{fileData.templateOriginalFileName}</div>;
        },
        width: "35%",
        disableSortBy: true
      },
      {
        Header: t("template_sorting.upload.format"),
        accessor: "type",
        Cell: ({ row }) => {
          const fileData = row.original.data || row.original;
          return (fileData.status !== -1) 
            /*? <div>{t("template_sorting.regist.imagetype_" + fileData.imageType)}</div> */
            ? <div>{fileData.fileType}</div> 
            : <span>{EMPTY_FORMAT_CELL}</span>;
        },
        width: "8%",
        disableSortBy: true
      },
      {
        Header: t("template_sorting.upload.size"),
        accessor: "size",
        width: "20%",
        Cell: ({ row }) => {
          const fileData = row.original.data || row.original;
          return (fileData.status !== -1) 
            ? <div className="single-line-row" title={fileData.strFileSize}>{fileData.strFileSize}</div> 
            : <span>{EMPTY_FORMAT_CELL}</span>;
        },
        disableSortBy: true
      },
      {
        Header: t("template_sorting.upload.regist_status"),
        accessor: "status",
        Cell: ({ row }) => {
          const fileData = row.original.data || row.original;
          return (fileData.status !== -1) 
            ? <div>{t("template_sorting.regist.status_" + fileData.status)}</div>
            : <span>{EMPTY_FORMAT_CELL}</span>;
        },
        width: "15%",
        disableSortBy: true
      },
      {
        Header: t("template_sorting.upload.status"),
        accessor: "status",
        Cell: ({ row }) => {
          const fileData = row.original.data || row.original;
          return (fileData.status !== -1) 
            ? <div><i className="fa fa-check"></i></div> 
            : <div className="loading-file-status"><LoadingUpload></LoadingUpload></div>;
        },
        width: "8%",
        disableSortBy: true
      },
      {
        Header: t("template_sorting.upload.action"),
        accessor: "action",
        className: 'upload-action',
        Cell: ({ row }) => {
          const fileData = row.original.data || row.original;
          //return <div><RiDeleteBin5Line className={(fileData.status !== -1) ? "" : "disabled"} onClick={() => (fileData.status !== -1) && this.deleteFile(fileData.templateOriginalFileName, fileData.timestamp)}></RiDeleteBin5Line></div>;
          return <div><RiDeleteBin5Line className={(fileData.status !== -1) ? "" : "disabled"} onClick={() => (fileData.status !== -1) && this.deleteFile(fileData)}></RiDeleteBin5Line></div>;
        },
        width: "8%",
        disableSortBy: true
      }
    ];
  };



  render() {
    const { t, commonStore: { templateSortingStore: { 
      currentConfigFile, selectedGroupId, listGroupDefinitions, currentUploadFile, uploadingFiles } }} = this.props;
    let selctedGroup = '';
    listGroupDefinitions.map((group) => {
      if (parseInt(group.id) === selectedGroupId) {
        selctedGroup = group.groupName;
      }
    });
    return (
      <div className="screen-template-sorting-upload">
        <ContainerBox headerTitle={`${t('template_sorting.regist.page_title')}`}>
          {/* <div className="admin-header row"> */}
          <div className="form-pannel row">
            <div className="col-sm-11">
              <div className="row" style={{ textAlign: "left", display: "flex", lineHeight: "30px" }}>
                <div className="l20">
                  <label>{t("template_sorting.edit.edition")}</label>
                  {currentConfigFile?.configFile?.edition}
                </div>
                <div className="l20">
                  <label>{t("template_sorting.edit.form_group_name")}</label>
                  {selctedGroup}
                </div>
                <div className="l20">
                  <label>{t("template_sorting.edit.form_id")}</label>
                  {currentConfigFile?.configFile?.formId}
                </div>
                <div className="l20">
                  <label>{t("template_sorting.edit.config_name")}</label>
                  {currentConfigFile?.configFile?.configName}
                </div>
              </div>
            </div>
          </div>
        </ContainerBox>

        <ContainerBox>
          <div className="screen-upload-body">
            <div className="box-body box-profile col-sm-6 su-column su-upload-pannel">
              <div className="su-sub-upload-pannel">

                <div className="su-upload-file">
                  <button type="button" className="btn btn-primary upload-button" onClick={this.handleUploadButton}>
                    {t("upload.btn_select_file")}
                  </button>
                  <UploadFileTemplateSorting 
                    file={currentUploadFile?.content} 
                    addFile={this.handleAddFile} 
                    mime={".jpg, .jpeg, .tif, .tiff, .png"}
                    maxFileSize={10*1024*1024}
                    types={["image/jpeg", "image/tiff", "image/png"]}
                  />
                </div>

                <div className="wrap-upload-file-list">
                  <div className="upload-file-list styled-scroll">
                    {<Table
                      disablePaging={true}
                      columns={this.columns()}
                      data={uploadingFiles}
                      rowClass={(row) => {
                        const fileData = row.original.data || row.original;
                        return currentUploadFile?.id === fileData.id ? "selected-item" : "";
                      }}
                      t={t}
                      disableSelectionPageSize={true}
                      hideTableFooter={true}
                      className="uploading-table"
                    />}
                    {uploadingFiles.length === 0 && <div className="table-no-data">
                      <p>{t("table_no_data")}</p>
                    </div>}
                  </div>
                </div>

              </div>
            </div>
            <div className="box-body box-profile col-sm-6 su-column su-preview">
              <iframe
                className="uploaded-file-iframe"
                frameBorder="0"
                title="uploaded-file"
                src={currentUploadFile?.link}
              />
            </div>
          </div>
        </ContainerBox>

        <div /*className="wrap-upload-file-list"*/>
          <div className="action-group">
            <button type="button" className="btn btn-primary" onClick={this.handleBackButton}>
              {t("template_sorting.upload.back")}
            </button>
            <button
              /*title={(selectedDefinitionId === -1) ? t("upload.upload_definition_to_execute_reading") : t("upload.upload_file_to_execute_reading")}*/
              type="button" className="btn-execute-reading btn btn-primary"
              onClick={this.handleRegistButton}
              //disabled={!currentUploadFile?.readyToExecute}
              //disabled={uploadingFiles.length === 0}
              disabled={this.disableRegistButton()}
            >
              {t("template_sorting.upload.regist")}
            </button>
          </div>
        </div>

      </div>
    );
  }

}

export default withRouter(withTranslation()(ScreenRegistTemplates));
