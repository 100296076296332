import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { ContainerBox, Table, ModalFooter } from 'components';
import ScreenFormGroupSetting from './ScreenFormGroupSetting';
import ScreenDownloadFileHeaderItem from './ScreenDownloadFileHeaderItem';
import { MODAL_ID, DATE_FORMAT, NO_DATE, EMPTY_FORMAT_CELL, CONFIG_FILE_EDITION } from '../../core/utils/constants';
import moment from 'moment';
import BaseComponent from '../BaseComponent';
import './style.scss';
import { IconFa } from "components";

const TABLE_ROW_WIDTH_FORM_GROUP = {
  NO: "6%",
  EDITION: "10%",
  FORM_GROUP_NAME: "19%",
  FORM_SETTING_NAME: "20%",
  TEAMS_NAME: "20%",
  CREATED_DATE: "11%",
  UPDATED_DATE: "11%",
  SUB_MENU: "3%",
};

@inject('commonStore', 'modalStore')
@observer
class ScreenFormGroup extends BaseComponent {

  executeLogicAfterRender = () => {
    this.reloadTable();
  }

  reloadTable = () => {
    this.props.commonStore.formGroupStore.clean();
    this.props.commonStore.formGroupStore.getFormGroups();
    this.props.commonStore.formSettingStore.getList();
    this.props.commonStore.teamStore.getTeamsAll();
  }

  executeLogicBeforeOutComponent = () => {
    this.props.commonStore.formGroupStore.clean();
  }

  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hideAll();
  };

  onConfirmDeleteById = (id) => {
    this.props.commonStore.formGroupStore.deleteFormGroup(id);
    this.closeModal();
  }

  onFetch = (tableData) => {
    this.props.commonStore.formGroupStore.updatePagingFiler(tableData);
    this.props.commonStore.formGroupStore.getFormGroups();
  };

  handleBack = () => {
    this.props.history.push("/definitions/");
  }

  openComfirmDeleteModal = (id) => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.REMOVE,
      isOpen: true,
      header: t('definition.remove_confirm'),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>{t('definition.form_group.remove_confirm_msg')}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t('definition.form_group.remove')}
            onConfirm={() => this.onConfirmDeleteById(id)}
            cancelButtonText={t('definition.cancel')}
            onCancel={this.closeModal}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: 'small'
    });
  };

  openFormGroupSettingsModal = (formGroupId, formGroupSetting) => {
    const { t, modalStore } = this.props;
    let listFormSettings = this.props?.commonStore?.formSettingStore?.listFormSettings;
    let teamsAll = this.props?.commonStore?.teamStore?.teamsAll;
    modalStore.show({
      id: MODAL_ID.SPECIFYING_FORM_SETTING,
      isOpen: true,
      header: `${t('definition.form_group.setting.page_title')}`,
      onCancel: this.closeModal,
      children: (
        <ScreenFormGroupSetting
          formGroupId={formGroupId}
          formGroupSetting={formGroupSetting}
          listFormSettings={listFormSettings}
          teamsAll={teamsAll}
          reloadParent={this.reloadTable}
          saveButtonText={t('definition.form_group.setting.save')}
        />
      ),
      type: "big"
    });
  };

  openDownloadFileHeaderItemModal = (formGroupId) => {
    const { t, modalStore, commonStore: { downloadFileHeaderItemStore } } = this.props;
    downloadFileHeaderItemStore.init(formGroupId, "").then(() => {
      modalStore.show({
        id: MODAL_ID.SPECIFYING_FORM_SETTING,
        isOpen: true,
        header: `${t('definition.download_file_header_item.page_title')}`,
        onCancel: this.closeModal,
        children: (
          <ScreenDownloadFileHeaderItem
            formGroupId={formGroupId}
            configfilesListsId=""
          />
        ),
        type: "big"
      });
    });
  };

  downloadConfigFilesList = (formGroupId) => {
    this.props.commonStore.definitionStore.downloadConfigFilesList(formGroupId);
  }

  render() {
    const { t, commonStore: { formGroupStore: { listGroupDefinitions, paging } } } = this.props;
    const columns = [
      {
        Header: t("definition.form_group.no"),
        disableSortBy: true,
        width: TABLE_ROW_WIDTH_FORM_GROUP.NO,
        Cell: ({ initialState, row }) => <div style={{ textAlign: "center" }}>{
          initialState?.initialTableState?.pageSize *
          initialState?.initialTableState?.pageIndex +
          row.index +
          1
        }</div>
        ,
      },
      {
        Header: t('definition.form_group.edition'),
        accessor: 'edition',
        Cell: ({ row: { original } }) => {
          let edition = "";
          if (original?.edition == CONFIG_FILE_EDITION.ITEM) {
            edition = t("definition.form_group.edition.item");
          } else if (original?.edition == CONFIG_FILE_EDITION.PREBUILT) {
            edition = t("definition.form_group.edition.prebuilt");
          } else {
            edition = t("definition.form_group.edition.manual");
          }
          return (
            <div>
              {edition}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_FORM_GROUP.EDITION
      },
      {
        Header: t('definition.form_group.form_group_name'),
        accessor: 'formGroupName',
        Cell: ({ row: { original, original: { id } } }) => {
          return (
            <div className="color-theme cursor-p"
              onClick={() => this.openFormGroupSettingsModal(id, original)}>
              {original.groupName ? original.groupName : EMPTY_FORMAT_CELL}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_FORM_GROUP.FORM_GROUP_NAME
      },
      {
        Header: t('definition.form_group.form_setting_name'),
        accessor: 'formSettingName',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.formSettingName ? original.formSettingName : EMPTY_FORMAT_CELL}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_FORM_GROUP.FORM_SETTING_NAME
      },
      {
        Header: t('definition.form_group.teams_name'),
        accessor: 'teamsName',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {
                0 < original.teams.length ?
                  original.teams.map((team) => {
                    return <div>{team.name}</div>
                  })
                  : <div>{t('definition.form_group.teams_all')}</div>
              }
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_FORM_GROUP.TEAMS_NAME
      },
      {
        Header: t('definition.form_group.created_date'),
        accessor: 'createdAt',
        Cell: ({ row: { original } }) => {
          return (
            <div className='space-new-line'>
              {original.createdAt ? moment(original.createdAt).format(DATE_FORMAT.full_time) : NO_DATE}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_FORM_GROUP.CREATED_DATE
      },
      {
        Header: t('definition.form_group.updated_date'),
        accessor: 'updatedAt',
        Cell: ({ row: { original } }) => {
          return (
            <div className='space-new-line'>
              {original.updatedAt ? moment(original.updatedAt).format(DATE_FORMAT.full_time) : NO_DATE}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_FORM_GROUP.UPDATED_DATE
      },
      {
        id: 'submenu',
        Cell: ({ row: { original: { id } } }) => {
          return <div>
            <div class="dropdown menu">
              <a data-target="#" href="#"
                data-toggle="dropdown"
                data-boundary="viewport"
                aria-haspopup="true"
                aria-expanded="false">
                <IconFa type="threedots" />
              </a>

              <ul class="dropdown-menu dropdown-menu-right" role="menu">

                {/* ダウンロードファイル出力情報編集 */}
                <li role="dropdown-item">
                  <a href="javascript:void(0)" onClick={() => this.openDownloadFileHeaderItemModal(id)}>
                    {t('definition.form_group.download_file_Header_item')}
                  </a>
                </li>

                {/* 帳票定義ダウンロード */}
                <li role="dropdown-item">
                  <a href="javascript:void(0)" onClick={() => this.downloadConfigFilesList(id)}>
                    {t('definition.form_group.download_config_files_list')}
                  </a>
                </li>

                {/* 削除 */}
                <li role="dropdown-item">
                  <a href="javascript:void(0)" onClick={() => this.openComfirmDeleteModal(id)}>
                    {t('definition.form_group.remove')}
                  </a>
                </li>

              </ul>

            </div>
          </div>
        },
        width: TABLE_ROW_WIDTH_FORM_GROUP.SUB_MENU
      }
    ];
    return (
      <div className="screen-form-group">
        <ContainerBox headerTitle={`${t('definition.form_group.form_group_management')}`} className="admin-body-container table-container">
          <div className="box-body document-item-info">
            <button
              className="form-group-man-btn-style btn btn-primary f-r mb-3 btn-form-group-man"
              type="button"
              onClick={this.handleBack}
            >
              {t("definition.form_group.back")}
            </button>
            <Table
              columns={columns}
              data={listGroupDefinitions}
              disablePaging={false}
              onFetch={this.onFetch}
              enableServerSidePaging={true}
              initialTableState={paging}
            />
          </div>
        </ContainerBox>
      </div>
    );
  }
}

export default withRouter(withTranslation()(ScreenFormGroup));
