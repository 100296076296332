import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { ModalFooter, Table } from "components";
import { Scrollbars } from "react-custom-scrollbars";
import { FORM_GROUP_SELECT_TEAMS_MODE, DOCUMENT_DOWNLOAD_SETTING, CONFIG_FILE_EDITION } from '../../core/utils/constants';
import { Validate } from "core/utils";
import "./style.scss";

const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
const TEAM_TABLE_ROW_WIDTH_MODEL = {
  CHECKBOX: isIE ? "5%" : "6%",
  NO: isIE ? "5%" : "6%",
  TEAM_NAME: "88%",
};

@inject("modalStore", "commonStore")
@observer
class FormGroupSetting extends Component {
  constructor(props) {
    super(props);
    this.listAllTeamsItems = [];
    this.listCheckedItems = [];
    this.state = {
      selectedFormSettings: this.props.formGroupSetting.formSettingId ? this.props.formGroupSetting.formSettingId : "-1",
      selectTeamsMode: 0 < this.props.formGroupSetting?.teams?.length ? FORM_GROUP_SELECT_TEAMS_MODE.INDIVIDUAL_TEAMS : FORM_GROUP_SELECT_TEAMS_MODE.ALL_TEAMS,
      documentDownloadSetting: this.props.formGroupSetting.documentDownloadSetting ? this.props.formGroupSetting.documentDownloadSetting : 0,
      downloadFolderName: this.props.formGroupSetting.downloadFolderName ? this.props.formGroupSetting.downloadFolderName : "<date><time>",
      downloadFileName: this.props.formGroupSetting.downloadFileName ? this.props.formGroupSetting.downloadFileName : "<date><time>",
      isChanged: false,
    };
    for (let i = 0; i < this.props.formGroupSetting.teams.length; i++) {
      this.listCheckedItems.push(this.props.formGroupSetting.teams[i].id);
    }
    for (let i = 0; i < this.props.teamsAll.length; i++) {
      this.listAllTeamsItems.push(this.props.teamsAll[i].id);
    }
  }

  onChange = (value) => {
    this.setState({
      selectedFormSettings: value,
      isChanged: true,
    });
  };

  onSave = (forceCreate = false) => {
    const { selectedFormSettings } = this.state;
    const { commonStore: { definitionStore },
      formGroupId, formGroupSetting } = this.props;

    if (formGroupSetting.edition == "Item") {
      let payload = {
        form_group_id: formGroupId,
        form_setting_id: selectedFormSettings == "-1" ? null : selectedFormSettings
      }
      definitionStore.specifyingFormSettings(
        payload, this.updateFormGroupSetting
      );
    } else {
      this.updateFormGroupSetting();
    }
  };

  updateFormGroupSetting = () => {
    const { selectTeamsMode, documentDownloadSetting, downloadFolderName, downloadFileName } = this.state;
    const { commonStore: { definitionStore, formGroupStore },
      formGroupId, reloadParent } = this.props;

    let teamsList = [];
    let notExistsTeamsIds = this.listAllTeamsItems.filter(i => this.listCheckedItems.indexOf(i) == -1);
    if (selectTeamsMode == FORM_GROUP_SELECT_TEAMS_MODE.INDIVIDUAL_TEAMS &&
      0 < notExistsTeamsIds.length) {
      for (let i = 0; i < this.listCheckedItems.length; i++) {
        teamsList.push(this.listCheckedItems[i]);
      }
    }

    let payloadSFormGroupSetting = {
      formGroupId: formGroupId,
      selectTeamsMode: selectTeamsMode,
      teamsIdList: teamsList,
      documentDownloadSetting: documentDownloadSetting,
      downloadFolderName: downloadFolderName,
      downloadFileName: downloadFileName,
    }
    formGroupStore.updateFormGroupSetting(
      payloadSFormGroupSetting,
      () => {
        definitionStore.clean();
        definitionStore.getDefinitions();
        reloadParent();
        this.closeAllModal();
      }
    );
  }

  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hide();
  };

  closeAllModal = () => {
    const { modalStore } = this.props;
    modalStore.hideAll();
  };

  openComfirmModal = () => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: "confirm",
      isOpen: true,
      header: t("definition.form_group.setting.page_title"),
      onCancel: () => this.closeModal("confirm"),
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>
              {t("definition.form_group.setting.confirm_save")}
            </span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t("definition.form_group.setting.save")}
            onConfirm={() => {
              this.closeModal();
              this.onSave();
            }}
            cancelButtonText={t("definition.form_group.setting.cancel")}
            onCancel={this.closeModal}
          />
        </div>
      ),
      type: 'big'
    });
  };

  onChangeSelectTeam = (event) => {
    const { teamsAll } = this.props;
    let value = event.target.value;

    for (let i = 0; i < teamsAll.length; i++) {
      let checkboxElm = document.getElementById(teamsAll[i].id.toFixed());
      if (value == FORM_GROUP_SELECT_TEAMS_MODE.ALL_TEAMS) {
        checkboxElm.disabled = true;
      } else {
        checkboxElm.disabled = false;
      }
    }

    this.setState({
      selectTeamsMode: value,
      isChanged: true,
    });
  }

  onChangeDocumentDownloadSetting = (value) => {
    this.setState({
      documentDownloadSetting: value,
      isChanged: true,
    });
  }

  onChangeDownloadFolderName = (value) => {
    this.setState({
      downloadFolderName: value,
      isChanged: true,
    });
  }

  onChangeDownloadFileName = (value) => {
    this.setState({
      downloadFileName: value,
      isChanged: true,
    });
  }

  handleRowSelected = (e) => {
    let element = e.target.value;
    if (element) {
      element = JSON.parse(element);
      if (e.target.checked) {
        this.addItemToList(element);
      } else {
        this.removeItemFromList(element);
      }
    }
  };

  addItemToList = (element) => {
    if (this.listCheckedItems.indexOf(element.id) < 0) {
      this.listCheckedItems.push(element.id);
    }
    this.setState({ isChanged: true });
  }

  removeItemFromList(element) {
    var index = this.listCheckedItems.indexOf(element.id);
    if (index !== -1) {
      this.listCheckedItems.splice(index, 1);
    }
    this.setState({ isChanged: true });
  }

  isChecked = (id) => {
    const { isChanged } = this.state;
    const { formGroupSetting, teamsAll } = this.props;

    if (!isChanged && formGroupSetting.teams.length == 0) {
      for (let i = 0; i < teamsAll.length; i++) {
        if (this.listCheckedItems.indexOf(teamsAll[i].id) < 0) {
          this.listCheckedItems.push(teamsAll[i].id);
        }
      }
      return true;
    }

    var index = this.listCheckedItems.indexOf(id);
    if (index !== -1) {
      return true;
    }
    return false;
  }

  columns = () => {
    const { selectTeamsMode } = this.state;
    const { t } = this.props;
    return [
      {
        id: "selection_teams",
        disableSortBy: true,
        Cell: ({ row }) => {
          let isChecked = this.isChecked(row.original.id);
          return (
            <div>
              <input
                id={row.original.id}
                type="checkbox"
                value={JSON.stringify(row?.original)}
                onChange={this.handleRowSelected}
                checked={isChecked}
                disabled={selectTeamsMode == FORM_GROUP_SELECT_TEAMS_MODE.ALL_TEAMS ? true : false}
              />
            </div>
          );
        },
        width: TEAM_TABLE_ROW_WIDTH_MODEL.CHECKBOX
      },
      {
        Header: t("definition.form_group.setting.teams.no"),
        disableSortBy: true,
        Cell: ({ initialState, row }) => <div style={{ textAlign: "center" }}>{
          row.index + 1
        }</div>,
        width: TEAM_TABLE_ROW_WIDTH_MODEL.NO
      },
      {
        Header: t('definition.form_group.setting.teams.name'),
        disableSortBy: true,
        accessor: 'teams_name',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.name}
            </div>
          );
        },
        width: TEAM_TABLE_ROW_WIDTH_MODEL.TEAM_NAME
      }
    ];
  };

  render() {
    const { t, saveButtonText, formGroupSetting, listFormSettings, teamsAll } = this.props;
    const { selectedFormSettings, documentDownloadSetting, downloadFolderName, downloadFileName } = this.state;
    return (
      <div className="wrap-form-group-setting">
        <Scrollbars key={"modal-body"} className="modal-body">
          <div className="row">
            <div className="col-sm-1 p-0"></div>
            <div className="col-sm-4 font-bold">
              {t("definition.form_group.edition")}
            </div>
            <div className="col-sm-6" style={{ padding: "5px" }}>
              <span style={{ color: "#555", wordBreak: "break-word" }}>
                {
                  formGroupSetting.edition == CONFIG_FILE_EDITION.MANUAL
                    ? CONFIG_FILE_EDITION.MANUAL + " / " + CONFIG_FILE_EDITION.RULE
                    : formGroupSetting.edition
                }
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1 p-0"></div>
            <div className="col-sm-4 font-bold">
              {t("definition.form_group.form_group_name")}
            </div>
            <div className="col-sm-6" style={{ padding: "5px" }}>
              <span style={{ color: "#555", wordBreak: "break-word" }}>{formGroupSetting.groupName}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1 p-0"></div>
            <div className="col-sm-4 font-bold">
              {t("form_setting.specifying_form_settings.form_name")}
            </div>
            <div className="col-sm-6" style={{ padding: "5px" }}>
              <select
                className="slc-setting-list"
                value={selectedFormSettings}
                onChange={(e) => this.onChange(e.target?.value)}
                disabled={formGroupSetting.edition !== "Item"}
              >
                <option value="-1">{t("form_setting.not_specified")}</option>
                {
                  (listFormSettings && listFormSettings.length > 0) &&
                  listFormSettings?.map(e => <option key={e.id} value={e.id}>{e.settingName}</option>)
                }
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1 p-0"></div>
            <div className="col-sm-4 font-bold">
              {t("definition.form_group.setting.download")}
            </div>
            <div className="col-sm-6" style={{ padding: "5px" }}>
              <select
                className="slc-setting-list"
                value={documentDownloadSetting}
                onChange={(e) => this.onChangeDocumentDownloadSetting(e.target?.value)}
                defaultValue={DOCUMENT_DOWNLOAD_SETTING.GLOBAL}
              >
                <option value={DOCUMENT_DOWNLOAD_SETTING.GLOBAL}>{t("definition.form_group.setting.download.global")}</option>
                <option value={DOCUMENT_DOWNLOAD_SETTING.INDIVIDUAL}>{t("definition.form_group.setting.download.individual")}</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1 p-0"></div>
            <div className="col-sm-4 font-bold">
              {" "}
            </div>
            <div className="col-sm-6" style={{ padding: "5px" }}>
              <div className="col-sm-3 font-bold" style={{ padding: "0px" }}>
                {t("definition.form_group.setting.download.foldername")}
              </div>
              <div className="col-sm-9" style={{ padding: "0px" }}>
                <input style={{ width: "100%" }} type="text" maxLength="255"
                  value={downloadFolderName}
                  disabled={documentDownloadSetting == DOCUMENT_DOWNLOAD_SETTING.INDIVIDUAL ? false : true}
                  onChange={(value) => this.onChangeDownloadFolderName(value.target.value)} >
                </input>
                {Validate.validateDownloadFolderName(downloadFolderName, t) !== ""
                  && <div className="err-msg">{Validate.validateDownloadFolderName(downloadFolderName, t)}</div>
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1 p-0"></div>
            <div className="col-sm-4 font-bold">
              {" "}
            </div>
            <div className="col-sm-6" style={{ padding: "5px" }}>
              <div className="col-sm-3 font-bold" style={{ padding: "0px" }}>
                {t("definition.form_group.setting.download.filename")}
              </div>
              <div className="col-sm-9" style={{ padding: "0px" }}>
                <input style={{ width: "100%" }} type="text" maxLength="255"
                  value={downloadFileName}
                  disabled={documentDownloadSetting == DOCUMENT_DOWNLOAD_SETTING.INDIVIDUAL ? false : true}
                  onChange={(value) => this.onChangeDownloadFileName(value.target.value)} >
                </input>
                {Validate.validateDownloadFileName(downloadFileName, t) !== ""
                  && <div className="err-msg">{Validate.validateDownloadFileName(downloadFileName, t)}</div>
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1 p-0"></div>
            <div className="col-sm-4 font-bold">
              {t("definition.form_group.teams_name")}
            </div>
            <div className="col-sm-6" style={{ padding: "5px" }}>
              <select
                className="slc-setting-list"
                onChange={(e) => this.onChangeSelectTeam(e)}
                defaultValue={0 < formGroupSetting?.teams?.length ?
                  FORM_GROUP_SELECT_TEAMS_MODE.INDIVIDUAL_TEAMS :
                  FORM_GROUP_SELECT_TEAMS_MODE.ALL_TEAMS}
              >
                <option value={FORM_GROUP_SELECT_TEAMS_MODE.ALL_TEAMS}>
                  {t("definition.form_group.setting.teams.all")}
                </option>
                <option value={FORM_GROUP_SELECT_TEAMS_MODE.INDIVIDUAL_TEAMS}>
                  {t("definition.form_group.setting.teams.individual")}
                </option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1 p-0"></div>
            <div className="col-sm-4 font-bold">
              {" "}
            </div>
            <div className="col-sm-6" style={{ padding: "5px" }}>
              {<Table
                columns={this.columns()}
                data={teamsAll}
                enableSelectRow={true}
                disableSelectionPageSize={true}
                disablePaging={true}
                t={t}
                enableServerSidePaging={false}
                hideTableFooter={true}
                className="uploading-table"
              />}
              {teamsAll.length === 0 &&
                <div className="table-no-data">
                  <p>{t("table_no_data")}</p>
                </div>
              }
            </div>
          </div>
        </Scrollbars >
        <ModalFooter key={"modal-footer"}
          saveButtonText={saveButtonText}
          onConfirm={this.onSave}
          cancelButtonText={t('definition.form_group.setting.cancel')}
          onCancel={this.closeAllModal}
          saveButtonClass="btn-primary"
        />
      </div >
    );
  }
}

export default withTranslation()(FormGroupSetting);
