import React from "react";
import { withTranslation } from "react-i18next";

import "./style.scss";

class PagingInfo extends React.Component {
  render() {
    const { tableState, t } = this.props;
    const from = tableState?.pageIndex * tableState?.pageSize;
    let to = from + tableState?.pageSize;
    if (to > tableState?.totalRecord){
      to = tableState?.totalRecord;
    }
    
    return (
      <span className="paging-info">
        {t("pagging.show_record", {
          fromIndex: from + 1,
          toIndex: to,
          dataLength: tableState?.totalRecord,
        })}
      </span>
    );
  }
}

export default withTranslation()(PagingInfo);
