import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { InputCheckbox } from "components";
import { DATE_FORMAT, ACCESSOR_SEARCH_BOX } from "../../core/utils/constants";
import { Helper } from "../../core/utils";
import "./style.scss";

@inject("commonStore")
@observer
class SearchBox extends Component {

  constructor(props) {
    super(props);
    this.state = {
      registeredDate: moment().format(DATE_FORMAT.frontend_minus),
      toDate: moment().format(DATE_FORMAT.frontend_minus)
    };
  }

  onChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleKeyPressForNumber = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^\d*$/.test(keyValue)) {
      event.preventDefault();
    }
  }

  handleChangeYear = (e) => {
    const { commonStore: { tenantStore: { setYear } } } = this.props;
    const re = /^\d*$/;
    const val = e.currentTarget.value;
    if (re.test(val) && (val === "" || parseInt(val) <= 9999)) {
      setYear(val)
    }
  }

  render() {
    const {
      t,
      commonStore: {
        tenantStore: { year, month, active, setMonth, setActive }
      }
    } = this.props;
    return <div className="admin-header row tenant-search-pannel">
      <div className="col-sm-12" style={{height: "24px", lineHeight: "24px", display: "flex", padding: 0}}>
        <label>{t("tenant.month_year")}</label>
        <input className="input-number" type="number"
          max="9999"
          value={year}
          onKeyPress={this.handleKeyPressForNumber}
          onChange={(e) => { this.handleChangeYear(e) }} />
        <label>{t("tenant.year")}</label>
        <select className="slct-month" onChange={(e) => setMonth(e.currentTarget.value)} value={month}>
          {Helper.getMonths().map(e => <option key={e} value={e}>{e}</option>)}
        </select>
        <label>{t("tenant.month")}</label>
        <InputCheckbox
          id={ACCESSOR_SEARCH_BOX.STATUS_PROCESSING}
          type="checkbox"
          label={t("tenant.checkbox_active")}
          onChange={(e) => setActive(e.currentTarget.checked ? 'true' : 'false')}
          checked={active === 'true'}
        />
      </div>
    </div>
  }
}

export default withTranslation()(SearchBox);
