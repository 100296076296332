import React, { Component } from 'react'
import { MdRotateRight, MdRotateLeft } from "react-icons/md"
import { AiOutlinePlus, AiOutlineMinus, AiOutlineCompress, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { ScaleLoader } from "react-spinners";
import { MAX_ZOOM_LEVEL, MIN_ZOOM_LEVEL, ZOOM_STEP } from '../../core/utils/constants';
import "./style.scss";
import { pdfjs, Document, Page } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const LoadingIcon = <ScaleLoader color={"#3c8dbc"} className="pdf-loading"></ScaleLoader>;
class MyPdfViewer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      scale: 1,
      rotate: 0,
      page: 1,
      pdfViewerWidth: null,
      pdfViewerHeight: null
    };
  }

  onUpdateHeight(height) {
    this.setState({ pdfViewerHeight: height });
  }

  onUpdateWidth(width) {
    this.setState({ pdfViewerWidth: width });
  }

  onUpdateContent(data) {
    if (data) this.setState({ data: data });
    document.getElementById("highlight-div").style.display = "none";
  }

  onLoadSuccess = () => {
    setTimeout(() => {
      document.getElementById("highlight-div").style.display = "block";
    }, 200);
  }

  onZoomIn = () => {
    const updatedScale = this.state.scale + ZOOM_STEP;
    if (this.state.scale < MAX_ZOOM_LEVEL) {
      this.setState({ scale: updatedScale });
      this.props.onUpdateUserScale(updatedScale);
      this.props.drawHighLightElm(updatedScale);
    }
  }

  onSetZoom = (zoomValue) => {
    if (zoomValue <= MAX_ZOOM_LEVEL && MIN_ZOOM_LEVEL <= zoomValue) this.setState({ scale: zoomValue });
  }

  onZoomOut = () => {
    const updatedScale = this.state.scale - ZOOM_STEP;
    if (this.state.scale > MIN_ZOOM_LEVEL) {
      this.setState({ scale: updatedScale });
      this.props.onUpdateUserScale(updatedScale);
      this.props.drawHighLightElm(updatedScale);
    }
  }

  onZoomReset = () => {
    this.setState({ scale: 1 });
    this.props.onUpdateUserScale(1);
    this.props.drawHighLightElm(1);
  }

  onRotateLeft = () => {
    this.setState({ rotate: (this.state.rotate + (360 - 90)) % 360 });
    this.props.onUpdateUserRotate((this.state.rotate + (360 - 90)) % 360);
    this.props.drawHighLightElm(this.state.scale);
  }

  onRotateRight = () => {
    this.setState({ rotate: (this.state.rotate + 90) % 360 });
    this.props.onUpdateUserRotate((this.state.rotate + 90) % 360);
    this.props.drawHighLightElm(this.state.scale);
  }

  onRotateReset = () => {
    this.setState({ rotate: 0 });
    this.props.onUpdateUserRotate(0);
    // this.props.drawHighLightElm(this.state.scale);
  }

  onNextPage = () => {
    const { page } = this.state;
    const { totalPage } = this.props;
    if (page < totalPage) {
      const newPage = this.state.page + 1;
      this.setState({ page: newPage });
      this.props.onChangePage(newPage);
    }
  }

  onPrevPage = () => {
    const { page } = this.state;
    const { totalPage } = this.props;
    if (page > 1) {
      const newPage = this.state.page - 1;
      this.setState({ page: newPage });
      this.props.onChangePage(newPage);
    }
  }

  onMouseOver = () => {
    const controlWrap = document.getElementsByClassName("control-wrapper")[0];
    controlWrap.style.display = "flex";
  }

  onMouseOut = () => {
    const controlWrap = document.getElementsByClassName("control-wrapper")[0];
    controlWrap.style.display = "none";
  }

  resetPage = () => {
    this.setState({ page: 1 });
  }

  render() {
    const { scale, rotate, page, pdfViewerWidth, pdfViewerHeight } = this.state;
    const { totalPage } = this.props;
    let width = this.state.rotate === 90 || this.state.rotate === 270 ? pdfViewerHeight : pdfViewerWidth
    return this.state.data ? <div className="my-pdf-viewer-wrapper" onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>
      <div className="control-wrapper">
        <AiOutlinePlus onClick={this.onZoomIn} />
        <AiOutlineCompress onClick={this.onZoomReset} />
        <AiOutlineMinus onClick={this.onZoomOut} />
        <MdRotateLeft onClick={this.onRotateLeft} />
        <MdRotateRight onClick={this.onRotateRight} />
      </div>
      <div className="paging-wrapper">
        <AiOutlineLeft onClick={this.onPrevPage} />
        <div className="page-info">{page}/{totalPage}</div>
        <AiOutlineRight onClick={this.onNextPage} />
      </div>
      <div className="pdf-content">
        <div id="highlight-div"></div>
        <div
          className="base-doc">
          <Document
            file={this.props.initalData}
            // file={null}
            loading={LoadingIcon}
          >
            {/* {Array.from(Array(10).keys()).map(e => { */}
            <Page loading={LoadingIcon} pageNumber={page} width={width} scale={scale} rotate={rotate} />
            {/* <Page loading={LoadingIcon} pageNumber={page} scale={scale} rotate={rotate} /> */}
            {/* })} */}
            {/* <Page loading={LoadingIcon} pageNumber={1} scale={scale} rotate={rotate} />
          <Page loading={LoadingIcon} pageNumber={2} scale={scale} rotate={rotate} /> */}
          </Document>
        </div>
      </div>
    </div> : <div className="my-pdf-viewer-wrapper">
      <div className="base-doc" style={{ height: "100%", paddingTop: "35vh" }}>{LoadingIcon}</div>
    </div>
  }
}

export default MyPdfViewer;