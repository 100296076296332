import { ApiService } from "core/services";

class AuthApi extends ApiService {

  logon(payload) {
    return this.post("logon", payload);
  }

  logoff() {
    return this.post("logoff");
  }

  getSystemStatus() {
    return this.get("system-status");
  }

  changePassword(payload) {
    return this.put("change-password", payload);
  }

  
}

export default AuthApi;
