import { action, observable, runInAction, configure } from "mobx";
import { ActionLogsAPI } from "apis";
import ApiStore from "./ApiStore";
import { DATE_FORMAT } from "../core/utils/constants";
import moment from "moment";
import BaseStore from "./BaseStore";

configure({ enforceActions: "observed" });
class ActionLogsStore extends BaseStore {
  @observable actionLogs = []; //list all actionLogs
  @observable tempFilterData = {};
  @observable keepFilterState = false;
  @observable currentPage = 1;

  constructor(props) {
    super(props);
    this.api = new ActionLogsAPI();
  }

  @action initial = () => {
    this.currentPage = 1;
  }

  /**
   * getActionLogs
   * get all action logs wihtout paging for tables
   *
   * @param {Object} payload : the filter object to query list action logs
   * @returns  Null update this.actionLogs and re-render the view
   */
  async getActionLogs() {
    const { size, page, sortDir, sortKey } = this.paging;
    let payload = {
      ...this.dataTransformer(this.savedSearchState?.filterData),
      size,
      page,
      sortDir,
      sortKey,
    };
    let response = await ApiStore.call(
      this.api,
      this.api.getActionLogs,
      payload
    );
    this.setTempSearchBoxFiler(this.savedSearchState?.filterData);
    if (response) {
      runInAction(() => {
        this.updatePagingFiler(response.paging);
        this.actionLogs = this.actionLogTransformer(response.elements);
        // save current actionLogs
        localStorage.setItem("actionLogs", JSON.stringify(this.actionLogs));
        if (this.paging?.totalRecord && this.paging?.totalRecord !== 0) {
          if (this.paging?.page > 1 && (!response.elements || response.elements.length === 0)) {
            this.updatePagingFiler({ page: this.paging.page - 1 });
            this.getActionLogs();
          }
        }
        this.setKeepFilterState(false);
      });
    }
  }

  /**
   * downloadactionLogs
   * download docuements
   *
   * @param {Array} listCheckedItems  : list target file to download
   * @param {String} currentActionLogType : current actionLog type( for server filter)
   * @returns  boolean
   */
  @action
  async downloadActionLogs(cb = null) {
    const { size, page, sortDir, sortKey } = this.paging;
    let payload = {
      ...this.dataTransformer(this.savedSearchState?.filterData),
      size,
      page,
      sortDir,
      sortKey,
    };
    let response = await ApiStore.call(
      this.api,
      this.api.downloadActionLogs,
      payload
    );
    if (response) {
      let fileName = "report_default";
      if (response?.headers.get("content-disposition")) {
        let disposition = response?.headers.get("content-disposition");
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, "");
        }
      }

      let allData;
      const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
      if (isIE) {
        var dataFile = [];
        await response.arrayBuffer().then(data => dataFile = data);
        allData = new Uint8Array(dataFile);
      } else {
        const reader = response.body.getReader();
        let chunks = [];
        let dataLength = 0;
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          dataLength += value.length;
          chunks.push(value);
        }

        allData = new Uint8Array(dataLength);
        let pos = 0;
        chunks.forEach(element => {
          allData.set(element, pos);
          pos += element.length;
        });
      }

      this.createAndDownloadBlobFile(allData.buffer, fileName);
      this.setTempSearchBoxFiler(this.savedSearchState?.filterData);
      this.getActionLogs();
      cb && cb();
    }
  }

  createAndDownloadBlobFile(body, fileName) {
    const blob = new Blob([body]);
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  @action
  updateSearchBoxCollapse(value) {
    this.savedSearchState = {
      ...this.savedSearchState,
      isCollapseOut: value,
    };
  }

  @action
  dataTransformer(data) {
    let transformedData = { ...data };
    if (transformedData?.fromTime) {
      transformedData.fromTime = moment(transformedData.fromTime).format(DATE_FORMAT.time_stamp);
    }
    if (transformedData?.toTime) {
      transformedData.toTime = moment(transformedData.toTime).format(DATE_FORMAT.time_stamp);
    }
    return transformedData;
  }

  actionLogTransformer(actionLog) {
    if (actionLog) {
      let idx = (this.paging.page - 1) * this.paging.size + 1;
      actionLog = actionLog.map((element) => {
        element.idx = idx++;
        return element;
      });
    }
    return actionLog;
  }

  @action
  applySearchBoxFiler() {
    this.savedSearchState = {
      ...this.savedSearchState,
      filterData: this.tempFilterData,
    };
  }

  @action
  updateTempSearchBoxFiler(value = null) {
    runInAction(() => {
      this.tempFilterData = { ...this.tempFilterData, ...value };
    });
  }

  @action
  setTempSearchBoxFiler(value = null) {
    runInAction(() => {
      this.tempFilterData = { ...value };
    });
  }

  @action
  setKeepFilterState = (value) => {
    this.keepFilterState = value;
  }

  @action
  clean() {
    if (!this.keepFilterState) {
      super.clean();
      this.savedSearchState = {};
    }
  }
}

export default ActionLogsStore;
