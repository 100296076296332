import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { ContainerBox, Table, ModalFooter } from 'components';
import DetailDefinition from './DetailDefinition';
import ScreenSpecifyingFormSettings from './ScreenSpecifyingFormSettings';
import ScreenDownloadFileHeaderItem from './ScreenDownloadFileHeaderItem';
import { MODE, MODAL_ID, DATE_FORMAT, NO_DATE, EMPTY_FORMAT_CELL, CONFIG_FILE_EDITION } from '../../core/utils/constants';
import moment from 'moment';
import BaseComponent from '../BaseComponent';
import './style.scss';
import { IconFa } from "components";

const TABLE_ROW_WIDTH_DEFINITION = {
  NO: "6%",
  EDITION: "10%",
  FORM_GROUP_ID: "14%",
  FORM_ID: "15%",
  CONFIG_NAME: "15%",
  CREATED_BY: "15%",
  CREATED_DATE: "11%",
  UPDATED_DATE: "11%",
  SUB_MENU: "3%",
};

@inject('commonStore', 'modalStore')
@observer
class ScreenDefinition extends BaseComponent {

  executeLogicAfterRender = () => {
    this.props.commonStore.definitionStore.clean();
    this.props.commonStore.definitionStore.getDefinitions();
    this.props.commonStore.formSettingStore.getList();
  }

  executeLogicBeforeOutComponent = () => {
    this.props.commonStore.definitionStore.clean();
  }

  onCancelUpdate = (userId, userStatus) => {
    this.closeModal();
  };

  onConfirmDeleteById = (id) => {
    this.props.commonStore.definitionStore.deleteDefinition(id);
    this.closeModal();
  }

  openComfirmModal = (id) => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.REMOVE,
      isOpen: true,
      header: t('definition.remove_confirm'),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>{t('definition.remove_user')}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t('definition.remove')}
            onConfirm={() => this.onConfirmDeleteById(id)}
            cancelButtonText={t('definition.cancel')}
            onCancel={this.closeModal}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: 'small'
    });
  };

  openModal = (mode) => {
    const { t, modalStore } = this.props;
    let definitionDetail = this.props?.commonStore?.definitionStore?.definition;
    modalStore.show({
      id: MODAL_ID.USER,
      isOpen: true,
      header: `${t('definition.definition_update')}`,
      onCancel: this.closeModal,
      children: (
        <DetailDefinition
          mode={mode}
          definitionDetail={definitionDetail}
          saveButtonText={t('definition.save')}
          onConfirm={this.onSaveUserDetail}
        />
      )
    });
  };

  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hideAll();
  };

  openSpecifyingFormSettingsModal = (formGroupId, formSettingId) => {
    const { t, modalStore } = this.props;
    let listFormSettings = this.props?.commonStore?.formSettingStore?.listFormSettings;
    modalStore.show({
      id: MODAL_ID.SPECIFYING_FORM_SETTING,
      isOpen: true,
      header: `${t('form_setting.specifying_form_settings.page_title')}`,
      onCancel: this.closeModal,
      children: (
        <ScreenSpecifyingFormSettings
          formGroupId={formGroupId}
          formSettingId={formSettingId}
          listFormSettings={listFormSettings}
          saveButtonText={t('definition.save')}
        />
      )
    });
  };

  openDownloadFileHeaderItemModal = (formGroupId, configfilesListsId) => {
    const { t, modalStore, commonStore: { downloadFileHeaderItemStore } } = this.props;
    downloadFileHeaderItemStore.init(formGroupId, configfilesListsId).then(() => {
      modalStore.show({
        id: MODAL_ID.SPECIFYING_FORM_SETTING,
        isOpen: true,
        header: `${t('definition.download_file_header_item.page_title')}`,
        onCancel: this.closeModal,
        children: (
          <ScreenDownloadFileHeaderItem
            formGroupId={formGroupId}
            configfilesListsId={configfilesListsId}
          />
        ),
        type: "big"
      });
    });
  };

  onFetch = (tableData) => {
    this.props.commonStore.definitionStore.updatePagingFiler(tableData);
    this.props.commonStore.definitionStore.getDefinitions();
  };

  handleFormGroupManagement = () => {
    this.props.history.push("/definitions/formgroup/");
  }

  render() {
    const { t, commonStore: { definitionStore: { definitions, paging } } } = this.props;
    const columns = [
      {
        Header: t("definition.no"),
        disableSortBy: true,
        width: TABLE_ROW_WIDTH_DEFINITION.NO,
        Cell: ({ initialState, row }) => <div style={{ textAlign: "center" }}>{
          initialState?.initialTableState?.pageSize *
          initialState?.initialTableState?.pageIndex +
          row.index +
          1
        }</div>
        ,
      },
      {
        Header: t('definition.edition'),
        accessor: 'edition',
        Cell: ({ row: { original } }) => {
          let edition = "";
          if (original?.edition == CONFIG_FILE_EDITION.ITEM) {
            edition = t("definition.edition.item");
          } else if (original?.edition == CONFIG_FILE_EDITION.RULE) {
            edition = t("definition.edition.rule");
          } else if (original?.edition == CONFIG_FILE_EDITION.PREBUILT) {
            edition = t("definition.edition.prebuilt");
          } else {
            edition = t("definition.edition.manual");
          }
          return (
            <div>
              {edition}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_DEFINITION.EDITION
      },
      {
        Header: t('definition.form_group_name'),
        accessor: 'formGroupName',
        Cell: ({ row: { original } }) => {
          return (
            <div className={`${original.edition === "Item" ? "color-theme cursor-p" : ""}`}
              onClick={() => original.edition === "Item" ? this.openSpecifyingFormSettingsModal(original.formGroupId, original.formSettingId) : undefined}>
              {original.formGroupName ? original.formGroupName : EMPTY_FORMAT_CELL}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_DEFINITION.FORM_GROUP_ID
      },
      {
        Header: t('definition.form_id'),
        accessor: 'formId',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.formId}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_DEFINITION.FORM_ID
      },

      {
        Header: t('definition.config_name'),
        id: 'configName',
        accessor: 'configName',
        Cell: ({ row: { original } }) => {
          return (
            <div
              className="color-theme cursor-p"
              onClick={() => this.props.commonStore.definitionStore.showEditModal(original.id, () => this.openModal(MODE.EDIT))}
            >
              {original.configName}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_DEFINITION.CONFIG_NAME
      },

      {
        Header: t('definition.created_by'),
        accessor: 'userId',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.userId}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_DEFINITION.CREATED_BY
      },
      {
        Header: t('definition.created_date'),
        accessor: 'createdAt',
        Cell: ({ row: { original } }) => {
          return (
            <div className='space-new-line'>
              {original.createdAt ? moment(original.createdAt).format(DATE_FORMAT.full_time) : NO_DATE}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_DEFINITION.CREATED_DATE
      },
      {
        Header: t('definition.updated_date'),
        accessor: 'updatedAt',
        Cell: ({ row: { original } }) => {
          return (
            <div className='space-new-line'>
              {original.updatedAt ? moment(original.updatedAt).format(DATE_FORMAT.full_time) : NO_DATE}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_DEFINITION.UPDATED_DATE
      },
      {
        id: 'submenu',
        Cell: ({ row: { original: { id, formGroupId } } }) => {
          return <div>
            <div class="dropdown menu">
              <a data-target="#" href="#"
                data-toggle="dropdown"
                data-boundary="viewport"
                aria-haspopup="true"
                aria-expanded="false">
                <IconFa type="threedots" />
              </a>

              <ul class="dropdown-menu dropdown-menu-right" role="menu">

                {/* ダウンロードファイル出力情報編集 */}
                <li role="dropdown-item">
                  <a href="javascript:void(0)" onClick={() => this.openDownloadFileHeaderItemModal(formGroupId, id)}>
                    {t('definition.download_file_Header_item')}
                  </a>
                </li>

                {/* 削除 */}
                <li role="dropdown-item">
                  <a href="javascript:void(0)" onClick={() => this.openComfirmModal(id)}>
                    {t('definition.remove')}
                  </a>
                </li>

              </ul>

            </div>
          </div>
        },
        width: TABLE_ROW_WIDTH_DEFINITION.SUB_MENU
      }
    ];
    return (
      <div className="screen-definition">
        <ContainerBox headerTitle={`${t('definition.definition_management')}`} className="admin-body-container table-container">
          <div className="box-body document-item-info">
            <button
              className="form-group-man-btn-style btn btn-primary f-r mb-3 btn-form-group-man"
              type="button"
              onClick={this.handleFormGroupManagement}
            >
              {t("definition.form_group_management")}
            </button>
            <Table
              columns={columns}
              data={definitions}
              disablePaging={false}
              onFetch={this.onFetch}
              enableServerSidePaging={true}
              initialTableState={paging}
            />
          </div>
        </ContainerBox>
      </div>
    );
  }
}

export default withRouter(withTranslation()(ScreenDefinition));
