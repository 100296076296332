import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import { DATE_FORMAT } from '../../core/utils/constants';
import jp from "date-fns/locale/ja";
import en from "date-fns/locale/en-US";
registerLocale("jp", jp);
registerLocale("el", en);
require('react-datepicker/dist/react-datepicker.css')

class DateComponent extends Component {

  onChange = (name, value) => {
    const { onChange } = this.props;
    onChange && onChange(name, value);
  };

  render() {
    const { name, value, maxDate, className, i18n } = this.props;
    return <DatePicker
      placeholderText={this.props.placeholder}
      dateFormat={DATE_FORMAT.frontend_minus_4_d_p}
      selected={value}
      value={value}
      popperPlacement={"bottom-end"}
      onChange={(e) => this.onChange(name, e)}
      maxDate={maxDate}
      className={className}
      isClearable
      locale= {i18n.language == 'jp' ? 'jp' : 'en'}
    />
  }
}

export default withTranslation()(DateComponent);
