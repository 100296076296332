import { action, observable, runInAction, configure } from "mobx";
import { CommonApi } from "apis";
import ApiStore from "./ApiStore";
import BaseStore from "./BaseStore";

configure({ enforceActions: "observed" });

class SettingStore extends BaseStore {
  @observable setting = null;
  @observable ipadresses = [];

  constructor(props) {
    super(props);
    this.api = new CommonApi();
  }

  getSetting = async () => {
    let data = await ApiStore.call(this.api, this.api.getCommonSetting);
    if (data) {
      runInAction(() => {
        this.setting = data;
      })
      return data;
    }
  }
  generateKey = async () => {
    let data = await ApiStore.call(this.api, this.api.generateKey);
    if (data) {
      runInAction(() => {
        this.setting = { ...this.setting, ...{ apiToken: data.apiToken } };
      })
      return data;
    }
  }

  setSetting = (payload) => {
    ApiStore.call(this.api, this.api.updateCommonSetting, payload);
    runInAction(() => {
      this.setting = payload;
    });
  }

  @action
  async getIpAddresses(cb) {
    const { size, page, sortDir, sortKey } = this.paging;
    let payload = { size, page, sortDir, sortKey };
    let response = await ApiStore.call(this.api, this.api.getIpAddressAll, payload);
    if (response) {
      runInAction(() => {
        this.updatePagingFiler(response.paging);
        this.ipadresses = response.elements;
        if (this.paging?.totalRecord && this.paging?.totalRecord !== 0) {
          if (this.paging?.page > 1 && (!response.elements || response.elements.length === 0)) {
            this.updatePagingFiler({ page: this.paging.page - 1 });
            this.getIpAddresses();
          }
        }
        cb && cb(response.elements);
      })
    }
  }

  @action
  async addIpAddress(payload, cb) {
    let response = await ApiStore.call(this.api, this.api.addIpAddress, payload);
    if (response) {
      runInAction(() => {
        this.getIpAddresses(cb);
      })
    }
  }

  @action
  async updateIpAddress(payload, cb) {
    let resp = await ApiStore.call(this.api, this.api.updateIpAddress, payload);
    if (resp) {
      runInAction(() => {
        this.getIpAddresses(cb);
      });
    }
  }

  @action
  async deleteIpaddress(id, cb) {
    let resp = await ApiStore.call(this.api, this.api.deleteIpaddress, id);
    if (resp) {
      runInAction(() => {
        this.getIpAddresses(cb);
      })
    }
  }

  @action
  cleanIpaddress() {
    if (!this.keepFilterState) {
      console.log("SettingStore -> clean -> this.keepFilterState", this.keepFilterState)
      super.clean();
    }
  }

  @action
  async saveIpAddress(deletedIpAddresses, restrictionIpAddress, cb) {
    let payload = {
      ipaddresses: [], 
    };
    for (const delElem of deletedIpAddresses) {
      payload.ipaddresses.push(
        {
          "id": delElem.id,
          "ipAddress": delElem.ip_address,
          "comment": delElem.comment,
          "status": 3,
        }
      );
    }

    for (const elem of restrictionIpAddress) {
      if (elem.status && (elem.status == 1)) {
        payload.ipaddresses.push(
          {
            "ipAddress": elem.ip_address,
            "comment": elem.comment,
            "status": elem.status,
          }
        );
      } else if (elem.status && (elem.status == 2)) {
        payload.ipaddresses.push(
          {
            "id": elem.id,
            "ipAddress": elem.ip_address,
            "comment": elem.comment,
            "status": elem.status,
          }
        );
      }
    }

    if (0 < payload.ipaddresses.length) {
      let response = await ApiStore.call(this.api, this.api.saveIpAddresses, payload);
      if (response) {
        this.ipadresses = [];
        this.cleanIpaddress();
        runInAction(() => {
          this.getIpAddresses(cb);
        })
      }
    } else {
      this.ipadresses = [];
      this.cleanIpaddress();
      this.getIpAddresses(cb);
    }
  }

}
export default SettingStore;
