import { action, observable, runInAction, configure, toJS } from "mobx";
import { DocumentsAPI, CommonApi, FileApi, TemplateSortingApi, FormSettingApi } from "apis";
import ApiStore from "./ApiStore";
import { ReactNotifications } from "core/logic";
import i18n from "../i18n";
import { SYSTEM_PATH, DATE_FORMAT, MAX_FILE_UPLOAD, CONFIG_FILE_EDITION, PREBUILT_MODELS, PREBUILT_COLUMN_ORDER_INVOICE, PREBUILT_COLUMN_ORDER_RECEIPT, PREBUILT_TABLE_ORDER } from "../core/utils/constants";
import moment from "moment";
import CONFIG from "../core/services/config";
import BaseStore from "./BaseStore";

configure({ enforceActions: "observed" });
class DocumentsStore extends BaseStore {
  @observable documents = []; //list all documents
  @observable currentDocument = {}; //current document
  @observable listAllDefinitions = []; //list all defineition of documents
  @observable listAllDefinitionsCache = []; //list all defineition of documents in cache
  @observable currentUploadFile = null; //current upload file
  @observable tempFilterData = {};
  @observable uploadingFiles = [];
  @observable highlights = [];
  @observable fileIndex = 0;
  @observable documentWidth = 0;
  @observable documentHeight = 0;
  @observable pdfBytes = null;
  @observable highlightedItem = null;
  @observable keepFilterState = false;
  @observable sortDefinition = 0;
  @observable currentPage = 1;
  @observable currentOcrItems = [];
  @observable listGroupDefinitions = [];
  @observable selectedDefinitionId = -1;
  @observable selectedGroupId = -1;
  @observable configFileEdition = null;
  @observable tabTitle = {};
  @observable dataHeaderDetail = {};
  @observable lastDataWhenDelete = {};
  @observable selectedTeamId = null;
  @observable listTemplateSortings = [];
  @observable selectedTemplateSortingId = -1;
  @observable selectedPrebuiltFormType = -1;
  @observable prebuiltSetting = null;

  // @observable uploadFilesList = [];
  defaultDefinitionName = "";
  //   savedSearchState = {};

  constructor(props) {
    super(props);
    this.api = new DocumentsAPI();
    this.commonApi = new CommonApi();
    this.fileApi = new FileApi();
    this.templateSortingApi = new TemplateSortingApi();
    this.formSettingApi = new FormSettingApi();
  }

  @action initial = () => {
    this.currentPage = 1;
    this.tabTitle = {};
    this.dataHeaderDetail = {};
    this.lastDataWhenDelete = {};
  }

  /**
   * updateValueChange
   * update value of current document
   *
   * @param {Interger} id : id of item
   * @param {String} value : New value of item
   * @returns  Null
   */
  @action
  updateValueChange = (id, value) => {
    this.currentDocument.pages = toJS(this.currentDocument?.pages).map(element => {
      element.pageItems = toJS(element?.pageItems).map(
        (item) => {
          if (item.itemsjshid === id) item.value = value;
          return item;
        }
      );
      return element;
    });
  };

  /**
   * removeItem
   * remove item of current document
   *
   * @param {Interger} id : id of item
   * @returns  Null
   */
  @action
  removeItem = (id) => {
    this.currentDocument.pages = toJS(this.currentDocument?.pages).map(element => {
      element.pageItems = toJS(element?.pageItems).filter(
        (item) => item.itemsjshid !== id
      );
      return element;
    });
    const page = this.currentDocument.pages[this.currentPage - 1]
    this.currentOcrItems = page.pageItems;
  };

  /**
  * removeItemDetail
  * remove detail items of current document
  *
  * @param {Array} ids : array ids of row item detail
  * @returns  Null
  */
  @action
  removeItemDetail = (ids) => {
    var index = this.currentOcrItems.findIndex(function (item) {  // find index to regenerate rid
      return item.itemsjshid == ids[0];
    });
    let tableName = this.currentDocument.pages[this.currentPage - 1].pageItems[index].tableName;
    if (!(tableName in this.lastDataWhenDelete))
      this.lastDataWhenDelete[tableName] = {}; // set value by table name and page no
    this.lastDataWhenDelete[tableName][this.currentPage - 1] = this.currentDocument.pages[this.currentPage - 1].pageItems[index]; // assign last data in case delete all data
    this.currentDocument.pages = toJS(this.currentDocument?.pages).map(element => {
      element.pageItems = toJS(element?.pageItems).filter(
        (item) => !ids.includes(item.itemsjshid)
      );
      return element;
    });
    const page = this.currentDocument.pages[this.currentPage - 1]
    for (let i = index; i < page.pageItems.length; i++) { //decrease rid from index to last of current page
      if (page.pageItems[i].rid && page.pageItems[i].tableName && page.pageItems[i].tableName != '')
        page.pageItems[i].rid = (parseInt(page.pageItems[i].rid) - 1).toString();
    }

    for (let i = this.currentPage; i < this.currentDocument.totalPage; i++) { //decrease rid of other next page
      var other_page = this.currentDocument.pages[i];
      if (other_page.pageItems.length) {
        for (let i = 0; i < other_page.pageItems.length; i++) {
          if (other_page.pageItems[i].rid && other_page.pageItems[i].tableName && other_page.pageItems[i].tableName != '')
            other_page.pageItems[i].rid = (parseInt(other_page.pageItems[i].rid) - 1).toString();
        }
      }
    }
    this.currentOcrItems = page.pageItems;
  };

  /**
  * addItem
  * add new item to current document
  *
  * @param {String} value : value of item
  * @returns  Null
  */
  @action
  addItem = (value) => {
    let data = this.currentDocument.pages[this.currentPage - 1].pageItems;
    var obj = {};
    obj.id = this.currentDocument.id;
    // find max itemsjshid to create new itemsjshid
    var array_max_itemsjshid = [];
    if (this.currentDocument.commonItems.length) { // find max in header
      array_max_itemsjshid.push(Math.max.apply(Math, this.currentDocument.commonItems.map(function (o) { return o.itemsjshid; })));
    }
    this.currentDocument.pages.map(function (value) { // find max in detail
      if (value.pageItems.length)
        array_max_itemsjshid.push(Math.max.apply(Math, value.pageItems.map(function (o) { return o.itemsjshid; })));
    });
    obj.itemsjshid = array_max_itemsjshid.length ? Math.max(...array_max_itemsjshid) + 1 : 0; // if there's no itemsjshid then start itemsjshid from 0
    obj.confidence = 100;
    obj.gid = null;
    obj.rid = -1;
    obj.width = 0;
    obj.height = 0;
    obj.xaxis = 0;
    obj.yaxis = 0;
    obj.tableName = null;
    obj.key = value;
    obj.keyword = null;
    obj.pageNo = this.currentPage;
    obj.value = '';
    var headerCount = data.filter(item => item.tableName == '' || item.tableName == null).length;
    data.splice(headerCount, 0, obj);
    //  data.push(obj);
    this.currentOcrItems = data;
  };

  /**
   * insertItemDetailByRowBelow
   * add new row items detail to current document
   * @param {Interger} first_itemsjshid: first itemsjshid of data Row (to calculate index insert)
   ** @param {Array} dataRow : array ids of line add below
   * @returns  Null
   */
  @action
  insertItemDetailByRowBelow = (first_itemsjshid, dataRow) => {
    var index = this.currentOcrItems.findIndex(function (item) {  // find index to insert new record
      return item.itemsjshid == first_itemsjshid
    });

    const merge = (a, b, i = 0) => a.splice(i, 0, ...b) && a; // Merge two arrays at a specific index

    let data = this.currentDocument.pages[this.currentPage - 1].pageItems;
    var key = Object.keys(dataRow);
    var new_row = [];

    // find max itemsjshid to create new itemsjshid
    var array_max_itemsjshid = [];
    if (this.currentDocument.commonItems.length) {
      array_max_itemsjshid.push(Math.max.apply(Math, this.currentDocument.commonItems.map(function (o) { return o.itemsjshid; })));
    }
    this.currentDocument.pages.map(function (value) {
      if (value.pageItems.length)
        array_max_itemsjshid.push(Math.max.apply(Math, value.pageItems.map(function (o) { return o.itemsjshid; })));
    });
    var new_itemsjshid = array_max_itemsjshid.length ? Math.max(...array_max_itemsjshid) + 1 : 0;
    key.forEach((value) => {
      var obj = {};
      obj.id = this.currentDocument.id;
      obj.itemsjshid = new_itemsjshid;
      obj.confidence = 100;
      obj.gid = data[index].gid;
      obj.rid = data[index].rid;
      obj.width = 0;
      obj.height = 0;
      obj.xaxis = 0;
      obj.yaxis = 0;
      obj.tableName = data[index].tableName;
      obj.key = value;
      obj.keyword = null;
      obj.pageNo = this.currentPage;
      obj.value = '';
      new_row.push(obj);
      new_itemsjshid++;
    });
    // increase rid below item from item insert
    for (let i = index; i < data.length; i++) {
      if (data[i].rid && data[i].tableName && data[i].tableName != '')
        data[i].rid = (parseInt(data[i].rid) + 1).toString();
    }

    for (let i = this.currentPage; i < this.currentDocument.totalPage; i++) { //increase rid of other next page
      var other_page = this.currentDocument.pages[i];
      if (other_page.pageItems.length) {
        for (let i = 0; i < other_page.pageItems.length; i++) {
          if (other_page.pageItems[i].rid && other_page.pageItems[i].tableName && other_page.pageItems[i].tableName != '')
            other_page.pageItems[i].rid = (parseInt(other_page.pageItems[i].rid) + 1).toString();
        }
      }
    }

    // merge new_row with data from index
    merge(data, new_row, index);
    this.currentOcrItems = data;
  };

  /**
   * insertItemDetailInLast
   * add new row items detail to last row of current document
   * @param {Interger} last_itemsjshid: last itemsjshid of data Row
   ** @param {Array} dataRow : array ids of line add below
   * @returns  Null
   */
  @action
  insertItemDetailInLast = (tabTitle) => {
    var grouped = this.groupBy(this.currentDocument.pages[this.currentPage - 1].pageItems, data => data.tableName);
    var dataDetail = grouped.get(tabTitle);
    const merge = (a, b, i = 0) => a.splice(i, 0, ...b) && a; // Merge two arrays at a specific index
    let data = this.currentDocument.pages[this.currentPage - 1].pageItems;
    var new_row = [];

    // find max itemsjshid to create new itemsjshid
    var array_max_itemsjshid = [];
    if (this.currentDocument.commonItems.length) {
      array_max_itemsjshid.push(Math.max.apply(Math, this.currentDocument.commonItems.map(function (o) { return o.itemsjshid; })));
    }
    this.currentDocument.pages.map(function (value) {
      if (value.pageItems.length)
        array_max_itemsjshid.push(Math.max.apply(Math, value.pageItems.map(function (o) { return o.itemsjshid; })));
    });
    var new_itemsjshid = array_max_itemsjshid.length ? Math.max(...array_max_itemsjshid) + 1 : 0;
    if (typeof dataDetail !== 'undefined') {
      var last_itemsjshid = dataDetail[dataDetail.length - 1].itemsjshid;
      var index = this.currentOcrItems.findIndex(function (item) {  // find index to insert new record
        return item.itemsjshid == last_itemsjshid
      });
      this.dataHeaderDetail[this.currentPage - 1][tabTitle].forEach((value) => {
        var obj = {};
        obj.id = this.currentDocument.id;
        obj.itemsjshid = new_itemsjshid;
        obj.confidence = 100;
        obj.gid = data[index].gid;
        obj.rid = (parseInt(data[index].rid) + 1).toString();
        obj.width = 0;
        obj.height = 0;
        obj.xaxis = 0;
        obj.yaxis = 0;
        obj.tableName = data[index].tableName;
        obj.key = value;
        obj.keyword = null;
        obj.pageNo = this.currentPage;
        obj.value = '';
        new_row.push(obj);
        new_itemsjshid++;
      });
      // merge new_row with data from index
      merge(data, new_row, index + 1);
      //increase rid below item from item insert
      for (let i = index + this.dataHeaderDetail[this.currentPage - 1][tabTitle].length + 1; i < data.length; i++) {
        if (data[i].rid && data[i].tableName && data[i].tableName != '')
          data[i].rid = (parseInt(data[i].rid) + 1).toString();
      }
    } else {
      // Set GId
      let insertGId = this.lastDataWhenDelete[tabTitle][this.currentPage - 1].gid;

      // Set index
      let insertIndex = 0;
      let leftDetailTabs = [];
      for (let i = 0; i < this.tabTitle[this.currentPage - 1].length; i++) {
        if (this.tabTitle[this.currentPage - 1][i] != tabTitle) {
          leftDetailTabs.push(this.tabTitle[this.currentPage - 1][i]);
        }
        else {
          break;
        }
      }

      insertIndex = this.currentDocument.pages[this.currentPage - 1].pageItems.filter(item =>
        item.tableName == '' || item.tableName == null
        || leftDetailTabs.includes(item.tableName)
      ).length;

      // Set RId
      let insertRId;
      let maxRIdCurrent = -1;
      for (let i = 0; i <= this.currentPage - 1; i++) {
        var page = this.currentDocument.pages[i];
        if (page.pageItems.length) {
          // Max Rid of detail item for page before
          if (i < this.currentPage - 1) {
            var pageDetailItems = this.currentDocument.pages[i].pageItems.filter((item, index) => item.tableName);
            if (pageDetailItems.length) {
              let maxRidOfPage = Math.max.apply(Math, pageDetailItems.map(function (o) { return parseInt(o.rid); }));
              maxRIdCurrent = maxRIdCurrent < maxRidOfPage ? maxRidOfPage : maxRIdCurrent;
            }
          }
          // Max Rid of detail item for items before in this page
          else {
            var pageItemsBefore = this.currentDocument.pages[i].pageItems.filter((item, index) => index < insertIndex);
            var pageDetailItemsBefore = pageItemsBefore.filter((item) => item.tableName);
            if (pageDetailItemsBefore.length) {
              let maxRidOfPage = Math.max.apply(Math, pageDetailItemsBefore.map(function (o) { return parseInt(o.rid); }));
              maxRIdCurrent = maxRIdCurrent < maxRidOfPage ? maxRidOfPage : maxRIdCurrent;
            }
          }
        }
      }
      insertRId = maxRIdCurrent + 1;

      this.dataHeaderDetail[this.currentPage - 1][tabTitle].forEach((value) => {
        var obj = {};
        obj.id = this.currentDocument.id;
        obj.itemsjshid = new_itemsjshid;
        obj.confidence = 100;
        obj.gid = insertGId;
        obj.rid = insertRId.toString();
        obj.width = 0;
        obj.height = 0;
        obj.xaxis = 0;
        obj.yaxis = 0;
        obj.tableName = tabTitle;
        obj.key = value;
        obj.keyword = null;
        obj.pageNo = this.currentPage;
        obj.value = '';
        new_row.push(obj);
        new_itemsjshid++;
      });

      // merge new_row with data from index
      merge(data, new_row, insertIndex);
      //increase rid below item from item insert
      for (let i = insertIndex + this.dataHeaderDetail[this.currentPage - 1][tabTitle].length; i < data.length; i++) {
        if (data[i].rid && data[i].tableName && data[i].tableName != '')
          data[i].rid = (parseInt(data[i].rid) + 1).toString();
      }
    }
    for (let i = this.currentPage; i < this.currentDocument.totalPage; i++) { //increase rid of other next page
      var other_page = this.currentDocument.pages[i];
      if (other_page.pageItems.length) {
        for (let i = 0; i < other_page.pageItems.length; i++) {
          if (other_page.pageItems[i].rid && other_page.pageItems[i].tableName && other_page.pageItems[i].tableName != '')
            other_page.pageItems[i].rid = (parseInt(other_page.pageItems[i].rid) + 1).toString();
        }
      }
    }

    this.currentOcrItems = data;
  };

  /**
   * getDefinitations
   * get the list of defination of documents
   *
   * @returns  boolean
   */
  @action
  async getDefinitations() {
    let tempDefns = await ApiStore.newCall(
      this.commonApi,
      this.commonApi.getDefinitations
    );
    if (tempDefns) {
      runInAction(() => {
        this.listAllDefinitions = tempDefns;
        this.listAllDefinitionsCache = this.listAllDefinitions;
        if (this.listAllDefinitions && this.listAllDefinitions[0]) {
          this.defaultDefinitionName = this.listAllDefinitions[0].configName;
          this.selectedDefinitionId = this.listAllDefinitions[0].id;
        }
      });
    }
  }

  @action
  async getDefinitationsInTeam() {
    let tempDefns = await ApiStore.newCall(
      this.commonApi,
      this.commonApi.getDefinitationsInTeam
    );
    if (tempDefns) {
      runInAction(() => {
        this.listAllDefinitions = tempDefns;
        this.listAllDefinitionsCache = this.listAllDefinitions;
        if (this.listAllDefinitions && this.listAllDefinitions[0]) {
          this.defaultDefinitionName = this.listAllDefinitions[0].configName;
          this.selectedDefinitionId = this.listAllDefinitions[0].id;
        }
      });
    }
  }

  @action
  resetConfigFileCode() {
    this.tempFilterData.configFileCode = "";
  }

  @action
  getDefinitationsByGroup(formGroupId) {
    if (!formGroupId || formGroupId == "null") {
      this.listAllDefinitions = this.listAllDefinitionsCache;
    }
    else {
      this.listAllDefinitions = this.listAllDefinitionsCache.filter(x => x.formGroupId == formGroupId);
    }
    return this.listAllDefinitions;
  }

  @action
  async getDefinitationsGroup() {
    let groups = await ApiStore.newCall(
      this.commonApi,
      this.commonApi.getDefinitationsGroup
    );
    if (groups) {
      runInAction(() => {
        if (groups) {
          this.listGroupDefinitions = groups.filter(f => f.edition == 'Item');
          if (this.listGroupDefinitions[0]) this.selectedGroupId = this.listGroupDefinitions[0].id
        }
      });
    }
  }

  @action
  async getDefinitationsGroupInTeam() {
    let groups = await ApiStore.newCall(
      this.commonApi,
      this.commonApi.getDefinitationsGroupInTeam
    );
    if (groups) {
      runInAction(() => {
        if (groups) {
          this.listGroupDefinitions = groups.filter(f => f.edition == 'Item');
          if (this.listGroupDefinitions[0]) this.selectedGroupId = this.listGroupDefinitions[0].id
        }
      });
    }
  }

  @action
  async getAllDefinitationsGroup() {
    let groups = await ApiStore.newCall(
      this.commonApi,
      this.commonApi.getDefinitationsGroup
    );
    if (groups) {
      runInAction(() => {
        if (groups) {
          this.listGroupDefinitions = groups;
        }
      });
    }
  }

  @action
  async getAllDefinitationsGroupInTeam() {
    let groups = await ApiStore.newCall(
      this.commonApi,
      this.commonApi.getDefinitationsGroupInTeam
    );
    if (groups) {
      runInAction(() => {
        if (groups) {
          this.listGroupDefinitions = groups;
        }
      });
    }
  }

  @action
  async getTemplateSortingsForUpload() {
    const { size, page, sortDir, sortKey } = this.paging;
    let payload = { size, page, sortDir, sortKey };
    let response = await ApiStore.call(this.templateSortingApi, this.templateSortingApi.getAllRegisted, payload);
    if (response) {
      runInAction(() => {
        this.listTemplateSortings = response.elements;
        if (this.listTemplateSortings[0]) this.selectedTemplateSortingId = this.listTemplateSortings[0].id
      })
    }
  }

  @action setCurrentUploadFile = (id) => {
    let file = null;
    this.uploadingFiles.forEach(e => {
      if (e.upload_id === id) file = e;
    });
    this.currentUploadFile = {
      ...this.currentUploadFile,
      upload_id: file?.upload_id,
      name: file?.name,
      type: file?.type,
      size: file?.size,
      timestamp: file?.timestamp,
      link: CONFIG.file_domain + encodeURIComponent(file?.previewFile + file?.previewFilename)
    };
    this.checkReadyExecute();
  }

  @action checkReadyExecute = () => {
    let canExecute = true;
    this.uploadingFiles.forEach(e => {
      if (!e.status) canExecute = false;
    });
    if (this.uploadingFiles.length === 0) canExecute = false;
    this.currentUploadFile = {
      ...this.currentUploadFile,
      readyToExecute: canExecute,
    };
  }

  /**
   * uploadDocument
   * upload file to backend
   *
   * @param {File} file : uploaded file
   *
   * @returns  promise
   */
  @action
  async uploadDocument(file) {
    this.checkReadyExecute();
    file.forEach(e => {
      let fileIndex = e.upload_id;
      ApiStore.call(
        this.fileApi,
        this.fileApi.uploadFile,
        [e.data, e.upload_id],
        true,
        null,
        true
      ).then(response => {
        if (response.fileName) {
          runInAction(() => {
            const completedFile = response;
            this.updateFileComplete(completedFile, fileIndex);
            this.setCurrentUploadFile(fileIndex);
            this.checkReadyExecute();
          });
        } else {
          ReactNotifications("error", i18n.t("api.response.no_network"), "");
          this.removeFileByIndex(fileIndex);
        }
      }).catch((err) => {
        if (err?.status) {
          if (err?.status === 413) {
            ReactNotifications("error", i18n.t("upload.file_size_over"), "");
          } else {
            let exception = err.json();
            try {
              exception.then((error) => {
                if (error?.message) {
                  ReactNotifications("error", error?.message, "");
                } else {
                  ReactNotifications(
                    "error",
                    i18n.t("api.response.no_message"),
                    ""
                  );
                }
              });
            } catch {
              ReactNotifications("error", i18n.t("api.response.no_network"), "");
            }
          }
          this.removeFileByIndex(fileIndex);
        } else {
          ReactNotifications("error", i18n.t("api.response.no_network"), "");
          this.removeFileByIndex(fileIndex);
        }
      })
    });
  }

  @action setHightlightedItem = (id) => {
    this.highlightedItem = id;
  }

  @action updateFileComplete = (fileData, index) => {
    const newFiles = this.uploadingFiles.map(e => {
      if (e.upload_id === index) {
        return {
          upload_id: index,
          name: fileData.fileName,
          previewFilename: fileData.previewFilename,
          type: fileData.fileType,
          size: fileData.fileSize,
          timestamp: fileData.timestamp,
          previewFile: fileData.previewFile,
          status: true
        }
      } else {
        return e;
      }
    });
    this.uploadingFiles = newFiles;
  }

  @action removeFileByIndex = (index) => {
    const newFiles = this.uploadingFiles.filter(e => {
      return (e.upload_id !== index);
    });
    this.uploadingFiles = newFiles;
    this.checkReadyExecute();
  }

  /**
   * getDocuments
   * get all document wihtou paging for tables
   *
   * @param {Object} payload : the filter object to query list documents
   * @returns  Null update this.documents and re-render the view
   */
  async getDocuments() {
    const { size, page, sortDir, sortKey } = this.paging;
    let payload = {
      ...this.dataTransformer(this.savedSearchState?.filterData),
      size,
      page,
      sortDir,
      sortKey,
    };
    let response = await ApiStore.call(
      this.api,
      this.api.getDocuments,
      payload
    );
    this.setTempSearchBoxFiler(this.savedSearchState?.filterData);
    if (response) {
      runInAction(() => {
        this.updatePagingFiler(response.paging);
        this.documents = this.documentTransformer(response.elements);
        // save current documents
        localStorage.setItem("documents", JSON.stringify(this.documents));
        if (this.paging?.totalRecord && this.paging?.totalRecord !== 0) {
          if (this.paging?.page > 1 && (!response.elements || response.elements.length === 0)) {
            this.updatePagingFiler({ page: this.paging.page - 1 });
            this.getDocuments();
          }
        }
        this.setKeepFilterState(false);
      });
    }
  }

  /**
   * saveCurrentDocument
   * save curent docuement
   *
   * @param {Object} history : the borser history to redirect to list screen after done
   * @returns  re-direct to list document
   */
  async saveCurrentDocument(history, cb = null, fb = null) {
    let response = await ApiStore.call(this.api, this.api.update, [
      this.currentDocument.id,
      this.currentDocument,
    ], false, fb);
    if (response) {
      cb && cb();
    }
  }

  async confirmCurrentDocument(history, cb = null, fb = null) {
    let response = await ApiStore.call(this.api, this.api.confirm, [
      this.currentDocument.id,
      this.currentDocument,
    ], false, fb);
    if (response) {
      cb && cb();
    }
  }

  async unconfirmCurrentDocument(history, cb = null, fb = null) {
    let response = await ApiStore.call(this.api, this.api.unconfirm, [
      this.currentDocument.id,
    ], false, fb);
    if (response) {
      cb && cb();
    }
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  /**
   * getDocumentById
   * get one documents
   *
   * @param {String} id : id of target document
   *
   * @returns  Promise
   */
  async getDocumentById(id, isCurrentDoc) {
    if (!isCurrentDoc) {
      this.initial();
    }

    let response = await ApiStore.call(this.api, this.api.getOneDocument, id);
    if (response) {
      // 事前構築済みモデルであれば帳票設定を取得
      let prebuiltSetting = null;
      let enableEmptyOutput = false;
      let prebuiltOrder = null;
      if (response.configFileEdition == CONFIG_FILE_EDITION.PREBUILT) {
        switch (response.configFileName) {
          case PREBUILT_MODELS.INVOICE:
            prebuiltOrder = PREBUILT_COLUMN_ORDER_INVOICE;
            break;
          case PREBUILT_MODELS.RECEIPT:
            prebuiltOrder = PREBUILT_COLUMN_ORDER_RECEIPT;
            break;
        }
        let formSetting = await ApiStore.call(this.formSettingApi, this.formSettingApi.getPrebuilt);
        if (formSetting) {
          prebuiltSetting = formSetting.map(el => {
            el.settingBody = JSON.parse(el.settingBody)
            return el
          }).find(el => el.settingName == response.configFileName);
          if (prebuiltSetting.settingBody.hasOwnProperty("ENABLE_EMPTY_OUTPUT")) {
            enableEmptyOutput = prebuiltSetting.settingBody["ENABLE_EMPTY_OUTPUT"];
            delete prebuiltSetting.settingBody["ENABLE_EMPTY_OUTPUT"];
          }
        }
      }

      // 重複, rid < 0, 事前構築済みモデル設定で出力しない設定になっているItemを削除
      response.pages = response.pages.map((p) => {
        var result = p.pageItems.filter((item, idx) =>
          ((this.getIndexOfItem(p.pageItems, item) === idx && parseInt(item.rid) >= 0) || !(item.tableName && item.tableName != ""))
          && (prebuiltSetting == null || prebuiltSetting.settingBody.hasOwnProperty(item.key)));
        return { ...p, pageItems: result };
      });

      // 欠けている項目の追加
      var min_rid = 0;
      // 項目に振られる連番の次の値を取得
      var new_itemsjshid = response.pages.map(p => p.pageItems.map(i => i.itemsjshid))
        .reduce((pre, current) => { pre.push(...current); return pre }, [])
        .reduce((a, b) => { return Math.max(a, b) }, 0) + 1;
      response.pages = response.pages.map((p, idx) => {
        if (enableEmptyOutput) {
          // 事前構築済みモデルかつ空欄出力設定ありの場合、ヘッダテーブルでも空欄項目を追加していく
          var header_items = p.pageItems.filter(t => t || t == "");
          var all_columns = Object.keys(prebuiltOrder[""]).filter(e => prebuiltSetting.settingBody.hasOwnProperty(e));
          for (var i = 0; i < all_columns.length; i++) {
            var column = all_columns[i];
            if (header_items.filter(e => e.key == column).length <= 0) {
              var new_obj = {};
              new_obj.id = response.id;
              new_obj.itemsjshid = new_itemsjshid;
              new_obj.confidence = 100;
              new_obj.gid = '';
              new_obj.rid = "1";
              new_obj.width = 0;
              new_obj.height = 0;
              new_obj.xaxis = 0;
              new_obj.yaxis = 0;
              new_obj.tableName = '';
              new_obj.key = column;
              new_obj.keyword = null;
              new_obj.pageNo = idx + 1;
              new_obj.value = '';
              new_itemsjshid++;
              p.pageItems.push(new_obj);
            }
          }
        }
        // 明細テーブルでループ
        var detail_tables = new Set(p.pageItems.map(i => i.tableName).filter(t => t && t != ""));
        detail_tables.forEach((table) => {
          var items = p.pageItems.filter(i => i.tableName == table);
          // テーブル内に存在するすべての項目名を取得
          var keys;
          if (enableEmptyOutput) {
            // 事前構築済みモデルの空欄出力設定ありの場合は帳票設定から取得
            keys = Object.keys(prebuiltOrder[table]).filter(e => prebuiltSetting.settingBody.hasOwnProperty(e));
          } else {
            keys = Array.from(new Set(items.map(i => i.key)));
          }

          // テーブル内のRID最大値を取得
          var max_rid = Math.max(...items.map(i => parseInt(i.rid)));
          // 追加する際にGID等をコピーする元となるitemの初期設定
          var sample_item = items[0];
          // RID/Keyの組み合わせで検索して存在しない項目を追加していく
          for (var rid = min_rid; rid <= max_rid; rid++) {
            var exist_items = items.filter((i) => rid == parseInt(i.rid));
            if (exist_items.length > 0) {
              sample_item = exist_items[0];
            } else {
              sample_item = items.find((i) => rid < parseInt(i.rid));
            }
            var exist_keys = exist_items.map(i => i.key);
            var missing_keys = keys.filter((k) => exist_keys.indexOf(k) == -1);
            missing_keys.forEach((k) => {
              var new_obj = {};
              new_obj.id = response.id;
              new_obj.itemsjshid = new_itemsjshid;
              new_obj.confidence = 100;
              new_obj.gid = sample_item.gid;
              new_obj.rid = rid.toString();
              new_obj.width = 0;
              new_obj.height = 0;
              new_obj.xaxis = 0;
              new_obj.yaxis = 0;
              new_obj.tableName = table;
              new_obj.key = k;
              new_obj.keyword = null;
              new_obj.pageNo = idx + 1;
              new_obj.value = '';
              new_itemsjshid++;
              p.pageItems.push(new_obj);
            });
          }
          min_rid = (max_rid + 1 >= 0) ? max_rid + 1 : 0;
        });

        if (prebuiltOrder != null) {
          // 事前構築済みモデルの場合は項目名を決められた順序で並べる
          p.pageItems.sort((a, b) => {
            let tableA = a.tableName != null ? a.tableName : "";
            let tableB = b.tableName != null ? b.tableName : "";
            if (PREBUILT_TABLE_ORDER[tableA] != PREBUILT_TABLE_ORDER[tableB]) {
              return PREBUILT_TABLE_ORDER[tableA] - PREBUILT_TABLE_ORDER[tableB];
            }
            var rid_a = parseInt(a.rid);
            var rid_b = parseInt(b.rid);
            if (rid_a != rid_b) {
              return rid_a - rid_b;
            }
            if (!prebuiltOrder[tableA].hasOwnProperty(a.key) && !prebuiltOrder[tableB].hasOwnProperty(b.key)) {
              return 0;
            } else if (!prebuiltOrder[tableA].hasOwnProperty(a.key)) {
              return 1;
            } else if (!prebuiltOrder[tableB].hasOwnProperty(b.key)) {
              return -1;
            } else {
              return prebuiltOrder[tableA][a.key] - prebuiltOrder[tableB][b.key];
            }
          });
        } else {
          // 明細テーブルを テーブル名 > GID > RID > 項目名 の順で比較してソート
          var items = p.pageItems.filter(i => i.tableName && i.tableName != "");
          var tables = Array.from(new Set(items.map(i => i.tableName)));
          var gids = Array.from(new Set(items.map(i => i.gid)));
          var keys = Array.from(new Set(items.map(i => i.key)));
          p.pageItems.sort((a, b) => {
            if (!a.tableName || a.tableName == "" || !b.tableName || b.tableName == "") {
              return 0;
            }
            if (tables.indexOf(a.tableName) != tables.indexOf(b.tableName)) {
              return tables.indexOf(a.tableName) - tables.indexOf(b.tableName);
            }
            if (gids.indexOf(a.gid) != gids.indexOf(b.gid)) {
              return gids.indexOf(a.gid) - gids.indexOf(b.gid);
            }
            var rid_a = parseInt(a.rid);
            var rid_b = parseInt(b.rid);
            if (rid_a != rid_b) {
              return rid_a - rid_b;
            }
            return keys.indexOf(a.key) - keys.indexOf(b.key)
          });
        }
        return p;
      });

      const page = response.pages[this.currentPage - 1];
      var array_key = [];
      var object_data = {};

      if (page.pageItems.length > 0 && typeof page.pageItems[0] !== 'undefined') {
        array_key = [...new Set(page.pageItems.map(item => item.tableName))];
        if (page.pageItems[0].tableName == '' || page.pageItems[0].tableName == null) {
          array_key.shift();
        }
        var grouped = this.groupBy(page.pageItems, data => data.tableName);
        array_key.forEach(tabTitle => {
          object_data[tabTitle] = [...new Set(grouped.get(tabTitle).map(item => item.key))];
        });
      }

      runInAction(() => {
        this.currentDocument = response;
        this.currentOcrItems = page.pageItems;
        this.documentWidth = page.width;
        this.documentHeight = page.height;
        this.getFileData();
        this.setHighlight(page.width, page.height);
        this.configFileEdition = response.configFileEdition;
        this.tabTitle[this.currentPage - 1] = array_key;
        this.dataHeaderDetail[this.currentPage - 1] = object_data;
        this.prebuiltSetting = prebuiltSetting;
      });
    }
  }

  getIndexOfItem(arr, item) {
    for (var i = 0; i < arr.length; i++) {
      var target = arr[i];
      if (target.key == item.key
        && target.pageNo == item.pageNo
        && target.gid == item.gid
        && target.rid == item.rid) {
        return i;
      }
    }
    return -1;
  }

  @action setCurrentPage = (currentPage) => {
    this.currentPage = currentPage;
    const page = this.currentDocument.pages[currentPage - 1]
    this.currentOcrItems = page.pageItems;
    this.documentWidth = page.width;
    this.documentHeight = page.height;
    this.setHighlight(page.width, page.height);
    var object_data = {};
    var array_key = null;
    if (page.pageItems.length > 0 && typeof page.pageItems[0] !== 'undefined') {
      array_key = [...new Set(page.pageItems.map(item => item.tableName))];
      if (page.pageItems[0].tableName == '' || page.pageItems[0].tableName == null) {
        array_key.shift();
      }
      var grouped = this.groupBy(page.pageItems, data => data.tableName);
      array_key.forEach(tabTitle => {
        object_data[tabTitle] = [...new Set(grouped.get(tabTitle).map(item => item.key))];
      });
    }
    if (!(currentPage - 1 in this.tabTitle)) { // check if exist data by page so dont need to set data
      this.tabTitle[currentPage - 1] = array_key;
      this.dataHeaderDetail[currentPage - 1] = object_data;
    }

  }

  @action resetHighlightedItem = async () => {
    this.highlightedItem = null;
  }

  @action getFileData = async () => {
    var url = "";
    var inputFilePath = "" + this.currentDocument?.inputFilePath;
    var prefix = inputFilePath.substring(0, inputFilePath.indexOf('/'));
    if (CONFIG.preview_pdf_container_name == prefix) {
      url = CONFIG.container_domain + inputFilePath;
    } else {
      url = CONFIG.file_domain + inputFilePath;
    }
    this.resetHighlightedItem();
    const existingPdfBytes = await fetch(url, {
      mode: 'cors'
    }).then(res => res.arrayBuffer());
    this.pdfBytes = existingPdfBytes;
  }

  @action setHighlight = async (width, height) => {
    this.documentHeight = height;
    this.documentWidth = width;
    this.highlights = this.currentOcrItems.map((element) => {
      let pos = {
        x: parseInt(element.xaxis) - 8,
        y: parseInt(element.yaxis) - 8,
        width: parseInt(element.width) + 16,
        height: parseInt(element.height) + 16,
        id: element.itemsjshid,
      };
      return pos;
    });
  }

  /**
   * executeCurrentDocument
   *
   * @param {Object} configId : configId
   * @param {Object} history : the borser history to redirect to list screen after done
   * @returns  re-direct to list document
   */
  async executeCurrentDocument(history) {
    //let configId = this.sortDefinition === 0 ? this.selectedDefinitionId : this.selectedGroupId;
    const payload = {
      itemSort: this.sortDefinition,
      inputFiles: this.uploadingFiles.map(e => {
        return {
          "inputFileName": e.name,
          "timestamp": e.timestamp,
        }
      }),
    };
    if (this.sortDefinition === 1) {
      payload.formGroupId = this.selectedGroupId;
    } else if (this.sortDefinition === 0) {
      payload.configId = this.selectedDefinitionId;
    } else if (this.sortDefinition === 2) {
      payload.templateSortingId = this.selectedTemplateSortingId;
    } else if (this.sortDefinition === 3) {
      payload.configId = this.selectedPrebuiltFormType;
    }
    payload["teamsId"] = this.selectedTeamId;
    let response = await ApiStore.call(this.fileApi, this.fileApi.executeFile, payload);
    if (response) {
      this.cleanCurrentFile();
      history.push(SYSTEM_PATH.DOCUMENT);
    }
  }
  /**
   * cleanCurrentFile
   *
   * @returns  Null
   */
  @action
  cleanCurrentFile() {
    this.currentUploadFile = null;
    this.uploadingFiles = [];
  }
  /**
   * updateDisableItems
   * update item disbale for download selection
   *
   * @param {boolean} is_checked : selected/dis-select
   * @param {String} document_type : item document type
   *
   * @returns  reupdate the list documents with new disable infor
   */
  @action
  updateDisableItems(is_checked, document_type) {
    if (this.documents && this.documents.length > 0) {
      this.documents = this.documents.map((item) => {
        if (item.configFileCode !== document_type) {
          item.disable = is_checked;
        }
        return item;
      });
    }
  }
  /**
   * deleteUploadFile
   *
   * @param {Object} history : the borser history to redirect to list screen after done
   * @returns  re-direct to list document
   */
  async deleteUploadFile(history) {
    if (this.currentUploadFile) {
      let response = await ApiStore.call(this.fileApi, this.fileApi.deleteFile);
      if (response) {
        this.cleanCurrentFile();
      }
    }
    history.push(SYSTEM_PATH.HOME);
  }

  /**
   * deleteUploadFile
   *
   * @param {Object} history : the borser history to redirect to list screen after done
   * @returns  re-direct to list document
   */
  async deleteUploadedFile(fileName, timestamp) {
    await ApiStore.call(this.fileApi, this.fileApi.deleteUploadedFile, { filename: fileName, timestamp: timestamp });
    runInAction(() => {
      this.uploadingFiles = this.uploadingFiles.filter(e => !(encodeURIComponent(e.name) === fileName && e.timestamp === timestamp));
      if (encodeURIComponent(this.currentUploadFile?.name) === fileName && this.currentUploadFile?.timestamp === timestamp) this.currentUploadFile = null;
      this.checkReadyExecute();
    });
  }

  /**
   * downloadDocuments
   * download docuements
   *
   * @param {Array} listCheckedItems  : list target file to download
   * @param {String} currentDocumentType : current document type( for server filter)
   * @returns  boolean
   */
  @action
  async downloadDocuments(listCheckedItems, currentDocumentType, cb = null) {
    let response = await ApiStore.call(this.api, this.api.downloadDocuments, {
      edition: currentDocumentType,
      listDocumentId: Object.values(listCheckedItems)?.join(","),
    });
    if (response) {
      let fileName = "report_default";
      if (response?.headers.get("content-disposition")) {
        let disposition = response?.headers.get("content-disposition");
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, "");
        }
      }

      // const reader = response.body.getReader();
      // let chunks = [];
      // let dataLength = 0;
      // while (true) {
      //   const { done, value } = await reader.read();
      //   if (done) {
      //     break;
      //   }
      //   dataLength += value.length;
      //   chunks.push(value);
      // }

      // let allData = new Uint8Array(dataLength);
      // let pos = 0;
      // chunks.forEach(element => {
      //   allData.set(element, pos);
      //   pos += element.length;
      // });

      let allData;
      const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
      if (isIE) {
        var dataFile = [];
        await response.arrayBuffer().then(data => dataFile = data);
        allData = new Uint8Array(dataFile);
      } else {
        const reader = response.body.getReader();
        let chunks = [];
        let dataLength = 0;
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          dataLength += value.length;
          chunks.push(value);
        }

        allData = new Uint8Array(dataLength);
        let pos = 0;
        chunks.forEach(element => {
          allData.set(element, pos);
          pos += element.length;
        });
      }

      this.createAndDownloadBlobFile(allData.buffer, fileName);
      this.setTempSearchBoxFiler(this.savedSearchState?.filterData);
      this.getDocuments();
      cb && cb();
    }
  }

  /**
   * downloadReadingResults
   *
   * @param {Int} documentId  : document id
   * @returns  boolean
   */
  @action
  async downloadReadingResults(documentId, cb = null) {
    let response = await ApiStore.call(this.api, this.api.downloadReadingResults, { documentId: documentId });
    if (response) {
      let fileName = "report_default";
      if (response?.headers.get("content-disposition")) {
        let disposition = response?.headers.get("content-disposition");
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, "");
        }
      }

      let allData;
      const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
      if (isIE) {
        var dataFile = [];
        await response.arrayBuffer().then(data => dataFile = data);
        allData = new Uint8Array(dataFile);
      } else {
        const reader = response.body.getReader();
        let chunks = [];
        let dataLength = 0;
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          dataLength += value.length;
          chunks.push(value);
        }

        allData = new Uint8Array(dataLength);
        let pos = 0;
        chunks.forEach(element => {
          allData.set(element, pos);
          pos += element.length;
        });
      }

      this.createAndDownloadBlobFile(allData.buffer, fileName);
      this.setTempSearchBoxFiler(this.savedSearchState?.filterData);
      this.getDocuments();
      cb && cb();
    }
  }

  createAndDownloadBlobFile(body, fileName) {
    const blob = new Blob([body]);
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  /**
   * deleteDocuments
   * delete documents
   *
   * @param {Array} listCheckedItems  : list target file to delete
   * @returns  boolean
   */
  @action
  async deleteDocuments(listCheckedItems, cb = null) {
    let response = await ApiStore.call(this.api, this.api.deleteDocuments, {
      listDocumentId: Object.values(listCheckedItems)?.join(","),
    });
    if (response) {
      cb && cb();
      this.getDocuments();
    }
  }

  /**
   * updateSelectedDefinitionId
   * update selected definition id follow user's changed
   *
   * @param {Integer} id : selected definition id
   *
   * @returns  null
   */
  @action
  updateSelectedDefinitionId(id) {
    this.selectedDefinitionId = id;
  }

  @action
  updateSelectedTeamId(id) {
    this.selectedTeamId = id;
  }

  @action
  updateSelectedGroupId = (value) => {
    this.selectedGroupId = value;
  }

  @action
  updateSortDefinition = (value) => {
    this.sortDefinition = value;
  }

  @action
  updateSelectedTemplateSortingId = (value) => {
    this.selectedTemplateSortingId = value;
  }

  @action
  updateSelectedPrebuiltFormType = (value) => {
    this.selectedPrebuiltFormType = value;
  }

  @action
  updateFileContent(content) {
    if ((this.uploadingFiles.length + content.length) <= MAX_FILE_UPLOAD) {
      runInAction(() => {
        const fileArray = content.map((e, index) => {
          this.fileIndex++;
          return { data: e, upload_id: this.fileIndex };
        });
        this.uploadingFiles = [...this.uploadingFiles, ...fileArray];
        this.currentUploadFile = {
          ...this.currentUploadFile,
          content: fileArray,
        };
        this.uploadDocument(fileArray);
      });
      return true;
    } else {
      return false
    }
  }

  @action
  updateSearchBoxCollapse(value) {
    this.savedSearchState = {
      ...this.savedSearchState,
      isCollapseOut: value,
    };
  }

  @action
  dataTransformer(data) {
    let transformedData = { ...data };
    if (transformedData?.fromTime) {
      transformedData.fromTime = moment(transformedData.fromTime).format(DATE_FORMAT.time_stamp);
    }
    if (transformedData?.toTime) {
      transformedData.toTime = moment(transformedData.toTime).format(DATE_FORMAT.time_stamp);
    }
    return transformedData;
  }

  documentTransformer(document) {
    if (document) {
      let idx = (this.paging.page - 1) * this.paging.size + 1;
      document = document.map((element) => {
        element.idx = idx++;
        return element;
      });
    }
    return document;
  }

  @action
  applySearchBoxFiler() {
    this.savedSearchState = {
      ...this.savedSearchState,
      filterData: this.tempFilterData,
    };
  }

  @action
  updateTempSearchBoxFiler(value = null) {
    runInAction(() => {
      this.tempFilterData = { ...this.tempFilterData, ...value };
    });
  }

  @action
  setTempSearchBoxFiler(value = null) {
    runInAction(() => {
      this.tempFilterData = { ...value };
    });
  }

  @action
  setSelectedTeamId = (value) => {
    this.selectedTeamId = value;
  }

  @action
  setKeepFilterState = (value) => {
    this.keepFilterState = value;
  }

  @action
  clean() {
    if (!this.keepFilterState) {
      super.clean();
      this.savedSearchState = {};
      this.selectedGroupId = -1;
      this.selectedTemplateSortingId = -1;
      this.selectedPrebuiltFormType = -1;
      this.sortDefinition = 0;
    }
  }
}

export default DocumentsStore;
