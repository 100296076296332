import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { ContainerBox, Table, ModalFooter } from 'components';
import SearchBox from './SearchBox';
import SettingAdmin from './SettingAdmin';
import SettingUser from './SettingUser';
import { MODE, MODAL_ID, DATE_FORMAT, NO_DATE, LANGUAGE_CODE_DEFAULT, PROFILE_ROLES_PREFIX } from '../../core/utils/constants';
import Switch from 'react-switch';
import moment from 'moment';
import { toJS } from 'mobx';

import './style.scss';
import BaseComponent from '../BaseComponent';

const TABLE_ROW_WIDTH = {
  NO: "3%",
  USER_ID: "20%",
  USER_NAME: "14%",
  USER_LEVEL: "9%",
  STATUS: "7%",
  LOGON_DATE: "9%",
  CREATED_DATE: "9%",
  UPDATED_DATE: "9%",
  ACCOUNT_LOCK: "12%",
  UNLOCK: "4%",
  REMOVE: "4%",
};

@inject('commonStore', 'modalStore')
@observer
class ScreenAdmin extends BaseComponent {

  executeLogicAfterRender = () => {
    this.props.commonStore.userStore.getUserInfo();
    this.props.commonStore.userStore.getUsersContainAdmin();
    this.props.commonStore.teamStore.getTeams();
  }

  onChangeUserDetail = (userDetail) => {
    this.setState({ userDetail });
  };

  onCancelUpdate = (userId, userStatus) => {
    this.closeModal();
  };

  onUpdateUserList = (userId, status) => {
    const { commonStore: { userStore } } = this.props;
    userStore.updateUserStatus({ userId, status });
    this.closeModal();
  };

  onConfirmUnlockUserById = (userId) => {
    this.props.commonStore.userStore.unlockUser(userId);
    this.closeModal();
  }

  onConfirmDeleteUserById = (userId) => {
    this.props.commonStore.userStore.deleteUser(userId);
    this.closeModal();
  }
  /**
   * openComfirmModal
   * remove user
   * 
   * @param {Object} userId: is edit or create
   * 
   * @returns  modal
   */
  openComfirmModal = (userId) => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.REMOVE,
      isOpen: true,
      header: t('admin.remove_confirm'),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>{t('admin.remove_user')}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t('admin.remove')}
            onConfirm={() => this.onConfirmDeleteUserById(userId)}
            cancelButtonText={t('admin.cancel')}
            onCancel={this.closeModal}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: 'small'
    });
  };

  /**
   * openUnlockComfirmModal
   * unlock user
   * 
   * @param {Object} userId: is edit or create
   * 
   * @returns  modal
   */
  openUnlockComfirmModal = (userId) => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.UPDATE,
      isOpen: true,
      header: t('admin.update_confirm'),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>{t('admin.unlock_user')}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t('admin.unlock')}
            onConfirm={() => this.onConfirmUnlockUserById(userId)}
            cancelButtonText={t('admin.cancel')}
            onCancel={this.closeModal}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: 'small'
    });
  };

  /**
   * openUserModal
   * show user modal to edit or create
   * 
   * @param {Object} mode: is edit or create
   * 
   * @returns  modal
   */
  openUserModal = (mode) => {
    const { t, modalStore } = this.props;
    let userDetail = this.props?.commonStore?.userStore?.selectedUser;
    let teams = this.props.commonStore.teamStore.teams;
    if (teams && teams.length > 0) {
      teams = teams.sort((a, b) => (a.id > b.id) ? 1 : -1);
    }
    if (mode === MODE.ADD) {
      userDetail = {
        userId: '',
        userName: '',
        email: '',
        userStatus: 0,
        userEmail: '',
        userPass: '',
        languageSetting: LANGUAGE_CODE_DEFAULT,
        role: 1,
        teamId: (teams && teams.length > 0) ? teams[0]?.id : ""
      }
    }
    modalStore.show({
      id: MODAL_ID.USER,
      isOpen: true,
      header: `${t('admin.user_management')} [${t('admin.account_page')}]`,
      onCancel: this.closeModal,
      children: (
        <SettingUser
          mode={mode}
          userDetail={userDetail}
          teams={teams}
          saveButtonText={t('admin.save')}
          onConfirm={this.onSaveUserDetail}
        />
      )
    });
  };

  //#endregion
  //#region update admin
  onChangeAdminDetail = (adminDetail) => {
    this.setState({ adminDetail });
  };

  onSaveAdmin = (adminDetail) => {
    const { commonStore: { userStore } } = this.props;
    userStore.updateAdmin(adminDetail, this.closeModal);
  };

  /**
   * openAdminModal
   * show admin modal to edit
   * 
   * @returns  modal
   */
  openAdminModal = () => {
    const { modalStore, commonStore: { userStore: { userInfo } }, t } = this.props;

    this.setState({ mode: 'adminDetail' });
    modalStore.show({
      id: MODAL_ID.ADMIN,
      isOpen: true,
      header: `${t('admin.user_management')} [${t('admin.contractor_setting')}]`,
      onCancel: this.closeModal,
      children: (
        <SettingAdmin
          adminDetail={userInfo}
          onChange={this.onChangeAdminDetail}
          saveButtonText={t('admin.save')}
          onConfirm={this.onSaveAdmin}
        />
      ),
      type: 'medium'
    });
  };

  // Close modal
  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hideAll();
  };

  handleChangeStatus = (userId, userStatus) => {
    const status = userStatus ? 0 : 1;
    const statusCancel = !userStatus ? 0 : 1;
    //change in store
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.UPDATE,
      isOpen: true,
      header: t('admin.update_confirm'),
      onCancel: () => this.onCancelUpdate(userId, statusCancel),
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>{t('admin.change_status')}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t('admin.update')}
            onConfirm={() => this.onUpdateUserList(userId, status)}
            cancelButtonText={t('admin.cancel')}
            onCancel={() => this.onCancelUpdate(userId, statusCancel)}
          />
        </div>
      ),
      type: 'small'
    });
  };

  executeLogicBeforeOutComponent = () => {
    this.props.commonStore.userStore.clean();
  }

  onFetch = (tableData) => {
    this.props.commonStore.userStore.updatePagingFiler(tableData);
    this.props.commonStore.userStore.getUsersContainAdmin();
  };

  render() {
    const { t, commonStore: { userStore: { users, userInfo } } } = this.props;
    let userIdSaved = localStorage.getItem("userId");
    const columns = [
      {
        Header: t("admin.no"),
        disableSortBy: true,
        width: TABLE_ROW_WIDTH.NO,
        Cell: ({ initialState, row }) =>
          initialState?.initialTableState?.pageSize *
          initialState?.initialTableState?.pageIndex +
          row.index +
          1,
      },
      {
        Header: t('admin.user_id'),
        accessor: 'userId',
        width: TABLE_ROW_WIDTH.USER_ID
      },
      {
        Header: t('admin.user_name'),
        id: 'userName',
        accessor: 'userName',
        Cell: ({ row: { original } }) => {
          return (
            <span className="color-theme cursor-p" onClick={() => this.props.commonStore.userStore.showEditModal(original.userId, () => this.openUserModal(MODE.EDIT))}>
              {original.userName}
            </span>
          );
        },
        width: TABLE_ROW_WIDTH.USER_NAME
      },
      {
        Header: t('admin.user_level'),
        id: 'role',
        accessor: 'role',
        Cell: ({ row: { original } }) => {
          return (
            <span>
              {t(PROFILE_ROLES_PREFIX + original.role)}
            </span>
          );
        },
        width: TABLE_ROW_WIDTH.USER_LEVEL
      },
      {
        Header: t('admin.status'),
        id: 'userStatus',
        accessor: 'userStatus',
        Cell: ({ row: { original: { userStatus } } }) => {
          if (userStatus === 0) {
            return (
              <span>
                {t("admin.status_valid")}
              </span>
            );
          } else {
            return (
              <span>
                {t("admin.status_invalid")}
              </span>
            );
          }
        },
        width: TABLE_ROW_WIDTH.STATUS
      },
      {
        Header: t('admin.last_logon_date'),
        accessor: 'lastLogonDate',
        Cell: ({ row: { original } }) => {
          return (
            <span>
              {original.lastLogonDate ? moment(original.lastLogonDate).format(DATE_FORMAT.full_time) : NO_DATE}
            </span>
          );
        },
        width: TABLE_ROW_WIDTH.LOGON_DATE
      },
      {
        Header: t('admin.created_date'),
        accessor: 'createdAt',
        Cell: ({ row: { original } }) => {
          return (
            <span>
              {original.createdAt ? moment(original.createdAt).format(DATE_FORMAT.full_time) : NO_DATE}
            </span>
          );
        },
        width: TABLE_ROW_WIDTH.CREATED_DATE
      },
      {
        Header: t('admin.updated_date'),
        accessor: 'updatedAt',
        Cell: ({ row: { original } }) => {
          return (
            <span>
              {original.updatedAt ? moment(original.updatedAt).format(DATE_FORMAT.full_time) : NO_DATE}
            </span>
          );
        },
        width: TABLE_ROW_WIDTH.UPDATED_DATE
      },
      {
        Header: t('admin.account_lock'),
        accessor: 'valid',
        Cell: ({ row: { original } }) => {
          let idx = original.valid ? original.valid.indexOf(".") : -1;
          let locktime = idx >= 0 ? original.valid.substring(0, idx) : original.valid;
          return (
            <span>
              {locktime}
            </span>
          );
        },
        width: TABLE_ROW_WIDTH.ACCOUNT_LOCK
      },
      {
        Header: t("admin.unlock"),
        id: 'unlock',
        className: 'admin-unlock',
        Cell: ({ row: { original: { userId, valid } } }) => {
          return (
            <button className="btn btn-primary"
              type="button"
              onClick={() => this.openUnlockComfirmModal(userId)}
              disabled={valid == null}
            >
              {t('admin.unlock_btn')}
            </button>
          );
        },
        width: 80
      },
      {
        Header: t("admin.action"),
        id: 'action',
        className: 'admin-action',
        Cell: ({ row: { original: { userId, userStatus } } }) => {
          return (
            <button className="btn btn-danger"
              type="button"
              onClick={() => this.openComfirmModal(userId)}
              disabled={userIdSaved === userId}
            >
              {t('admin.remove')}
            </button>
          );
        },
        width: 80
      }
    ];
    return (
      <div className="screen-admin">
        <ContainerBox headerTitle={t('admin.user_management')} className="admin-sb-container">
          <div className="box-body box-profile">
            <SearchBox
              userInfo={userInfo}
              openModal={() => this.openAdminModal()}
              openUserModal={() => this.openUserModal(MODE.ADD)}
            />
          </div>
        </ContainerBox>
        <ContainerBox type="basic" className="admin-body-container table-container">
          <div className="box-body document-item-info">
            <Table columns={columns} data={users ? toJS(users) : []}
              disablePaging={false}
              onFetch={this.onFetch}
              enableServerSidePaging={true}
              initialTableState={this.props.commonStore.userStore?.paging}
            />
          </div>
        </ContainerBox>
      </div>
    );
  }
}
export default withRouter(withTranslation()(ScreenAdmin));
