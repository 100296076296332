import { Component } from 'react';
import { observer } from "mobx-react";
import './style.scss'

@observer
class BaseComponent extends Component {
  trHandler = () => {
    // compute for table body
    let table = document.getElementsByTagName("table");
    if (table && table[0]) {
      let tableHead = document.getElementsByTagName("thead");
      if (tableHead && tableHead[0]) {
        let tableBody = document.getElementsByTagName("tbody");
        if (tableBody && tableBody[0]) {
          let tableBodyHeight = table[0].offsetHeight - tableHead[0].offsetHeight;

          // compute tr
          let trElements = document.getElementsByTagName("tr");
          let trHeight = 0;
          let num = 0;
          for (let element of trElements) {
            if (num !== 0) {
              trHeight += element.offsetHeight;
            }
            num++;
          }

          if (tableBodyHeight > trHeight) {
            tableHead[0].style.overflowY = "hidden";
          } else {
            tableHead[0].style.overflowY = "scroll";
          }
        }
      }
    }
  }

  tableHandler = () => {
    let tableContainerHeight = document.getElementsByClassName("table-container");
    if (tableContainerHeight && tableContainerHeight[0]) {
      tableContainerHeight[0].style.height = this.tableOriginalSize + "px";
      let table = document.getElementsByTagName("table");
      if (table && table[0]) {
        let tableHead = document.getElementsByTagName("thead");
        if (tableHead && tableHead[0]) {
          let tableBody = document.getElementsByTagName("tbody");
          let tableFoot = document.getElementsByTagName("tfoot");
          if (tableBody && tableBody[0]) {
            let tableBodyHeight = table[0].offsetHeight - tableHead[0].offsetHeight - tableFoot[0].offsetHeight;
            tableBody[0].style.height = tableBodyHeight + "px";
          }
        }
      }
    }
  }

  componentDidMount = () => {
    this.executeLogicAfterRender();
    let tableContainerHeight = document.getElementsByClassName("table-container");
    if (tableContainerHeight && tableContainerHeight[0]) {
      this.tableOriginalSize = tableContainerHeight[0].offsetHeight;
    }
    this.tableHandler();
  }

  componentWillUnmount = () => {
    this.executeLogicBeforeOutComponent();
  }

  componentDidUpdate = () => {
    this.executeOnDidUpdate();
    this.trHandler();
  }

  executeLogicAfterRender = () => {
  }

  executeLogicBeforeOutComponent = () => {
  }

  executeOnDidUpdate = () => {

  }
}
export default BaseComponent;
