import React from "react";
import { withTranslation } from "react-i18next";

import "./style.scss";
// for pdf files
class InputWrap extends React.Component {
    render() {
        const { children, customClass } = this.props;
        let className = customClass ? "input-wrap " + customClass :  "input-wrap";
        return (
            <div className={className}>
                <div className="input-field">{children}</div>
            </div>
        );
    }
}

export default withTranslation()(InputWrap);
