import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { ModalFooter } from "components";
import { Scrollbars } from "react-custom-scrollbars";
import "./style.scss";

@inject("modalStore", "commonStore")
@observer
class SpecifyingFormSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFormSettings: this.props.formSettingId ? this.props.formSettingId : "-1"
    };
  }

  onChange = (value) => {
    this.setState({
      selectedFormSettings: value
    });
  };

  onSave = (forceCreate = false) => {
    const { selectedFormSettings } = this.state;
    const { commonStore: { definitionStore }, formGroupId, t } = this.props;
    let payload = {
      form_group_id: formGroupId,
      form_setting_id: selectedFormSettings == "-1" ? null : selectedFormSettings
    }
    definitionStore.specifyingFormSettings(
      payload,
      () => {
        definitionStore.getDefinitions();
        this.closeAllModal();
      }
    );
  };

  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hide();
  };

  closeAllModal = () => {
    const { modalStore } = this.props;
    modalStore.hideAll();
  };

  openComfirmModal = () => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: "confirm",
      isOpen: true,
      header: t("form_setting.specifying_form_settings.update_confirm"),
      onCancel: () => this.closeModal("confirm"),
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>
              {t("form_setting.specifying_form_settings.change_information")}
            </span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t("form_setting.specifying_form_settings.save")}
            onConfirm={() => {
              this.closeModal();
              this.onSave();
            }}
            cancelButtonText={t("form_setting.specifying_form_settings.cancel")}
            onCancel={this.closeModal}
          />
        </div>
      ),
      type: "small"
    });
  };

  render() {
    const { t, saveButtonText, listFormSettings } = this.props;
    const { selectedFormSettings } = this.state;
    return (
      <div className="specifying-form-settings">
        <Scrollbars key={"modal-body"} className="modal-body">
          <div className="row" style={{ marginTop: "40px" }}>
            <div className="col-sm-1 p-0"></div>
            <div className="col-sm-4 font-bold">
              {t("form_setting.specifying_form_settings.form_name")}
            </div>
            <div className="col-sm-6" style={{padding: "5px"}}>
              <select
                className="slc-setting-list"
                value={selectedFormSettings}
                // disabled={listFormSettings.length < 1}
                onChange={(e) => this.onChange(e.target?.value)}
              >
                <option value="-1">{t("form_setting.not_specified")}</option>
                {
                    (listFormSettings && listFormSettings.length > 0) &&
                    listFormSettings?.map(e => <option key={e.id} value={e.id}>{e.settingName}</option>)
                }
              </select>
            </div>
          </div>
        </Scrollbars>
        <ModalFooter key={"modal-footer"}
          saveButtonText={saveButtonText}
          onConfirm={this.onSave}
        />
      </div>
    );
  }
}

export default withTranslation()(SpecifyingFormSettings);
