import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { ContainerBox, Table, ModalFooter } from 'components';
import { MODAL_ID, DATE_FORMAT, NO_DATE, EMPTY_FORMAT_CELL } from '../../core/utils/constants';
import moment from 'moment';
import BaseComponent from '../BaseComponent';
import './style.scss';

const TABLE_ROW_WIDTH_MODEL = {
  NO: "6%",
  TEAM_ID: "10%",
  TEAM_NAME: "44%",
  CREATED_BY: "15%",
  UPDATED_DATE: "15%",
  REMOVE: "5%",
};

@inject('commonStore', 'modalStore')
@observer
class ScreenTeam extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      teamName: '',
    }
  }

  // executeLogicAfterRender = () => {
  //   this.props.commonStore.teamStore.clean();
  //   this.props.commonStore.teamStore.getTeams();
  // }

  // executeLogicBeforeOutComponent = () => {
  //   this.props.commonStore.teamStore.clean();
  // }

  componentWillMount = () => {
    this.props.commonStore.teamStore.clean();
    this.props.commonStore.teamStore.getTeams();
  };

  doAfterAdd = teamId => {
    this.closeModal();
    this.props.history.push("/teams/edit/" + teamId);
  }

  onConfirmAdd = () => {
    const { commonStore: { teamStore } } = this.props;
    const { t } = this.props;
    if (this.state.teamName.trim() == "") {
      document.getElementById("required_msg").textContent = t("validate.field_is_empty");
    } else {
      let payload = {
        "name": this.state.teamName,
      }
      teamStore.addTeam(payload, this.openConfirmGoToAddUser);
      this.closeModal();
      this.state.teamName = "";
    }
  }

  onChangeItemNameNew = (value) => {
    document.getElementById("required_msg").textContent = '';
    this.state.teamName = value;
  }

  openConfirmGoToAddUser = teamId => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.ADD_USER_CONFIRM,
      isOpen: true,
      // header: t('team.modal_team_management_title'),
      notButtonX: true,
      // onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>{t('team.add_user_confirm_msg')}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t('common.ok')}
            onConfirm={() => this.doAfterAdd(teamId)}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: 'small'
    });

  }

  openAddModal = () => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.CREATE_TEAM,
      isOpen: true,
      header: t('team.modal_team_management_title'),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body modal-add-item">
            <div className="row">
              <div className="col-sm-1" />
              <div className="col-sm-4 font-bold">
                <span className="color-red">*</span>
                {t("setting_definition.required")}
              </div>
            </div>
            <div className="row" style={{ paddingTop: "15px" }}>
              <div className="col-sm-1" />
              <div className="col-sm-4 font-bold">{t("team.team_name")}<span className="color-red">*</span></div>
              <div className="col-sm-6" id="div_item_name">
                <input name="team_name"
                  type="text"
                  maxLength={255}
                  onChange={(value) => this.onChangeItemNameNew(value.target.value)}
                />
                <span className="color-red" id="required_msg"></span>
              </div>
            </div>
          </div>

          <ModalFooter key={"modal-footer"}
            saveButtonText={t('user.create')}
            onConfirm={() => this.onConfirmAdd()}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: 'medium'
    });
  };

  onConfirmDeleteById = (id) => {
    const { commonStore: { teamStore } } = this.props;
    teamStore.deleteTeam(
      id,
      () => {
        teamStore.getTeams();
        this.closeModal();
      }
    );
  }

  openComfirmDeleteModal = (id) => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.REMOVE,
      isOpen: true,
      header: t('definition.remove_confirm'),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>{t('team.remove_confirm_msg')}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t('team.remove')}
            onConfirm={() => this.onConfirmDeleteById(id)}
            cancelButtonText={t('definition.cancel')}
            onCancel={this.closeModal}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: 'small'
    });
  };

  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hideAll();
  };

  onEdit = (teamId) => {
    this.props.history.push("/teams/edit/" + teamId);
  };

  onFetch = (tableData) => {
    this.props.commonStore.teamStore.updatePagingFiler(tableData);
    this.props.commonStore.teamStore.getTeams();
  };

  render() {
    const { t, commonStore: { teamStore: { teams, paging } } } = this.props;
    const columns = [
      {
        Header: t("team.no"),
        disableSortBy: true,
        width: TABLE_ROW_WIDTH_MODEL.NO,
        Cell: ({ initialState, row }) => <div style={{ textAlign: "center" }}>{
          initialState?.initialTableState?.pageSize *
          initialState?.initialTableState?.pageIndex +
          row.index +
          1
        }</div>
        ,
      },
      {
        Header: t('team.team_id'),
        accessor: 'id',
        Cell: ({ row: { original } }) => {
          return (
            <span>
              {original.id ? original.id : EMPTY_FORMAT_CELL}
            </span>
          );
        },
        width: TABLE_ROW_WIDTH_MODEL.TEAM_ID
      },
      {
        Header: t('team.team_name'),
        accessor: 'name',
        Cell: ({ row: { original } }) => {
          return (
            <div className="color-theme cursor-p"
              onClick={() => this.onEdit(original.id)}>
              {original.name ? original.name : EMPTY_FORMAT_CELL}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_MODEL.TEAM_NAME
      },
      {
        Header: t('team.created_date'),
        accessor: 'createdAt',
        Cell: ({ row: { original } }) => {
          return (
            <span>
              {original.createdAt ? moment(original.createdAt).format(DATE_FORMAT.full_time) : NO_DATE}
            </span>
          );
        },
        width: TABLE_ROW_WIDTH_MODEL.CREATED_BY
      },
      {
        Header: t('team.updated_date'),
        accessor: 'updatedAt',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.updatedAt ? moment(original.updatedAt).format(DATE_FORMAT.full_time) : NO_DATE}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_MODEL.UPDATED_DATE
      },
      {
        Header: t("team.remove"),
        id: 'action',
        className: 'admin-action',
        Cell: ({ row: { original: { id } } }) => {
          return <div>
            <button className="btn btn-danger" type="button" onClick={() => this.openComfirmDeleteModal(id)}>
              {t('team.remove')}
            </button>
          </div>
        },
        width: 100,
      }
    ];

    let contractorName = "";
    if (localStorage.getItem("userInfo")) {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      contractorName = userInfo.tenantName;
    }

    return (
      <div className="screen-team">
        <ContainerBox headerTitle={`${t('team.page_title')}`}>
          <div className="admin-header-container">
            <div className="col-sm-2" style={{ textAlign: "left", fontWeight: "bold" }}>
              {t("team.contractor")}
            </div>
            <div className="col-sm-7" style={{ textAlign: "center" }}>
              {contractorName}
            </div>
            <div className="col-sm-3" style={{ textAlign: "right" }}>
              <button
                className="btn btn-primary"
                type="button"
                onClick={this.openAddModal}
              >
                {t("team.create_new_team")}
              </button>
            </div>
          </div>
        </ContainerBox>

        <ContainerBox type="basic" className="admin-body-container table-container">
          <div className="box-body document-item-info">
            <Table
              columns={columns}
              data={teams}
              disablePaging={false}
              onFetch={this.onFetch}
              enableServerSidePaging={true}
              initialTableState={paging}
            />
          </div>
        </ContainerBox>
      </div>
    );
  }
}

export default withRouter(withTranslation()(ScreenTeam));
