import { NotificationManager } from "react-notifications";

const ReactNotifications = (type, message, action, t) => {
    const timeClose = 3000;
    switch (type) {
        case "info":
            NotificationManager.info(message, action, timeClose);
            break;
        case "success":
            NotificationManager.success(
                t(action + " Success", action + " Success"),
                "",
                timeClose
            );
            break;
        case "warning":
            NotificationManager.warning(
                message,
                action + " Warning",
                timeClose
            );
            break;
        case "error":
            NotificationManager.error(message, "", timeClose);
            break;
        default:
            NotificationManager.success(
                t(action + " Success", action + " Success"),
                "",
                timeClose
            );
    }
};

export default ReactNotifications;
