import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { InputField, ModalFooter } from "components";
import { Scrollbars } from "react-custom-scrollbars";
import { Validate } from "core/utils";
import "./style.scss";

@inject("modalStore", "commonStore")
@observer
class DetailDefinition extends Component {
  constructor(props) {
    super(props);
    const { definitionDetail } = this.props;
    this.state = {
      editablePass: false,
      status: false,
      definitionDetail: definitionDetail,
      error: {}
    };
  }

  onChange = (name, value, messageError) => {
    const { definitionDetail, error } = this.state;
    definitionDetail[name] = value;
    if (!messageError || error[name] !== messageError) {
      error[name] = "";
    }
    this.setState({ definitionDetail });
  };

  onConfirm = () => {
    const { definitionDetail } = this.state;
    const { t } = this.props;
    let err = true;
    let error = {};
    const checkId = Validate.validateNotNull(definitionDetail?.id, t);
    const checkName = Validate.validateNotNull(definitionDetail?.configName, t);
    if (checkId) {
      err = false;
      error.id = checkId;
    }
    if (checkName) {
      err = false;
      error.configName = checkName;
    }
    if (err) {
      this.openComfirmModal();
    }
    this.setState({ error });
  };

  onHandleSaveUser = isDeleted => {
    if (isDeleted) {
      this.openComfirmOverrideUserModal()
    } else {
      this.closeAllModal()
      this.props.commonStore.userStore.getUsers();
    }
  }

  onSaveDetail = (forceCreate = false) => {
    const { definitionDetail } = this.state;
    const {
      commonStore: { definitionStore },
      t
    } = this.props;
    definitionStore.updateDefinition(
      definitionDetail,
      () => {
        this.props.commonStore.definitionStore.getDefinitions();
        this.closeAllModal();
      },
      () => this.setState({ definitionDetail: definitionDetail }),
      t
    );
  };

  onEditablePass = (value) => {
    const { userDetail } = this.state;
    delete userDetail["password"];
    delete userDetail["confirmPassword"];
    this.setState({
      editablePass: value,
      error: {}
    });
  };

  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hide();
  };

  closeAllModal = () => {
    const { modalStore } = this.props;
    modalStore.hideAll();
  };

  openComfirmModal = () => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: "confirm",
      isOpen: true,
      header: t("setting_definition.update_confirm"),
      onCancel: () => this.closeModal("confirm"),
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>
              {t("setting_definition.change_information")}
            </span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t("setting_definition.save")}
            onConfirm={() => {
              this.closeModal();
              this.onSaveDetail();

            }}
            cancelButtonText={t("setting_definition.cancel")}
            onCancel={this.closeModal}
          />
        </div>
      ),
      type: "small"
    });
  };

  openComfirmOverrideUserModal = () => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: "confirm",
      isOpen: true,
      header: t("user.confirmation"),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>
              {t('user.re-create-user-messsage')}
            </span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t("user.create")}
            onConfirm={() => {
              this.closeModal();
              this.onSaveUserDetail(true);
            }}
            cancelButtonText={t("user.cancel")}
            onCancel={this.closeModal}
          />
        </div>
      ),
      type: "small"
    });
  };

  render() {
    const { t, saveButtonText } = this.props;
    const { definitionDetail, error } = this.state;
    return (
      <div className="detail-definition">
        <Scrollbars key={"modal-body"} className="modal-body">
          <div className="row">
            <div className="col-sm-1" />
            <div className="col-sm-4 font-bold">
              <span className="color-red">*</span>
              {t("setting_definition.required")}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1 p-0"></div>
            <div className="col-sm-4 font-bold">
              {t("setting_definition.config_name")}
              <span className="color-red">*</span>
            </div>
            <div className="col-sm-6">
              <InputField
                name="configName"
                maxLength={255}
                value={definitionDetail?.configName}
                onChange={this.onChange}
                type="text"
                placeholder={t("setting_definition.config_name")}
                validate={Validate.validateNotNull}
                error={error.configName}
              />
            </div>
          </div>
        </Scrollbars>
        <ModalFooter key={"modal-footer"}
          saveButtonText={saveButtonText}
          onConfirm={this.onConfirm}
        />
      </div>
    );
  }
}

export default withTranslation()(DetailDefinition);
