import { SYSTEM_PATH } from "../../core/utils/constants";
const menus = [
  {
    key: "upload",
    path: SYSTEM_PATH.UPLOAD,
    name: "menu.report_upload",
    permission: ["0", "1", "2"]
  },
  {
    key: "download",
    path: SYSTEM_PATH.DOCUMENT,
    name: "menu.csv_download",
    permission: ["0", "1", "2"]
  },
  {
    key: "documentsdownload",
    path: SYSTEM_PATH.DOCUMENT_DOWNLOAD,
    name: "menu.report_download",
    permission: ["0", "1", "2"]
  },
  {
    key: "definition",
    path: SYSTEM_PATH.FORM_DEFINITION,
    name: "menu.form_definition",
    permission: ["0", "2"]
  },
  {
    key: "templatesorting",
    path: SYSTEM_PATH.TEMPLATE_SORTING,
    name: "menu.template_sorting",
    permission: ["0", "2"]
  },
  {
    key: "formsetting",
    path: SYSTEM_PATH.FORM_SETTING,
    name: "menu.form_setting",
    permission: ["0", "2"]
  },
  {
    key: "model",
    path: SYSTEM_PATH.MODEL_MNG,
    name: "menu.model_management",
    permission: ["0", "2"]
  },
  {
    key: "postprocessing",
    path: SYSTEM_PATH.POST_PROCESSING,
    name: "menu.post_processing",
    permission: ["0", "2"]
  },
  {
    key: "admin",
    path: SYSTEM_PATH.ADMIN,
    name: "menu.user_management",
    permission: ["0", "2"]
  },
  {
    key: "team",
    path: SYSTEM_PATH.TEAM,
    name: "menu.team",
    permission: ["0", "2"]
  },
  {
    key: "tenant",
    path: SYSTEM_PATH.TENANT,
    name: "menu.tenant_management",
    permission: ["2"]
  },
  {
    key: "generalsetting",
    path: SYSTEM_PATH.GENERAL_SETTING,
    name: "menu.general_setting",
    permission: ["0", "2"]
  },
  {
    key: "individualsetting",
    path: SYSTEM_PATH.INDIVIDUAL_SETTING,
    name: "menu.individual_setting",
    permission: ["0", "1", "2"]
  },
  {
    key: "actionlogs",
    path: SYSTEM_PATH.ACTION_LOG,
    name: "menu.action_logs",
    permission: ["0", "2"]
  },
];

export default menus;
