import React from "react";
import { withTranslation } from "react-i18next";
import { DEFAULT_PAGE_SIZE } from "../../core/utils/constants";
import "./style.scss";

class SelectPageSize extends React.Component {
  render() {
    const { value, onChange, values, t, disabled } = this.props;
    return (
      <select value={value || DEFAULT_PAGE_SIZE} onChange={onChange} className="select" style={{ display: disabled ? "none" : "" }}>
        {values.map(val => (
          <option key={val} value={val}>
            {t("pagging.show")} {val}
          </option>
        ))}
      </ select>
    );
  }
}

export default withTranslation()(SelectPageSize);
