import React, { Component } from 'react'
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { ContainerBox } from 'components';
import BaseComponent from '../BaseComponent';
import { LANGUAGE_CODE, DOWNLOAD_FILE_FORMAT, DOWNLOAD_FILE_FORMAT_DEFAULT, DOWNLOAD_FILE_CHAR_ENCODING, DOWNLOAD_FILE_CHAR_ENCODING_DEFAULT, DOWNLOAD_FILE_RECOGNIZE_NEWLINE, DOWNLOAD_FILE_RECOGNIZE_NEWLINE_DEFAULT, READ_COMPLETE_EMAIL_NOTIFICATION, READ_COMPLETE_EMAIL_NOTIFICATION_DEFAULT, READ_COMPLETE_EMAIL_NOTIFICATION_DEFAULT_LGWAN } from "../../core/utils/constants";
import './style.scss';
import URL_CONFIG from './../../core/services/config'

@inject('commonStore', 'modalStore')
@observer
class ScreenIndividualSetting extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            language_setting: LANGUAGE_CODE.JP,
            download_file_format: DOWNLOAD_FILE_FORMAT_DEFAULT,
            download_file_char_encoding: DOWNLOAD_FILE_CHAR_ENCODING_DEFAULT,
            download_file_recognize_newline: DOWNLOAD_FILE_RECOGNIZE_NEWLINE_DEFAULT,
            read_complete_email_notification: URL_CONFIG.is_connect_to_azure_app_insight ? 
                READ_COMPLETE_EMAIL_NOTIFICATION_DEFAULT : 
                READ_COMPLETE_EMAIL_NOTIFICATION_DEFAULT_LGWAN,
        }
    }

    // Set value for controls after render DOM
    executeLogicAfterRender = async () => {
        const { commonStore: { individualSettingStore: { getSetting } } } = this.props;
        const setting = await getSetting();
        if (setting) {
            // Update state
            this.setState({
                language_setting: setting.languageSetting ? setting.languageSetting : LANGUAGE_CODE.JP,
                download_file_format: setting.downloadFileFormat,
                download_file_char_encoding: setting.downloadFileCharEncoding,
                download_file_recognize_newline: setting.downloadFileRecognizeNewline,
                read_complete_email_notification: URL_CONFIG.is_connect_to_azure_app_insight ? 
                    setting.readCompleteEmailNotification : 
                    READ_COMPLETE_EMAIL_NOTIFICATION_DEFAULT_LGWAN,
            });
            // Set language for web page
            this.props.commonStore.switchLanguagesStore.onSwitch(this.state.language_setting);
        }
    }

    executeOnDidUpdate = () => {
        // Attach tooltip
        window.$('[data-toggle="tooltip"]').tooltip('destroy');
        window.$('[data-toggle="tooltip"]').tooltip({ container: '.screen-individual-setting' });
    }

    // Select language
    handleSelectLanguage = (value) => {
        this.setState({
            language_setting: value
        });
    }

    // Select download_file_format
    handleSelectDwnFileFormat = (value) => {
        document.getElementById("file_format_required_msg").textContent = '';
        this.setState({
            download_file_format: value
        });
    }

    // Select download_file_char_encoding
    handleSelectDwnFileCharEnc = (value) => {
        document.getElementById("file_char_encoding_format_required_msg").textContent = '';
        this.setState({
            download_file_char_encoding: value
        });
    }

    // Select download_file_recognize_newline
    handleSelectDwnFileRecgNewline = (value) => {
        document.getElementById("file_recognize_newline_required_msg").textContent = '';
        this.setState({
            download_file_recognize_newline: value
        });
    }

    // Select read_complete_email_notification
    handleSelectDwnReadCmplNotification = (value) => {
        document.getElementById("read_complete_email_notification_required_msg").textContent = '';
        this.setState({
            read_complete_email_notification: value
        });
    }

    // Submit
    handleSubmit = () => {
        document.getElementById("file_format_required_msg").textContent = '';
        document.getElementById("file_char_encoding_format_required_msg").textContent = '';

        const { t, commonStore: { individualSettingStore: { setSetting } } } = this.props;
        if (this.state.download_file_format === null || this.state.download_file_format === '') {
            document.getElementById("file_format_required_msg").textContent = t("validate.field_is_require");
        }
        else if (this.state.download_file_char_encoding === null || this.state.download_file_char_encoding === '') {
            document.getElementById("file_char_encoding_format_required_msg").textContent = t("validate.field_is_require");
        }
        else if (this.state.download_file_recognize_newline === null || this.state.download_file_recognize_newline === '') {
            document.getElementById("file_recognize_newline_required_msg").textContent = t("validate.field_is_require");
        }
        else if (this.state.read_complete_email_notification === null || this.state.read_complete_email_notification === '') {
            document.getElementById("read_complete_email_notification_required_msg").textContent = t("validate.field_is_require");
        }
        else {
            const payload = {
                language_setting: this.state.language_setting,
                download_file_format: this.state.download_file_format,
                download_file_char_encoding: this.state.download_file_char_encoding,
                download_file_recognize_newline: this.state.download_file_recognize_newline,
                read_complete_email_notification: URL_CONFIG.is_connect_to_azure_app_insight ? 
                    this.state.read_complete_email_notification : 
                    READ_COMPLETE_EMAIL_NOTIFICATION_DEFAULT_LGWAN,
            };
            setSetting(payload).then(res => {
                if (res) {
                    // Update state
                    this.setState({
                        language_setting: res.languageSetting,
                        download_file_format: res.downloadFileFormat,
                        download_file_char_encoding: res.downloadFileCharEncoding,
                        download_file_recognize_newline: res.downloadFileRecognizeNewline,
                        read_complete_email_notification: URL_CONFIG.is_connect_to_azure_app_insight ? 
                            res.readCompleteEmailNotification : 
                            READ_COMPLETE_EMAIL_NOTIFICATION_DEFAULT_LGWAN,
                        });
                    // Set language for web page
                    this.props.commonStore.switchLanguagesStore.onSwitch(res.languageSetting);
                }
            });
        }
    }

    render() {
        const { t } = this.props;
        const { language_setting, download_file_format, download_file_char_encoding,
            download_file_recognize_newline, read_complete_email_notification} = this.state;
        return (
            <div className="screen-individual-setting">
                <ContainerBox headerTitle={t("individual_setting.page_title")} className="admin-sb-container">
                    <div className="box-body">
                        {/* Setting language start */}
                        <div className="row">
                            <div className="col-sm-3 font-bold">
                                {t("individual_setting.language")}
                            </div>
                            <div className="col-sm-9" style={{"display": "flex"}}>
                                <div style={{"width": "70px"}}>
                                    <input type="radio" id="ip-lng-jp" name="language_setting" value={LANGUAGE_CODE.JP}
                                        checked={language_setting === LANGUAGE_CODE.JP}
                                        onChange={()=>{}}
                                        onClick={() => this.handleSelectLanguage(LANGUAGE_CODE.JP)}>
                                    </input>
                                    <label htmlFor="ip-lng-jp" style={{ marginLeft: "8px" }}>
                                        {t("individual_setting.language.japan")}
                                    </label>
                                </div>
                                <div style={{"marginLeft": "16px"}}>
                                    <input type="radio" id="ip-lng-en" name="language_setting" value={LANGUAGE_CODE.EN}
                                        checked={language_setting === LANGUAGE_CODE.EN}
                                        onChange={()=>{}}
                                        onClick={() => this.handleSelectLanguage(LANGUAGE_CODE.EN)}>
                                    </input>
                                    <label htmlFor="ip-lng-en" style={{ marginLeft: "8px" }}>
                                        {t("individual_setting.language.english")}
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* Setting language end */}

                        {/* Output CSV start */}
                        <div className="row">
                            <div className="col-sm-3 font-bold">
                                {t("individual_setting.download_csv.file_format")}
                            </div>
                            <div className="col-sm-9" style={{"display": "flex"}}>
                                <div style={{"width": "70px"}}>
                                    <input type="radio" id="ip-fm-csv" name="download_file_format" value={DOWNLOAD_FILE_FORMAT.CSV}
                                        checked={download_file_format === DOWNLOAD_FILE_FORMAT.CSV}
                                        onChange={()=>{}}
                                        onClick={() => this.handleSelectDwnFileFormat(DOWNLOAD_FILE_FORMAT.CSV)}>
                                    </input>
                                    <label htmlFor="ip-fm-csv" style={{ marginLeft: "8px" }}>
                                        {t("individual_setting.download_csv.file_format.csv")}
                                    </label>
                                    <i className="fa fa-info-circle ar-tooltip"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={t("individual_setting.download_csv.file_format.tooltip")}>
                                    </i>
                                </div>
                                <div style={{"marginLeft": "16px"}}>
                                    <input type="radio" id="ip-fm-csv-airead" name="download_file_format" value={DOWNLOAD_FILE_FORMAT.CSV_AIREAD}
                                        checked={download_file_format === DOWNLOAD_FILE_FORMAT.CSV_AIREAD}
                                        onChange={()=>{}}
                                        onClick={() => this.handleSelectDwnFileFormat(DOWNLOAD_FILE_FORMAT.CSV_AIREAD)}>
                                    </input>
                                    <label htmlFor="ip-fm-csv-airead" style={{ marginLeft: "8px" }}>
                                        {t("individual_setting.download_csv.file_format.csv_airead")}
                                    </label>
                                    <i className="fa fa-info-circle ar-tooltip"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={t("individual_setting.download_csv.file_format.csv.tooltip")}>
                                    </i>
                                </div>
                                <span style={{marginLeft: "20px"}} className="color-red" id="file_format_required_msg"></span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 font-bold">
                                {t("individual_setting.download_csv.file_character_code")}
                            </div>
                            <div className="col-sm-9" style={{"display": "flex"}}>
                                <div style={{"width": "70px"}}>
                                    <input type="radio" id="ip-fm-enc-sjis" name="download_file_char_encoding" value={DOWNLOAD_FILE_CHAR_ENCODING.SJIS}
                                        checked={download_file_char_encoding === DOWNLOAD_FILE_CHAR_ENCODING.SJIS}
                                        onChange={()=>{}}
                                        onClick={() => this.handleSelectDwnFileCharEnc(DOWNLOAD_FILE_CHAR_ENCODING.SJIS)}>
                                    </input>
                                    <label htmlFor="ip-fm-enc-sjis" style={{ marginLeft: "8px" }}>
                                        S-JIS
                                    </label>
                                </div>
                                <div style={{"marginLeft": "16px", "width": "70px"}}>
                                    <input type="radio" id="ip-fm-enc-utf8" name="download_file_char_encoding" value={DOWNLOAD_FILE_CHAR_ENCODING.UTF_8_WITHOUT_BOM}
                                        checked={download_file_char_encoding === DOWNLOAD_FILE_CHAR_ENCODING.UTF_8_WITHOUT_BOM}
                                        onChange={()=>{}}
                                        onClick={() => this.handleSelectDwnFileCharEnc(DOWNLOAD_FILE_CHAR_ENCODING.UTF_8_WITHOUT_BOM)}>
                                    </input>
                                    <label htmlFor="ip-fm-enc-utf8" style={{ marginLeft: "8px" }}>
                                        UTF-8
                                    </label>
                                </div>
                                <div style={{"marginLeft": "16px"}}>
                                    <input type="radio" id="ip-fm-enc-utf8-bom" name="download_file_char_encoding" value={DOWNLOAD_FILE_CHAR_ENCODING.UTF_8_WITH_BOM}
                                        checked={download_file_char_encoding === DOWNLOAD_FILE_CHAR_ENCODING.UTF_8_WITH_BOM}
                                        onChange={()=>{}}
                                        onClick={() => this.handleSelectDwnFileCharEnc(DOWNLOAD_FILE_CHAR_ENCODING.UTF_8_WITH_BOM)}>
                                    </input>
                                    <label htmlFor="ip-fm-enc-utf8-bom" style={{ marginLeft: "8px" }}>
                                        UTF-8(with BOM)
                                    </label>
                                </div>
                                <span style={{marginLeft: "20px"}} className="color-red" id="file_char_encoding_format_required_msg"></span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 font-bold">
                                {t("individual_setting.download_csv.file_recognize_newline")}
                            </div>
                            <div className="col-sm-9" style={{"display": "flex"}}>
                                <div style={{"width": "110px"}}>
                                    <input type="radio" id="ip-fm-newline-string" name="download_file_recognize_newline" value={DOWNLOAD_FILE_RECOGNIZE_NEWLINE.NEWLINE_STRING}
                                        checked={download_file_recognize_newline === DOWNLOAD_FILE_RECOGNIZE_NEWLINE.NEWLINE_STRING}
                                        onChange={()=>{}}
                                        onClick={() => this.handleSelectDwnFileRecgNewline(DOWNLOAD_FILE_RECOGNIZE_NEWLINE.NEWLINE_STRING)}>
                                    </input>
                                    <label htmlFor="ip-fm-newline-string" style={{ marginLeft: "8px" }}>
                                        {t("individual_setting.download_csv.file_recognize_newline.string")}
                                    </label>
                                    <i className="fa fa-info-circle ar-tooltip"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={t("individual_setting.download_csv.file_recognize_newline.string.tooltip")}>
                                    </i>
                                </div>
                                <div style={{"marginLeft": "16px"}}>
                                    <input type="radio" id="ip-fm-newline-code" name="download_file_recognize_newline" value={DOWNLOAD_FILE_RECOGNIZE_NEWLINE.NEWLINE_CODE}
                                        checked={download_file_recognize_newline === DOWNLOAD_FILE_RECOGNIZE_NEWLINE.NEWLINE_CODE}
                                        onChange={()=>{}}
                                        onClick={() => this.handleSelectDwnFileRecgNewline(DOWNLOAD_FILE_RECOGNIZE_NEWLINE.NEWLINE_CODE)}>
                                    </input>
                                    <label htmlFor="ip-fm-newline-code" style={{ marginLeft: "8px" }}>
                                        {t("individual_setting.download_csv.file_recognize_newline.code")}
                                    </label>
                                    <i className="fa fa-info-circle ar-tooltip"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={t("individual_setting.download_csv.file_recognize_newline.code.tooltip")}>
                                    </i>
                                </div>
                                <span style={{marginLeft: "20px"}} className="color-red" id="file_recognize_newline_required_msg"></span>
                            </div>
                        </div>
                        {/* Output CSV end */}

                        {/* Email Notification start */}
                        <div className="row">
                            <div className="col-sm-3 font-bold">
                                {t("individual_setting.read_complete_email_notification")}
                            </div>
                            <div className="col-sm-9" style={{"display": "flex"}}>
                                <div style={{"width": "70px"}}>
                                    <input type="radio" id="ip-email-on" name="read_complete_email_notification" value={READ_COMPLETE_EMAIL_NOTIFICATION.ON}
                                        checked={read_complete_email_notification === READ_COMPLETE_EMAIL_NOTIFICATION.ON}
                                        disabled={!URL_CONFIG.is_connect_to_azure_app_insight}
                                        onChange={()=>{}}
                                        onClick={() => this.handleSelectDwnReadCmplNotification(READ_COMPLETE_EMAIL_NOTIFICATION.ON)}>
                                    </input>
                                    <label htmlFor="ip-email-on" style={{ marginLeft: "8px" }}>
                                        {t("individual_setting.read_complete_email_notification.on")}
                                    </label>
                                </div>
                                <div style={{"marginLeft": "16px"}}>
                                    <input type="radio" id="ip-email-off" name="read_complete_email_notification" value={READ_COMPLETE_EMAIL_NOTIFICATION.OFF}
                                        checked={read_complete_email_notification === READ_COMPLETE_EMAIL_NOTIFICATION.OFF}
                                        disabled={!URL_CONFIG.is_connect_to_azure_app_insight}
                                        onChange={()=>{}}
                                        onClick={() => this.handleSelectDwnReadCmplNotification(READ_COMPLETE_EMAIL_NOTIFICATION.OFF)}>
                                    </input>
                                    <label htmlFor="ip-email-off" style={{ marginLeft: "8px" }}>
                                        {t("individual_setting.read_complete_email_notification.off")}
                                    </label>
                                </div>
                                <span style={{marginLeft: "20px"}} className="color-red" id="read_complete_email_notification_required_msg"></span>
                            </div>
                        </div>
                        {/* Email Notification end */}

                        <div className="row">
                            <div className="col-sm-12" style={{ textAlign: "center" }}>
                                <button className="btn btn-primary" onClick={this.handleSubmit}>
                                    {t("individual_setting.save")}
                                </button>
                            </div>
                        </div>
                    </div>
                </ContainerBox>
            </div>
        )
    }
}

export default withRouter(withTranslation()(ScreenIndividualSetting));