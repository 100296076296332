import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import CONFIG from "./core/services/config";

import en from './locales/en'
import jp from './locales/jp'

let resources = {
    en: en,
    jp: jp
}
i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        interpolation: { escapeValue: false }, // React already does escaping
        lng: CONFIG.defaultLang,
        // debug: true,
        keySeparator: false,
        resources,
    });

export default i18n;
