import { FileApi } from "apis";
import { ReactNotifications } from "core/logic";
class FileStore {
    async upload(payload) {
        const response = await FileApi.upload(payload);
        if (response && response.message === "OK") {
            ReactNotifications("success", response.message, "Upload");
        } else {
            ReactNotifications("error", response.message, "Upload");
        }
    }
    async read() {
        ReactNotifications("success", "", "Read");
        // const response = await FileApi.read();
        // if (response && response.message === "OK") {
        //     ReactNotifications("success", response.message, "Upload");
        // } else {
        //     ReactNotifications("error", response.message, "Upload");
        // }
    }
}
export default FileStore;
