import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { InputWrap, InputCheckbox } from "components";
import { observer, inject } from 'mobx-react';
import { LOG_ACCESSOR_SEARCH_BOX, DATE_FORMAT, DATE_PLACHOLDER_FORMAT, DATE_SEPARATE } from '../../core/utils/constants';
import DateComponent from '../../components/DateComponent'
import "./style.scss";
import moment from 'moment'
@inject('commonStore', 'actionLogsStore', 'modalStore')
@observer
class SearchBox extends Component {
    tableOriginalSize = 0;
    constructor(props) {
        super(props);
        const { savedData } = this.props;
        this.state = {
            filterData: {},
            errors: {
                fromTime: "",
                toTime: "",
                hasError: () => {
                    return (this.state?.errors?.fromTime !== "" || this.state?.errors?.toTime !== "")
                }
            },
            isCollapseOut: (savedData?.isCollapseOut === true) ? savedData?.isCollapseOut : false,
        };
    }
    /**
     * handleFilterDataChange
     * handle all filter data change
     * update current state
     * 
     * @param {string} name name of the field
     * @param {string} value value of the field
     * 
     * @returns Null setState and rerender component
     */
    handleFilterDataChange = (name, value) => {
        const { actionLogsStore } = this.props;
        if ([LOG_ACCESSOR_SEARCH_BOX.TO_TIME, LOG_ACCESSOR_SEARCH_BOX.FROM_TIME].includes(name)) {
            if (value) {
                let timeStamp = moment(value).format(DATE_FORMAT.time_stamp)
                this.validationDateData(name, timeStamp)
            }
        }
        let data = {}
        data[name] = value;
        actionLogsStore.updateTempSearchBoxFiler(data);
    }

    /**
     * componentWillMount
     * get definitions of user
     * 
     */
    componentWillMount() {
        this.props.commonStore.userStore.getUsersContainAdmin();
    }

    componentDidMount() {
        const { isCollapseOut } = this.state;
        document.getElementById("collapsible").checked = isCollapseOut;
        this.actionLogHandleCollapse(!isCollapseOut);
        let tableContainerHeight = document.getElementsByClassName("table-container");
        if (tableContainerHeight && tableContainerHeight[0]) {
            this.tableOriginalSize = tableContainerHeight[0].offsetHeight;
        }

        this.tableHandler();
    }

    /**
     * validationDateData
     * 
     * rules:
     * fromTime is required when toTime has value
     * toTime is required when fromTime has value
     * fromTime is smaller than fromTime
     * 
     * @param {string} currentField name of the field
     * @param {string} newValue value of the field
     * 
     * @returns set errors setState and rerender component
     */
    validationDateData = (currentField, newValue) => {
        let filterData = this.state.filterData
        let errors = this.state.errors;

        errors.fromTime = "";
        errors.toTime = "";
        if (newValue) {
            if (currentField === LOG_ACCESSOR_SEARCH_BOX.FROM_TIME && filterData.toTime && newValue > filterData.toTime) {
                errors.fromTime = 'search_box.from_time_greater_to_time';
            }
            if (currentField === LOG_ACCESSOR_SEARCH_BOX.TO_TIME && filterData.fromTime && filterData.fromTime > newValue) {
                errors.toTime = 'search_box.from_time_greater_to_time';
            }
        }
        this.setState({ errors: { ...this.state.errors, ...errors } })
    }

    actionLogHandleCollapse(isCollapseOut) {
        let element = document.getElementById("collapse-header");
        if (isCollapseOut && element) {
            element.classList.add("collapse_in");
            element.classList.remove("collapse_out");
        } else {
            element.classList.remove("collapse_in");
            element.classList.add("collapse_out");
        }
    }

    collapseHander = (e) => {
        const { isCollapseOut } = this.state;
        const { actionLogsStore } = this.props;

        actionLogsStore.updateSearchBoxCollapse(!isCollapseOut);
        this.actionLogHandleCollapse(isCollapseOut);
        document.getElementById("collapsible").checked = !isCollapseOut
        this.setState({ isCollapseOut: !isCollapseOut });
        this.tableHandler();
    }

    tableHandler = () => {
        // compute table.
        // search box height
        let collapsibleContentHeight = document.getElementsByClassName("collapsible-content");
        if (collapsibleContentHeight && collapsibleContentHeight[0]) {
            let tableContainerHeight = document.getElementsByClassName("table-container");
            if (tableContainerHeight && tableContainerHeight[0]) {
                let tableHeight = tableContainerHeight[0].offsetHeight;
                if (collapsibleContentHeight[0].offsetHeight === 0) {
                    tableContainerHeight[0].style.height = this.tableOriginalSize - 2 + "px";
                } else {
                    tableContainerHeight[0].style.height = tableHeight - collapsibleContentHeight[0].offsetHeight + 10.7 + "px";
                }

                // compute for table body
                let table = document.getElementsByTagName("table");
                if (table && table[0]) {
                    let tableHead = document.getElementsByTagName("thead");
                    if (tableHead && tableHead[0]) {
                        let tableBody = document.getElementsByTagName("tbody");
                        if (tableBody && tableBody[0]) {
                            let tableBodyHeight = table[0].offsetHeight - tableHead[0].offsetHeight;
                            tableBody[0].style.height = tableBodyHeight + "px";
                        }
                    }
                }
            }
        }
    }

    /**
     * render
     * render search box
     * 
     */
    render() {
        const { t, commonStore: { userStore } } = this.props;
        return (
            <div className="search-form">
                <div id="collapse-header" className="collapse-header" onClick={() => this.collapseHander()}>
                    <h4 className="sb-form-title">{t("log_search_box.narrowing_conditions")}</h4>
                </div>
                <input id="collapsible" className="toggle" type="checkbox"></input>

                <div className="collapsible-content row action-log-search-pannel">
                    <div className="row row-2nd">
                        <div style={{ display: "flex" }}>
                            <div style={{ lineHeight: "30px" }}>
                                <label>{t("log_search_box.executed_date")}</label>
                            </div>
                            <div className={"searchbox-dp " + (this.state.errors[LOG_ACCESSOR_SEARCH_BOX.FROM_TIME] !== "" ? "error" : "")}>
                                <InputWrap customClass="registered-date-input-wrap">
                                    <div className="input-group date">
                                        <label className="date-label">
                                            <div className="input-group-addon">
                                                <i className="fa fa-calendar"></i>
                                            </div>
                                            <DateComponent placeholder={DATE_PLACHOLDER_FORMAT} name={LOG_ACCESSOR_SEARCH_BOX.FROM_TIME} onChange={this.handleFilterDataChange}
                                                value={this.props.actionLogsStore?.tempFilterData?.fromTime} maxDate={new Date()} />
                                        </label>
                                    </div>
                                    <span className="color-red">{t(this.state.errors[LOG_ACCESSOR_SEARCH_BOX.FROM_TIME])}</span>
                                </InputWrap>
                            </div>
                            <div className="sb-dp-separate">
                                <label>{DATE_SEPARATE}</label>
                            </div>
                            <div className={"searchbox-dp " + (this.state.errors[LOG_ACCESSOR_SEARCH_BOX.TO_TIME] !== "" ? "error" : "")}>
                                <InputWrap>
                                    <label className="hideLabel">{t("log_search_box.to_date")}</label>
                                    <div className="input-group date ">
                                        <label className="date-label">
                                            <div className="input-group-addon">
                                                <i className="fa fa-calendar"></i>
                                            </div>
                                            <DateComponent placeholder={DATE_PLACHOLDER_FORMAT} name={LOG_ACCESSOR_SEARCH_BOX.TO_TIME} onChange={this.handleFilterDataChange}
                                                value={this.props.actionLogsStore?.tempFilterData?.toTime} maxDate={new Date()} />
                                        </label>
                                    </div>
                                </InputWrap>
                                <span className="color-red">{t(this.state.errors[LOG_ACCESSOR_SEARCH_BOX.TO_TIME])}</span>
                            </div>
                        </div>

                        <div className="l10">
                            <label>{t("log_search_box.user_id")}</label>
                            <select className="select download-select"
                                onChange={(e) => this.handleFilterDataChange(LOG_ACCESSOR_SEARCH_BOX.USER_ID, e?.target?.value)}
                                value={this.props.actionLogsStore.tempFilterData?.userId ? this.props.actionLogsStore.tempFilterData?.userId : ""}>
                                <option key={"null"} value={"null"}>{t("log_search_box.all_definition_file")}</option>
                                {
                                    userStore.users?.map((user) => {
                                        return <option key={user?.userId} value={user?.userId}>{user?.userId}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <div className="row row-3rd">
                        <div style={{ whiteSpace: "nowrap" }}>
                            <label className="lbl-status">{t("log_search_box.action")}</label>
                        </div>
                        <div>
                            <InputCheckbox
                                id={LOG_ACCESSOR_SEARCH_BOX.ACTION_LOGIN}
                                type="checkbox"
                                label={t("log_search_box.action_login")}
                                onChange={(e) => this.handleFilterDataChange(LOG_ACCESSOR_SEARCH_BOX.ACTION_LOGIN, e?.target?.checked)}
                                checked={this.props.actionLogsStore?.tempFilterData?.login ? true : false}
                            />
                            <InputCheckbox
                                id={LOG_ACCESSOR_SEARCH_BOX.ACTION_LOGOUT}
                                type="checkbox"
                                label={t("log_search_box.action_logout")}
                                onChange={(e) => this.handleFilterDataChange(LOG_ACCESSOR_SEARCH_BOX.ACTION_LOGOUT, e?.target?.checked)}
                                checked={this.props.actionLogsStore?.tempFilterData?.logout ? true : false}
                            />
                            <InputCheckbox
                                id={LOG_ACCESSOR_SEARCH_BOX.ACTION_RECORD_UPLOAD}
                                type="checkbox"
                                label={t("log_search_box.action_record_upload")}
                                onChange={(e) => this.handleFilterDataChange(LOG_ACCESSOR_SEARCH_BOX.ACTION_RECORD_UPLOAD, e?.target?.checked)}
                                checked={this.props.actionLogsStore?.tempFilterData?.documentUpload ? true : false}
                            />
                            <InputCheckbox
                                id={LOG_ACCESSOR_SEARCH_BOX.ACTION_CSV_DOWNLOAD}
                                type="checkbox"
                                label={t("log_search_box.action_csv_download")}
                                onChange={(e) => this.handleFilterDataChange(LOG_ACCESSOR_SEARCH_BOX.ACTION_CSV_DOWNLOAD, e?.target?.checked)}
                                checked={this.props.actionLogsStore?.tempFilterData?.csvDownload ? true : false}
                            />
                            <InputCheckbox
                                id={LOG_ACCESSOR_SEARCH_BOX.ACTION_FORM_DEFINITION_UPLOAD}
                                type="checkbox"
                                label={t("log_search_box.action_form_definition_upload")}
                                onChange={(e) => this.handleFilterDataChange(LOG_ACCESSOR_SEARCH_BOX.ACTION_FORM_DEFINITION_UPLOAD, e?.target?.checked)}
                                checked={this.props.actionLogsStore?.tempFilterData?.formUpload ? true : false}
                            />
                            <InputCheckbox
                                id={LOG_ACCESSOR_SEARCH_BOX.ACTION_CORRECT_READING_RESULT}
                                type="checkbox"
                                label={t("log_search_box.action_correct_reading_result")}
                                onChange={(e) => this.handleFilterDataChange(LOG_ACCESSOR_SEARCH_BOX.ACTION_CORRECT_READING_RESULT, e?.target?.checked)}
                                checked={this.props.actionLogsStore?.tempFilterData?.editDocument ? true : false}
                            />
                            <InputCheckbox
                                id={LOG_ACCESSOR_SEARCH_BOX.ACTION_DELETE_DOCUMENT}
                                type="checkbox"
                                label={t("log_search_box.action_delete_document")}
                                onChange={(e) => this.handleFilterDataChange(LOG_ACCESSOR_SEARCH_BOX.ACTION_DELETE_DOCUMENT, e?.target?.checked)}
                                checked={this.props.actionLogsStore?.tempFilterData?.documentDelete ? true : false}
                            />
                            <InputCheckbox
                                id={LOG_ACCESSOR_SEARCH_BOX.ACTION_DEBUG_DOWNLOAD}
                                type="checkbox"
                                label={t("log_search_box.action_debug_download")}
                                onChange={(e) => this.handleFilterDataChange(LOG_ACCESSOR_SEARCH_BOX.ACTION_DEBUG_DOWNLOAD, e?.target?.checked)}
                                checked={this.props.actionLogsStore?.tempFilterData?.debugDownload ? true : false}
                            />
                            <InputCheckbox
                                id={LOG_ACCESSOR_SEARCH_BOX.ACTION_RECORD_DOWNLOAD}
                                type="checkbox"
                                label={t("log_search_box.action_record_download")}
                                onChange={(e) => this.handleFilterDataChange(LOG_ACCESSOR_SEARCH_BOX.ACTION_RECORD_DOWNLOAD, e?.target?.checked)}
                                checked={this.props.actionLogsStore?.tempFilterData?.documentDownload ? true : false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(SearchBox);
