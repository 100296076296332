import { ApiService } from "core/services";

class FormSettingApi extends ApiService {
  constructor() {
    super("form-setting");
  }

  getAll(payload = null) {
    return this.get("all", payload);
  }

  getPrebuilt(payload = null) {
    return this.get("prebuilt", payload);
  }

  getList(payload = null) {
    return this.get("", payload);
  }

  addFormSetting(payload) {
    return this.post("", payload);
  }

  updateFormSetting(payload) {
    return this.put(`${payload.id}`, { form_edition: payload.form_edition, setting_body: payload.setting_body });
  }

  deleteFormSetting(id) {
    return this.delete(`${id}`);
  }
}

export default FormSettingApi;
