import { observable, action, configure, runInAction } from "mobx";
// import { DialogBody, DialogFooter, DialogHeader } from "core/ui";

configure({
    enforceActions: "observed"
});
class ModalStore {
    @observable instances = [];
    @observable loading = false;

    @action
    showLoading() {
        this.loading = true;
    }

    @action
    hideLoading() {
        this.loading = false;
    }

    @action
    show(options) {
        const instance = new ModalInstance(options);
        this.instances.push(instance);
    }

    getCurrentInstance = () => {
        const length = this.instances.length;
        return this.instances[length - 1];
    };

    @action
    hide(id) {
        runInAction(() => {
            this.instances.splice(-1, 1);
        });
    }
    @action
    hideAll() {
        runInAction(() => {
            this.instances = [];
        });
    }
}

export default new ModalStore();

class ModalInstance {
    constructor(options) {
        this.id = options.id;
        this.isOpen = options.isOpen;
        this.onAfterOpen = options.onAfterOpen;
        this.onRequestClose = options.onRequestClose;
        this.contentLabel = options.contentLabel;
        this.header = options.header;
        this.saveButtonText = options.saveButtonText;
        this.cancelButtonText = options.cancelButtonText;
        this.saveButtonClass = options.saveButtonClass;
        this.onConfirm = options.onConfirm;
        this.onCancel = options.onCancel;
        this.children = options.children;
        this.type = options.type;
        this.notButtonX = options.notButtonX;
    }
    @observable id = "";
    @observable isOpen = false;
    @observable onAfterOpen = () => {};
    @observable onRequestClose = () => {};
    @observable contentLabel = "";
    @observable header = "";
    @observable saveButtonText = "";
    @observable cancelButtonText = "";
    @observable saveButtonClass = "";
    @observable onConfirm = () => {};
    @observable onCancel = () => {};
    @observable children = null;
    @observable type = "";
    @observable notButtonX = true;
}
