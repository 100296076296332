import React, { Component } from "react";
import { Browser } from "core/utils";

const secured = WrappedComponent => {
    return class extends Component {
        isLoggedIn = () => {
            const sessionId = Browser.getSavedInfo("sessionId");
            return sessionId;
        };

        UNSAFE_componentWillMount() {
            if (!this.isLoggedIn()) {
                // this.props.history.push("/login");
                this.props.history.push("/login", {from: this.props.location?.pathname});
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };
};

export default secured;
