import React, { Component,  } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { ModalFooter } from "components";
import { Scrollbars } from "react-custom-scrollbars";
//import { FORM_GROUP_SELECT_TEAMS_MODE } from '../../core/utils/constants';
import "./style.scss";

const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
/*
const TEAM_TABLE_ROW_WIDTH_MODEL = {
  CHECKBOX: isIE ? "5%" : "6%",
  NO: isIE ? "5%" : "6%",
  TEAM_NAME: "88%",
};
*/

@inject("modalStore", "commonStore")
@observer
class ScreenDownloadFileHeaderItem extends Component {
  constructor(props) {
    super(props);
    const { commonStore: { downloadFileHeaderItemStore }, formGroupId, configfilesListsId } = this.props;

    this.state = {
      selectedHeaderItem : [],
      selectedDetailItem : [],
      notSelectedHeaderItem : [], 
      notSelectedDetailItem : [], 
      selectedHeaderItemOutput : "",
      selectedHeaderItemNotOutput : "",
      selectedDetailItemOutput : "",
      selectedDetailItemNotOutput : "",
      selectedSettingType : downloadFileHeaderItemStore.downloadFileHeaderItem.configfilesListsId != "" ? 1 : 0,
    }

    this.updateNotSelectedItems(
      downloadFileHeaderItemStore.downloadFileHeaderItem.settingBody.header.slice(),
      downloadFileHeaderItemStore.downloadFileHeaderItem.settingBody.detail.slice(),
      true );
  }

  isSubmited = false;
  isEditting = false;

  // Set value for controls after render DOM
  /*
  executeLogicAfterRender = () => {
    const { commonStore: { downloadFileHeaderItemStore }, formGroupId, configfilesListsId } = this.props;
    // this.props.commonStore.formSettingStore.setFormSetting(this.props.commonStore.formSettingStore.formSettingDefault);
  }

  onChange = (value) => {
    this.setState({
      selectedFormSettings: value,
      isChanged: true,
    });
  };
  */

  onSave = (forceCreate = false) => {
    const { commonStore: { downloadFileHeaderItemStore }, formGroupId, configfilesListsId } = this.props;
    const { selectedHeaderItem, selectedDetailItem, selectedSettingType } = this.state;

    let payload = {
      isDelete: selectedSettingType === 0 && downloadFileHeaderItemStore.downloadFileHeaderItem.configfilesListsId != "",
      formGroupId: formGroupId,
      configfilesListsId: configfilesListsId,
      settingBody: JSON.stringify({
        header: selectedHeaderItem,
        detail: selectedDetailItem,
      }),
    }

    downloadFileHeaderItemStore.registDownloadFileHeaderItem(payload, this.closeAllModal());

    /*
    const { selectedFormSettings } = this.state;
    const { commonStore: { definitionStore }, 
        formGroupId, formGroupSetting } = this.props;

    if (formGroupSetting.edition == "Item") {
      let payload = {
        form_group_id: formGroupId,
        form_setting_id: selectedFormSettings == "-1" ? null : selectedFormSettings
      }
      definitionStore.specifyingFormSettings(
        payload, this.updateFormGroupSetting
      );
    } else {
      this.updateFormGroupSetting();
    }
    */

    
  };

  /*
  updateFormGroupSetting = () => {
    const { selectTeamsMode } = this.state;
    const { commonStore: { definitionStore, formGroupStore }, 
        formGroupId, reloadParent } = this.props;

    let teamsList = [];
    let notExistsTeamsIds = this.listAllTeamsItems.filter(i => this.listCheckedItems.indexOf(i) == -1);
    if (selectTeamsMode == FORM_GROUP_SELECT_TEAMS_MODE.INDIVIDUAL_TEAMS &&
      0 < notExistsTeamsIds.length) {
      for (let i = 0; i < this.listCheckedItems.length; i++) {
        teamsList.push(this.listCheckedItems[i]);
      }
    }

    let payloadSFormGroupSetting = {
      formGroupId: formGroupId,
      selectTeamsMode: selectTeamsMode,
      teamsIdList: teamsList,
    }
    formGroupStore.updateFormGroupSetting(
      payloadSFormGroupSetting,
      () => {
        definitionStore.clean();
        definitionStore.getDefinitions();
        reloadParent();
        this.closeAllModal();
      }
    );
  }
  */

  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hide();
    this.isSubmited = false;
  };

  closeAllModal = () => {
    const { modalStore } = this.props;
    modalStore.hideAll();
    this.isSubmited = false;
  };

  restoreInitState = () => {
    const { commonStore: { downloadFileHeaderItemStore } } = this.props;
    this.state.selectedHeaderItemNotOutput = "";
    this.state.selectedHeaderItemOutput = "";
    this.state.selectedDetailItemNotOutput = "";
    this.state.selectedDetailItemOutput = "";
    
    this.updateNotSelectedItems(
      downloadFileHeaderItemStore.settingDefault.settingBody.header.slice(), 
      downloadFileHeaderItemStore.settingDefault.settingBody.detail.slice(),
      false);
  };

  moveToHeaderOutputItemsAll = () => {
    //const { commonStore: { downloadFileHeaderItemStore } } = this.props;
    const { notSelectedHeaderItem, selectedDetailItem } = this.state;

    let newSelectedHeaderItem = this.state.selectedHeaderItem.slice();
    for (let i = 0; i < notSelectedHeaderItem.length; i++) {
      newSelectedHeaderItem.push(notSelectedHeaderItem[i]);
    }
    this.state.selectedHeaderItemNotOutput = "";

    this.updateNotSelectedItems(newSelectedHeaderItem, selectedDetailItem, false);
  }

  moveToHeaderOutputItems = () => {
    const { selectedDetailItem } = this.state;

    let newSelectedHeaderItem = this.state.selectedHeaderItem.slice();
    newSelectedHeaderItem.push(this.state.selectedHeaderItemNotOutput);
    this.state.selectedHeaderItemNotOutput = "";

    this.updateNotSelectedItems(newSelectedHeaderItem, selectedDetailItem, false);
  }

  moveToHeaderNotOutputItemsAll = () => {
    const { selectedDetailItem } = this.state;

    this.state.selectedHeaderItemOutput = "";

    this.updateNotSelectedItems([], selectedDetailItem, false);
  }

  moveToHeaderNotOutputItems = () => {
    const { selectedDetailItem } = this.state;

    let newSelectedHeaderItem = this.state.selectedHeaderItem.slice();
    let foundIdx = newSelectedHeaderItem.indexOf(this.state.selectedHeaderItemOutput);
    if (0 <= foundIdx) {
      newSelectedHeaderItem.splice(foundIdx, 1);
    }
    this.state.selectedHeaderItemOutput = "";

    this.updateNotSelectedItems(newSelectedHeaderItem, selectedDetailItem, false);
  }

  moveToDetailOutputItemsAll = () => {
    const { notSelectedDetailItem, selectedHeaderItem } = this.state;

    let newSelectedDetailItem = this.state.selectedDetailItem.slice();
    for (let i = 0; i < notSelectedDetailItem.length; i++) {
      newSelectedDetailItem.push(notSelectedDetailItem[i]);
    }
    this.state.selectedDetailItemNotOutput = "";

    this.updateNotSelectedItems(selectedHeaderItem, newSelectedDetailItem, false);
  }

  moveToDetailOutputItems = () => {
    const { selectedHeaderItem } = this.state;

    let newSelectedDetailItem = this.state.selectedDetailItem.slice();
    newSelectedDetailItem.push(this.state.selectedDetailItemNotOutput);
    this.state.selectedDetailItemNotOutput = "";

    this.updateNotSelectedItems(selectedHeaderItem, newSelectedDetailItem, false);
  }

  moveToDetailNotOutputItemsAll = () => {
    const { selectedHeaderItem } = this.state;

    this.state.selectedDetailItemOutput = "";

    this.updateNotSelectedItems(selectedHeaderItem, [], false);
  }

  moveToDetailNotOutputItems = () => {
    const { selectedHeaderItem } = this.state;

    let newSelectedDetailItem = this.state.selectedDetailItem.slice();
    let foundIdx = newSelectedDetailItem.indexOf(this.state.selectedDetailItemOutput);
    if (0 <= foundIdx) {
      newSelectedDetailItem.splice(foundIdx, 1);
    }
    this.state.selectedDetailItemOutput = "";

    this.updateNotSelectedItems(selectedHeaderItem, newSelectedDetailItem, false);
  }

  replaceArrayElements(array, pos, target) {
    return array.reduce((resultArray, el, id, originalArray) => [
        ...resultArray,
        id === pos ? originalArray[target] :
        id === target ? originalArray[pos] :
        el
    ], []);
  }

  replacePositionUpHeader = () => {
    const { selectedDetailItem } = this.state;

    let newSelectedHeaderItem = this.state.selectedHeaderItem.slice();
    let foundIdx = newSelectedHeaderItem.indexOf(this.state.selectedHeaderItemOutput);
    if (1 <= foundIdx) {
      newSelectedHeaderItem = this.replaceArrayElements(
        newSelectedHeaderItem, foundIdx, foundIdx-1);
    }

    this.updateNotSelectedItems(newSelectedHeaderItem, selectedDetailItem, false);
  }

  replacePositionDownHeader = () => {
    const { selectedDetailItem } = this.state;

    let newSelectedHeaderItem = this.state.selectedHeaderItem.slice();
    let foundIdx = newSelectedHeaderItem.indexOf(this.state.selectedHeaderItemOutput);
    if (foundIdx < newSelectedHeaderItem.length - 1) {
      newSelectedHeaderItem = this.replaceArrayElements(
        newSelectedHeaderItem, foundIdx, foundIdx+1);
    }

    this.updateNotSelectedItems(newSelectedHeaderItem, selectedDetailItem, false);
  }

  replacePositionUpDetail = () => {
    const { selectedHeaderItem } = this.state;

    let newSelectedDetailItem = this.state.selectedDetailItem.slice();
    let foundIdx = newSelectedDetailItem.indexOf(this.state.selectedDetailItemOutput);
    if (1 <= foundIdx) {
      newSelectedDetailItem = this.replaceArrayElements(
        newSelectedDetailItem, foundIdx, foundIdx-1);
    }

    this.updateNotSelectedItems(selectedHeaderItem, newSelectedDetailItem, false);
  }

  replacePositionDownDetail = () => {
    const { selectedHeaderItem } = this.state;

    let newSelectedDetailItem = this.state.selectedDetailItem.slice();
    let foundIdx = newSelectedDetailItem.indexOf(this.state.selectedDetailItemOutput);
    if (foundIdx < newSelectedDetailItem.length -1) {
      newSelectedDetailItem = this.replaceArrayElements(
        newSelectedDetailItem, foundIdx, foundIdx+1);
    }

    this.updateNotSelectedItems(selectedHeaderItem, newSelectedDetailItem, false);
  }

  // updateNotSelectedItems = () => {
  updateNotSelectedItems = (newSelectedHeaderItem, newSelectedDetailItem, isInit = false) => {
    const { commonStore: { downloadFileHeaderItemStore } } = this.props;
    //const { selectedHeaderItem, selectedDetailItem } = this.state;

    let newNotSelectedHeaderItem = [];
    for (let headerIdx = 0; headerIdx < downloadFileHeaderItemStore.headerItemAll.length; headerIdx++) {
      let item = downloadFileHeaderItemStore.headerItemAll[headerIdx];
      //if (selectedHeaderItem.length == 0 || selectedHeaderItem.indexOf(item) < 0) {
      if (newSelectedHeaderItem.length == 0 || newSelectedHeaderItem.indexOf(item) < 0) {
        newNotSelectedHeaderItem.push(item);
      }
    }
    //this.state.notSelectedHeaderItem = newNotSelectedHeaderItem;
    /* this.setState ({
      notSelectedHeaderItem : newNotSelectedHeaderItem,
    }); */

    let newNotSelectedDetailItem = [];
    for (let detailIdx = 0; detailIdx < downloadFileHeaderItemStore.detailItemAll.length; detailIdx++) {
      let item = downloadFileHeaderItemStore.detailItemAll[detailIdx];
      // if (selectedDetailItem.length == 0 || selectedDetailItem.indexOf(item) < 0) {
      if (newSelectedDetailItem.length == 0 || newSelectedDetailItem.indexOf(item) < 0) {
        newNotSelectedDetailItem.push(item);
      }
    }
    // this.state.notSelectedDetailItem = newNotSelectedDetailItem;
    /*this.setState ({
      notSelectedDetailItem : newNotSelectedDetailItem,
    });*/

    if (isInit) {
      this.state.selectedHeaderItem = newSelectedHeaderItem;
      this.state.notSelectedHeaderItem = newNotSelectedHeaderItem;
      this.state.selectedDetailItem = newSelectedDetailItem;
      this.state.notSelectedDetailItem = newNotSelectedDetailItem;
  } else {
      this.setState ({
        selectedHeaderItem : newSelectedHeaderItem,
        notSelectedHeaderItem : newNotSelectedHeaderItem,
        selectedDetailItem : newSelectedDetailItem,
        notSelectedDetailItem : newNotSelectedDetailItem,
      });
    }
  }

  handleChangeSettingType = (newValue) => {
    this.setState ({
      selectedSettingType : parseInt(newValue),
    });
  }

  onChangeHeaderItemNotOutput = (newValue) => {
    this.setState ({
      selectedHeaderItemNotOutput : newValue,
    });
  }

  onChangeHeaderItemOutput = (newValue) => {
    this.setState ({
      selectedHeaderItemOutput : newValue,
    });
  }

  onChangeDetailItemNotOutput = (newValue) => {
    this.setState ({
      selectedDetailItemNotOutput : newValue,
    });
  }

  onChangeDetailItemOutput = (newValue) => {
    this.setState ({
      selectedDetailItemOutput : newValue,
    });
  }

  openComfirmModal = () => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: "confirm",
      isOpen: true,
      type: "small",
      header: t("definition.download_file_header_item.page_title"),
      onCancel: () => this.closeModal(),
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>
              {t("definition.download_file_header_item.save_confirm")}
            </span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t("definition.download_file_header_item.regist")}
            onConfirm={this.onSave}
            cancelButtonText={t("definition.download_file_header_item.cancel")}
            onCancel={this.closeModal}
          />
        </div>
      ),
    });
  };

  /*
  openComfirmModal = () => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: "confirm",
      isOpen: true,
      header: t("definition.form_group.setting.page_title"),
      onCancel: () => this.closeModal("confirm"),
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>
              {t("definition.form_group.setting.confirm_save")}
            </span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t("definition.form_group.setting.save")}
            onConfirm={() => {
              this.closeModal();
              this.onSave();
            }}
            cancelButtonText={t("definition.form_group.setting.cancel")}
            onCancel={this.closeModal}
          />
        </div>
      ),
      type: 'big'
    });
  };
  */

  /*
  onChangeSelectTeam = (event) => {
    const { teamsAll } = this.props;
    let value = event.target.value;

    for (let i = 0; i < teamsAll.length; i++) {
      let checkboxElm = document.getElementById(teamsAll[i].id.toFixed());
      if (value == FORM_GROUP_SELECT_TEAMS_MODE.ALL_TEAMS) {
        checkboxElm.disabled = true;
      } else {
        checkboxElm.disabled = false;
      }
    }

    this.setState({
      selectTeamsMode: value,
      isChanged: true,
    });
  }
  */

  /*
  handleRowSelected = (e) => {
    let element = e.target.value;
    if (element) {
      element = JSON.parse(element);
      if (e.target.checked) {
        this.addItemToList(element);
      } else {
        this.removeItemFromList(element);
      }
    }
  };
  */

  /*
  addItemToList = (element) => {
    if (this.listCheckedItems.indexOf(element.id) < 0) {
      this.listCheckedItems.push(element.id);
    }
    this.setState({ isChanged: true });
  }
  */

  /*
  removeItemFromList(element) {
    var index = this.listCheckedItems.indexOf(element.id);
    if (index !== -1) {
      this.listCheckedItems.splice(index, 1);
    }
    this.setState({ isChanged: true });
  }
  */

  /*
  isChecked = (id) => {
    const { isChanged } = this.state;
    const { formGroupSetting, teamsAll } = this.props;

    if (!isChanged && formGroupSetting.teams.length == 0) {
      for (let i = 0; i < teamsAll.length; i++) {
        if (this.listCheckedItems.indexOf(teamsAll[i].id) < 0) {
          this.listCheckedItems.push(teamsAll[i].id);
        }
      }
      return true;
    }

    var index = this.listCheckedItems.indexOf(id);
    if (index !== -1) {
      return true;
    }
    return false;
  }
  */

  /*
  columns = () => {
    //const { selectTeamsMode } = this.state;
    const { t } = this.props;
    return [
      {
        id: "selection_teams",
        disableSortBy: true,
        Cell: ({ row }) => {
          let isChecked = this.isChecked(row.original.id);
          return (
            <div>
              <input
                id={row.original.id}
                type="checkbox"
                value={JSON.stringify(row?.original)}
                onChange={this.handleRowSelected}
                checked={isChecked}
                disabled={ selectTeamsMode == FORM_GROUP_SELECT_TEAMS_MODE.ALL_TEAMS ? true : false }
              />
            </div>
          );
        },
        width: TEAM_TABLE_ROW_WIDTH_MODEL.CHECKBOX
      },
      {
        Header: t("definition.form_group.setting.teams.no"),
        disableSortBy: true,
        Cell: ({ initialState, row }) => <div style={{ textAlign: "center" }}>{
          row.index + 1
        }</div>,
        width: TEAM_TABLE_ROW_WIDTH_MODEL.NO
      },
      {
        Header: t('definition.form_group.setting.teams.name'),
        disableSortBy: true,
        accessor: 'teams_name',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.name}
            </div>
          );
        },
        width: TEAM_TABLE_ROW_WIDTH_MODEL.TEAM_NAME
      }
    ];
  };
  */

  render() {
    const { t, commonStore: { downloadFileHeaderItemStore }, formGroupId, configfilesListsId, i18n } = this.props;
    const { selectedHeaderItem, selectedDetailItem, notSelectedHeaderItem, notSelectedDetailItem, selectedSettingType } = this.state;
    return (
      <div className="wrap-download-file-header-item">
        <Scrollbars key={"modal-body"} className="modal-body">
          {
            configfilesListsId != "" ?
            <div className="row">
              <div className="col-sm-4">
                {t("definition.download_file_header_item.setting_type")}
              </div>
              <div className="col-sm-4">
                <select
                  className="slc-setting-list"
                  onChange={(e) => this.handleChangeSettingType(e.target?.value)}
                  defaultValue={selectedSettingType}
                >
                  <option key="0" value="0"
                    /*selected={selectedSettingType === 0 ? "true": "false" }*/
                  >{t("definition.download_file_header_item.take_over_group_setting")}</option>
                  <option key="1" value="1"
                    /*selected={selectedSettingType !== 1 ? "true": "false" }*/
                  >{t("definition.download_file_header_item.individually_configured")}</option>
                </select>
              </div>
            </div>
            : ""
          }
          <div className="row">
            <div className="col-sm-4 font-bold">
              {t("definition.download_file_header_item.header.title")}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-4 txt_center">
              {t("definition.download_file_header_item.items")}
            </div>
            <div className="col-sm-2"></div>
            <div className="col-sm-4 txt_center ">
              {t("definition.download_file_header_item.output_items")}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-4" style={{padding: "5px"}}>
              <select
                className="slc-setting-list"
                onChange={(e) => this.onChangeHeaderItemNotOutput(e.target?.value)}
                size={downloadFileHeaderItemStore.headerItemAll.length}
                disabled={configfilesListsId != "" && selectedSettingType === 0}
              >
                {/*notSelectedHeaderItem?.map(e => <option key={e} value={e}>{e}</option>)*/}
                {notSelectedHeaderItem.map(e => <option key={e} value={e}>{i18n.t("definition.download_file_header_item." + e)}</option>)}
              </select>
            </div>
            <div className="col-sm-2">
              <button className="btn_arrow btn-primary" type="button"
                onClick={() => this.moveToHeaderOutputItemsAll()}
                disabled={notSelectedHeaderItem.length === 0}
              >
                <span className="fa fa-angle-double-right"></span>
              </button>
              <br/>
              <button className="btn_arrow btn-primary" type="button"
                onClick={() => this.moveToHeaderNotOutputItemsAll()}
                disabled={selectedHeaderItem.length === 0}
              >
                <span className="fa fa-angle-double-left"></span>
              </button>
              <br/>
              <br/>
              <button className="btn_arrow btn-primary" type="button"
                onClick={() => this.moveToHeaderOutputItems()}
                disabled={notSelectedHeaderItem.length === 0 || this.state.selectedHeaderItemNotOutput === ""}
              >
                <span className="fa fa-angle-right"></span>
              </button>
              <br/>
              <button className="btn_arrow btn-primary" type="button"
                onClick={() => this.moveToHeaderNotOutputItems()}
                disabled={selectedHeaderItem.length === 0 || this.state.selectedHeaderItemOutput === ""}
              >
                <span className="fa fa-angle-left"></span>
              </button>
            </div>
            <div className="col-sm-4" style={{padding: "5px"}}>
              <select
                className="slc-setting-list"
                onChange={(e) => this.onChangeHeaderItemOutput(e.target?.value)}
                size={downloadFileHeaderItemStore.headerItemAll.length}
                disabled={configfilesListsId != "" && selectedSettingType === 0}
              >
                {/*selectedHeaderItem.map(e => <option key={e} value={e}>{e}</option>)*/}
                {selectedHeaderItem.map(e => <option key={e} value={e}>{i18n.t("definition.download_file_header_item." + e)}</option>)}
              </select>
              <div align="right">
                <button className="btn_up_down btn-primary" type="button"
                  onClick={() => this.replacePositionUpHeader()}
                  disabled={selectedHeaderItem.length === 0 || this.state.selectedHeaderItemOutput === ""}
                >
                  {t("definition.download_file_header_item.item_up")}
                </button>
                <button className="btn_up_down btn-primary" type="button"
                  onClick={() => this.replacePositionDownHeader()}
                  disabled={selectedHeaderItem.length === 0 || this.state.selectedHeaderItemOutput === ""}
                >
                  {t("definition.download_file_header_item.item_down")}
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 font-bold"> 
              {t("definition.download_file_header_item.detail.title")}
              <i className="fa fa-info-circle ar-tooltip"
                data-toggle="tooltip"
                data-placement="top"
                title={t("definition.download_file_header_item.detail.tooltip")}>
              </i>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-4 txt_center">
              {t("definition.download_file_header_item.items")}
            </div>
            <div className="col-sm-2"></div>
            <div className="col-sm-4 txt_center ">
              {t("definition.download_file_header_item.output_items")}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-4" style={{padding: "5px"}}>
              <select
                className="slc-setting-list"
                onChange={(e) => this.onChangeDetailItemNotOutput(e.target?.value)}
                size={downloadFileHeaderItemStore.detailItemAll.length}
                disabled={configfilesListsId != "" && selectedSettingType === 0}
              >
                {/*notSelectedDetailItem.map(e => <option key={e} value={e}>{e}</option>)*/}
                {notSelectedDetailItem.map(e => <option key={e} value={e}>{i18n.t("definition.download_file_header_item." + e)}</option>)}
              </select>
            </div>
            <div className="col-sm-2">
              <button className="btn_arrow btn-primary" type="button"
                onClick={() => this.moveToDetailOutputItemsAll()}
                disabled={notSelectedDetailItem.length === 0}
              >
                <span className="fa fa-angle-double-right"></span>
              </button>
              <br/>
              <button className="btn_arrow btn-primary" type="button"
                onClick={() => this.moveToDetailNotOutputItemsAll()}
                disabled={selectedDetailItem.length === 0}
              >
                <span className="fa fa-angle-double-left"></span>
              </button>
              <br/>
              <br/>
              <button className="btn_arrow btn-primary" type="button"
                onClick={() => this.moveToDetailOutputItems()}
                disabled={notSelectedDetailItem.length === 0 || this.state.selectedDetailItemNotOutput === ""}
              >
                <span className="fa fa-angle-right"></span>
              </button>
              <br/>
              <button className="btn_arrow btn-primary" type="button"
                onClick={() => this.moveToDetailNotOutputItems()}
                disabled={selectedDetailItem.length === 0 || this.state.selectedDetailItemOutput === ""}
              >
                <span className="fa fa-angle-left"></span>
              </button>
            </div>
            <div className="col-sm-4" style={{padding: "5px"}}>
              <select
                className="slc-setting-list"
                onChange={(e) => this.onChangeDetailItemOutput(e.target?.value)}
                size={downloadFileHeaderItemStore.detailItemAll.length}
                disabled={configfilesListsId != "" && selectedSettingType === 0}
              >
                {/*selectedDetailItem.map(e => <option key={e} value={e}>{e}</option>)*/}
                {selectedDetailItem.map(e => <option key={e} value={e}>{i18n.t("definition.download_file_header_item." + e)}</option>)}
              </select>
              <div align="right">
                <button className="btn_up_down btn-primary" type="button"
                    onClick={() => this.replacePositionUpDetail()}
                    disabled={selectedDetailItem.length === 0 || this.state.selectedDetailItemOutput === ""}
                >
                    {t("definition.download_file_header_item.item_up")}
                </button>
                <button className="btn_up_down btn-primary" type="button"
                    onClick={() => this.replacePositionDownDetail()}
                    disabled={selectedDetailItem.length === 0 || this.state.selectedDetailItemOutput === ""}
                >
                  {t("definition.download_file_header_item.item_down")}
                </button>
              </div>
            </div>
          </div>
          {/*
          <div className="row">
            <div className="col-sm-4 font-bold">
              {t("form_setting.specifying_form_settings.form_name")}
            </div>
            <div className="col-sm-6" style={{padding: "5px"}}>
              <select
                className="slc-setting-list"
                value={selectedFormSettings}
                onChange={(e) => this.onChange(e.target?.value)}
                disabled={formGroupSetting.edition !== "Item"}
              >
                <option value="-1">{t("form_setting.not_specified")}</option>
                {
                    (listFormSettings && listFormSettings.length > 0) &&
                    listFormSettings?.map(e => <option key={e.id} value={e.id}>{e.settingName}</option>)
                }
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1 p-0"></div>
            <div className="col-sm-4 font-bold">
              {t("definition.form_group.teams_name")}
            </div>
              <div className="col-sm-6" style={{padding: "5px"}}>
              <select 
                className="slc-setting-list"
                onChange={(e) => this.onChangeSelectTeam(e)}
                defaultValue={ 0 < formGroupSetting?.teams?.length ? 
                  FORM_GROUP_SELECT_TEAMS_MODE.INDIVIDUAL_TEAMS : 
                  FORM_GROUP_SELECT_TEAMS_MODE.ALL_TEAMS }
              >
                <option value={FORM_GROUP_SELECT_TEAMS_MODE.ALL_TEAMS}>
                  {t("definition.form_group.setting.teams.all")}
                </option>
                <option value={FORM_GROUP_SELECT_TEAMS_MODE.INDIVIDUAL_TEAMS}>
                  {t("definition.form_group.setting.teams.individual")}
                </option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1 p-0"></div>
            <div className="col-sm-4 font-bold">
              {" "}
            </div>
              <div className="col-sm-6" style={{padding: "5px"}}>
                {<Table
                  columns={this.columns()}
                  data={teamsAll}
                  enableSelectRow={true}
                  disableSelectionPageSize={true}
                  disablePaging={true}
                  t={t}
                  enableServerSidePaging={false}
                  hideTableFooter={true}
                  className="uploading-table"
                />}
                {teamsAll.length === 0 &&
                  <div className="table-no-data">
                    <p>{t("table_no_data")}</p>
                  </div>
                }
              </div>
          </div>
          */}
        </Scrollbars>
        <ModalFooter key={"modal-footer"}
          saveButtonText={t('definition.download_file_header_item.regist')}
          onConfirm={this.openComfirmModal}
          cancelButtonText={t('definition.download_file_header_item.init')}
          onCancel={this.restoreInitState}
          saveButtonClass="btn-primary"
        />
      </div>
    );
  }
}

export default withTranslation()(ScreenDownloadFileHeaderItem);
