import { observable, runInAction, configure } from "mobx";
import { IndividualSettingApi } from "apis";
import ApiStore from "./ApiStore";
import BaseStore from "./BaseStore";

configure({ enforceActions: "observed" });

class IndividualSettingStore extends BaseStore {
    constructor(props) {
        super(props);
        this.api = new IndividualSettingApi();
    }

    getSetting = async () => {
        let data = await ApiStore.call(this.api, this.api.getSetting);
        return data;
    }

    setSetting = async (payload) => {
        let result = await ApiStore.call(this.api, this.api.setSetting, payload);
        return result;
    }

}

export default IndividualSettingStore;