import React from "react";
import { observer, inject } from "mobx-react";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import { RingLoader } from "react-spinners";
import "./style.scss";
// import { css } from "@emotion/core";
// import { Loading } from "../../lotties";

@inject("apiStore")
@observer
class Spinner extends React.Component {
  render() {
    const { loading } = this.props.apiStore;
    return (
      <div
        className={classnames("spinner", {
          "display-n": !loading
        })}
      >
        <RingLoader color="#fff" />
        {/* <Loading></Loading> */}
      </div>
    );
  }
}

export default withTranslation()(Spinner);
