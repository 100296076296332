import { action, observable, runInAction, configure } from "mobx";
import { DownloadFileHeaderItemApi, IndividualSettingApi } from "apis";
import { DOWNLOAD_FILE_FORMAT } from "../core/utils/constants";
import ApiStore from "./ApiStore";
import BaseStore from "./BaseStore";

configure({ enforceActions: "observed" });
class DownloadFileHeaderItemStore extends BaseStore {
  @observable keepFilterState = false;
  //@observable fullFormSettings = [];
  @observable sortListFormSettings = [];
  @observable listFormSettings = [];
  //@observable formSetting = {};


  @observable isExistsConfigFilesListSetting = false;
  @observable fullSettings = [];
  @observable downloadFileHeaderItem = {};
  @observable settingDefault = {};
  @observable settingDefaultCsv = {
    id: -1,
    formGroupId: -1,
    configfilesListsId: "",
    settingBody: {
      // Header Item
      header: ["document_id", "form_id"],
      // Detail Item
      detail: ["document_id"],
    }
  };
  @observable settingDefaultCsvAiread = {
    id: -1,
    formGroupId: -1,
    configfilesListsId: "",
    settingBody: {
      // Header Item
      header: ["Image", "processDate", "result", "form_id"],
      // Detail Item
      detail: [],
    }
  };
  @observable headerItemAll = ["Image", "processDate", "result", "document_id", "form_id"];
  @observable detailItemAll = ["Image", "processDate", "result", "document_id", "form_id", "page_no"];

  constructor(props) {
    super(props);
    this.api = new DownloadFileHeaderItemApi();
  }

  async init(formGroupId, configfilesListsId) {
    let individualSettingApi = new IndividualSettingApi();
    const individualSetting = await ApiStore.call(individualSettingApi, individualSettingApi.getSetting);
    if (individualSetting) {
      if (individualSetting.downloadFileFormat === DOWNLOAD_FILE_FORMAT.CSV) {
        this.settingDefault = JSON.parse(JSON.stringify(this.settingDefaultCsv));
      } else {
        this.settingDefault = JSON.parse(JSON.stringify(this.settingDefaultCsvAiread));
      }
    } else {
      this.settingDefault = JSON.parse(JSON.stringify(this.settingDefaultCsv));
    }

    this.clean();

    this.isExistsConfigFilesListSetting = false;
    let response = null;
    if (configfilesListsId) {
      response = await ApiStore.call(this.api, this.api.getConfigFilesListsSetting, configfilesListsId);
      if (!response.settingBody) {
        response = await ApiStore.call(this.api, this.api.getFormGroupSetting, formGroupId);
      } else {
        this.isExistsConfigFilesListSetting = true;
      }
    } else {
      response = await ApiStore.call(this.api, this.api.getFormGroupSetting, formGroupId);
    }

    if (response) {
      runInAction(() => {
        if (response.settingBody) {
          this.downloadFileHeaderItem.id = response.id;
          this.downloadFileHeaderItem.formGroupId = response.formGroupId;
          this.downloadFileHeaderItem.configfilesListsId = response.configfilesListsId;
          this.downloadFileHeaderItem.settingBody = JSON.parse(response.settingBody);
        } else {
          this.downloadFileHeaderItem = this.settingDefault;
        }
      })
    }
  }

  async getDownloadFileHeaderItem(formGroupId, configfilesListsId) {
    let response = null;
    if (configfilesListsId) {
      response = await ApiStore.call(this.api, this.api.getConfigFilesListsSetting, configfilesListsId);
      if (!response) {
        response = await ApiStore.call(this.api, this.api.getFormGroupSetting, formGroupId);
      }
    } else {
      response = await ApiStore.call(this.api, this.api.getFormGroupSetting, formGroupId);
    }

    if (response) {
      runInAction(() => {
        this.downloadFileHeaderItem = response;
        if (response.settingBody) {
          this.fullSettings = response.map(el => {
            el.settingBody = JSON.parse(el.settingBody)
            return el
          });
        } else {
          this.fullSettings = JSON.parse(JSON.stringify(this.settingDefault));
        }
      })
    }

    //let response = await ApiStore.call(this.api, this.api.getAll);
    /*
    if (response) {
      runInAction(() => {
        this.fullFormSettings = response.map(el => {
          el.settingBody = JSON.parse(el.settingBody)
          return el
        });
        if (this.fullFormSettings && this.fullFormSettings.length > 0) {
          this.sortListFormSettings = this.fullFormSettings.map(({ id, settingName }) => ({ id, settingName }));
        }
        else {
          this.sortListFormSettings = [];
        }
      })
    }
    */
  }

  /*
  async getList() {
    let response = await ApiStore.call(this.api, this.api.getList);
    if (response) {
      runInAction(() => {
        this.listFormSettings = response;
      })
    }
  }
  */

  /*
  addFormSetting = async (payload) => {
    let response = await ApiStore.call(this.api, this.api.addFormSetting, payload);
    if (response) {
      runInAction(() => {
        this.getAll();
        this.setFormSetting(this.formSettingDefault);
      })
    }
  }
  */

  /*
  updateFormSetting = async (payload) => {
    let response = await ApiStore.call(this.api, this.api.updateFormSetting, payload);
    if (response) {
      runInAction(() => {
        this.getAll();
        response.settingBody = JSON.parse(response.settingBody);
        this.formSetting = response;
      })
    }
  }
  */

  /*
  @action
  async deleteFormSetting(id) {
    let response = await ApiStore.call(this.api, this.api.deleteFormSetting, id);
    if (response) {
      runInAction(() => {
        this.getAll();
        this.setFormSetting(this.formSettingDefault);
      })
    }
  }
  */

  async registDownloadFileHeaderItem(payload, cb) {
    let response = await ApiStore.call(this.api, this.api.putDownloadFileHeaderItem, payload);
    if (response) {
      runInAction(() => {
        cb && cb(response);
      })
    }
  }

  @action
  setFormSettingBody = (idx, value) => {
    this.downloadFileHeaderItem.settingBody[idx] = value;
  }

  @action
  setFormSetting = (data) => {
    this.downloadFileHeaderItem = JSON.parse(JSON.stringify(data));
  }

  /*
  @action
  convertFormSetting = (settingBody) => {
    if (settingBody) {
      settingBody.PAGE_SEG_MODE = !this.isBlank(settingBody.PAGE_SEG_MODE) ? parseInt(settingBody.PAGE_SEG_MODE) : '';
      settingBody.RESCAN_THRESHOLD = !this.isBlank(settingBody.RESCAN_THRESHOLD) ? parseInt(settingBody.RESCAN_THRESHOLD) : '';
      settingBody.LINE_REMOVAL_THRESHOLD = !this.isBlank(settingBody.LINE_REMOVAL_THRESHOLD) ? parseInt(settingBody.LINE_REMOVAL_THRESHOLD) : '';
      settingBody.LINE_REMOVAL_MULTIPLE_BY_TEXT_HEGHT = !this.isBlank(settingBody.LINE_REMOVAL_MULTIPLE_BY_TEXT_HEGHT) ?  parseFloat(settingBody.LINE_REMOVAL_MULTIPLE_BY_TEXT_HEGHT) : '';
      settingBody.HOUGH_THRESHOLD = !this.isBlank(settingBody.HOUGH_THRESHOLD) ? parseInt(settingBody.HOUGH_THRESHOLD) : '';
      settingBody.THRESH_VALUE = !this.isBlank(settingBody.THRESH_VALUE) ? parseInt(settingBody.THRESH_VALUE) : '';
      settingBody.BINTHRESH_ON_LINE_REMOVAL = !this.isBlank(settingBody.BINTHRESH_ON_LINE_REMOVAL) ? parseInt(settingBody.BINTHRESH_ON_LINE_REMOVAL) : '';
      settingBody.BLACK_WHITE_THRESHOLD = !this.isBlank(settingBody.BLACK_WHITE_THRESHOLD) ? parseInt(settingBody.BLACK_WHITE_THRESHOLD) : '';
      settingBody.SHARPEN_VALUE = !this.isBlank(settingBody.SHARPEN_VALUE) ? parseFloat(settingBody.SHARPEN_VALUE) : '';
      settingBody.SHORT_LINE_THRESH_H = !this.isBlank(settingBody.SHORT_LINE_THRESH_H) ? parseInt(settingBody.SHORT_LINE_THRESH_H) : '';
      settingBody.SHORT_LINE_THRESH_V = !this.isBlank(settingBody.SHORT_LINE_THRESH_V) ? parseInt(settingBody.SHORT_LINE_THRESH_V) : '';
      settingBody.THIN_LINE_REMOVAL_THRESHOLD_W = !this.isBlank(settingBody.THIN_LINE_REMOVAL_THRESHOLD_W) ? parseInt(settingBody.THIN_LINE_REMOVAL_THRESHOLD_W) : '';
      settingBody.THIN_LINE_REMOVAL_THRESHOLD_H = !this.isBlank(settingBody.THIN_LINE_REMOVAL_THRESHOLD_H) ? parseInt(settingBody.THIN_LINE_REMOVAL_THRESHOLD_H) : '';
      settingBody.ERODE_THIN_LINE_W = !this.isBlank(settingBody.ERODE_THIN_LINE_W) ? parseInt(settingBody.ERODE_THIN_LINE_W) : '';
      settingBody.ERODE_THIN_LINE_H = !this.isBlank(settingBody.ERODE_THIN_LINE_H) ? parseInt(settingBody.ERODE_THIN_LINE_H) : '';
      settingBody.LINE_EXTENSION_LEN = !this.isBlank(settingBody.LINE_EXTENSION_LEN) ? parseInt(settingBody.LINE_EXTENSION_LEN) : '';
      settingBody.ROUNDED_CORNER_THRESHOLD = !this.isBlank(settingBody.ROUNDED_CORNER_THRESHOLD) ? parseInt(settingBody.ROUNDED_CORNER_THRESHOLD) : '';
      settingBody.ROUNDED_CORNER_PADDING = !this.isBlank(settingBody.ROUNDED_CORNER_PADDING) ? parseInt(settingBody.ROUNDED_CORNER_PADDING) : '';
      settingBody.DOT_SIZE_THRESHOLD = !this.isBlank(settingBody.DOT_SIZE_THRESHOLD) ? parseInt(settingBody.DOT_SIZE_THRESHOLD) : '';
      settingBody.REMOVE_RECTANGLE_MIN_AREA_THRESHOLD = !this.isBlank(settingBody.REMOVE_RECTANGLE_MIN_AREA_THRESHOLD) ? parseInt(settingBody.REMOVE_RECTANGLE_MIN_AREA_THRESHOLD) : '';
      settingBody.REMOVE_RECTANGLE_MAX_AREA_THRESHOLD = !this.isBlank(settingBody.REMOVE_RECTANGLE_MAX_AREA_THRESHOLD) ? parseInt(settingBody.REMOVE_RECTANGLE_MAX_AREA_THRESHOLD) : '';
      settingBody.LINKED_ITEM_THRESHOLD = !this.isBlank(settingBody.LINKED_ITEM_THRESHOLD) ? parseFloat(settingBody.LINKED_ITEM_THRESHOLD) : '';
    }
    return settingBody;
  }
  */

  @action
  clean() {
    if (!this.keepFilterState) {
      console.log("DownloadFileHeaderItemStore -> clean -> this.keepFilterState", this.keepFilterState)
      super.clean();
    }
  }

  isBlank = (value) => {
    if (typeof(value) === "string") {
        value = value.trim();
    }
    if (value === null || value === undefined || value === "") {
        return true;
    }
    return false;
  }
}

export default DownloadFileHeaderItemStore;