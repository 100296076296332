import { ApiService } from "core/services";

class TeamApi extends ApiService {
  constructor() {
    super("teams");
  }

  getAll(payload = null) {
    return this.get("", payload);
  }

  getTeamById(payload = null) {
    return this.get(`${payload.id}`, payload.pageInfo);
  }

  addTeam(payload) {
    return this.post("", payload);
  }

  updateTeamName(payload) {
    return this.put(`${payload.id}`, { name: payload.name });
  }

  getUsersNotInTeam(payload = null) {
    return this.get(`users-not-in-team/${payload.id}`, payload.pageInfo);
  }

  addUsersForTeam(payload) {
    return this.post(`add-user/${payload.id}`, { sequences: payload.sequences });
  }

  deleteUserForTeam(payload) {
    return this.delete(`user/${payload.id}?sequence=${payload.sequence}`);
  }

  deleteTeam(id) {
    return this.delete(`${id}`);
  }

  getTeamOfUser() {
    return this.get(`user`);
  }

  getTeamsAll() {
    return this.get(`admin`);
  }

  getUsersInSameTeams() {
    return this.get(`users-in-same-teams`);
  }

}

export default TeamApi;
