import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { PROFILE_ROLES_PREFIX, RENEWAL_SPAN_CODE } from '../../core/utils/constants'
import "./style.scss";
import SearchBox from './SearchBox';
import { observer } from "mobx-react";

@observer
class ProfileUser extends Component {
  render() {
    const { t, userInfo } = this.props;
    return (
      <div className="box-body box-profile">
        <div className="row profile-item">
          <div className="col-sm-3">{t("profile.user_id")}</div>
          <div className="col-sm-9 profile-value">
            {userInfo.userId}
          </div>
        </div>
        <div className="row profile-item">
          <div className="col-sm-3">
            {t("profile.company_information")}
          </div>
          <div className="col-sm-9 profile-value">
            {userInfo.tenantName}
          </div>
        </div>
        <div className="row profile-item">
          <div className="col-sm-3">
            {t("profile.user_level")}
          </div>
          <div className="col-sm-9 profile-value">
            {t(PROFILE_ROLES_PREFIX + userInfo.role)}
          </div>
        </div>
        <div className="row profile-item">
          <div className="col-sm-3">
            {t("profile.number_of_contracts")}
          </div>
          <div className="col-sm-9 profile-value">
            {userInfo.contractAmount}{" "}
            <span>
              {userInfo &&
                userInfo.contractAmount &&
                userInfo.contractAmount > 1
                ? t("profile.sheets")
                : t("profile.sheet")}
            </span>
            <span>
              {" "}{userInfo &&
                userInfo.contractRenewalSpan &&
                userInfo.contractRenewalSpan == RENEWAL_SPAN_CODE.YEAR
                ? t("profile.per_year")
                : t("profile.per_month")}
            </span>
          </div>
        </div>
        <div className="row profile-item">
          <div className="col-sm-3">
            {t("profile.monthly_usage")}
          </div>
          <div className="col-sm-3 profile-value">
            {userInfo.useAmount}{" "}
            <span>
              {userInfo &&
                userInfo.useAmount &&
                userInfo.useAmount > 1
                ? t("profile.sheets")
                : t("profile.sheet")}
            </span>
          </div>
          <SearchBox className="col-sm-6 amount-search-pannel" />
        </div>
      </div>
    );
  }
}

export default withTranslation()(ProfileUser);
