import { action, observable, runInAction, configure } from "mobx";
import { IndividualModelApi } from "apis";
import ApiStore from "./ApiStore";
import BaseStore from "./BaseStore";

configure({ enforceActions: "observed" });

class IndividualModelStore extends BaseStore {
  @observable models = [];
  @observable model = {};
  @observable keepFilterState = false;

  constructor(props) {
    super(props);
    this.api = new IndividualModelApi();
  }

  async getModels() {
    const { size, page, sortDir, sortKey } = this.paging;
    let payload = { size, page, sortDir, sortKey };
    let response = await ApiStore.call(this.api, this.api.getAll, payload);
    if (response) {
      runInAction(() => {
        this.updatePagingFiler(response.paging);
        this.models = response.elements;
        if (this.paging?.totalRecord && this.paging?.totalRecord !== 0) {
          if (this.paging?.page > 1 && (!response.elements || response.elements.length === 0)) {
            this.updatePagingFiler({ page: this.paging.page - 1 });
            this.getModels();
          }
        }
      })
    }
  }

  @action
  async deleteModel(id) {
    let resp = await ApiStore.call(this.api, this.api.deleteModel, id);
    if (resp) {
      runInAction(() => {
        this.getModels();
      })
    }
  }

  @action
  clean() {
    if (!this.keepFilterState) {
      console.log("IndividualModelStore -> clean -> this.keepFilterState", this.keepFilterState)
      super.clean();
    }
  }
}

export default IndividualModelStore;