import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer, MobXProviderContext } from 'mobx-react';
import { useForm, Controller } from "react-hook-form";
import { Scrollbars } from "react-custom-scrollbars";
import { ModalFooter } from "components";
import { Validate } from "core/utils";
import DateComponent from '../../components/DateComponent';
import { DATE_FORMAT, LANGUAGE_CODE_DEFAULT, RENEWAL_SPAN_CODE_DEFAULT } from "../../core/utils/constants";
import moment from "moment";
import "./style.scss";

function useStores() {
  return React.useContext(MobXProviderContext)
}

const UpdateTenant = observer((props) => {

  const { modalStore, commonStore } = useStores();

  const { t } = useTranslation();

  const { control, register, errors, setError, handleSubmit, setValue } = useForm({
    defaultValues: {
      name: props.data.name,
      language: props.data.language || LANGUAGE_CODE_DEFAULT,
      startDate: props.data.startDate ? new Date(props.data.startDate) : null,
      endDate: props.data.endDate ? new Date(props.data.endDate) : null,
      contractAmount: props.data.contractAmount || null,
      contractAccount: props.data.contractAccount || null,
      contractRenewalSpan: props.data.contractRenewalSpan || RENEWAL_SPAN_CODE_DEFAULT,
      news: props.data.news || null,
      userId: props.data.userId,
      groupId: props.data.groupId
    }
  });

  const closeModal = () => {
    modalStore.hide();
  }

  const openComfirmModal = (data) => {
    modalStore.show({
      id: "confirm",
      isOpen: true,
      type: "small",
      header: t("tenant.update_tenant"),
      onCancel: () => closeModal(),
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>
              {t("tenant.update_tenant_confirm")}
            </span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t("tenant.update_submit")}
            onConfirm={async () => {
              const response = await commonStore.tenantStore.updateTenant(data);
              if (response) {
                commonStore.tenantStore.getTenants();
                commonStore.tenantStore.loadDefaultTenantFormData();
                modalStore.hideAll();
              } else {
                closeModal();
              }
            }}
            cancelButtonText={t("tenant.cancel")}
            onCancel={() => closeModal()}
          />
        </div>
      ),
    });
  };

  const handleMaxNumber = (value, field) => {
    if (value > 1000000000) {
      setValue(field, 1000000000)
    }
  }

  const onSubmit = data => {
    if (Validate.validateContractNumber(data.contractAmount, t)) {
      setError("contractAmount", {
        type: "password_condition",
        message: Validate.validateContractNumber(data.contractAmount, t)
      });
    } else if (Validate.validateAccountNumber(data.contractAccount, t)) {
      setError("contractAccount", {
        type: "password_condition",
        message: Validate.validateAccountNumber(data.contractAccount, t)
      });
    } else if (data.startDate && data.endDate && Validate.validateDateRange(moment(data.startDate).format(DATE_FORMAT.time_stamp), moment(data.endDate).format(DATE_FORMAT.time_stamp), t)) {
      setError("startDate", {
        type: "password_condition",
        message: Validate.validateDateRange(moment(data.startDate).format(DATE_FORMAT.time_stamp), moment(data.endDate).format(DATE_FORMAT.time_stamp), t)
      });
    } else {
      const payload = {
        "tenantId": data.groupId,
        "tenantName": data.name,
        "contractAmount": data.contractAmount,
        "contractAccount": data.contractAccount,
        "contractRenewalSpan": data.contractRenewalSpan ? parseInt(data.contractRenewalSpan) : RENEWAL_SPAN_CODE_DEFAULT,
        "news": data.news
      };
      if (data.startDate) payload.startDate = moment(data.startDate).format(DATE_FORMAT.time_stamp);
      if (data.endDate) payload.endDate = moment(data.endDate).format(DATE_FORMAT.time_stamp);
      openComfirmModal(payload);
    }
  }

  return <div className="wrap-create-tenant">
    <Scrollbars key={"modal-body"} className="modal-body">
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          <span className="color-red">*</span>
          {t("tenant.required")}
        </div>
      </div>
      <input type="hidden" name="userId" ref={register}/>
      <input type="hidden" name="groupId" ref={register}/>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          {t("tenant.name")}
        </div>
        <div className="col-sm-6">
          <Controller
            render={({ value }) => 
              <span style={{color: "#555", wordBreak: "break-word"}}>{value}</span>
            }
            name="name"
            control={control}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          {t("tenant.date_range")}
          {/* <span className="color-red">*</span> */}
        </div>
        <div className="col-sm-3 input-date-range">
          <Controller
            render={({ onChange, onBlur, value }) => <div className="input-group date">
              <label className="date-label">
                <div className="input-group-addon">
                  <i className="fa fa-calendar"></i>
                </div>
                <DateComponent className={errors["startDate"] && "error-input"} value={value} onChange={(e, dateValue) => onChange(dateValue)} />
              </label>
            </div>
            }
            name="startDate"
            control={control}
          // rules={{ required: t("validate.field_is_empty") }}
          />
          {/* {errors["startDate"] && <div className="color-red">{errors["startDate"]?.message}</div>} */}
        </div>
        <div className="col-sm-3 input-date-range">
          <Controller
            render={({ onChange, onBlur, value }) => <div className="input-group date">
              <label className="date-label">
                <div className="input-group-addon">
                  <i className="fa fa-calendar"></i>
                </div>
                <DateComponent className={errors["endDate"] && "error-input"} value={value} onChange={(e, dateValue) => onChange(dateValue)} />
              </label>
            </div>}
            name="endDate"
            control={control}
          // rules={{ required: t("validate.field_is_empty") }}
          />
          {/* {errors["endDate"] && <div className="color-red">{errors["endDate"]?.message}</div>} */}
        </div>
        {errors["startDate"] && <div className="col-sm-5"></div>}
        {errors["startDate"] && <div className="col-sm-7"><div className="color-red">{errors["startDate"]?.message}</div></div>}
      </div>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          {t("tenant.total")}
          <span className="color-red">*</span>
        </div>
        <div className="col-sm-6">
          <input
            min={100}
            placeholder={t("tenant.total_place_holder")}
            className={errors["contractAmount"] && "error-input"}
            name="contractAmount"
            type="number"
            onChange={e => handleMaxNumber(e.target.value, "contractAmount")}
            ref={register({ required: t("validate.field_is_empty") })}
          />
          {errors["contractAmount"] && <div className="color-red">{errors["contractAmount"]?.message}</div>}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          {t("tenant.active_user")}
          <span className="color-red">*</span>
        </div>
        <div className="col-sm-6">
          <input
            min={1}
            onChange={e => handleMaxNumber(e.target.value, "contractAccount")}
            onPaste={e => e.preventDefault()}
            placeholder={t("tenant.active_user_place_holder")}
            className={errors["contractAccount"] && "error-input"}
            name="contractAccount"
            type="number"
            ref={register({ required: t("validate.field_is_empty") })}
          />
          {errors["contractAccount"] && <div className="color-red">{errors["contractAccount"]?.message}</div>}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          {t("tenant.renewal_span")}
          <span className="color-red">*</span>
        </div>
        <div className="col-sm-6">
          <select className="slct" name="contractRenewalSpan" ref={register}>
            <option value="0">{t("tenant.renewal_span_month")}</option>
            <option value="1">{t("tenant.renewal_span_year")}</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          {t("tenant.update_tenant.news")}
        </div>
        <div className="col-sm-6">
          <textarea style={{"maxHeight":"220px"}}
            placeholder={t("tenant.overview_management.news.place_holder")}
            name="news"
            ref={register}
          />
        </div>
      </div>
    </Scrollbars>
    <ModalFooter key={"modal-footer"}
      saveButtonText={t('tenant.update_submit')}
      onConfirm={handleSubmit(onSubmit)}
      cancelButtonText={t('tenant.cancel')}
      onCancel={() => { modalStore.hideAll(); commonStore.tenantStore.loadDefaultTenantFormData(); }}
      saveButtonClass="btn-primary"
    />
  </div>
});

export default UpdateTenant;