import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { ContainerBox, Table } from "components";
import SearchBox from "./SearchBox";
import { TABLE_CODE, LOG_ACTION } from "../../core/utils/constants"

import {
  ACCESSOR_LOG,
  DATE_FORMAT,
  EMPTY_FORMAT_CELL,
} from "../../core/utils/constants";
import { observer, inject } from "mobx-react";
import moment from "moment";
import "./style.scss";
import BaseComponent from "../BaseComponent";

@inject('actionLogsStore', 'modalStore')
@observer
class ActionLogs extends BaseComponent {
  constructor(props) {
    super(props);
  }

  // table height
  /**
   * columns
   *
   * @returns column objects for table
   */
  columns = () => {
    const { t } = this.props;
    return [
      {
        Header: t("log.no"),
        disableSortBy: true,
        width: "4%",
        accessor: ACCESSOR_LOG.INDEX,
      },
      {
        Header: t("log.action"),
        accessor: ACCESSOR_LOG.ACTION,
        width: "16%",
        Cell: ({ row }) => {
          let action = EMPTY_FORMAT_CELL;
          switch (row?.original?.action) {
            case LOG_ACTION.RECORD_UPLOAD:
              action = t("log.action_record_upload");
              break;
            case LOG_ACTION.CSV_DOWNLOAD:
              action = t("log.action_csv_download");
              break;
            case LOG_ACTION.FORM_DEFINITION_UPLOAD:
              action = t("log.action_form_definition_upload");
              break;
            case LOG_ACTION.CORRECT_READING_RESULT:
              action = t("log.action_correct_reading_result");
              break;
            case LOG_ACTION.LOGIN:
              action = t("log.action_login");
              break;
            case LOG_ACTION.LOGOUT:
              action = t("log.action_logout");
              break;
            case LOG_ACTION.DELETE_DOCUMENT:
              action = t("log.action_delete_document");
              break;
            case LOG_ACTION.DEBUG_DOWNLOAD:
              action = t("log.action_debug_download");
              break;
            case LOG_ACTION.RECORD_DOWNLOAD:
              action = t("log.action_record_download");
              break;
          }
          return action;
        },
      },
      {
        Header: t("log.action_detail"),
        accessor: ACCESSOR_LOG.ACTION_DETAIL,
        width: "35%",
        className: "cell-action-detail",
        Cell: ({ row }) =>
          row?.original?.actionDetail
            ? row?.original?.actionDetail
            : EMPTY_FORMAT_CELL,
      },
      {
        Header: t("log.user_id"),
        accessor: ACCESSOR_LOG.USER_ID,
        width: "30%",
      },
      {
        Header: t("log.executed_at"),
        accessor: ACCESSOR_LOG.EXECUTED_AT,
        Cell: ({ row }) =>
          row?.original?.executedAt
            ? moment(row?.original?.executedAt).format(DATE_FORMAT.full_time)
            : EMPTY_FORMAT_CELL,
        width: "15%",
      },
    ];
  };

  /**
   * componentWillMount
   * get list actionLogs from BE
   *
   */
  componentWillMount = () => {
    this.props.actionLogsStore.clean();
    this.props.actionLogsStore.getActionLogs();
  };

  /**
   * handleDownload
   * handle download actions
   *
   */
  handleDownload = (data) => {
    this.props.actionLogsStore.applySearchBoxFiler();
    this.props.actionLogsStore.downloadActionLogs();
  };

  /**
   * handleUpdate
   * update screen follow search filter
   *
   */
  handleUpdate = () => {
    this.props.actionLogsStore.updatePagingFiler({ page: 1 });
    this.props.actionLogsStore.applySearchBoxFiler();
    this.props.actionLogsStore.getActionLogs();
  };

  renderSearchElement = () => {
    const { t } = this.props;

    return (
      <button
        className="download-btn-style btn btn-primary mb-3"
        type="button"
        onClick={this.handleUpdate}
      >
        {t("log.btn_update")}
      </button>
    );
  };

  onFetch = (tableData) => {
    this.props.actionLogsStore.updatePagingFiler(tableData);
    this.props.actionLogsStore.getActionLogs();
  };

  /**
   * render
   * render download main content
   *
   */
  render() {
    const { t } = this.props;
    let customSelectedBox = [10, 50, 100, 500, 1000];
    return (
      <div className="screen-action-log">
        <ContainerBox headerTitle={t("log.title")} className="search-container">
          <div className="box-body box-profile">
            <SearchBox savedData={this.props.location.state?.savedSearchState} />
          </div>
        </ContainerBox>
        <ContainerBox type="basic" className="action-log-container table-container">
          <div className="box-body document-item-info">
            <button
              className="download-btn-style btn btn-primary f-r mb-3 btn-download-action-log"
              type="button"
              onClick={this.handleDownload}
            >
              {t("log.download")}
            </button>
            <Table
              tblCode={TABLE_CODE.LOG}
              columns={this.columns()}
              data={this.props.actionLogsStore?.actionLogs}
              t={t}
              element={this.renderSearchElement()}
              customSelectedBox={customSelectedBox}
              disablePaging={false}
              onFetch={this.onFetch}
              enableServerSidePaging={true}
              initialTableState={this.props.actionLogsStore?.paging}
            />
          </div>
        </ContainerBox>
      </div>
    );
  }
}
export default withRouter(withTranslation()(ActionLogs));
