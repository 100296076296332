import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Scrollbars } from "react-custom-scrollbars";
import { ModalFooter } from "components";
import { observer, inject } from 'mobx-react';
import BaseComponent from '../BaseComponent';
import "./style.scss";

@inject('commonStore', 'modalStore')
@observer
class OverviewManagement extends BaseComponent { 
  constructor(props) {
    super(props);
    this.state = {
      infoUrl: '',
      news: ''
    };
  }

  // Set value for controls after render DOM
  executeLogicAfterRender = async () => {
    const { commonStore: { systemStore: { getSettingOverviewMng } } } = this.props;
    const setting = await getSettingOverviewMng();
    if (setting) {
        // Update state
        this.setState({
          infoUrl: setting.info_url,
          news: setting.news
        });
    }
  }

  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hide();
  };

  onConfirm = () => {
    const { commonStore: { systemStore: { setSettingOverviewMng } } } = this.props;
    const payload = {
      infoUrl: this.state.infoUrl,
      news: this.state.news
    };
    setSettingOverviewMng(payload).then(res => {
        if (res) {
          // Update state
          this.setState({
            infoUrl: res.info_url,
            news: res.news
          });
        }
    });
  }

  onChangeInfoUrl = (value) => {
    this.setState({
      infoUrl: value
    });
  }

  onChangeNews = (value) => {
    this.setState({
      news: value
    });
  }

  render() {
    const { t } = this.props;
    const { infoUrl, news } = this.state;

    return <div className="wrap-create-tenant">
        <Scrollbars key={"modal-body"} className="modal-body">
          <div className="row">
            <div className="col-sm-3 font-bold">
              <span className="color-red">*</span>
              {t("tenant.required")}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 font-bold">
              {t("tenant.overview_management.info_url")}
            </div>
            <div className="col-sm-9">
              <input
                placeholder={t("tenant.overview_management.info_url.place_holder")}
                name="infoUrl"
                type="text"
                maxLength={255}
                value={infoUrl}
                onChange={(value) => this.onChangeInfoUrl(value.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 font-bold">
              {t("tenant.overview_management.news")}
            </div>
            <div className="col-sm-9">
              <textarea
                placeholder={t("tenant.overview_management.news.place_holder")}
                name="news"
                value={news}
                onChange={(value) => this.onChangeNews(value.target.value)}
              />
            </div>
          </div>
        </Scrollbars>
        <ModalFooter key={"modal-footer"}
          saveButtonText={t('tenant.submit')}
          onConfirm={() => {
              this.closeModal();
              this.onConfirm();
            }}
          saveButtonClass="btn-primary"
        />
      </div>
  }
}

export default withTranslation()(OverviewManagement);