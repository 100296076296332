import { action, observable, configure, runInAction } from "mobx";
import { AuthApi } from "apis";
import ApiStore from "./ApiStore";

configure({
  enforceActions: "observed"
});

const defaultResetFormData = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: ""
};

class AuthStore {
  constructor() {
    this.api = new AuthApi();
  }
  @observable dataUrl = null;
  @observable resetPasswordFormData = defaultResetFormData;

  @action
  setResetPasswordFormData = (data) => {
    this.resetPasswordFormData = data;
  }

  @action
  loadDefaultResetPasswordFormData = () => {
    this.resetPasswordFormData = defaultResetFormData;
  }

  /**
   * signIn
   * handle sign in action
   * 
   * @param {Object} payload : username / password object
   * @returns  boolean
   */
  @action
  async signIn(payload) {
    let user = await ApiStore.call(this.api, this.api.logon, payload);
    if (user) {
      this.saveUserInfo(user, true);
      return true
    }
    return false
  }

  @action
  async changePassword(payload) {
    let response = await ApiStore.call(this.api, this.api.changePassword, payload);
    return response;
  }

  @action
  async getSystemStatus() {
    let data = await ApiStore.call(this.api, this.api.getSystemStatus);
    runInAction(() => {
      this.dataUrl = data?.info_url;
    });
    return data;
  }

  /**
   * logoff
   * handle sign off action
   * 
   * @returns  null
   */
  @action
  async logoff() {
    await ApiStore.call(this.api, this.api.logoff).finally(() => {
      localStorage.clear();
      sessionStorage.clear();
    });
  }

  /**
   * saveUserInfo
   * save user infomation to local storage
   * 
   * @param {Object} data : user object
   * @param {boolean} rememberMe : rememberMe
   * 
   * @returns  null
   */
  saveUserInfo = (data, rememberMe) => {
    if (rememberMe) {
      localStorage.setItem("sessionId", data.sessionId);
      localStorage.setItem("userId", data.userId);
      localStorage.setItem("role", data.role);
    } else {
      sessionStorage.setItem("sessionId", data.sessionId);
      sessionStorage.setItem("userId", data.userId);
      sessionStorage.setItem("role", data.role);
    }
  };
}
export default new AuthStore();
