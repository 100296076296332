import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import "./style.scss";
class ContainerBox extends Component {
    render() {
        const { children, headerTitle, type, hideTitle, className } = this.props;
        const classString = type === "basic" ? "box" : "box box-primary";
        return (
            <div className={className + " content"}>
                {headerTitle && ( hideTitle ?
                    <section className="content-header hideTitle">
                        <h1>{headerTitle}</h1>
                    </section>
                    :
                    <section className="content-header">
                        <h1>{headerTitle}</h1>
                    </section>
                )}
                <div className={classString}>{children}</div>
            </div>
        );
    }
}

export default withRouter(ContainerBox);
