import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { ContainerBox, Table, ModalFooter } from 'components';
import { CONFIG_FILE_EDITION, MODAL_ID, TEMPLATE_SORTING_CLASSIFICATION_TYPE, TEMPLATE_SORTING_STATUS } from '../../core/utils/constants';
import { Validate } from "core/utils";
import BaseComponent from '../BaseComponent';
import './style.scss';

const TABLE_ROW_WIDTH_MODEL = {
  CHK_BOX: "3%",
  NO: "5%",
  EDITION: "8%",
  FORM_GROUP_NAME: "19%",
  FORM_ID: "21%",
  CONFIG_NAME: "21%",
  STATUS: "13%",
  REGIST_TEMPLATE: "10%",
  KEYWORD: "23%",
};

@inject('commonStore', 'modalStore')
@observer
class ScreenEditTemplateSorting extends BaseComponent {
  templateSortingId = null;
  constructor(props) {
    super(props);
    //this.listCheckedItems = {};
    //this.fullDataOfListCheckedItems = {};
    this.listCheckedItems = new Map();
    this.fullDataOfListCheckedItems = new Map();
    this.keywords = new Map();
    this.templateSortingId = this.props.match.params?.id;
    this.state = {
      settingName: '',
      classificationType: TEMPLATE_SORTING_CLASSIFICATION_TYPE.IMAGE,
      maxFeatures: 2000,
      matchCtTh: 50,
      sizeDiffTh: -1,
      keywordRect: "",
      checkAllList: {},
    }
  }

  componentWillMount = () => {
    const { t, commonStore: { templateSortingStore } } = this.props;
    templateSortingStore.clean();
    templateSortingStore.getTemplateSortingFormGroupById(this.templateSortingId).then(() => {
      templateSortingStore.getDefinitationsGroup().then(() => {
        templateSortingStore.getTemplateSortingById(this.templateSortingId).then(() => {
          this.initCheckAllList();
          this.initKeywords();
        });
      });
    });
  };

  initCheckAllList = () => {
    const { t, commonStore: { templateSortingStore: { templateSortingFormMap, selectedGroupId } } } = this.props;
    this.listCheckedItems = new Map();
    this.fullDataOfListCheckedItems = new Map();
    this.state.checkAllList = {};
    let formList = templateSortingFormMap[selectedGroupId];
    if (formList) {
      formList.forEach((element) => {
        if (element && parseInt(element.status) === TEMPLATE_SORTING_STATUS.NOT_REGISTED) {
          this.state.checkAllList[element.configFile.id] = false;
        } else if (element && parseInt(element.status) === TEMPLATE_SORTING_STATUS.REGISTED) {
          this.state.checkAllList[element.configFile.id] = true;
          this.listCheckedItems.set(element.configFile.id, true);
          this.fullDataOfListCheckedItems.set(element.configFile.id, element);
        }
      });
    }
    this.setState(this.listCheckedItems);
    this.setState(this.fullDataOfListCheckedItems);
  };

  initKeywords = () => {
    const { t, commonStore: { templateSortingStore: { templateSortingFormMap, selectedGroupId } } } = this.props;
    this.keywords = new Map();
    let formList = templateSortingFormMap[selectedGroupId];
    if (formList) {
      formList.forEach((element) => {
        if (element && element.keyword) {
          this.keywords.set(element.configFile.id, element.keyword);
        } else {
          this.keywords.set(element.configFile.id, "");
        }
      });
    }
    this.setState(this.keywords);
  };

  /*
  openChangeTempalteSortingSettingModal = () => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.UPDATE_TEMPLATE_SORTING,
      isOpen: true,
      header: t('template_sorting.mng.modal.dialog_title'),
      onCancel: this.closeModal,
      children: (
        <ScreenChangeTempalteSortingSetting
          saveButtonText={t('common.save')}
          settingName={this.props.commonStore.templateSortingStore.templateSorting.settingName}
          maxFeatures={this.props.commonStore.templateSortingStore.templateSorting.maxFeatures}
          matchCtTh={this.props.commonStore.templateSortingStore.templateSorting.matchCtTh}
          sizeDiffTh={this.props.commonStore.templateSortingStore.templateSorting.sizeDiffTh}
          templateSortingId={this.templateSortingId}
          mode={MODE.EDIT}
        />
      ),
      type: MODAL_TYPE.SMALL
    });
  };
  */

  openChangeModal = () => {
    const { modalStore, t } = this.props;
    const { commonStore: { templateSortingStore: { templateSorting } } } = this.props;
    modalStore.show({
      id: MODAL_ID.UPDATE_TEMPLATE_SORTING,
      isOpen: true,
      header: t('template_sorting.mng.modal.dialog_title'),
      onCancel: this.closeModal,
      children: (
        <div className="screen-template-sorting-setting-modal">
          <div key={"modal-body"} className="modal-body modal-add-item">
            <div className="row">
              <div className="col-sm-1" />
              <div className="col-sm-4 font-bold">
                <span className="color-red">*</span>
                {t("setting_definition.required")}
              </div>
            </div>

            <div className="row" style={{ paddingTop: "15px" }}>
              <div className="col-sm-1" />
              <div className="col-sm-4 font-bold">
                {t("template_sorting.common.setting_name")}
                <span className="color-red">*</span>
              </div>
              <div className="col-sm-6" id="settingName">
                <input name="settingName"
                  id="input_settingName"
                  type="text"
                  maxLength={255}
                  placeholder={t("template_sorting.common.setting_name")}
                  onChange={this.onChangeSettingName}
                  defaultValue={templateSorting.settingName}
                />
                <span className="color-red" id="required_msg_settingName"></span>
              </div>
            </div>

            <div className="row" style={{ paddingTop: "15px" }}>
              <div className="col-sm-1" />
              <div className="col-sm-4 font-bold">
                {t("template_sorting.common.classification_type")}
                <span className="color-red">*</span>
              </div>
              <div className="col-sm-6" id="classificationType">
                {
                  templateSorting.classificationType && templateSorting.classificationType == TEMPLATE_SORTING_CLASSIFICATION_TYPE.KEYWORD
                    ? t("template_sorting.common.classification_type.keyword")
                    : t("template_sorting.common.classification_type.image")
                }
                <span className="color-red" id="required_msg_classificationType"></span>
              </div>
            </div>

            {
              (!templateSorting.classificationType || templateSorting.classificationType == TEMPLATE_SORTING_CLASSIFICATION_TYPE.IMAGE) &&
              <div className="row" style={{ paddingTop: "15px" }}>
                <div className="col-sm-1" />
                <div className="col-sm-4 font-bold">
                  {t("template_sorting.common.max_features")}
                  <span className="color-red">*</span>
                  <i className="fa fa-info-circle ar-tooltip"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t("template_sorting.mng.modal.max_features.tooltip")}>
                  </i>
                </div>
                <div className="col-sm-6" id="max_features">
                  <input name="maxFeatures"
                    id="input_maxFeatures"
                    type="number"
                    min={1}
                    max={2147483647}
                    defaultValue={templateSorting.maxFeatures}
                    placeholder={t("template_sorting.common.max_features")}
                    onChange={this.onChangeSettingNumber}
                    onKeyPress={this.handleKeyPressForNumber}
                  />
                  <span className="color-red" id="required_msg_maxFeatures"></span>
                </div>
              </div>
            }
            {
              (!templateSorting.classificationType || templateSorting.classificationType == TEMPLATE_SORTING_CLASSIFICATION_TYPE.IMAGE) &&
              <div className="row" style={{ paddingTop: "15px" }}>
                <div className="col-sm-1" />
                <div className="col-sm-4 font-bold">
                  {t("template_sorting.common.match_ct_th")}
                  <span className="color-red">*</span>
                  <i className="fa fa-info-circle ar-tooltip"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t("template_sorting.mng.modal.match_ct_th.tooltip")}>
                  </i>
                </div>
                <div className="col-sm-6" id="match_ct_th">
                  <input name="matchCtTh"
                    id="input_matchCtTh"
                    type="number"
                    min={0}
                    max={2147483647}
                    defaultValue={templateSorting.matchCtTh}
                    placeholder={t("template_sorting.common.match_ct_th")}
                    onChange={this.onChangeSettingNumber}
                    onKeyPress={this.handleKeyPressForNumber}
                  />
                  <span className="color-red" id="required_msg_matchCtTh"></span>
                </div>
              </div>
            }
            {
              (!templateSorting.classificationType || templateSorting.classificationType == TEMPLATE_SORTING_CLASSIFICATION_TYPE.IMAGE) &&
              <div className="row" style={{ paddingTop: "15px" }}>
                <div className="col-sm-1" />
                <div className="col-sm-4 font-bold">
                  {t("template_sorting.common.size_diff_th")}
                  <span className="color-red">*</span>
                  <i className="fa fa-info-circle ar-tooltip"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t("template_sorting.mng.modal.size_diff_th.tooltip")}>
                  </i>
                </div>
                <div className="col-sm-6" id="size_diff_th">
                  <input name="sizeDiffTh"
                    id="input_sizeDiffTh"
                    type="number"
                    min={-1}
                    max={2147483647}
                    defaultValue={templateSorting.sizeDiffTh}
                    placeholder={t("template_sorting.common.size_diff_th")}
                    onChange={this.onChangeSettingNumber}
                    onKeyPress={this.handleKeyPressForNumber}
                  />
                  <span className="color-red" id="required_msg_sizeDiffTh"></span>
                </div>
              </div>
            }
            {
              (templateSorting.classificationType && templateSorting.classificationType == TEMPLATE_SORTING_CLASSIFICATION_TYPE.KEYWORD) &&
              <div className="row" style={{ paddingTop: "15px" }}>
                <div className="col-sm-1" />
                <div className="col-sm-4 font-bold">
                  {t("template_sorting.common.keyword_rect")}
                  <span className="color-red">*</span>
                  <i className="fa fa-info-circle ar-tooltip"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t("template_sorting.mng.modal.keyword_rect.tooltip")}>
                  </i>
                </div>
                <div className="col-sm-6" id="keyword_rect">
                  <input name="keywordRect"
                    id="input_keywordRect"
                    type="text"
                    defaultValue={templateSorting.keywordRect}
                    placeholder={t("template_sorting.common.keyword_rect")}
                    onChange={this.onChangeSettingString}
                    onKeyPress={this.handleKeyPressForKeywordRect}
                  />
                  <span className="color-red" id="required_msg_keywordRect"></span>
                </div>
              </div>
            }
          </div>

          <ModalFooter key={"modal-footer"}
            saveButtonText={t('common.save')}
            onConfirm={() => this.onConfirmChangeTemplateSortingSetting()}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: 'medium'
    });
  };

  onConfirmChangeTemplateSortingSetting = () => {
    const { commonStore: { templateSortingStore } } = this.props;
    const { t } = this.props;
    let templateSorting = templateSortingStore.templateSorting;
    if (templateSorting.classificationType && templateSorting.classificationType == TEMPLATE_SORTING_CLASSIFICATION_TYPE.KEYWORD) {
      if (!this.checkKeywordSetting()) {
        return;
      }
      let payload = {
        "id": this.templateSortingId,
        "settingName": document.getElementById("input_settingName").value,
        "classificationType": TEMPLATE_SORTING_CLASSIFICATION_TYPE.KEYWORD,
        "maxFeatures": 2000,
        "matchCtTh": 50,
        "sizeDiffTh": -1,
        "keywordRect": document.getElementById("input_keywordRect").value,
      }
      templateSortingStore.updateTemplateSortingSetting(payload, this.afterChangeTemplateSortingSetting);
    } else {
      if (!this.checkSetting()) {
        return;
      }
      let payload = {
        "id": this.templateSortingId,
        "settingName": document.getElementById("input_settingName").value,
        "classificationType": TEMPLATE_SORTING_CLASSIFICATION_TYPE.IMAGE,
        "maxFeatures": document.getElementById("input_maxFeatures").value,
        "matchCtTh": document.getElementById("input_matchCtTh").value,
        "sizeDiffTh": document.getElementById("input_sizeDiffTh").value,
        "keywordRect": "",
      }
      templateSortingStore.updateTemplateSortingSetting(payload, this.afterChangeTemplateSortingSetting);
    }
  }

  afterChangeTemplateSortingSetting = () => {
    const { commonStore: { templateSortingStore } } = this.props;
    this.closeModal();
    this.state.settingName = templateSortingStore.templateSorting.settingName;
    document.getElementById("text_settingName").textContent = this.state.settingName;
    this.state.classificationType = templateSortingStore.templateSorting.classificationType;
    document.getElementById("text_classificationType").textContent = this.state.classificationType;
    if (this.state.classificationType && this.state.classificationType == TEMPLATE_SORTING_CLASSIFICATION_TYPE.KEYWORD) {
      this.state.keywordRect = templateSortingStore.templateSorting.keywordRect;
      document.getElementById("text_keywordRect").textContent = this.state.keywordRect;
    } else {
      this.state.maxFeatures = templateSortingStore.templateSorting.maxFeatures;
      document.getElementById("text_maxFeatures").textContent = this.state.maxFeatures;
      this.state.matchCtTh = templateSortingStore.templateSorting.matchCtTh;
      document.getElementById("text_matchCtTh").textContent = this.state.matchCtTh;
      this.state.sizeDiffTh = templateSortingStore.templateSorting.sizeDiffTh;
      document.getElementById("text_sizeDiffTh").textContent = this.state.sizeDiffTh;
    }
  }

  checkSetting = () => {
    const { t } = this.props;
    let errmsg = "";
    let haserr = false;
    document.getElementById("required_msg_settingName").textContent = '';
    document.getElementById("required_msg_maxFeatures").textContent = '';
    document.getElementById("required_msg_matchCtTh").textContent = '';
    document.getElementById("required_msg_sizeDiffTh").textContent = '';

    let settingName = document.getElementById("input_settingName").value;
    errmsg = Validate.validateNotNull(settingName, t);
    if (errmsg !== "") {
      document.getElementById("required_msg_settingName").textContent = errmsg;
      haserr = true;
    }
    let maxFeatures = document.getElementById("input_maxFeatures").value;
    errmsg = Validate.validateMaxFeatures(maxFeatures, t);
    if (errmsg !== "") {
      document.getElementById("required_msg_maxFeatures").textContent = errmsg;
      haserr = true;
    }
    let matchCtTh = document.getElementById("input_matchCtTh").value;
    errmsg = Validate.validateMatchCtTh(matchCtTh, t);
    if (errmsg !== "") {
      document.getElementById("required_msg_matchCtTh").textContent = errmsg;
      haserr = true;
    }
    let sizeDiffTh = document.getElementById("input_sizeDiffTh").value;
    errmsg = Validate.validateSizeDiffTh(sizeDiffTh, t);
    if (errmsg !== "") {
      document.getElementById("required_msg_sizeDiffTh").textContent = errmsg;
      haserr = true;
    }
    return !haserr;
  }

  checkKeywordSetting = () => {
    const { t } = this.props;
    let errmsg = "";
    let haserr = false;
    document.getElementById("required_msg_settingName").textContent = '';
    document.getElementById("required_msg_keywordRect").textContent = '';

    let settingName = document.getElementById("input_settingName").value;
    errmsg = Validate.validateNotNull(settingName, t);
    if (errmsg !== "") {
      document.getElementById("required_msg_settingName").textContent = errmsg;
      haserr = true;
    }
    let keywordRect = document.getElementById("input_keywordRect").value;
    errmsg = Validate.validateKeywordRect(keywordRect, t);
    if (errmsg !== "") {
      document.getElementById("required_msg_keywordRect").textContent = errmsg;
      haserr = true;
    }
    return !haserr;
  }

  onChangeSettingName = (event) => {
    //let value = event.target.value;
    document.getElementById("required_msg_settingName").textContent = '';
  }

  onChangeSettingNumber = (event) => {
    const target = event.target;
    const name = target.name;
    const value = parseInt(target.value);
    document.getElementById("required_msg_" + name).textContent = '';
    if (2147483647 < value) {
      target.value = this.state[name];
    }
  }

  handleKeyPressForNumber = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^-|\d*$/.test(keyValue)) {
      event.preventDefault();
    }
  }

  onChangeSettingString = (event) => {
    const target = event.target;
    const name = target.name;
    document.getElementById("required_msg_" + name).textContent = '';
  }

  handleKeyPressForKeywordRect = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^[tblrTBLR, \d]*$/.test(keyValue)) {
      event.preventDefault();
    }
  }

  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hideAll();
  };

  trHandler = () => {
    // compute for table body
    let table = document.getElementsByClassName("tbl-edit-team");
    if (table && table[0]) {
      let tableHead = window.$(".tbl-edit-team table thead");
      if (tableHead && tableHead[0]) {
        let tableBody = window.$(".tbl-edit-team table tbody");
        if (tableBody && tableBody[0]) {
          let tableBodyHeight = table[0].offsetHeight - tableHead[0].offsetHeight;

          // compute tr
          let trElements = window.$(".tbl-edit-team table tbody tr");
          let trHeight = 0;
          let num = 0;
          for (let i = 0; i < trElements.length; i++) {
            if (num !== 0) {
              trHeight += trElements[i].offsetHeight;
            }
            num++;
          }

          if (tableBodyHeight > trHeight) {
            tableHead[0].style.overflowY = "hidden";
          } else {
            tableHead[0].style.overflowY = "scroll";
          }
        }
      }
    }
  }

  /*
    closeModal = () => {
      const { modalStore } = this.props;
      modalStore.hideAll();
    };
  
    openUpdateModal = () => {
      const { t, modalStore } = this.props;
      modalStore.show({
        id: MODAL_ID.UPDATE_TEMPLATE_SORTING,
        isOpen: true,
        header: `${t('team.modal_add_user_title')}`,
        onCancel: this.closeModal,
        children: (
          <ScreenTeamAddUser
            teamId={this.teamId}
          />
        ),
        type: 'medium'
      });
    };
  */

  /*
    onFetch = (tableData) => {
      const { t, commonStore: { templateSortingStore } } = this.props;
      templateSortingStore.updatePagingFiler(tableData);
      templateSortingStore.getTemplateSortingFormGroupById(this.templateSortingId).then(()=>{
        templateSortingStore.getDefinitationsGroup().then(()=>{
          templateSortingStore.getTemplateSortingById(this.templateSortingId);
        });
      });
    };
  */

  isCheckAll = () => {
    const { t, commonStore: { templateSortingStore: { templateSorting, templateSortingFormMap, selectedGroupId } } } = this.props;
    let isCheckAll = true;

    if (-1 < selectedGroupId) {
      let formList = templateSortingFormMap[selectedGroupId];
      if (formList) {
        formList.forEach((element) => {
          if (element && (parseInt(element.statusTemplates) === TEMPLATE_SORTING_STATUS.REGISTED
            || templateSorting.classificationType == TEMPLATE_SORTING_CLASSIFICATION_TYPE.KEYWORD) && !this.listCheckedItems.get(element.configFile.id)) {
            isCheckAll = false;
          }
        });
      }
    } else {
      isCheckAll = false;
    }
    return isCheckAll;
  }

  checkAllActionHandler = (event) => {
    const { t, commonStore: { templateSortingStore: { templateSortingFormMap, selectedGroupId } } } = this.props;
    if (event.target.checked) {
      this.addItemsToList();
    } else {
      this.removeItemsFromList();
    }

    Object.keys(this.state.checkAllList).forEach((key) => {
      let formList = templateSortingFormMap[selectedGroupId];
      if (formList) {
        formList.forEach((element) => {
          if (element && parseInt(element.status) === TEMPLATE_SORTING_STATUS.REGISTED
            && parseInt(element.configFile.id) === key) {
            this.state.checkAllList[key] = event.target.checked;
          }
        });
      }
    })
  }

  addItemsToList = () => {
    const { t, commonStore: { templateSortingStore: { templateSorting, templateSortingFormMap, selectedGroupId } } } = this.props;
    if (-1 < selectedGroupId) {
      let formList = templateSortingFormMap[selectedGroupId];
      if (formList) {
        formList.forEach((element) => {
          if (element && (parseInt(element.statusTemplates) === TEMPLATE_SORTING_STATUS.REGISTED
            || templateSorting.classificationType == TEMPLATE_SORTING_CLASSIFICATION_TYPE.KEYWORD)) {
            this.addItemToList(element);
          }
        });
      }
    }
  }

  addItemToList = (element) => {
    //this.listCheckedItems[element.idx] = true;
    //this.fullDataOfListCheckedItems[element.idx] = element;
    this.listCheckedItems.set(element.configFile.id, true);
    this.fullDataOfListCheckedItems.set(element.configFile.id, element);
    //this.listCheckedItems.set  = {{element.idx}: element.id};
    //this.fullDataOfListCheckedItems = {"{element.idx}": element};
    this.rowHandler(true, element);
  }

  removeItemsFromList() {
    const { t, commonStore: { templateSortingStore: { templateSortingFormMap, selectedGroupId } } } = this.props;
    if (-1 < selectedGroupId) {
      let formList = templateSortingFormMap[selectedGroupId];
      if (formList) {
        formList.forEach((element) => {
          this.removeItemFromList(element);
        });
      }
    }
  }

  removeItemFromList(element) {
    this.listCheckedItems.delete(element.configFile.id);
    this.fullDataOfListCheckedItems.delete(element.configFile.id);
    this.rowHandler(false, element);
  }

  rowHandler = (isChecked, element) => {
    //if (Object.keys(this.listCheckedItems).length >= 1) {
    //  isChecked = true;
    //}
    if (0 < this.listCheckedItems.size) {
      isChecked = true;
    }
    if (isChecked) {
      this.setState(this.listCheckedItems);
      this.setState(this.fullDataOfListCheckedItems);
      //this.setState(this.listCheckedItems);
      //this.setState(this.fullDataOfListCheckedItems);
    } else {
      this.setState({
        listCheckedItems: new Map(),
        fullDataOfListCheckedItems: new Map(),
      });
    }

    /*
    if (isChecked) {
      this.setState({
        configFileEdition: element.configFileEdition,
        configFileCode: element.configFileCode,
        formGroupId: element.formGroupId,
        documentId: element.id,
      });
    } else {
      this.setState({
        configFileEdition: null,
        configFileCode: null,
        formGroupId: null,
        documentId: null,
      });
    }
    */
  }

  isSelectedItem = (id) => {
    let isChecked = false;
    //Object.keys(this.listCheckedItems).forEach((key) => {
    //  if (id === parseInt(key)) {
    //    isChecked = true;
    //  }
    //})
    if (this.listCheckedItems.get(id)) {
      isChecked = true;
    }
    return isChecked;
  };

  isDisableRow = (status) => {
    return !(parseInt(status) === TEMPLATE_SORTING_STATUS.REGISTED);
  };

  onChangeFormGroup = (event) => {
    const { commonStore: { templateSortingStore: { selectedGroupId } } } = this.props;
    //const target = event.target;
    let value = event.target.value;
    if (parseInt(value) !== selectedGroupId && selectedGroupId !== -1) {
      if (0 < this.listCheckedItems.size) {
        this.openComfirmChangeFormGroupModal(event.target);
      } else {
        this.closeChangeFormGroupModalOK(event.target);
      }
    } else if (selectedGroupId === -1) {
      this.closeChangeFormGroupModalOK(event.target);
    }
  };

  openComfirmChangeFormGroupModal = (target) => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: "confirm",
      isOpen: true,
      header: t("template_sorting.edit.modal.change_group"),
      onCancel: () => this.closeChangeFormGroupModalCancel(target),
      children: <div className="text-c">
        <div key={"modal-body"} className="modal-body">
          <span>
            {t("template_sorting.edit.modal.change_information")}
          </span>
        </div>
        <ModalFooter key={"modal-footer"}
          saveButtonText={t("common.ok")}
          onConfirm={() => {
            this.closeChangeFormGroupModalOK(target);
          }}
          cancelButtonText={t("common.cancel")}
          onCancel={() => {
            this.closeChangeFormGroupModalCancel(target);
          }}
        />
      </div>,
      type: "small"
    });
  };

  closeChangeFormGroupModalCancel = (target) => {
    const { modalStore } = this.props;
    const { t, commonStore: { templateSortingStore: { selectedGroupId } } } = this.props;
    target.value = selectedGroupId;
    modalStore.hide();
  };

  closeChangeFormGroupModalOK = (target) => {
    const { modalStore } = this.props;
    const { t, commonStore: { templateSortingStore } } = this.props;
    templateSortingStore.setSelectedGroupId(parseInt(target.value));
    this.initCheckAllList();
    this.initKeywords();
    this.render();
    modalStore.hide();
  };

  onRegistTemplates = (configfilesListsId) => {
    const { commonStore: { templateSortingStore } } = this.props;
    templateSortingStore.setCurrentConfigFile(configfilesListsId);
    this.props.history.push("/templatesorting/templates/" + configfilesListsId);
  };

  handleBackButton = () => {
    const { commonStore: { templateSortingStore } } = this.props;
    templateSortingStore.cleanAll();
    this.props.history.push("/templatesorting");
  };

  handleSaveButton = () => {
    const { commonStore: { templateSortingStore } } = this.props;
    templateSortingStore.updateTemplateSortingForms(this.templateSortingId, this.listCheckedItems, this.keywords);
  }

  handleRowSelected = (e) => {
    let element = e.target.value;
    if (element) {
      element = JSON.parse(element);
      if (e.target.checked) {
        this.addItemToList(element);
      } else {
        this.removeItemFromList(element);
      }
    }
  };

  getKeyword = (id) => {
    return this.keywords.get(id);
  };

  onChangeKeyword = (e, id) => {
    let element = e.target.value;
    this.keywords.set(id, element);
  };

  refreshKeywordInputs = () => {
    const { commonStore: { templateSortingStore: { templateSorting, templateSortingFormMap, selectedGroupId } } } = this.props;
    if (templateSorting.classificationType != TEMPLATE_SORTING_CLASSIFICATION_TYPE.KEYWORD) {
      return;
    }
    let formList = templateSortingFormMap[selectedGroupId];
    if (formList) {
      formList.forEach((element) => {
        let input = document.getElementById("input_keyword_" + element.configFile.id);
        let value = this.keywords.get(element.configFile.id);
        // inputのvalueを更新
        let nativeSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
        nativeSetter.call(input, value);
        let ev2 = new Event("input", { bubbles: true });
        input.dispatchEvent(ev2);
      });
    }
  };

  isDisabledSaveButton = () => {
    /* const { t, commonStore: { templateSortingStore: { templateSortingFormMap, selectedGroupId } } } = this.props;
    let formList = [];
    let ret = true;
    if (typeof templateSortingFormMap !== 'undefined' && selectedGroupId !== -1 ) {
      for (var key in templateSortingFormMap) {
        if (parseInt(key) === selectedGroupId) {
          formList = templateSortingFormMap[key];
        }
      }
    }
    if (formList) {
      formList.map((form) => {
        let status = form.status;
        if (status) {
          if (parseInt(status) === TEMPLATE_SORTING_STATUS.REGISTED) {
            ret = false;
          }
        }
      });
    }*/

    return !(0 < this.listCheckedItems.size);
    //return ret;
  };

  render() {
    const { t, commonStore: { templateSortingStore: { paging, templateSorting, templateSortingFormMap, selectedGroupId, listGroupDefinitions } } } = this.props;
    let pageIndex = 0;
    let selctedGroup = '';
    let formList = [];
    if (typeof templateSorting.settingName !== 'undefined') {
      this.state.settingName = templateSorting.settingName;
    }
    if (typeof templateSorting.classificationType !== 'undefined') {
      this.state.classificationType = templateSorting.classificationType;
    }
    if (typeof templateSorting.maxFeatures !== 'undefined') {
      this.state.maxFeatures = templateSorting.maxFeatures;
    }
    if (typeof templateSorting.matchCtTh !== 'undefined') {
      this.state.matchCtTh = templateSorting.matchCtTh;
    }
    if (typeof templateSorting.sizeDiffTh !== 'undefined') {
      this.state.sizeDiffTh = templateSorting.sizeDiffTh;
    }
    if (typeof templateSorting.keywordRect !== 'undefined') {
      this.state.keywordRect = templateSorting.keywordRect;
    }
    if (typeof templateSortingFormMap !== 'undefined' && selectedGroupId !== -1) {
      for (var key in templateSortingFormMap) {
        if (parseInt(key) === selectedGroupId) {
          formList = templateSortingFormMap[key];
        }
      }
      listGroupDefinitions.map((group) => {
        if (parseInt(group.id) === selectedGroupId) {
          selctedGroup = group.groupName;
        }
      });
    }
    let columns = [];
    if (templateSorting.classificationType && templateSorting.classificationType == TEMPLATE_SORTING_CLASSIFICATION_TYPE.KEYWORD) {
      columns = [
        {
          id: "selection",
          Header: (row) => {
            if (pageIndex || pageIndex === 0) {
              pageIndex += 1;
            }
            return (
              <input id={pageIndex}
                className="cb-check-all"
                type="checkbox"
                checked={this.isCheckAll(row.idx)}
                onChange={this.checkAllActionHandler} />
            );
          },
          Cell: ({ row }) => {
            let isChecked = this.isSelectedItem(row.original.configFile.id);
            return (
              <div>
                <input
                  id={row?.original?.idx}
                  type="checkbox"
                  value={JSON.stringify(row?.original)}
                  onChange={this.handleRowSelected}
                  checked={isChecked}
                />
              </div>
              //)
            );
          },
          width: TABLE_ROW_WIDTH_MODEL.CHK_BOX,
        },
        {
          Header: t("common.no"),
          accessor: 'no',
          disableSortBy: true,
          width: TABLE_ROW_WIDTH_MODEL.NO,
          Cell: ({ row }) => <div style={{ textAlign: "center" }}>{
            row.index +
            1
          }</div>
          ,
        },
        {
          Header: t('template_sorting.edit.edition'),
          accessor: 'edition',
          Cell: ({ row: { original } }) => {
            return (
              <div>
                {original?.configFile?.edition && original?.configFile?.edition == CONFIG_FILE_EDITION.RULE
                  ? t("template_sorting.edit.edition.rule") : t("template_sorting.edit.edition.manual")}
              </div>
            );
          },
          width: TABLE_ROW_WIDTH_MODEL.EDITION
        },
        {
          Header: t('template_sorting.edit.form_group_name'),
          accessor: 'form_group_name',
          Cell: ({ row: { original } }) => {
            return (
              <div>
                {selctedGroup}
              </div>
            );
          },
          width: TABLE_ROW_WIDTH_MODEL.FORM_GROUP_NAME
        },
        {
          Header: t('template_sorting.edit.form_id'),
          accessor: 'form_id',
          Cell: ({ row: { original } }) => {
            return (
              <div>
                {original?.configFile?.formId}
              </div>
            );
          },
          width: TABLE_ROW_WIDTH_MODEL.FORM_ID
        },
        {
          Header: t('template_sorting.edit.config_name'),
          accessor: 'config_name',
          Cell: ({ row: { original } }) => {
            return (
              <div>
                {original?.configFile?.configName}
              </div>
            );
          },
          width: TABLE_ROW_WIDTH_MODEL.CONFIG_NAME
        },
        {
          Header: () => {
            return (
              <div>
                {t("template_sorting.edit.keyword")}
                <i className="fa fa-info-circle ar-tooltip"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={t("template_sorting.edit.keyword.tooltip")}>
                </i>
              </div>
            );
          },
          id: 'keyword',
          Cell: ({ row: { original } }) => {
            let keyword = this.getKeyword(original.configFile.id);
            return (
              <div>
                <input className="template-sorting-keyword-input"
                  name="keyword"
                  id={"input_keyword_" + original.configFile.id}
                  type="text"
                  defaultValue={keyword}
                  onChange={(e) => this.onChangeKeyword(e, original.configFile.id)}
                />
              </div>
            );
          },
          width: TABLE_ROW_WIDTH_MODEL.KEYWORD,
        }
      ];
    } else {
      columns = [
        {
          id: "selection",
          Header: (row) => {
            if (pageIndex || pageIndex === 0) {
              pageIndex += 1;
            }
            return (
              <input id={pageIndex}
                className="cb-check-all"
                disabled=
                {(selectedGroupId != -1) ? false : true}
                type="checkbox"
                checked={this.isCheckAll(row.idx)}
                onChange={this.checkAllActionHandler} /> /*&&
            <i className="fa fa-info-circle ar-tooltip"
                data-toggle="tooltip"
                data-placement="top"
                title={t("template_sorting.mng.modal.max_features.tooltip")} />*/
            );
          },
          Cell: ({ row }) => {
            let isChecked = this.isSelectedItem(row.original.configFile.id);
            return (
              //this.isAccessableItem(row?.original?.status) && (
              <div>
                <input
                  id={row?.original?.idx}
                  type="checkbox"
                  //value={row?.original?.configFile?.status}
                  value={JSON.stringify(row?.original)}
                  onChange={this.handleRowSelected}
                  checked={isChecked}
                  disabled={this.isDisableRow(row?.original?.statusTemplates)}
                />
              </div>
              //)
            );
          },
          width: TABLE_ROW_WIDTH_MODEL.CHK_BOX,
        },
        {
          Header: t("common.no"),
          accessor: 'no',
          disableSortBy: true,
          width: TABLE_ROW_WIDTH_MODEL.NO,
          Cell: ({ /*initialState,*/ row }) => <div style={{ textAlign: "center" }}>{
            /*initialState?.initialTableState?.pageSize *
            initialState?.initialTableState?.pageIndex +*/
            row.index +
            1
          }</div>
          ,
        },
        {
          Header: t('template_sorting.edit.edition'),
          accessor: 'edition',
          Cell: ({ row: { original } }) => {
            return (
              <div>
                {original?.configFile?.edition && original?.configFile?.edition == CONFIG_FILE_EDITION.RULE
                  ? t("template_sorting.edit.edition.rule") : t("template_sorting.edit.edition.manual")}
              </div>
            );
          },
          width: TABLE_ROW_WIDTH_MODEL.EDITION
        },
        {
          Header: t('template_sorting.edit.form_group_name'),
          accessor: 'form_group_name',
          Cell: ({ row: { original } }) => {
            return (
              <div>
                {selctedGroup}
              </div>
            );
          },
          width: TABLE_ROW_WIDTH_MODEL.FORM_GROUP_NAME
        },
        {
          Header: t('template_sorting.edit.form_id'),
          accessor: 'form_id',
          Cell: ({ row: { original } }) => {
            return (
              <div>
                {original?.configFile?.formId}
              </div>
            );
          },
          width: TABLE_ROW_WIDTH_MODEL.FORM_ID
        },
        {
          Header: t('template_sorting.edit.config_name'),
          accessor: 'config_name',
          Cell: ({ row: { original } }) => {
            return (
              <div>
                {original?.configFile?.configName}
              </div>
            );
          },
          width: TABLE_ROW_WIDTH_MODEL.CONFIG_NAME
        },
        {
          Header: t('template_sorting.edit.status'),
          accessor: 'status',
          Cell: ({ row: { original } }) => {
            return (
              <div>
                {/*{t("template_sorting.edit.status_" + original?.status)}*/}
                {t("template_sorting.edit.status_" + original?.statusTemplates)}
              </div>
            );
          },
          width: TABLE_ROW_WIDTH_MODEL.STATUS
        },
        {
          Header: t('template_sorting.edit.regist'),
          id: 'regist',
          /*className: 'admin-action',*/
          Cell: ({ row: { original } }) => {
            return (
              <div>
                <button className="btn btn-primary template-sorting-btn-style"
                  type="button"
                  onClick={() => this.onRegistTemplates(original?.configFile?.id)}>
                  {t('template_sorting.edit.regist_btn')}
                </button>
              </div>
            );
          },
          width: TABLE_ROW_WIDTH_MODEL.REGIST_TEMPLATE,
        }
      ];
    }

    return (
      <div className="screen-template-sorting">
        <ContainerBox headerTitle={`${t('template_sorting.edit.page_title')}`}>
          <div className="admin-header row">
            <div className="col-sm-11">
              <div className="row">
                <div className="col-sm-3" style={{ textAlign: "left", fontWeight: "bold" }}>
                  {t("template_sorting.common.setting_name")}
                </div>
                <div className="col-sm-3" id="text_settingName">
                  {this.state.settingName}
                </div>
                <div className="col-sm-3" style={{ textAlign: "left", fontWeight: "bold" }}>
                  {t("template_sorting.common.classification_type")}
                </div>
                <div className="col-sm-3" id="text_classificationType">
                  {this.state.classificationType && this.state.classificationType == TEMPLATE_SORTING_CLASSIFICATION_TYPE.KEYWORD
                    ? t("template_sorting.common.classification_type.keyword") : t("template_sorting.common.classification_type.image")}
                </div>
              </div>

              {
                (!this.state.classificationType || this.state.classificationType == TEMPLATE_SORTING_CLASSIFICATION_TYPE.IMAGE) &&
                <div className="row">
                  <div className="col-sm-3" style={{ textAlign: "left", fontWeight: "bold" }}>
                    {t("template_sorting.common.max_features")}
                  </div>
                  <div className="col-sm-1" id="text_maxFeatures">
                    {this.state.maxFeatures}
                  </div>

                  <div className="col-sm-3" style={{ textAlign: "left", fontWeight: "bold" }}>
                    {t("template_sorting.common.match_ct_th")}
                  </div>
                  <div className="col-sm-1" id="text_matchCtTh">
                    {this.state.matchCtTh}
                  </div>

                  <div className="col-sm-3" style={{ textAlign: "left", fontWeight: "bold" }}>
                    {t("template_sorting.common.size_diff_th")}
                  </div>
                  <div className="col-sm-1" id="text_sizeDiffTh">
                    {this.state.sizeDiffTh}
                  </div>
                </div>
              }
              {
                this.state.classificationType == TEMPLATE_SORTING_CLASSIFICATION_TYPE.KEYWORD &&
                <div className="row">
                  <div className="col-sm-3" style={{ textAlign: "left", fontWeight: "bold" }}>
                    {t("template_sorting.common.keyword_rect")}
                  </div>
                  <div className="col-sm-9" id="text_keywordRect">
                    {this.state.keywordRect}
                  </div>
                </div>
              }
            </div>

            <div className="col-sm-1">
              <div className="row">
                <button
                  className="btn btn-primary f-r mb-3 mr-3"
                  type="button"
                  onClick={this.openChangeModal}>
                  {t("template_sorting.edit.change")}
                </button>
              </div>
            </div>
          </div>
        </ContainerBox>

        <ContainerBox type="basic" className="admin-body-container table-container">
          <div className="box-body document-item-info">
            <div className="row">
              <div className="selection-header" style={{ paddingRight: "0px", marginLeft: "5px" }}>
                {t("template_sorting.edit.formGroup")}
                <select
                  className="select"
                  defaultValue={selectedGroupId}
                  style={{ width: "20%", marginLeft: "5px" }}
                  onChange={(e) => this.onChangeFormGroup(e)}
                >
                  {(selectedGroupId == -1) &&
                    <option key="-1" value="-1">{t("template_sorting.edit.formGroup_default")}</option>}
                  {listGroupDefinitions.map((item) => {
                    if (item?.id === selectedGroupId) {
                      return <option key={item?.id} value={item?.id} selected="selected">{item?.groupName}</option>;
                    } else {
                      return <option key={item?.id} value={item?.id}>{item?.groupName}</option>;
                    }
                  })
                  }
                </select>
                <div style={{ display: "block", margin: "0 0 0 auto" }}>
                  <button
                    disabled={this.isDisabledSaveButton()}
                    className="btn btn-primary f-r mb-3 mr-3"
                    style={{ marginLeft: "10px", marginRight: "20px", width: "80px" }}
                    onClick={() => this.handleSaveButton()}
                  >
                    {t("template_sorting.edit.save")}
                  </button>
                  <button
                    className="btn btn-primary f-r mb-3 mr-3"
                    style={{ marginLeft: "10px", marginRight: "10px", width: "80px" }}
                    onClick={() => this.handleBackButton()}
                  >
                    {t("template_sorting.edit.back")}
                  </button>
                </div>
              </div>
            </div>
            <Table
              /*className="tbl-edit-template-sorting"*/
              columns={columns}
              data={formList}
              disablePaging={true}
              disableSelectionPageSize={true}
              /*onFetch={this.onFetch}*/
              enableServerSidePaging={false}
              initialTableState={paging}
              hideTableFooter={true}
              useCustomSortProcess={true}
              customSortProcess={this.refreshKeywordInputs}
            />
            {formList.length === 0 &&
              <div className="table-no-data">
                <p>{t("table_no_data")}</p>
              </div>
            }
          </div>
        </ContainerBox>
      </div>
    );
  }

}

export default withRouter(withTranslation()(ScreenEditTemplateSorting));
