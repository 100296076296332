import { ApiService } from "core/services";

class DefinitionApi extends ApiService {
  constructor() {
    super("configFilesList");
  }

  getAll(payload = null) {
    return this.get("admin", payload);
  }

  updateDefinition(payload) {
    return this.put(`${payload.id}`, { configName: payload.configName });
  }

  deleteDefinition(id) {
    return this.delete(`${id}`);
  }

  specifyingFormSettings(payload) {
    return this.put("form-group", payload);
  }

  getFormGroupsAll(payload = null) {
    return this.get("form-group/admin", payload);
  }

  deleteFormGroup(id) {
    return this.delete(`form-group/${id}`);
  }

  updateFormGroupSetting(payload) {
    return this.put(`form-group/${payload.formGroupId}`, payload);
  }

  downloadConfigFilesList(formGroupId) {
    return this.get(`download/${formGroupId}`);
  }
}

export default DefinitionApi;
