import { ApiService } from "core/services";

class UserApi extends ApiService {
    constructor() {
        super("users");
    }

    getCurrentUserInfo() {
        return this.get("user-info");
    }

    getAll(payload = null) {
        return this.get("", payload);
    }

    getAllContainAdmin(payload = null) {
        return this.get("/all", payload);
    }

    createUser(payload) {
        return this.post("?force=" + payload.forceCreate, payload);
    }

    getUser(userId) {
        return this.get(`${userId}/`);
    }

    updateUserStatus(payload) {
        return this.put(`${payload.userId}/status`, { "status": payload.status });
    }

    updateUser(payload) {
        return this.put("", payload);
    }

    updateAdmin(payload) {
        return this.put("/admin", payload);
    }

    deleteUser(userId) {
        return this.delete(`${userId}/`);
    }

    getMonthlyAmount(payload) {
        return this.get("amount", payload)
    }

    unlockUser(userId) {
        return this.put(`${userId}/unlock`);
    }
}

export default UserApi;
