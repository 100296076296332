import React from "react";
import DatePicker from "react-datepicker";
import { withTranslation } from "react-i18next";
import classnames from "classnames";
import moment from "moment";
import {DATE_FORMAT, AUTO_COMPLETE_STRING, AUTO_COMPLETE_ON} from "../../core/utils/constants" 

import "./style.scss";
// for pdf files
class InputField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            error: true,
            messageError: ""
        };
    }

    handleChange = (type, name, value) => {
        const { onChange, validate, t } = this.props;
        let val = "";
        switch (type) {
            case "date":
                val = moment(value).format(DATE_FORMAT.frontend_minus);
                break;
            default:
                val = value.target.value;
        }
        const messageError = validate ? validate(val, t) : "";
        onChange(name, val, messageError);
    };
    renderByType = () => {
        const {
            value,
            type,
            placeholder,
            options,
            name,
            disabled,
            maxLength,
            onBlur,
            autocomplete
        } = this.props;
        switch (type) {
            case "date":
                return (
                    <div className="input-group date">
                        <div className="input-group-addon">
                            <i className="fa fa-calendar"></i>
                        </div>
                        <DatePicker
                            value={value}
                            selected={null}
                            onChange={value =>
                                this.handleChange("date", name, value)
                            }
                        />
                    </div>
                );
            case "select":
                return (
                    <select className="select" value={value} onChange={value =>
                            this.handleChange("select", name, value)
                        }>
                        {options &&
                            options.map(opt => {
                                return <option key={opt}>{opt}</option>;
                            })}
                    </select>
                );
            default:
                return (
                    <input
                        autoComplete={(autocomplete && autocomplete === true) ? AUTO_COMPLETE_ON : AUTO_COMPLETE_STRING }
                        type={type}
                        placeholder={placeholder}
                        onChange={value =>
                            this.handleChange("text", name, value)
                        }
                        onBlur={onBlur}
                        value={value ? value : ""}
                        disabled={disabled}
                        maxLength={maxLength}
                    />
                );
        }
    };

    render() {
        const { hideLabel, label, disabled, error } = this.props;
        return (
            <div
                className={classnames("input-field input-component", {
                    error: error && !disabled
                })}>
                {label && <label className={hideLabel === true ? "hideLabel" : ""}>{label}</label>}
                {this.renderByType()}
                {error && <span className="color-red">{error}</span>}
            </div>
        );
    }
}

export default withTranslation()(InputField);
