import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { ContainerBox, Table, ModalFooter } from 'components';
import { PROFILE_ROLES_PREFIX, MODAL_ID } from '../../core/utils/constants';
import BaseComponent from '../BaseComponent';
import ScreenTeamAddUser from './ScreenTeamAddUser';
import ScreenChangeTeamName from './ScreenChangeTeamName';
import './style.scss';

const TABLE_ROW_WIDTH_MODEL = {
  NO: "6%",
  USER_ID: "30%",
  USER_NAME: "30%",
  USER_LEVEL: "24%",
  REMOVE: "5%",
};

@inject('commonStore', 'modalStore')
@observer
class ScreenEditTeam extends BaseComponent {
  teamId = null;
  constructor(props) {
    super(props);
    this.state = {
      teamName: '',
    }
    this.teamId = this.props.match.params?.id;
  }

  // executeLogicAfterRender = () => {
  //   this.props.commonStore.teamStore.clean();
  //   this.props.commonStore.teamStore.getTeamById(this.teamId);
  // }

  // executeLogicBeforeOutComponent = () => {
  //   this.props.commonStore.teamStore.clean();
  // }

  componentWillMount = () => {
    this.props.commonStore.teamStore.clean();
    this.props.commonStore.teamStore.getTeamById(this.teamId);
  };

  openChangeTeamNameModal = () => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.UPDATE_TEAM,
      isOpen: true,
      header: t('team.modal_team_management_title'),
      onCancel: this.closeModal,
      children: (
        <ScreenChangeTeamName
          saveButtonText={t('definition.save')}
          teamName={this.props.commonStore.teamStore.teamName}
          teamId={this.teamId}
        />
      )
    });
  };

  onConfirmDeleteById = (sequence) => {
    const { commonStore: { teamStore } } = this.props;
    let payload = {
      id: this.teamId,
      sequence: sequence
    }
    teamStore.deleteUserForTeam(
      payload,
      () => {
        teamStore.getTeamById(this.teamId);
        this.closeModal();
      }
    );
  }

  trHandler = () => {
    // compute for table body
    let table = document.getElementsByClassName("tbl-edit-team");
    if (table && table[0]) {
      let tableHead = window.$(".tbl-edit-team table thead");
      if (tableHead && tableHead[0]) {
        let tableBody = window.$(".tbl-edit-team table tbody");
        if (tableBody && tableBody[0]) {
          let tableBodyHeight = table[0].offsetHeight - tableHead[0].offsetHeight;

          // compute tr
          let trElements = window.$(".tbl-edit-team table tbody tr");
          let trHeight = 0;
          let num = 0;
          for (let i = 0; i < trElements.length; i++) {
            if (num !== 0) {
              trHeight += trElements[i].offsetHeight;
            }
            num++;
          }

          if (tableBodyHeight > trHeight) {
            tableHead[0].style.overflowY = "hidden";
          } else {
            tableHead[0].style.overflowY = "scroll";
          }
        }
      }
    }
  }

  openComfirmDeleteModal = (sequence) => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.REMOVE,
      isOpen: true,
      header: t('definition.remove_confirm'),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>{t('team.remove_user_confirm_msg')}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t('team.remove')}
            onConfirm={() => this.onConfirmDeleteById(sequence)}
            cancelButtonText={t('definition.cancel')}
            onCancel={this.closeModal}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: 'small'
    });
  };

  openAddUserModal = () => {
    const { t, modalStore } = this.props;
    modalStore.show({
      id: MODAL_ID.UPDATE_TEAM_ADD_USER,
      isOpen: true,
      header: `${t('team.modal_add_user_title')}`,
      onCancel: this.closeModal,
      children: (
        <ScreenTeamAddUser
          teamId={this.teamId}
        />
      ),
      type: "big"
    });
  };

  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hideAll();
  };

  onFetch = (tableData) => {
    this.props.commonStore.teamStore.updatePagingFiler(tableData);
    this.props.commonStore.teamStore.getTeamById(this.teamId);
  };

  render() {
    const { t, commonStore: { teamStore: { paging } } } = this.props;
    const teamName = this.props.commonStore.teamStore.teamName || "";
    const teamUsers = this.props.commonStore.teamStore.teamUsers || [];
    const columns = [
      {
        Header: t("team.no"),
        disableSortBy: true,
        width: TABLE_ROW_WIDTH_MODEL.NO,
        Cell: ({ initialState, row }) => <div style={{ textAlign: "center" }}>{
          initialState?.initialTableState?.pageSize *
          initialState?.initialTableState?.pageIndex +
          row.index +
          1
        }</div>
        ,
      },
      {
        Header: t('team.user_id'),
        accessor: 'userId',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.userId}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_MODEL.USER_ID
      },
      {
        Header: t('team.user_name'),
        accessor: 'userName',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.userName}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_MODEL.USER_NAME
      },
      {
        Header: t('team.user_level'),
        accessor: 'role',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {t(PROFILE_ROLES_PREFIX + original.role)}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_MODEL.USER_LEVEL
      },
      {
        Header: t("team.remove"),
        id: 'action',
        className: 'admin-action',
        Cell: ({ row: { original: { sequence } } }) => {
          return <div>
            <button className="btn btn-danger" type="button" onClick={() => this.openComfirmDeleteModal(sequence)}>
              {t('team.remove')}
            </button>
          </div>
        },
        width: 100,
      }
    ];

    return (
      <div className="screen-team">
        <ContainerBox headerTitle={`${t('team.page_edit_team_title')}`}>
          <div className="admin-header-container">
            <div className="col-sm-2" style={{ textAlign: "left", fontWeight: "bold" }}>
              {t("team.team_name")}
            </div>
            <div className="col-sm-7" style={{ textAlign: "center" }}>
              {teamName}
            </div>
            <div className="col-sm-3" style={{ textAlign: "right" }}>
              <button
                className="btn btn-primary"
                type="button"
                onClick={this.openChangeTeamNameModal}
              >
                {t("team.change")}
              </button>
            </div>
          </div>
        </ContainerBox>

        <ContainerBox type="basic" className="admin-body-container table-container">
          <div className="box-body document-item-info">
            <button
              className="btn btn-primary team-btn-style f-r mb-3"
              type="button"
              onClick={this.openAddUserModal}>
              {t("team.add_user")}
            </button>
            <Table
              className="tbl-edit-team"
              columns={columns}
              data={teamUsers}
              disablePaging={false}
              onFetch={this.onFetch}
              enableServerSidePaging={true}
              initialTableState={paging}
            />
          </div>
        </ContainerBox>
      </div>
    );
  }

}

export default withRouter(withTranslation()(ScreenEditTeam));
