import { ApiService } from "core/services";

class DocumentsDownloadAPI extends ApiService {
  constructor() {
    super("documentsdownload");
  }

  /**
   * getDocuments
   * prepare to search
   *
   * @param {Object} payload : filter conditions
   * @returns  boolean
   */
  getDocuments(payload = null) {
    return this.get("", payload);
  }

  /**
   * downloadDocuments
   * prepare to download
   *
   * @param {Object} payload : conditions
   * @returns  boolean
   */
  downloadDocuments(payload) {
    return this.get("download", payload);
  }
}

export default DocumentsDownloadAPI;
