import { action, observable, runInAction, configure } from "mobx";
import { DefinitionApi } from "apis";
import ApiStore from "./ApiStore";
import BaseStore from "./BaseStore";

configure({ enforceActions: "observed" });

class DefinitionStore extends BaseStore {
  @observable definitions = [];
  @observable definition = {};
  @observable keepFilterState = false;

  constructor(props) {
    super(props);
    this.api = new DefinitionApi();
  }

  async getDefinitions() {
    const { size, page, sortDir, sortKey } = this.paging;
    let payload = { size, page, sortDir, sortKey };
    let response = await ApiStore.call(this.api, this.api.getAll, payload);
    if (response) {
      runInAction(() => {
        this.updatePagingFiler(response.paging);
        this.definitions = response.elements;
        if (this.paging?.totalRecord && this.paging?.totalRecord !== 0) {
          if (this.paging?.page > 1 && (!response.elements || response.elements.length === 0)) {
            this.updatePagingFiler({ page: this.paging.page - 1 });
            this.getDefinitions();
          }
        }
      })
    }
  }

  async updateDefinition(payload, cb, rb, t) {
    let resp = await ApiStore.call(this.api, this.api.updateDefinition, payload);
    if (resp) {
      runInAction(() => {
        const definitionDetail = resp;
        const index = this.definitions.findIndex(
          o => o.id === payload.id
        );
        this.definitions[index] = definitionDetail;
        cb && cb();
      });
    } else {
      rb && rb();
    }
  }

  @action
  async deleteDefinition(id) {
    let resp = await ApiStore.call(this.api, this.api.deleteDefinition, id);
    if (resp) {
      runInAction(() => {
        this.getDefinitions();
      })
    }
  }

  async specifyingFormSettings(payload, cb) {
    let resp = await ApiStore.call(this.api, this.api.specifyingFormSettings, payload);
    if (resp) {
      runInAction(() => {
        cb && cb();
      });
    }
  }

  @action showEditModal = (id, cb) => {
    this.definition = this.definitions.find(e => id === e.id);
    cb && cb();
  }

  @action
  clean() {
    if (!this.keepFilterState) {
      console.log("DefinitionStore -> clean -> this.keepFilterState", this.keepFilterState)
      super.clean();
    }
  }

  @action
  async downloadConfigFilesList(formGroupId) {
    let response = await ApiStore.call(this.api, this.api.downloadConfigFilesList, formGroupId);
    if (response) {
      let fileName = "report_default";
      if (response?.headers.get("content-disposition")) {
        let disposition = response?.headers.get("content-disposition");
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, "");
        }
      }

      let allData;
      const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
      if (isIE) {
        var dataFile = [];
        await response.arrayBuffer().then(data => dataFile = data);
        allData = new Uint8Array(dataFile);
      } else {
        const reader = response.body.getReader();
        let chunks = [];
        let dataLength = 0;
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          dataLength += value.length;
          chunks.push(value);
        }
 
        allData = new Uint8Array(dataLength);
        let pos = 0;
        chunks.forEach(element => {
          allData.set(element, pos);
          pos += element.length;
        });
      }

      this.createAndDownloadConfigFilesListFile(allData.buffer, fileName);
    }
  }

  createAndDownloadConfigFilesListFile(body, fileName) {
    const blob = new Blob([body]);
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}

export default DefinitionStore;