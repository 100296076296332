import { ApiService } from "core/services";

class PostProcessingApi extends ApiService {
  constructor() {
    super("post-job-setting");
  }

  getAll(payload = null) {
    return this.get("", payload);
  }

  deleteModel(id) {
    return this.delete(`${id}`);
  }
}

export default PostProcessingApi;
