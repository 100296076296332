import React, { Component } from "react";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import CONFIG from "../../core/services/config"
import { ENV_MODE } from "../../core/utils/constants"

import "./style.scss";
class SwitchLanguages extends Component {
    render() {
        const { t, mode } = this.props;
        return (
            <div className="switch-lang">
                <div>
                    {
                        mode === 0 ? 
                        <span>
                            {CONFIG.version}版
                            {
                                CONFIG.env === ENV_MODE.DEV ? " - 開発環境" : 
                                CONFIG.env === ENV_MODE.STAGING ? " - ステージング" : ""
                            }
                            {" ("}
                            Version {CONFIG.version}
                            {
                                CONFIG.env === ENV_MODE.DEV ? " - Development" : 
                                CONFIG.env === ENV_MODE.STAGING ? " - Staging" : ""
                            }
                            {")"}
                        </span> :
                        <span>
                            { t("common.version", {version: CONFIG.version})}{
                            CONFIG.env === ENV_MODE.DEV ? t("common.version_suffix_dev") : 
                            CONFIG.env === ENV_MODE.STAGING ? t("common.version_suffix_stagging") : ""}
                        </span>
                    }
                </div>
            </div>
        );
    }
}

export default withTranslation()(SwitchLanguages);
