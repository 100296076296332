import { ApiService } from "core/services";

class TenantApi extends ApiService {
  constructor() {
    super("tenant");
  }

  getAll(payload = null) {
    return this.get("", payload);
  }

  createTenant(payload) {
    return this.post("", payload);
  }

  updateTenant(payload) {
    return this.put("", payload);
  }

  // Get overview management
  getSettingOverviewMng() {
    return this.get("systems");
  }

  // Set overview management
  setSettingOverviewMng(payload) {
    return this.put("systems", payload);
  }

}

export default TenantApi;
