const ENV_MODE = process.env.REACT_APP_ENV_MODE &&
    (
        process.env.REACT_APP_ENV_MODE == "production"
        || process.env.REACT_APP_ENV_MODE == "staging"
        || process.env.REACT_APP_ENV_MODE == "dev"
    )
    ? process.env.REACT_APP_ENV_MODE : "dev";
const URL_CONFIG = {
    production: {
        domain: "airead-cloud.com/api/v1",
        file_domain: "https://airead-cloud.com/",
        port: 443,
        ssl: true,
        defaultLang: "jp",
        prefix_url: "",
        default_avatar: "/dist/img/default-avatar.jpg",
        max_file_upload_size: 100000000,//100MB
        upload_file_types: ['application/pdf'],
        version: "2.2.0",
        env: "production",
        is_connect_to_azure_app_insight: true,
        app_insight_instrumentation_key: "133632f6-bc05-4d6f-b15e-4d363b57e317",
        container_domain: "https://aireadoncloudprd.blob.core.windows.net/",
        preview_pdf_container_name: "preview",
    },
    staging: {
        domain: "staging.airead-cloud.com/api/v1",
        port: 443,
        file_domain: "https://staging.airead-cloud.com/",
        ssl: true,
        defaultLang: "jp",
        prefix_url: "",
        default_avatar: "/dist/img/default-avatar.jpg",
        max_file_upload_size: 100000000,//100MB
        upload_file_types: ['application/pdf'],
        version: "2.2.0",
        env: "staging",
        is_connect_to_azure_app_insight: true,
        app_insight_instrumentation_key: "2ed796cc-dd79-404e-9f63-6bf84291584a",
        container_domain: "https://aireadoncloudstg.blob.core.windows.net/",
        preview_pdf_container_name: "preview",
    },
    dev: {
        domain: "dev.airead-cloud.com/api/v1",
        port: 443,
        file_domain: "https://dev.airead-cloud.com/",
        ssl: true,
        defaultLang: "jp",
        prefix_url: "",
        default_avatar: "/dist/img/default-avatar.jpg",
        max_file_upload_size: 100000000,//100MB
        upload_file_types: ['application/pdf'],
        version: "2.2.0",
        env: "dev",
        is_connect_to_azure_app_insight: true,
        app_insight_instrumentation_key: "9b2864af-b432-4593-9f19-7b7dd35b1f93",
        container_domain: "https://aireadonclouddev.blob.core.windows.net/",
        preview_pdf_container_name: "preview",
    }
};

export default URL_CONFIG[ENV_MODE];