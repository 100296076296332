import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { PROFILE_ROLES_PREFIX } from '../../core/utils/constants';
import { Table } from 'components';
import BaseComponent from '../BaseComponent';
import "./style.scss";

const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
const TABLE_ROW_WIDTH_MODEL = {
  CHECKBOX: isIE ? "5%" : "6%",
  NO: isIE ? "5%" : "6%",
  USER_ID: "30%",
  USER_NAME: "30%",
  USER_LEVEL: "28%",
};

@inject("modalStore", "commonStore")
@observer
class ScreenTeamAddUser extends BaseComponent {
  constructor(props) {
    super(props);
    this.listCheckedItems = [];
    this.state = {
      userId: "",
      userName: "",
      isChanged: false,
    };
  }

  // executeLogicAfterRender = () => {
  //   this.props.commonStore.teamStore.clean();
  //   this.getUsersNotInTeam();
  // }

  // executeLogicBeforeOutComponent = () => {
  //   this.props.commonStore.teamStore.clean();
  // }

  componentWillMount = () => {
    this.props.commonStore.teamStore.clean();
    this.getUsersNotInTeam();
  };

  trHandler = () => {
    // compute for table body
    let table = document.getElementsByClassName("tbl-team-add-user");
    if (table && table[0]) {
      let tableHead = window.$(".tbl-team-add-user table thead");
      if (tableHead && tableHead[0]) {
        let tableBody = window.$(".tbl-team-add-user table tbody");
        if (tableBody && tableBody[0]) {
          let tableBodyHeight = table[0].offsetHeight - tableHead[0].offsetHeight;

          // compute tr
          let trElements = window.$(".tbl-team-add-user table tbody tr");
          let trHeight = 0;
          let num = 0;
          for (let i = 0; i < trElements.length; i++) {
            if (num !== 0) {
              trHeight += trElements[i].offsetHeight;
            }
            num++;
          }

          if (tableBodyHeight > trHeight) {
            tableHead[0].style.overflowY = "hidden";
          } else {
            tableHead[0].style.overflowY = "scroll";
          }
        }
      }
    }
  }

  getUsersNotInTeam = () => {
    const { teamId } = this.props;
    let payload = {
      id: teamId,
      userId: this.state.userId,
      userName: this.state.userName
    }
    this.props.commonStore.teamStore.getUsersNotInTeam(payload);
  }

  renderSearchElement = () => {
    const { t } = this.props;

    return (
      <button
        className="btn btn-primary btn-update"
        type="button"
        onClick={this.getUsersNotInTeam}
      >
        {t("team.update")}
      </button>
    );
  };

  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hide();
  };

  closeAllModal = () => {
    const { modalStore } = this.props;
    modalStore.hideAll();
  };

  handleRowSelected = (e) => {
    let element = e.target.value;
    if (element) {
      element = JSON.parse(element);
      if (e.target.checked) {
        this.addItemToList(element);
      } else {
        this.removeItemFromList(element);
      }
    }
  };

  isChecked = (sequence) => {
    var index = this.listCheckedItems.indexOf(sequence);
    if (index !== -1) {
      return true;
    }
    return false;
  }

  addItemToList = (element) => {
    this.listCheckedItems.push(element.sequence);
    this.setState({ isChanged: true });
  }

  removeItemFromList(element) {
    var index = this.listCheckedItems.indexOf(element.sequence);
    if (index !== -1) {
      this.listCheckedItems.splice(index, 1);
    }
    this.setState({ isChanged: true });
  }

  onChangeUserName = (value) => {
    this.setState({
      userName: value
    });
  };

  onChangeUserId = (value) => {
    this.setState({
      userId: value
    });
  };

  addUsersForTeam = () => {
    const { teamId, commonStore: { teamStore } } = this.props;
    let payload = {
      id: teamId,
      sequences: this.listCheckedItems
    }
    teamStore.addUsersForTeam(
      payload,
      () => {
        teamStore.getTeamById(teamId);
        this.listCheckedItems = [];
        this.getUsersNotInTeam();
      }
    );
  }

  onFetch = (tableData) => {
    this.props.commonStore.teamStore.updatePagingFiler(tableData);
    this.getUsersNotInTeam();
    this.listCheckedItems = [];
  };

  render() {
    const { t, commonStore: { teamStore: { paging } } } = this.props;
    const usersNotInTeam = this.props.commonStore.teamStore.usersNotInTeam || [];
    const columns = [
      {
        id: "selection",
        disableSortBy: true,
        Cell: ({ row }) => {
          let isChecked = this.isChecked(row.original.sequence);
          return (
            <div>
              <input
                id={row.original.userId}
                type="checkbox"
                value={JSON.stringify(row?.original)}
                onChange={this.handleRowSelected}
                checked={isChecked}
              />
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_MODEL.CHECKBOX
      },
      {
        Header: t("team.no"),
        disableSortBy: true,
        Cell: ({ initialState, row }) => <div style={{ textAlign: "center" }}>{
          initialState?.initialTableState?.pageSize *
          initialState?.initialTableState?.pageIndex +
          row.index +
          1
        }</div>,
        width: TABLE_ROW_WIDTH_MODEL.NO
      },
      {
        Header: t('team.user_id'),
        accessor: 'userId',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.userId}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_MODEL.USER_ID
      },
      {
        Header: t('team.user_name'),
        accessor: 'userName',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.userName}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_MODEL.USER_NAME
      },
      {
        Header: t('team.user_level'),
        accessor: 'role',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {t(PROFILE_ROLES_PREFIX + original.role)}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_MODEL.USER_LEVEL
      }
    ];
    return (
      <div className="add-team-user" style={{ padding: "5px" }}>
      {/* <Scrollbars key={"modal-body"} className="modal-body"> */}
        <div className="box-body">
          <div className="row">
            <div className="col-sm-12">
              {t("team.filter_condition")}
            </div>
          </div>
          <div style={{ height: "30px", lineHeight: "30px", marginTop: "10px" }}>
            <div className="div-2-eq-l">
              <span>{t("team.user_id")}</span>
              <input style={{ width: "70%", marginLeft: "10px" }}
                type="text"
                value={this.state.userId}
                onChange={e => this.onChangeUserId(e.target.value)}
              />
            </div>
            <div className="div-2-eq-r">
              <span>{t("team.user_name")}</span>
              <input style={{ width: "70%", marginLeft: "10px" }}
                type="text"
                value={this.state.userName}
                onChange={e => this.onChangeUserName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="box-body">
          <button
            className="btn btn-primary f-r mb-3"
            type="button"
            onClick={this.addUsersForTeam}
            disabled={this.listCheckedItems.length === 0}>
            {t("team.add_user")}
          </button>
          <Table
            className="tbl-team-add-user"
            columns={columns}
            data={usersNotInTeam}
            disablePaging={false}
            onFetch={this.onFetch}
            enableServerSidePaging={true}
            initialTableState={paging}
            element={this.renderSearchElement()}
          />
        </div>
        {/* </Scrollbars> */}
      </div>
    );
  }
}

export default withTranslation()(ScreenTeamAddUser);
