import { ApiService } from "core/services";

class CommonApi extends ApiService {

  /**
   * getDefinitations
   * Return definitaion list belong to user
   * 
   * @param {Object} payload : username / password object
   * @returns  boolean
   */
  getDefinitations() {
    return this.get("configFilesList");
  }

  getDefinitationsInTeam() {
    return this.get("configFilesList/in-team");
  }

  getDefinitationsGroup() {
    return this.get("configFilesList/form-group");
  }

  getDefinitationsGroupInTeam() {
    return this.get("configFilesList/form-group/in-team");
  }

  getCommonSetting() {
    return this.get("setting");
  }

  updateCommonSetting(payload) {
    return this.put("setting", payload);
  }

  generateKey() {
    return this.post("api-token");
  }

  getIpAddressAll(payload = null) {
    return this.get("setting/ipaddress", payload);
  }

  addIpAddress(payload) {
    return this.post("setting/ipaddress", payload);
  }

  updateIpAddress(payload) {
    return this.put(`setting/ipaddress/${payload.id}`, payload.updateInfo);
  }

  deleteIpaddress(id) {
    return this.delete(`setting/ipaddress/${id}`);
  }

  saveIpAddresses(payload) {
    return this.post("setting/ipaddresses", payload.ipaddresses);
  }

}

export default CommonApi;
