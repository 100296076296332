import React, { Component } from "react";
import classnames from "classnames";
import "./style.scss";
// for pdf files
class ModalContent extends Component {
    render() {
        const {
            header,
            onCancel,
            classN,
            children,
            notButtonX
        } = this.props;
        return (
            <div className={classnames("modal-content", classN)}>
                {!notButtonX && (
                    <div className="modal-header">
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={onCancel}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 className="modal-title">{header}</h4>
                </div>
                )}
                {children}
            </div>
        );
    }
}

export default ModalContent;
