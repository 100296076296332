import React from "react";
import { withTranslation } from "react-i18next";

import "./style.scss";
// for pdf files
class InputCheckbox extends React.Component {
    render() {
        const { id, type, name, value, checked, label, onChange, customClass } = this.props;
        let className = customClass ? customClass + " input-checkbox" : "input-checkbox";
        return (
            <div className={className}>
                <input
                    id={id}
                    type={type}
                    name={name}
                    value={value}
                    checked={checked}
                    onChange={onChange}
                />
                <label htmlFor={id}>{label}</label>
            </div>
        );
    }
}

export default withTranslation()(InputCheckbox);
