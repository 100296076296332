import { ApiService } from "core/services";

class SystemSettingApi extends ApiService {
    // Get system setting
    getSettingOverviewMng() {
        return this.get("systems");
    }

    // Update system setting
    setSettingOverviewMng(payload) {
        return this.put("systems", payload);
    }
}

export default SystemSettingApi;