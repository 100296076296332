import "whatwg-fetch";
import CONFIG from "./config";
import i18next from "i18next";
import { Browser } from "core/utils";
const methods = ["get", "post", "put", "delete", "patch", "upload"];
class ApiService {
    constructor(url = null, headers = null) {
        this.headers = headers;
        const http = CONFIG.ssl ? "https" : "http";
        if (url) {
            this.url = `${http}://${CONFIG.domain}/${url}`;
        }
        else {
            this.url = `${http}://${CONFIG.domain}`;
        }
        methods.forEach(method => {
            this[method] = (url, body, headers = null) => {
                return this.callApi(method, url, body, headers);
            };
        });
    }

    callApi = (METHOD, url = "", body, headers = null) => {
        const sessionId = Browser.getSavedInfo("sessionId");
        let _headers = Object.assign(
            {
                "Content-Type": "application/json",
                'Accept': 'application/json',
                sessionId,
                "Accept-Language": i18next.language ? i18next.language : "jp",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Expose-Headers": "Content-Disposition",
                "Pragma": "no-cache"
            },
            this.headers,
            headers
        );
        if (METHOD === "upload") {
            METHOD = 'post';
            _headers = {
                sessionId,
                "Accept-Language": i18next.language ? i18next.language : "jp",
            }
        }

        const options = {
            method: METHOD,
            headers: _headers,
            mode: "cors"
        };
        let queryString = "";
        if (METHOD.toUpperCase() === "GET") {
            queryString = this.buildQueryString(body);
        } else {
            if (body) {
                if (!_headers["Content-Type"]) {
                    options.body = body;
                } else {
                    options.body = JSON.stringify(body);
                }
            }
        }

        if (url) {
            url = "/" + url;
        }
        let _url = this.url + url
        if (queryString !== "") {
            _url = _url + "?" + queryString;
        }

        return fetch(_url, options)


    };

    buildQueryString = body => {
        if (body) {
            let searchParams = new URLSearchParams();
            Object.keys(body).forEach((key) => {
                if (body[key] && body[key] !== "null") {
                    searchParams.append(key, body[key]);
                }
            });
            return searchParams.toString();
        }

        return "";
    };
}

export default ApiService;
