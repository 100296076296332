import React from "react";
import { withTranslation } from "react-i18next";
import ReactTable from "./ReactTable";
import ReactResizableTable from "./ReactResizableTable";

import "./style.scss";

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageIndex: 0,
    };
  }
  tranformTableStateToServerState = (tableState) => {
    let newState = {
      page: tableState.pageIndex + 1,
      size: tableState.pageSize,
      sortKey: tableState.sortBy[0]?.id,
      sortDir: tableState.sortBy[0]
        ? tableState.sortBy[0]?.desc
          ? "DESC"
          : "ASC"
        : null,
    };
    this.props.onFetch(newState);
  };
  tranformServerStateToTableState = (initialTableState) => {
    return initialTableState
      ? {
        pageIndex: initialTableState.page - 1,
        totalPage: initialTableState.totalPage,
        totalRecord: initialTableState.totalRecord,
        pageSize: initialTableState.size,
        sortBy: initialTableState.sortKey
          ? [
            {
              id: initialTableState.sortKey,
              desc: initialTableState.sortDir === "DESC",
            },
          ]
          : [],
      }
      : null;
  };

  render() {
    const {
      tblCode,
      columns,
      data,
      rowClass,
      t,
      element,
      customSelectedBox,
      hideTableFooter,
      disableSelectionPageSize,
      disablePaging = false,
      disableField,
      disableFieldValue = null,
      enableServerSidePaging = false,
      initialTableState,
      className,
      tableFooter,
      useCustomSortProcess = false,
      customSortProcess = null,
      resizable = false
    } = this.props;
    const { pageIndex } = this.state;
    return resizable ? (
      <ReactResizableTable
        tblCode={tblCode}
        t={t}
        element={element}
        customSelectedBox={customSelectedBox}
        columns={columns}
        data={data}
        hideTableFooter={hideTableFooter}
        disableSelectionPageSize={disableSelectionPageSize}
        disableFieldValue={disableFieldValue}
        disableField={disableField}
        disablePaging={disablePaging}
        pageIndexProp={pageIndex}
        rowClass={rowClass}
        changePageIndexProp={(pageIndex) => {
          this.setState({ pageIndex });
        }}
        enableServerSidePaging={enableServerSidePaging}
        onFetch={this.tranformTableStateToServerState}
        initialTableState={this.tranformServerStateToTableState(
          initialTableState
        )}
        className={className}
        tableFooter={tableFooter}
        useCustomSortProcess={useCustomSortProcess}
        customSortProcess={customSortProcess}
      />
    ) : (
      <ReactTable
        tblCode={tblCode}
        t={t}
        element={element}
        customSelectedBox={customSelectedBox}
        columns={columns}
        data={data}
        hideTableFooter={hideTableFooter}
        disableSelectionPageSize={disableSelectionPageSize}
        disableFieldValue={disableFieldValue}
        disableField={disableField}
        disablePaging={disablePaging}
        pageIndexProp={pageIndex}
        rowClass={rowClass}
        changePageIndexProp={(pageIndex) => {
          this.setState({ pageIndex });
        }}
        enableServerSidePaging={enableServerSidePaging}
        onFetch={this.tranformTableStateToServerState}
        initialTableState={this.tranformServerStateToTableState(
          initialTableState
        )}
        className={className}
        tableFooter={tableFooter}
        useCustomSortProcess={useCustomSortProcess}
        customSortProcess={customSortProcess}
      />
    );
  }
}

export default withTranslation()(Table);
