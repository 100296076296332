import { TEMPLATE_SORTING_CLASSIFICATION_TYPE } from "./constants";

const validateNotNull = (value, t) => {
  if (!value || !value.trim())
    return t("validate.field_is_empty");
  return "";
};
const validateEmail = (value, t) => {
  const check = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );
  if (!check) if (!check) return t("validate.user_id_invalid");
  return "";
};
const validateEmailAdmin = (value, t) => {
  const check = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );
  if (!check) if (!check) return t("validate.admin_id_invalid");
  return "";
};
const validatePassword = (value, t) => {
  if (validateNotNull(value, t)) return validateNotNull(value, t);
  const check = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value);
  if (!check || value.includes(" ") || value.includes("."))
    return t("validate.password_condition");
  return "";
};
const validateDateRange = (startDate, endDate, t) => {
  if (startDate > endDate)
    return t("validate.date_range_invalid");
  return "";
};
const validateConfirmPass = (value, t, valueComp) => {
  if (validateNotNull(value, t)) return validateNotNull(value, t);
  if (value !== valueComp)
    return t("validate.password_mismatch");
  return "";
};
const validateContractNumber = (value, t) => {
  if (value < 100)
    return t("validate.contract_number_validate");
  return "";
};
const validateAccountNumber = (value, t) => {
  if (value < 1)
    return t("validate.account_number_validate");
  return "";
};
const validateThreshold = (value, t) => {
  if (value < 0 || value > 99)
    return t("validate.threshold");
  return "";
};
const validateDataRetentionPeriod = (value, t) => {
  if (value === null || value === undefined || value === "")
    return t("validate.field_is_empty");
  if (value < 0)
    return t("validate.more_or_equal");
  return "";
};
const validateDownloadFolderName = (value, t) => {
  const check = /^(<date>|<time>|<year>|<month>|<day>|<item:.*?>|[^<>:\*\?"\| ]*)*$/.test(
    value
  );
  if (!check) if (!check) return t("validate.download_folder_name");
  return "";
};
const validateDownloadFileName = (value, t) => {
  const check = /^(<date>|<time>|<year>|<month>|<day>|<inputfile>|<item:.*?>|[^<>\\\/:\*\?"\| ]*)*$/.test(
    value
  );
  if (!check) if (!check) return t("validate.download_file_name");
  return "";
};
const validateClassificationType = (value, t) => {
  if (value === null || typeof value === undefined || value === "")
    return t("validate.field_is_empty");
  if (value != TEMPLATE_SORTING_CLASSIFICATION_TYPE.IMAGE && value != TEMPLATE_SORTING_CLASSIFICATION_TYPE.KEYWORD)
    return t("validate.validate.classificationType");
  return "";
};
const validateMaxFeatures = (value, t) => {
  if (value === null || typeof value === undefined || value === "")
    return t("validate.field_is_empty");
  if (Number.isNaN(value) || value < 1 || 2147483647 < value)
    return t("validate.validate.maxFeatures");
  return "";
};
const validateMatchCtTh = (value, t) => {
  if (value === null || typeof value === undefined || value === "")
    return t("validate.field_is_empty");
  if (Number.isNaN(value) || value < 0 || 2147483647 < value)
    return t("validate.validate.matchCtTh");
  return "";
};
const validateSizeDiffTh = (value, t) => {
  if (value === null || typeof value === undefined || value === "")
    return t("validate.field_is_empty");
  if (Number.isNaN(value) || value < -1 || 2147483647 < value)
    return t("validate.validate.sizeDiffTh");
  return "";
};
const validateKeywordRect = (value, t) => {
  if (value === null || typeof value === undefined)
    return t("validate.field_is_empty");
  const check = /^(|([ ]*[tbrlTBRL][ ]*,[ ]*(\d{1,2}|100)[ ]*)(,[ ]*[tbrlTBRL][ ]*,[ ]*(\d{1,2}|100)[ ]*)*,{0,1})$/.test(
    value
  );
  if (!check) return t("validate.validate.keywordRect");
  return "";
};
const validateIpAddress = (value, t) => {
  if (value === null || typeof value === undefined || value === "")
    return t("validate.field_is_empty");

  const check1 = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/.test(
    value
  );
  const check2 = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})\/(\d{1,2})$/.test(
    value
  );
  if (!check1 && !check2) return t("validate.validate.ipaddress");
  return "";
};
export default {
  validateNotNull,
  validateConfirmPass,
  validatePassword,
  validateEmail,
  validateDateRange,
  validateContractNumber,
  validateAccountNumber,
  validateThreshold,
  validateEmailAdmin,
  validateDataRetentionPeriod,
  validateDownloadFolderName,
  validateDownloadFileName,
  validateClassificationType,
  validateMaxFeatures,
  validateMatchCtTh,
  validateSizeDiffTh,
  validateKeywordRect,
  validateIpAddress
};
