import { ApiService } from "core/services";

class TemplateSortingApi extends ApiService {
  constructor() {
    super("templatesorting");
  }

  getAll(payload = null) {
    return this.get("", payload);
  }

  getAllRegisted(payload = null) {
    return this.get(`registed`, payload);
  }

  getTemplateSortingById(payload = null) {
    return this.get(`${payload.id}`, payload.pageInfo);
  }

  getTemplateSortingFormGroupById(id) {
    return this.get(`form-group/${id}`);
  }

  updateTemplateSortingFormGroup(payload) {
    return this.put(`form-group/${payload.templateSortingId}`, payload);
  }

  getTemplateSortingTemplatesById(id) {
    return this.get(`templates/${id}`);
  }

  getTemplateSortingTemplatesByFormGoupId(id) {
    return this.get(`templates-form-group/${id}`);
  }

  addTemplateSorting(payload) {
    return this.post("", payload);
  }

  updateTemplateSorting(payload) {
    return this.put(`${payload.id}`, payload);
  }

/*   getUsersNotInTeam(payload = null) {
    return this.get(`users-not-in-team/${payload.id}`, payload.pageInfo);
  }

  addUsersForTeam(payload) {
    return this.post(`add-user/${payload.id}`, { sequences: payload.sequences });
  }

  deleteUserForTeam(payload) {
    return this.delete(`user/${payload.id}?sequence=${payload.sequence}`);
  }
 */
  deleteTemplateSorting(id) {
    return this.delete(`${id}`);
  }

  /*
  executeRegist(payload) {
    return this.post(`regist-template/${payload.templateSortingId}`, payload);
  }
  */

  executeRegistTemplates(payload) {
    return this.post(`templates/${payload.configfilesListsId}`, payload.files);
  }

  deleteTemplates(templatesId) {
    return this.delete(`templates/${templatesId}`);
  }

  /*   getTeamOfUser() {
    return this.get(`user`);
  }
 */}

export default TemplateSortingApi;
