import React, { Component } from "react";
import classnames from "classnames";
import "./style.scss";
// for pdf files
class ModalFooter extends Component {
    render() {
        const {
            saveButtonText,
            saveButtonClass,
            onConfirm,
            onCancel,
            cancelButtonText
        } = this.props;
        const classButton = saveButtonClass ? saveButtonClass : "btn-primary";
        const alignCenter = cancelButtonText ? "" : "text-c";
        return (
            <div className={classnames("modal-footer", alignCenter)}>
                {cancelButtonText && (
                    <button
                        type="button"
                        className="btn btn-danger cancel-button"
                        data-dismiss="modal"
                        onClick={onCancel}
                    >
                        {cancelButtonText}
                    </button>
                )}
                <button
                    type="button"
                    className={`btn ${classButton}`}
                    onClick={onConfirm}
                >
                    {saveButtonText}
                </button>
            </div>
        );
    }
}

export default ModalFooter;
