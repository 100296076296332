import { ApiService } from "core/services";

class IndividualModelApi extends ApiService {
  constructor() {
    super("models");
  }

  getAll(payload = null) {
    return this.get("", payload);
  }

  deleteModel(id) {
    return this.delete(`${id}`);
  }
}

export default IndividualModelApi;
