import React from "react";
import { withTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";

import "./style.scss";

class ReactPaging extends React.Component {
  
  paging = (ref, forcePage) =>{
    const {useCustomForcePage} = this.props;
    if (useCustomForcePage){
      if (ref?.state){
        ref.setState({selected:forcePage});
      }
    }
  }

  render() {
    const { pageCount, forcePage, t, onPageChange, breakLabel, marginPagesDisplayed, pageRangeDisplayed, className } = this.props;
    return (
      <ReactPaginate
        ref={(ref) => {this.paging(ref, forcePage)}}
        forcePage={forcePage}
        previousLabel={t("pagging.previous")}
        nextLabel={t("pagging.next")}
        breakLabel={breakLabel === "" ? breakLabel : "..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={marginPagesDisplayed === 0 ? marginPagesDisplayed : 2}
        pageRangeDisplayed={pageRangeDisplayed === 0 ? pageRangeDisplayed : 5}
        onPageChange={onPageChange}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={className ? className : "active"}
      />
    );
  }
}

export default withTranslation()(ReactPaging);
