import React, { Component } from "react";
import { ContainerBox } from "components";
import { withTranslation } from "react-i18next";
import ProfileContent from "./ProfileContent";
import "./style.scss";

class ProfileUser extends Component {
  render() {
    const { t, userInfo } = this.props;
    let textArr = (userInfo?.notification)?.split("\n");
    const text = textArr?.join("</br>");
    /* Limit character
      const maxStringLength = 1024;
      const numLine = textArr?.length;
      if (numLine > 10) {
        textArr = textArr.slice(0, 10);
      }
      const text = textArr?.join("</br>")?.substr(0, maxStringLength) + (numLine > 10 || userInfo?.notification?.length > maxStringLength ? "..." : "");
    */
    return (
      <div className="profile-user-wrap">
        {userInfo.notification && <ContainerBox headerTitle={t('profile.tenant_notification')} className="profile-head-container">
          <div className="content" dangerouslySetInnerHTML={{ __html: text }}></div>
        </ContainerBox>}
        <ContainerBox headerTitle={t('profile.user_information')} className="profile-user" >
          <ProfileContent t={t} userInfo={userInfo} />
        </ContainerBox>
      </div>
    );
  }
}

export default withTranslation()(ProfileUser);
