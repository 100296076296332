import React from "react";
import { withTranslation } from "react-i18next";

import "./style.scss";
// for pdf files
class IconFa extends React.Component {
  renderByType = () => {
    const { type } = this.props;
    switch (type) {
      case "upload":
        return <i className="fa fa-cloud-upload"></i>;
      case "download":
        return <i className="fa fa-download"></i>;
      case "documentsdownload":
        return <i className="fa fa-cloud-download"></i>;
      case "admin":
        return <i className="fa fa-user"></i>;
      case "definition":
        return <i className="fa fa-files-o"></i>;
      case "templatesorting":
        return <i className="fa fa-arrows"></i>;
      case "formsetting":
        return <i className="fas fa-tools"></i>;
      case "tenant":
        return <i className="fas fa-globe-americas"></i>;
      case "generalsetting":
        return <i className="fa fa-cogs"></i>;
      case "individualsetting":
        return <i className="fa fa-cog"></i>;
      case "model":
        return <i className="fas fa-brain"></i>;
      case "team":
        return <i className="fa fa-users"></i>;
      case "postprocessing":
        return <i className="fa fa-exchange"></i>;
      case "threedots":
        return <i className="fa fa-ellipsis-h"></i>;
      case "actionlogs":
        return <i className="fa fa-list"></i>;
      default:
        return <i className="fa fa-cloud-upload"></i>;
    }
  };
  render() {
    return this.renderByType();
  }
}

export default withTranslation()(IconFa);
