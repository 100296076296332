import { ApiService } from "core/services";

class DownloadFileHeaderItemApi extends ApiService {
  constructor() {
    super("downloadfileheaderitem");
  }

  getFormGroupSetting(formGroupId) {
    return this.get(`form-group/${formGroupId}`);
  }

  getConfigFilesListsSetting(configfilesListsId) {
    return this.get(`configFilesList/${configfilesListsId}`);
  }

  putDownloadFileHeaderItem(payload) {
    return this.put("", payload);
  }
}

export default DownloadFileHeaderItemApi;
