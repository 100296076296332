import FileStore from "./FileStore";
import TenantStore from "./TenantStore";
import UserStore from "./UserStore";
import DefinitionStore from "./DefinitionStore";
import FormGroupStore from "./FormGroupStore";
import SettingStore from "./SettingStore";
import { Browser } from "../core/utils";
import IndividualSettingStore from "./IndividualSettingStore";
import SystemStore from "./SystemStore";
import SwitchLanguagesStore from "./SwitchLanguagesStore";
import IndividualModelStore from "./IndividualModelStore";
import FormSettingStore from "./FormSettingStore";
import TeamStore from "./TeamStore";
import PostProcessingStore from "./PostProcessingStore";
import TemplateSortingStore from "./TemplateSortingStore";
import DownloadFileHeaderItemStore from "./DownloadFileHeaderItemStore";


export default class CommonStore {
  constructor() {
    this.FileStore = new FileStore();
    this.tenantStore = new TenantStore();
    this.userStore = new UserStore();
    this.definitionStore = new DefinitionStore();
    this.formGroupStore = new FormGroupStore();
    this.settingStore = new SettingStore();
    this.individualSettingStore = new IndividualSettingStore();
    this.systemStore = new SystemStore();
    this.switchLanguagesStore = new SwitchLanguagesStore();
    this.individualModelStore = new IndividualModelStore();
    this.formSettingStore = new FormSettingStore();
    this.teamStore = new TeamStore();
    this.postProcessingStore = new PostProcessingStore();
    this.templateSortingStore = new TemplateSortingStore();
    this.downloadFileHeaderItemStore = new DownloadFileHeaderItemStore()
  }

  hasPermission = permission => {
    return permission.includes(Browser.getSavedInfo("role"))
  }

}
