import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { ContainerBox, Table, ModalFooter } from 'components';
import { MODAL_ID, DATE_FORMAT, NO_DATE, EMPTY_FORMAT_CELL, MODAL_TYPE, MODE, TEMPLATE_SORTING_CLASSIFICATION_TYPE } from '../../core/utils/constants';
import { Validate } from "core/utils";
import moment from 'moment';
import BaseComponent from '../BaseComponent';
import './style.scss';

const TABLE_ROW_WIDTH_MODEL = {
  NO: "5%",
  SORTING_DEFINITION_ID: "15%",
  CLASSIFICATION_TYPE: "15%",
  SORTING_DEFINITION_NAME: "30%",
  CREATED_DATE: "15%",
  UPDATED_DATE: "15%",
  REMOVE: "5%",
};

@inject('commonStore', 'modalStore')
@observer
class ScreenTemplateSortingSetting extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      settingName: '',
      classificationType: TEMPLATE_SORTING_CLASSIFICATION_TYPE.IMAGE,
      maxFeatures: 2000,
      matchCtTh: 50,
      sizeDiffTh: -1,
      keywordRect: "",
    }
  }

  componentWillMount = () => {
    this.props.commonStore.templateSortingStore.clean();
    this.props.commonStore.templateSortingStore.getTemplateSortings();
  };

  doAfterAdd = id => {
    this.closeModal();
    this.props.history.push("/templatesorting/edit/" + id);
  }

  onConfirmAdd = () => {
    const { commonStore: { templateSortingStore } } = this.props;
    const { t } = this.props;
    if (!this.checkSetting()) {
      return;
    }
    let payload = {
      "settingName": this.state.settingName,
      "classificationType": this.state.classificationType,
      "maxFeatures": this.state.maxFeatures,
      "matchCtTh": this.state.matchCtTh,
      "sizeDiffTh": this.state.sizeDiffTh,
      "keywordRect": this.state.keywordRect,
    }

    templateSortingStore.addTemplateSorting(payload, this.openConfirmGoToAddForm);

    this.closeModal();
    this.state.settingName = "";
    this.state.maxFeatures = 2000;
    this.state.matchCtTh = 50;
    this.state.sizeDiffTh = -1;
    this.state.keywordRect = "";
  }

  checkSetting = () => {
    const { t } = this.props;
    let errmsg = "";
    let haserr = false;
    document.getElementById("required_msg_settingName").textContent = '';
    document.getElementById("required_msg_classificationType").textContent = '';
    document.getElementById("required_msg_maxFeatures").textContent = '';
    document.getElementById("required_msg_matchCtTh").textContent = '';
    document.getElementById("required_msg_sizeDiffTh").textContent = '';
    document.getElementById("required_msg_keywordRect").textContent = '';

    errmsg = Validate.validateNotNull(this.state.settingName, t);
    if (errmsg !== "") {
      document.getElementById("required_msg_settingName").textContent = errmsg;
      haserr = true;
    }
    errmsg = Validate.validateClassificationType(this.state.classificationType, t);
    if (errmsg !== "") {
      document.getElementById("required_msg_classificationType").textContent = errmsg;
      haserr = true;
    }
    errmsg = Validate.validateMaxFeatures(this.state.maxFeatures, t);
    if (errmsg !== "") {
      document.getElementById("required_msg_maxFeatures").textContent = errmsg;
      haserr = true;
    }
    errmsg = Validate.validateMatchCtTh(this.state.matchCtTh, t);
    if (errmsg !== "") {
      document.getElementById("required_msg_matchCtTh").textContent = errmsg;
      haserr = true;
    }
    errmsg = Validate.validateSizeDiffTh(this.state.sizeDiffTh, t);
    if (errmsg !== "") {
      document.getElementById("required_msg_sizeDiffTh").textContent = errmsg;
      haserr = true;
    }
    errmsg = Validate.validateKeywordRect(this.state.keywordRect, t);
    if (errmsg !== "") {
      document.getElementById("required_msg_keywordRect").textContent = errmsg;
      haserr = true;
    }
    return !haserr;
  }

  onChangeSettingName = (event) => {
    let value = event.target.value;
    document.getElementById("required_msg_settingName").textContent = '';
    this.state.settingName = value;
  }

  onChangeSettingNumber = (event) => {
    const target = event.target;
    const name = target.name;
    const value = parseInt(target.value);
    document.getElementById("required_msg_" + name).textContent = '';
    if (2147483647 < value) {
      target.value = this.state[name];
    } else {
      this.setState({ [name]: value });
    }
  }

  onChangeClassificationType = (event) => {
    let value = event.target.value;
    document.getElementById("required_msg_classificationType").textContent = '';
    this.state.classificationType = value;
  }

  onChangeSettingString = (event) => {
    const target = event.target;
    const name = target.name;
    const value = event.target.value;
    document.getElementById("required_msg_" + name).textContent = '';
    this.setState({ [name]: value });
  }

  handleKeyPressForNumber = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^-|\d*$/.test(keyValue)) {
      event.preventDefault();
    }
  }

  openConfirmGoToAddForm = id => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.UPDATE_TEMPLATE_SORTING_ADD_FORM,
      isOpen: true,
      notButtonX: true,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>{t('template_sorting.mng.modal.add_form_confirm_msg')}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t('common.ok')}
            onConfirm={() => this.doAfterAdd(id)}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: MODAL_TYPE.SMALL
    });

  }

  openAddModal = () => {
    const { modalStore, t } = this.props;
    this.state.maxFeatures = 2000;
    this.state.matchCtTh = 50;
    this.state.sizeDiffTh = -1;
    this.state.keywordRect = "";
    modalStore.show({
      id: MODAL_ID.CREATE_TEMPLATE_SORTING,
      isOpen: true,
      header: t('template_sorting.mng.modal.dialog_title'),
      onCancel: this.closeModal,
      children: (
        <div className="screen-template-sorting-setting-modal">
          <div key={"modal-body"} className="modal-body modal-add-item">
            <div className="row">
              <div className="col-sm-1" />
              <div className="col-sm-4 font-bold">
                <span className="color-red">*</span>
                {t("setting_definition.required")}
              </div>
            </div>

            <div className="row" style={{ paddingTop: "15px" }}>
              <div className="col-sm-1" />
              <div className="col-sm-4 font-bold">
                {t("template_sorting.common.setting_name")}
                <span className="color-red">*</span>
              </div>
              <div className="col-sm-6" id="settingName">
                <input name="settingName"
                  type="text"
                  maxLength={255}
                  placeholder={t("template_sorting.common.setting_name")}
                  onChange={this.onChangeSettingName}
                />
                <span className="color-red" id="required_msg_settingName"></span>
              </div>
            </div>

            <div className="row" style={{ paddingTop: "15px" }}>
              <div className="col-sm-1" />
              <div className="col-sm-4 font-bold">
                {t("template_sorting.common.classification_type")}
                <span className="color-red">*</span>
              </div>
              <div className="col-sm-6" id="classificationType">
                <select
                  className="select"
                  placeholder={t("template_sorting.common.classification_type")}
                  defaultValue={TEMPLATE_SORTING_CLASSIFICATION_TYPE.IMAGE}
                  //style={{ width: "20%", marginLeft: "5px" }}
                  onChange={(e) => this.onChangeClassificationType(e)}
                >
                  <option key={TEMPLATE_SORTING_CLASSIFICATION_TYPE.IMAGE} value={TEMPLATE_SORTING_CLASSIFICATION_TYPE.IMAGE}>{t("template_sorting.common.classification_type.image")}</option>;
                  <option key={TEMPLATE_SORTING_CLASSIFICATION_TYPE.KEYWORD} value={TEMPLATE_SORTING_CLASSIFICATION_TYPE.KEYWORD}>{t("template_sorting.common.classification_type.keyword")}</option>;
                </select>
                <span className="color-red" id="required_msg_classificationType"></span>
              </div>
            </div>

            {/*<div className="row" style={{ paddingTop: "15px" }}>
              <div className="col-sm-1" />
              <div className="col-sm-4 font-bold">
                {t("template_sorting.common.max_features")}
                <span className="color-red">*</span>
                <i className="fa fa-info-circle ar-tooltip"
                   data-toggle="tooltip"
                   data-placement="top"
                   title={t("template_sorting.mng.modal.max_features.tooltip")}>
                </i>
              </div>
              <div className="col-sm-6" id="max_features">*/}
            <input name="maxFeatures"
              //type="number"
              type="hidden"
              min={1}
              max={2147483647}
              defaultValue={this.state.maxFeatures}
              placeholder={t("template_sorting.common.max_features")}
              onChange={this.onChangeSettingNumber}
              onKeyPress={this.handleKeyPressForNumber}
            />
            <span className="color-red" id="required_msg_maxFeatures"></span>
            {/*</div>
            </div>*/}

            {/*<div className="row" style={{ paddingTop: "15px" }}>
              <div className="col-sm-1" />
              <div className="col-sm-4 font-bold">
                {t("template_sorting.common.match_ct_th")}
                <span className="color-red">*</span>
                <i className="fa fa-info-circle ar-tooltip"
                   data-toggle="tooltip"
                   data-placement="top"
                   title={t("template_sorting.mng.modal.match_ct_th.tooltip")}>
                </i>
              </div>
              <div className="col-sm-6" id="match_ct_th">*/}
            <input name="matchCtTh"
              //type="number"
              type="hidden"
              min={0}
              max={2147483647}
              defaultValue={this.state.matchCtTh}
              placeholder={t("template_sorting.common.match_ct_th")}
              onChange={this.onChangeSettingNumber}
              onKeyPress={this.handleKeyPressForNumber}
            />
            <span className="color-red" id="required_msg_matchCtTh"></span>
            {/*</div>
            </div>*/}

            {/*<div className="row" style={{ paddingTop: "15px" }}>
              <div className="col-sm-1" />
              <div className="col-sm-4 font-bold">
                {t("template_sorting.common.size_diff_th")}
                <span className="color-red">*</span>
                <i className="fa fa-info-circle ar-tooltip"
                   data-toggle="tooltip"
                   data-placement="top"
                   title={t("template_sorting.mng.modal.size_diff_th.tooltip")}>
                </i>
              </div>
              <div className="col-sm-6" id="size_diff_th">*/}
            <input name="sizeDiffTh"
              //type="number"
              type="hidden"
              min={-1}
              max={2147483647}
              defaultValue={this.state.sizeDiffTh}
              placeholder={t("template_sorting.common.size_diff_th")}
              onChange={this.onChangeSettingNumber}
              onKeyPress={this.handleKeyPressForNumber}
            />
            <span className="color-red" id="required_msg_sizeDiffTh"></span>
            <input name="keywordRect"
              //type="number"
              type="hidden"
              defaultValue={this.state.keywordRect}
              placeholder={t("template_sorting.common.keyword_rect")}
              onChange={this.onChangeSettingString}
            />
            <span className="color-red" id="required_msg_keywordRect"></span>
            {/*</div>
            </div>*/}
          </div>

          <ModalFooter key={"modal-footer"}
            saveButtonText={t('common.create')}
            onConfirm={() => this.onConfirmAdd()}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: 'medium'
    });
  };

  onConfirmDeleteById = (id) => {
    const { commonStore: { templateSortingStore } } = this.props;
    templateSortingStore.deleteTemplateSorting(
      id,
      () => {
        templateSortingStore.getTemplateSortings();
        this.closeModal();
      }
    );
  }

  openComfirmDeleteModal = (id) => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.REMOVE,
      isOpen: true,
      header: t('definition.remove_confirm'),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>{t('template_sorting.mng.remove_confirm_msg')}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t('common.remove')}
            onConfirm={() => this.onConfirmDeleteById(id)}
            cancelButtonText={t('definition.cancel')}
            onCancel={this.closeModal}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: 'small'
    });
  };

  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hideAll();
  };

  onEdit = (id) => {
    this.props.history.push("/templatesorting/edit/" + id);
  };

  onFetch = (tableData) => {
    this.props.commonStore.templateSortingStore.updatePagingFiler(tableData);
    this.props.commonStore.templateSortingStore.getTemplateSortings();
  };

  render() {
    const { t, commonStore: { templateSortingStore: { templateSortings, paging } } } = this.props;
    const columns = [
      {
        Header: t("common.no"),
        disableSortBy: true,
        width: TABLE_ROW_WIDTH_MODEL.NO,
        Cell: ({ initialState, row }) => <div style={{ textAlign: "center" }}>{
          initialState?.initialTableState?.pageSize *
          initialState?.initialTableState?.pageIndex +
          row.index +
          1
        }</div>
        ,
      },
      {
        Header: t('template_sorting.mng.id'),
        accessor: 'id',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.id ? original.id : EMPTY_FORMAT_CELL}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_MODEL.SORTING_DEFINITION_ID
      },
      {
        Header: t('template_sorting.common.classification_type'),
        accessor: 'classificationType',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.classificationType && original.classificationType == TEMPLATE_SORTING_CLASSIFICATION_TYPE.KEYWORD
                ? t('template_sorting.common.classification_type.keyword') : t('template_sorting.common.classification_type.image')}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_MODEL.CLASSIFICATION_TYPE
      },
      {
        Header: t('template_sorting.common.setting_name'),
        accessor: 'settingName',
        Cell: ({ row: { original } }) => {
          return (
            <div className="color-theme cursor-p"
              onClick={() => this.onEdit(original.id)}>
              {original.settingName}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_MODEL.SORTING_DEFINITION_NAME
      },
      {
        Header: t('common.created_date'),
        accessor: 'createdAt',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.createdAt ? moment(original.createdAt).format(DATE_FORMAT.full_time) : NO_DATE}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_MODEL.CREATED_DATE
      },
      {
        Header: t('common.updated_date'),
        accessor: 'updatedAt',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.updatedAt ? moment(original.updatedAt).format(DATE_FORMAT.full_time) : NO_DATE}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_MODEL.UPDATED_DATE
      },
      {
        Header: t("common.remove"),
        id: 'action',
        className: 'admin-action',
        Cell: ({ row: { original: { id } } }) => {
          return <div>
            <button className="btn btn-danger" type="button" onClick={() => this.openComfirmDeleteModal(id)}>
              {t('common.remove')}
            </button>
          </div>
        },
        width: 100,
      }
    ];
    return (
      <div className="screen-template-sorting">
        <ContainerBox headerTitle={`${t('template_sorting.mng.page_title')}`} className="admin-body-container table-container">
          <div className="box-body document-item-info">
            <button
              className="btn btn-primary template-sorting-btn-style f-r mb-3"
              type="button"
              onClick={this.openAddModal}>
              {t("template_sorting.mng.create")}
            </button>
            <Table
              columns={columns}
              data={templateSortings}
              disablePaging={false}
              onFetch={this.onFetch}
              enableServerSidePaging={true}
              initialTableState={paging}
            />
          </div>
        </ContainerBox>
      </div>
    );
  }

}

export default withRouter(withTranslation()(ScreenTemplateSortingSetting));
