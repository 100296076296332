export default {
  translation: {
    //api message
    "api.response.worng_format": "応答本文は正しい形式ではありません",
    "api.response.no_message": "エラーメッセージの応答はありません",
    "api.response.server_error": "サーバ接続で問題が発生しました\nお手数ですがもういちど接続してください",
    "api.response.no_network": "サーバ接続で問題が発生しました\nお手数ですがもういちど接続してください",

    //static page
    "staticpage.notfound.title": "Oops! Page not found.",
    "staticpage.notfound.description": "We could not find the page you were looking for. Meanwhile, you may ",
    "staticpage.notfound.return": "return to home",
    //login screens
    // 'login.brand_name': "AIRead on Cloud",
    // "login.title": "サインインしてセッションを開始します",
    // "login.userId.placeHolder": "ユーザID",
    // "login.password.placeHolder": "パスワード",
    // "login.fields.required": "ユーザID または パスワード が入力されていません",
    // "login.signin": "サインイン",
    // "login.worngcridential": "ユーザID または パスワード が異なります \r\n入力内容をご確認ください",
    'login.brand_name': "AIRead on Cloud",
    "login.title": "AIRead ログイン (Login)",
    "login.userId.placeHolder": "メールアドレス (mail address)",
    "login.password.placeHolder": "パスワード (password)",
    "login.fields.required": "メールアドレス または パスワード が入力されていません (Please enter your mail address / password)",
    "login.signin": "Login",
    "login.worngcridential": "メールアドレス または パスワード が異なります \r\n入力内容をご確認ください (Invalid mail address / password)",

    // common
    "table_no_data": "データはありません",
    "table_rearrange_order": "並び順を替える",
    "common.version": "{{version}}版",
    "common.version_suffix_dev": " - 開発環境",
    "common.version_suffix_stagging": " - ステージング",
    "common.create": "作成",
    "common.save": "保存",
    "common.update": "更新",
    "common.remove": "削除",
    "common.update_confirm": "変更の確認",
    "common.remove_confirm": "削除の確認",
    "common.created_date": "作成日",
    "common.updated_date": "更新日",
    "common.no": "No.",
    "common.ok": "OK",
    "common.cancel": "キャンセル",

    //layout -header
    "header.sign_out": "ログアウト",
    //user module text
    "user.confirmation": "確認",
    "user.re-create-user-messsage": "過去に削除した同じユーザIDが存在します。\n 再度ユーザを作成してもよろしいですか？",
    "user.create": "作成",
    "user.cancel": "キャンセル",

    // For validatec
    "validate.email_invalid": "メールアドレス が無効です",
    "validate.password_condition": "半角 8文字以上、英大文字・英小文字・数字それぞれを最低1文字ずつ含む必要があります",
    "validate.password_mismatch": "パスワードが一致していません",
    "validate.field_is_empty": "この項目は必須入力項目です",
    "validate.field_is_require": "この項目は必須入力項目です",
    "validate.user_id_invalid": "ユーザID が設定されていません",
    "validate.admin_id_invalid": "管理者IDが無効です",
    "validate.contract_number_validate": "100より大きい整数を指定してください",
    "validate.account_number_validate": "1より大きい整数を指定してください",
    "validate.date_range_invalid": "開始日は終了日より前を指定してください",
    "validate.threshold": "しきい値は0から99の間の値を指定してください",
    "validate.threshold_invalid": "左のしきい値は右のしきい値より大きくなければなりません",
    "validate.more_or_equal": "Value must be greater than or equal to 0",
    "validate.validate.classificationType": "読取種別の値が不正です",
    "validate.validate.maxFeatures": "1以上の整数を指定してください",
    "validate.validate.matchCtTh": "0以上の整数を指定してください",
    "validate.validate.sizeDiffTh": "-1以上の整数を指定してください",
    "validate.validate.keywordRect": "読取範囲の形式が不正です",
    "validate.validate.ipaddress": "IPアドレスの形式が不正です",
    "validate.download_folder_name": "ダウンロードフォルダ名の形式が不正です",
    "validate.download_file_name": "ダウンロードファイル名の形式が不正です",

    // For form definition
    "definition.change_information": "変更内容を保存しますか？",
    "definition.remove_confirm": "削除の確認",
    "definition.update_confirm": "変更の確認",
    "definition.required": "は必須入力項目です",
    "definition.update": "変更",
    "definition.save": "保存",
    "definition.remove_user": "定義を削除しますか？",
    "definition.updated_date": "更新日",
    "definition.created_date": "作成日",
    "definition.definition_management": "帳票定義管理",
    "definition.definition_update": "定義名を変更",
    "definition.cancel": "キャンセル",
    "definition.user_id": "ID",
    "definition.remove": "削除",
    "definition.brandname": "AIRead on Cloud",
    "definition.action": "削除",
    "definition.no": "No.",
    "definition.config_name": "定義名",
    "definition.form_id": "Form ID",
    "definition.form_group_name": "Group Name",
    "definition.edition": "Edition",
    "definition.edition.manual": "座標",
    "definition.edition.item": "ルール v1",
    "definition.edition.rule": "ルール v2",
    "definition.edition.prebuilt": "事前構築済みモデル",
    "definition.created_by": "作成者",
    "definition.form_group_management": "グループ管理",
    "definition.download_file_Header_item": "ダウンロードファイル出力情報編集",

    // For form group
    "definition.form_group.no": "No.",
    "definition.form_group.edition": "Edition",
    "definition.form_group.edition.manual": "座標 / ルール v2",
    "definition.form_group.edition.item": "ルール v1",
    "definition.form_group.edition.prebuilt": "事前構築済みモデル",
    "definition.form_group.form_group_name": "Group Name",
    "definition.form_group.form_setting_name": "帳票設定名",
    "definition.form_group.teams_name": "利用チーム",
    "definition.form_group.teams_all": "全チーム",
    "definition.form_group.created_date": "作成日",
    "definition.form_group.updated_date": "更新日",
    "definition.form_group.download_file_Header_item": "ダウンロードファイル出力情報編集",
    "definition.form_group.download_config_files_list": "帳票定義ダウンロード",
    "definition.form_group.remove": "削除",
    "definition.form_group.back": "戻る",
    "definition.form_group.form_group_management": "グループ管理",
    "definition.form_group.remove_confirm_msg": "このグループを削除してもよろしいですか？\r\n削除されたグループの帳票定義も同時に削除されます。",
    "definition.form_group.setting.page_title": "グループの設定",
    "definition.form_group.setting.confirm_save": "変更内容を保存しますか？",
    "definition.form_group.setting.save": "保存",
    "definition.form_group.setting.cancel": "キャンセル",
    "definition.form_group.setting.teams.all": "全チーム",
    "definition.form_group.setting.teams.individual": "チームを個別に指定",
    "definition.form_group.setting.teams.no": "No.",
    "definition.form_group.setting.teams.name": "チーム名",
    "definition.form_group.setting.download": "帳票ダウンロード",
    "definition.form_group.setting.download.global": "テナント全体の設定に従う",
    "definition.form_group.setting.download.individual": "個別に設定",
    "definition.form_group.setting.download.foldername": "フォルダ名",
    "definition.form_group.setting.download.filename": "ファイル名",

    // For download file header item
    "definition.download_file_header_item.page_title": "ダウンロードファイル出力情報",
    "definition.download_file_header_item.header.title": "ヘッダ情報",
    "definition.download_file_header_item.detail.title": "明細情報",
    "definition.download_file_header_item.detail.tooltip": "ダウンロードファイル形式が”CSV”の場合のみ有効",
    "definition.download_file_header_item.regist": "登録",
    "definition.download_file_header_item.init": "初期設定に戻す",
    "definition.download_file_header_item.items": "項目",
    "definition.download_file_header_item.output_items": "出力項目",
    "definition.download_file_header_item.item_up": "上へ",
    "definition.download_file_header_item.item_down": "下へ",
    "definition.download_file_header_item.save_confirm": "ダウンロードファイル出力情報を登録します。よろしいですか？",
    "definition.download_file_header_item.cancel": "キャンセル",
    "definition.download_file_header_item.Image": "アップロードファイル名[Image]",
    "definition.download_file_header_item.processDate": "処理完了日時[processDate]",
    "definition.download_file_header_item.result": "処理結果[result]",
    "definition.download_file_header_item.document_id": "読取り結果ID[document_id]",
    "definition.download_file_header_item.form_id": "フォームID[form_id]",
    "definition.download_file_header_item.page_no": "ページ番号[page_no]",
    "definition.download_file_header_item.setting_type": "設定区分",
    "definition.download_file_header_item.take_over_group_setting": "グループの設定を引継ぐ",
    "definition.download_file_header_item.individually_configured": "帳票定義毎に設定",

    // For admin

    "admin.create_user": "新しいユーザを作成しますか？",
    "admin.create_tenant": "Create new tenant",
    "admin.change_information": "変更内容を保存しますか？",
    "admin.remove_confirm": "削除の確認",
    "admin.update_confirm": "変更の確認",
    "admin.change_status": "設定を変更してもよろしいですか？",
    "admin.account_page": "詳細",
    "admin.editing_password": "パスワードを編集する",
    "admin.required": "は必須入力項目です",
    "admin.email": "メールアドレス",
    "admin.password_confirm": "パスワード再入力",
    "admin.contractor_name": "管理者名",
    "admin.contractor_setting": "管理者",
    "admin.update": "変更",
    "admin.save": "保存",
    "admin.password": "パスワード",
    "admin.remove_user": "このユーザを削除してもよろしいですか？",
    "admin.unlock_user": "このユーザのアカウントロックを解除してもよろしいですか？",
    "admin.create_user_btn": "新規ユーザの作成",
    "admin.remove": "削除",
    "admin.last_logon_date": "ログオン日時",
    "admin.updated_date": "更新日時",
    "admin.created_date": "作成日時",
    "admin.account_lock": "アカウントロック",
    "admin.unlock": "解除",
    "admin.unlock_btn": "解除",
    "admin.user_name": "ユーザ名",
    "admin.user_level": "ユーザレベル",
    "admin.user_management": "ユーザ管理",
    "admin.admin": "管理者",
    "admin.contractor": "ご契約者",
    "admin.cancel": "キャンセル",
    "admin.user_id": "ユーザID",
    "admin.status": "有効/無効",
    "admin.status_valid": "有効",
    "admin.status_invalid": "無効",
    "admin.brandname": "AIRead on Cloud",
    "admin.action": "削除",
    "admin.no": "No.",

    // For setting user
    "setting_user.change_information": "変更内容を保存しますか？",
    "setting_user.update_confirm": "変更の確認",
    "setting_user.editing_password": "パスワードを編集する",
    "setting_user.required": "は必須入力項目です",
    "setting_user.email": "メールアドレス",
    "setting_user.password_confirm": "パスワード再入力",
    "setting_user.create": "作成",
    "setting_user.confirmation": "確認",
    "setting_user.save": "保存",
    "setting_user.password": "パスワード",
    "setting_user.user_name": "ユーザ名",
    "setting_user.cancel": "キャンセル",
    "setting_user.user_id": "ユーザID",
    "setting_user.user_id_place_holder": "メールアドレスを入力して下さい",
    "setting_user.status": "ステータス",
    "setting_user.language": "言語",
    "setting_user.language.english": "English",
    "setting_user.language.japan": "日本語",
    "setting_user.user_level": "ユーザレベル",

    // For setting definition
    "setting_definition.change_information": "変更内容を保存しますか？",
    "setting_definition.update_confirm": "変更の確認",
    "setting_definition.required": "は必須入力項目です",
    "setting_definition.confirmation": "確認",
    "setting_definition.save": "保存",
    "setting_definition.config_name": "定義名",
    "setting_definition.cancel": "キャンセル",
    "setting_definition.definition_id": "ID",

    // For search box
    "search_box.account": "アカウント",
    "search_box.sheets": "枚",
    "search_box.sheet": "枚",
    "search_box.accounts": "アカウント",
    "search_box.num_contract_account": "ご契約アカウント数",
    "search_box.number_of_contracts": "ご契約枚数",
    "search_box.use_this_month": "今月のご利用枚数",
    "search_box.file_name": "【ファイル名】",
    "search_box.file_name_place_holder": "ファイル名",
    "search_box.formGroup": "【グループ】",
    "search_box.definition_file": "【定義ファイル】",
    "search_box.narrowing_conditions": "絞り込み条件",
    "search_box.registered_date": "【登録日時】",
    "search_box.to_date": "【現在まで】",
    "search_box.status": "【ステータス】",
    "search_box.processing": "処理中",
    "search_box.read_error": "読取エラー",
    "search_box.reading_completed": "読取完了",
    "search_box.updated": "更新済",
    "search_box.confirmed": "確定",
    "search_box.downloaded": "ダウンロード済",
    "search_box.search": "検索",
    "search_box.create_user_btn": "新規ユーザの作成",
    "search_box.change_setting": "管理者情報の変更",
    "search_box.brand_name": "AIRead on Cloud",
    "search_box.ok": "OK",
    "search_box.from_time_greater_to_time": "終了日が開始日より大きいです",
    "search_box.all_definition_file": "【選択してください】",
    "search_box.team_name": "【チーム名】",

    // For profile
    "profile.sheets": "枚",
    "profile.sheet": "枚",
    "profile.company_information": "ご契約者",
    "profile.user_level": "ユーザレベル",
    "profile.number_of_contracts": "ご契約枚数",
    "profile.use_this_month": "今月のご利用枚数",
    "profile.monthly_usage": "月間利用枚数",
    "profile.user_information": "ユーザ情報",
    "profile.user_id": "ユーザID",
    "profile.role_0": "管理者ユーザ",
    "profile.role_1": "一般ユーザ",
    "profile.role_2": "アライズ管理者 ",
    "profile.tenant_notification": "お知らせ",
    "profile.per_month": "/ 月",
    "profile.per_year": "/ 年",
    "profile.period_of_use": "利用年月",
    "profile.period_of_use_year": "年",
    "profile.period_of_use_month": "月",
    "profile.period_of_use_update": "更新",

    // For pagging
    "pagging.show": "表示件数",
    "pagging.next": "次",
    "pagging.previous": "前",
    "pagging.show_record": "{{dataLength}} 件中　{{fromIndex}}～{{toIndex}}　件を表示",

    // For edit
    "edit.preview": "プレビュー",
    "edit.item_confidence": "信頼値",
    "edit.file_name": "ファイル名",
    "edit.screen": "読取結果修正",
    "edit.item_name": "項目名",
    "edit.output_value": "出力値",
    "edit.output_value_place_holder": "出力値",
    "edit.verification": "確認",
    "edit.cancel": "キャンセル",
    "edit.save": "保存",
    "edit.cancel_confirm_title": "確認",
    "edit.cancel_description": "保存せずに戻ります。よろしいですか？",
    "edit.cancel_yes": "はい",
    "edit.cancel_no": "いいえ",
    "edit.save_confirm_title": "確認",
    "edit.save_description": "保存しました。一覧画面に戻りますか？",
    "edit.save_yes": "はい",
    "edit.save_no": "いいえ",
    "edit.verify_at_least_one_item_to_save": "1つ以上の項目を確認・編集する必要があります",
    "edit.update_confirm_title": "確認",
    "edit.update_description": "データが更新されています。画面を更新してください",
    "edit.update_yes": "はい",
    "edit.update_no": "いいえ",
    "edit.info_file_name": "ファイル名",
    "edit.info_definition_file": "定義ファイル",
    "edit.info_status": "ステータス",
    "edit.change_page_confirm_title": "確認",
    "edit.change_page_description": "修正内容を保存せずに帳票を切り替えますか？",
    "edit.change_page_yes": "はい",
    "edit.change_page_no": "いいえ",
    "edit.remove_confirm_msg": "この項目を削除してもよろしいですか？",
    "edit.add_new_title": "項目を追加",
    "edit.required_msg": "は必須入力項目です",
    //"edit.item_name": "項目名",
    "edit.add_one_line": "1行追加",
    "edit.add_one_line_above": "上に1行追加",
    "edit.delete_line": "行を削除",
    "edit.confirm": "確定",
    "edit.unconfirm": "確定解除",
    "edit.unconfirm_before_edit": "編集するためには確定解除する必要があります",
    "edit.table.prebuilt.header": "ヘッダ",
    "edit.table.prebuilt.items": "明細",
    "edit.table.prebuilt.tax_details": "税詳細",

    // For upload
    "upload.filename": "名称",
    "upload.format": "形式",
    "upload.size": "サイズ",
    "upload.status": "状態",
    "upload.action": "削除",
    "upload.preview": "プレビュー",
    "upload.no": "No.",
    // "upload.filename": "名称",
    // "upload.format": "形式",
    // "upload.size": "サイズ",
    // "upload.status": "ステータス",
    // "upload.action": "実行",
    "upload.cant_upload_filenum": "同時にアップロードできるファイルは100ファイルまでです",
    "upload.btn_select_file": "ファイルを選択",
    "upload.cancel": "キャンセル",
    "upload.execute": "実行",
    "upload.sort_definition_file": "定義ファイルを指定する",
    "upload.key_sorting": "キーワード仕分けを使用する（RuleEditorの定義のみ）",
    "upload.sort_definition_tooltip_1": "Please select a definition file with edition = Item to enable sort function",
    "upload.sort_definition_tooltip_2": "",
    "upload.sort_definition_title": "仕分け手段",
    "upload.confirmation": "確認",
    "upload.cant_upload_warning": "Warning",
    "upload.confirmation_delete": "削除の確認",
    "upload.confirm_desc": "読み取り処理を開始します。 よろしいですか？",
    "upload.confirm_delete_desc": "ファイルを削除しますか？",
    "upload.execute_reading": "読取実行",
    "upload.drag_drop_desc": "ファイルを選択、またはここにファイルをドロップしてください",
    "upload.report_upload": "帳票アップロード",
    "upload.remove": "削除",
    "upload.close": "終了",
    "upload.file_name": "ファイル名",
    "upload.file_format": "ファイル形式",
    "upload.file_size": "ファイルサイズ",
    "upload.definition_file": "定義ファイル",
    "upload.file_erorrs": "アップロードが失敗しました。時間をおいて再度お試しください",
    // "upload.file_size_over": "このファイルは大きすぎます。{{maxlength}} KB未満である必要があります",
    "upload.file_size_over": "ファイルサイズは100MB以内でお願いします。(PDF,JPG, TIFF, PNG形式)",
    "upload.file_size_over_input": "ファイルサイズは100MB以内でお願いします。(PDF,JPG, TIFF, PNG形式、100ファイルまで）",
    "upload.file_type_not_allow": "アップロードされたファイルがPDF形式ではありません。PDFファイルをアップロードしてくださ",
    "upload.upload_file_to_execute_reading": "アップロードするファイルを選択してください",
    "upload.upload_definition_to_execute_reading": "定義ファイルを選択してください",
    "upload.template_sorting_filter": "画像/キーワード仕分け (座標・ルールv2)",
    "upload.group_definition_filter": "キーワード仕分け (ルールv1)",
    "upload.not_group_definition_filter": "定義ファイルを指定する",
    "upload.no_group": "注文書",
    "upload.copy": "クリップボードへコピーしました。",
    "upload.prebuilt_definition": "事前構築済みモデル定義",
    "upload.prebuilt_invoice": "請求書",
    "upload.prebuilt_receipt": "レシート",

    // For download

    "download.no": "No.",
    "download.csv_download": "CSVダウンロード",
    "download.file_name": "ファイル名",
    "download.definition_file": "定義ファイル",
    "download.registered_date": "登録日時",
    "download.status": "ステータス",
    "download.download": "CSVダウンロード",
    "download.download_reading_results": "デバッグ情報ダウンロード",
    "download.pagenumber": "ページ番号",
    "download.formGroup": "グループ",
    "download.update_date_and_time": "更新日時",
    "download.edit": "編集",
    "download.ok": "OK",
    "download.select_to_download": "ダウンロードするデータを選択してください",
    'download.brand_name': "AIRead on Cloud",
    "download.status_0": "処理中",
    "download.status_1": "読取エラー",
    "download.status_2": "読取完了",
    "download.status_3": "更新済",
    "download.status_4": "ダウンロード済",
    "download.status_5": "確定",
    "download.btn_update": "更新",
    "download.action": "編集",
    "document.remove": "削除",
    "document.remove_confirm": "削除の確認",
    "document.remove_document": "選択されたデータを削除します。よろしいですか",
    "document.remove_yes": "はい",
    "document.remove_no": "いいえ",
    "download.select_to_remove": "削除するファイルを選択してください",
    "download.selected_item": "選択した数: ",
    "download.select_data_must_same_type": "全てのチェックを有効にするには、\r\n同じ定義ファイル、または同じグループ\r\nに絞り込んでください。",
    "download.cant_download_confirm": "確認",
    "download.cant_download_warning": "Warning",
    "download.cant_download_document": "読取エラー帳票はダウンロードできません",
    "download.cant_download_close": "終了",
    "download.prebuilt": "事前構築済みモデル",
    "download.prebuilt_invoice": "請求書",
    "download.prebuilt_receipt": "レシート",

    // menu
    "menu.user_management": "ユーザ管理",
    "menu.csv_download": "CSVダウンロード",
    "menu.report_upload": "帳票アップロード",
    "menu.report_download": "帳票ダウンロード",
    "menu.form_definition": "帳票定義管理",
    "menu.tenant_management": "テナント管理 利用状況",
    "menu.general_setting": "環境設定",
    "menu.individual_setting": "個別設定",
    "menu.model_management": "個別学習モデル管理",
    "menu.form_setting": "帳票設定管理",
    "menu.team": "チーム管理",
    "menu.post_processing": "後処理設定管理",
    "menu.template_sorting": "仕分け管理",
    "menu.action_logs": "ログ",

    "change_password.submit": "変更",
    "change_password.title": "パスワードの変更",
    "change_password.menu_button": "パスワードの変更",
    "change_password.old_password": "現在のパスワード",
    "change_password.new_password": "新しいパスワード",
    "change_password.confirm_password": "パスワード再入力",

    "tenant.create_tenant_confirm": "新しいテナントを作成します。よろしいですか？",
    "tenant.page_title": "テナント管理 利用状況",
    "tenant.show_password": "パスワードを表示",
    "tenant.month_year": "利用年月",
    "tenant.year": "年",
    "tenant.month": "月",
    "tenant.create_tenant": "テナント作成",
    "tenant.checkbox_active": "利用期間中の契約者のみ表示する",
    "tenant.success_document": "利用枚数",
    "tenant.success_item": "(item)",
    "tenant.success_manual": "(manual)",
    "tenant.success_rule": "(rule)",
    "tenant.success_prebuilt": "(prebuilt)",
    "tenant.fail_document": "読取エラー",
    "tenant.start_date": "利用開始日",
    "tenant.end_date": "利用終了日",
    "tenant.total_table": "合計",
    "tenant.required": "は必須入力項目です",
    "tenant.email": "E-mail",
    "tenant.update": "更新",
    "tenant.id": "TenantID",
    "tenant.name": "契約者名",
    "tenant.name_place_holder": "正式な企業・組織名",
    "tenant.cancel": "キャンセル",
    "tenant.date_range": "利用期間（開始～終了）",
    "tenant.total": "契約枚数",
    "tenant.total_place_holder": "S(100), M(500), L(1000)",
    "tenant.renewal_span": "契約更新単位",
    "tenant.renewal_span_month": "月",
    "tenant.renewal_span_year": "年",
    "tenant.admin_id": "管理者ID",
    "tenant.admin_id_place_holder": "管理者のメールアドレス",
    "tenant.active_user": "契約アカウント数",
    "tenant.active_user_place_holder": "契約者数 最少は 1",
    "tenant.admin_name": "管理者名",
    "tenant.admin_name_place_holder": "管理者のユーザ名",
    "tenant.admin_password": "パスワード",
    "tenant.confirm_password": "パスワード再入力",
    "tenant.submit": "作成",
    "tenant.no": "No.",
    "tenant.language": "言語",
    "tenant.language.english": "English",
    "tenant.language.japan": "日本語",
    "tenant.update_submit": "変更",
    "tenant.update_tenant": "テナント内容変更",
    "tenant.update_tenant.news": "お知らせ",
    "tenant.overview_management": "全体管理",
    "tenant.overview_management.info_url": "お知らせ（ログイン）",
    "tenant.overview_management.info_url.place_holder": "URLを入力",
    "tenant.overview_management.news": "お知らせ（全体共通）",
    "tenant.overview_management.news.place_holder": "メッセージを入力",
    "tenant.update_tenant_confirm": "テナント内容変更。よろしいですか？",
    "tenant.team_name": "チーム名",

    "general_setting.threshold_title": "読取結果の信頼値のしきい値",
    "general_setting.low": "信頼値 低",
    "general_setting.mid": "信頼値 中",
    "general_setting.high": "信頼値 高",
    "general_setting.save": "保存",
    "general_setting.api_token": "API Token",
    "general_setting.generate": "生成する",
    "general_setting.data_retention_period": "データ保存期間",
    "general_setting.data_retention_period.tooltip": "指定した日数より前のデータをサーバから削除する。0の場合は無期限。",
    "general_setting.document_download": "帳票ダウンロード",
    "general_setting.document_download_folder": "フォルダ名",
    "general_setting.document_download_file": "ファイル名",
    "general_setting.document_download_description_before_link": "※使用できるタグは",
    "general_setting.document_download_description_link": "マニュアル",
    "general_setting.document_download_description_after_link": "の「環境設定」を参照",
    "general_setting.day": "日",
    "general_setting.restriction_ip_address": "IPアドレスによる接続制限",
    "general_setting.restriction_ip_address.edit": "編集",
    "general_setting.restriction_ip_address.page_title": "IPアドレスによる接続制限",
    "general_setting.restriction_ip_address.ip": "IPアドレス",
    "general_setting.restriction_ip_address.comment": "コメント",
    "general_setting.restriction_ip_address.back": "戻る",
    "general_setting.restriction_ip_address.create": "規則を追加",
    "general_setting.restriction_ip_address.remove_confirm": "削除の確認",
    "general_setting.restriction_ip_address.remove_confirm_msg": "このIPアドレスを削除してもよろしいですか？",
    "general_setting.restriction_ip_address.detail.page_title": "IPアドレスによる接続制限[詳細]",
    "general_setting.restriction_ip_address.detail.ip.desc_1": "サブネットマスクを指定して範囲指定も可能です",
    "general_setting.restriction_ip_address.detail.ip.desc_2": "[IPアドレスの指定例]",
    "general_setting.restriction_ip_address.detail.ip.desc_3": "10.2.30.4",
    "general_setting.restriction_ip_address.detail.ip.desc_4": "10.2.30.0/24",
    "general_setting.restriction_ip_address.detail.ip_duplicated": "指定されたIPアドレスは既に存在します",

    // Individual setting
    "individual_setting.page_title": "個別設定",
    "individual_setting.language": "言語",
    "individual_setting.language.english": "English",
    "individual_setting.language.japan": "日本語",
    "individual_setting.download_csv.file_format": "ダウンロードファイル形式",
    "individual_setting.download_csv.file_format.tooltip": "項目名を列としたCSV形式",
    "individual_setting.download_csv.file_format.csv": "CSV",
    "individual_setting.download_csv.file_format.csv.tooltip": "読み取り信頼度や座標情報などを含むCSV形式",
    "individual_setting.download_csv.file_format.csv_airead": "CSV(AIRead形式)",
    "individual_setting.download_csv.file_character_code": "ダウンロードファイル文字コード",
    "individual_setting.download_csv.file_recognize_newline": "ダウンロードファイル内の改行形式",
    "individual_setting.download_csv.file_recognize_newline.string": "文字列(\\n)",
    "individual_setting.download_csv.file_recognize_newline.code": "改行コード",
    "individual_setting.download_csv.file_recognize_newline.string.tooltip": "文字列(\\n)のまま出力する",
    "individual_setting.download_csv.file_recognize_newline.code.tooltip": "文字列(\\n)を改行コードに変換して出力する",
    "individual_setting.read_complete_email_notification": "読取完了時にメール受信",
    "individual_setting.read_complete_email_notification.on": "する",
    "individual_setting.read_complete_email_notification.off": "しない",
    "individual_setting.save": "保存",

    // Individual learning model management
    "individual_model_mng.page_title": "個別学習モデル管理",
    "individual_model_mng.no": "No.",
    "individual_model_mng.model_type": "モデル種類",
    "individual_model_mng.model_name": "モデル名",
    "individual_model_mng.model_size": "サイズ(MB)",
    "individual_model_mng.created_by": "登録者",
    "individual_model_mng.updated_date": "最終登録日",
    "individual_model_mng.cancel": "キャンセル",
    "individual_model_mng.remove": "削除",
    "individual_model_mng.remove_confirm_msg": "このモデルを削除してもよろしいですか？",
    "individual_model_mng.model_type_print": "活字モデル",
    "individual_model_mng.model_type_manual": "手書きモデル",

    // Form setting
    "form_setting.page_title": "帳票設定管理",
    "form_setting.lbl.extract_method": "抽出方式",
    "form_setting.lbl.form_setting_name": "帳票設定名",
    "form_setting.lbl.basic_setting": "基本設定",
    "form_setting.lbl.image_processing": "画像処理",
    "form_setting.lbl.rule_specification": "ルール指定",
    "form_setting.lbl.prebuilt.basic_setting": "基本設定",
    "form_setting.lbl.prebuilt.item_name_setting": "項目名設定",
    "form_setting.lbl.prebuilt_model": "事前構築済みモデル",
    "form_setting.lbl.coordinate_specification": "座標指定",
    "form_setting.btn.save": "保存",
    "form_setting.btn.remove": "削除",
    "form_setting.btn.save_as": "名前を付けて保存",
    "form_setting.err_requied": "この項目は必須入力項目です",
    "form_setting.add_model_title": "帳票設定名の指定",
    "form_setting.update_model_title": "変更の確認",
    "form_setting.remove_model_title": "削除の確認",
    "form_setting.remove_model_content": "設定を削除しますか？",
    "form_setting.not_specified": "指定しない",
    "form_setting.change_setting_without_saving": "保存せずに戻ります。\r\nよろしいですか？",
    "form_setting.err_out_in_range": "{{from}}～{{to}}で設定してください。",
    "form_setting.prebuilt_invoice": "請求書",
    "form_setting.prebuilt_receipt": "レシート",
    "form_setting.updated_at": "更新日時",
    "form_setting.prebuilt.item_name_setting.no": "No.",
    "form_setting.prebuilt.item_name_setting.default_item_name": "デフォルト項目名",
    "form_setting.prebuilt.item_name_setting.type": "種別",
    "form_setting.prebuilt.item_name_setting.description": "説明",
    "form_setting.prebuilt.item_name_setting.output": "出力",
    "form_setting.prebuilt.item_name_setting.converted_item_name": "変更後の項目名",

    // 01_基本設定タブ/ 01_Basic Settings tab
    "form_setting.ocr_lang": "活字用OCRモデル",
    "form_setting.ocr_lang.tooltip": "活字OCRエンジンで利用するモデル名。拡張子 .traineddata を除外したファイル名を指定。",
    "form_setting.page_seg_mode": "文字列の切取ロジック",
    "form_setting.page_seg_mode.char_size": "文字の大きさが可変",
    "form_setting.page_seg_mode.font_size": "文字の大きさが均一",
    "form_setting.page_seg_mode.tooltip": "OCRエンジンの文字識別アルゴリズムを指定",
    "form_setting.rescan_threshold": "再読み込みの閾値",
    "form_setting.rescan_threshold.tooltip": "指定した値よりもコンポーネントのConf値が低い場合に読み直します。",
    "form_setting.rescan_threshold.place_holder": "整数で入力してください",
    "form_setting.use_dup_char_reducer": "文字の多重認識を減らす処理を行う",
    "form_setting.reduce_target": "対象文字種",
    "form_setting.reduce_target.number": "数字",
    "form_setting.reduce_target.symboy": "記号",
    "form_setting.reduce_target.hiragana": "ひらがな",
    "form_setting.reduce_target.katakana": "カタカナ",
    "form_setting.reduce_target.alphabet": "アルファベット",
    "form_setting.proc_max_page": "読取処理をする最大ページ数",
    "form_setting.proc_max_page.tooltip": "「アウトプットと帳票仕分けの単位」が「ファイル単位でアウトプット、帳票仕分けは1ページ目のみ」の場合のみ有効",
    "form_setting.file_idenfication_type": "アウトプットと帳票仕分けの単位",
    "form_setting.file_idenfication_type.0": "ページ単位でアウトプット、帳票仕分け",
    "form_setting.file_idenfication_type.1": "ファイル単位でアウトプット、帳票仕分けは1ページ目のみ",

    // 02_座標指定/ 02_Coordinate specification
    "form_setting.handwrite_lang": "手書き用OCRモデル",
    "form_setting.use_segment_model": "改善した手書きの文字切取りロジックを使用する",
    "form_setting.export_space": "OCR結果にスペースを含める",
    "form_setting.hw_replace_thresh": "文字を置換する信頼度(手書き)",
    "form_setting.hw_replace_char": "置換後の文字",
    "form_setting.format_margin": "位置合わせ矩形の認識マージン",
    "form_setting.print_noise_filters": "活字のノイズ閾値",
    "form_setting.hw_noise_filters": "手書きのノイズ閾値\r\n(文字の高さ、複数文字指定時)",
    "form_setting.hw_white_threshold": "手書きのノイズ閾値\r\n(白画素の割合、1文字指定時)",
    // 02_キーワード指定/ 02_Keyword specification
    "form_setting.component_level": "抽出モードの指定",
    "form_setting.component_level.item": "キーワード指定",
    "form_setting.component_level.cell": "キーワード指定（表検出付き）",
    "form_setting.component_level.tooltip": "抽出モードを設定する。",
    "form_setting.ocr_pdf_with_text": "テキスト付PDFでもOCRを実行する",
    "form_setting.use_dl_string_detection": "改善した文字列検知ロジックを使用する",
    "form_setting.string_detection_split_large_rectangle": "行間隔の狭い文字列の精度改善",
    "form_setting.black_list_ext": "OCR結果から除外する文字リスト",
    "form_setting.black_list_ext.tooltip": "抽出対象から除外する文字を記載する。",
    "form_setting.linked_item_threshold": "1つの文字列とみなす文字間隔(高さのN倍)",
    "form_setting.linked_item_threshold.tooltip": "1つの文字列とみなす文字の間隔。値は文字の高さに対する倍率。",
    "form_setting.space_mode": "改善したスペース検出ロジックを使用する",
    "form_setting.reduce_image_before_ocr": "画像の解像度が大きい場合に小さくする",
    "form_setting.reduce_image_before_ocr.tooltip": "画像の長辺の解像度が5000pxより大きい場合、小さくしてからOCRを実行します。",

    // 03_画像処理/ 03_ Image processing
    "form_setting.de_noise": "ノイズ除去を行う",
    "form_setting.is_slope_correction": "傾き補正を行う",
    "form_setting.auto_rotation": "回転補正を行う(90度単位)",
    "form_setting.skip_line_remove": "罫線除去を無効化する",
    "form_setting.line_removal_threshold": "直線除去を行う最短の長さ(ピクセル)",
    "form_setting.line_removal_threshold.tooltip": "直線とみなす長さの閾値（ピクセル)。0の場合、文字の高さに合わせて自動で設定します。",
    "form_setting.line_removal_multiple_by_text_heght": "直線除去時の文字高さに対する倍数",
    "form_setting.line_removal_multiple_by_text_heght.tooltip": "直線除去を行う最短の長さが\"0\"の場合に、平均の文字高さの何倍を直線除去のしきい値とするかを設定します。",
    "form_setting.hough_threshold": "点線除去を行う最短の長さ(ピクセル)",
    "form_setting.hough_threshold.tooltip": "点線とみなす長さのしきい値（ピクセル）。",
    "form_setting.restore_text": "罫線除去で欠けてしまった文字の復元処理を行う",
    "form_setting.thresh_value": "二値化の閾値",
    "form_setting.thresh_value.tooltip": "二値化の閾値。0の場合、画像全体のヒストグラムから自動で設定。",
    "form_setting.binthresh_on_line_removal": "直線除去時の二値化の閾値",
    "form_setting.binthresh_on_line_removal.tooltip": "直線除去時にだけ適用する二値化の閾値。直線除去がきれいになっていないときに調整する。0の場合、画像全体のヒストグラムから自動で設定。",
    "form_setting.black_white_threshold": "白黒反転処理を実施する黒の比率(%)",
    "form_setting.black_white_threshold.tooltip": "二値化後に白黒を反転する閾値（％）。設定した％より黒の割合が多い場合に白黒を反転。",
    "form_setting.sharpen_value": "シャープ補正値",
    "form_setting.sharpen_value.tooltip": "OCR実行前に画像をシャープ化する。画像がぼやけている場合に効果がある。0の場合は処理しない。使用する場合、0.2～0.7が値の目安。",
    "form_setting.short_line_thresh_h": "短い罫線（横）除去の閾値",
    "form_setting.short_line_thresh_h.tooltip": "長い罫線に接している短い罫線（横）を検知・除去する閾値。罫線の長さ（ピクセル）を指定します。",
    "form_setting.short_line_thresh_v": "短い罫線（縦）除去の閾値",
    "form_setting.short_line_thresh_v.tooltip": "長い罫線に接している短い罫線（縦）を検知・除去する閾値。罫線の長さ（ピクセル）を指定します。",
    "form_setting.use_short_dotline_removal": "短い点線も除去する(処理時間がA4画像1枚当たり2秒ほど増加)",
    "form_setting.thin_line_removal_threshold_w": "細かいノイズ除去の閾値（幅）",
    "form_setting.thin_line_removal_threshold_w.tooltip": "指定した幅(ピクセル)より細かいノイズを除去します。",
    "form_setting.thin_line_removal_threshold_h": "細かいノイズ除去の閾値（高さ）",
    "form_setting.thin_line_removal_threshold_h.tooltip": "指定した高さ(ピクセル)より細かいノイズを除去します。",
    "form_setting.erode_thin_line_w": "細かいノイズ除去の縮小フィルタ（幅）",
    "form_setting.erode_thin_line_w.tooltip": "細かいノイズ除去の収縮(Erode)処理のフィルターサイズ（幅）。1以上の整数を指定します。",
    "form_setting.erode_thin_line_h": "細かいノイズ除去の縮小フィルタ（高さ）",
    "form_setting.erode_thin_line_h.tooltip": "細かいノイズ除去の収縮(Erode)処理のフィルターサイズ（高さ）。1以上の整数を指定します。",
    "form_setting.conv_tiff_dpi": "TIFFを300DPIに変換してからOCRを実行する",
    "form_setting.extraction_colors": "抽出する色",
    "form_setting.extraction_colors.tooltip": "抽出する色を指定します。",
    "form_setting.removal_colors": "除去する色",
    "form_setting.removal_colors.tooltip": "除去する色を指定します。",
    "form_setting.colors.black": "黒",
    "form_setting.colors.red": "赤",
    "form_setting.colors.yellow": "黄",
    "form_setting.colors.green": "縁",
    "form_setting.colors.water": "水",
    "form_setting.colors.blue": "青",
    "form_setting.colors.purple": "紫",
    "form_setting.colors.custom": "カスタム",
    "form_setting.filter_color_before_lineremoval": "色抽出・除去を罫線除去の前に行う",
    "form_setting.line_extension_len": "罫線を延長する長さ",
    "form_setting.line_extension_len.tooltip": "罫線の両端を伸ばす長さ(ピクセル)。0以上の整数を指定します。",
    "form_setting.rounded_corner_threshold": "矩形の丸い角を除去する閾値",
    "form_setting.rounded_corner_threshold.tooltip": "半径が指定したピクセル値未満の丸い角を除去します。0を指定した場合、この機能は働きません。",
    "form_setting.rounded_corner_padding": "丸い角の除去範囲を拡張する長さ",
    "form_setting.rounded_corner_padding.tooltip": "丸い角を除去する際に指定したピクセル分、除去範囲を拡大します。この値を大きくすると消し残りが減りますが、その分文字まで消える可能性があります。",
    "form_setting.remove_dotted_background": "ドット背景除去を行う",
    "form_setting.dot_size_threshold": "ドットサイズ",
    "form_setting.dot_size_threshold.tooltip": "指定したサイズ以下のドットを除去する。",
    "form_setting.remove_small_rectangles": "小さい矩形を削除",
    "form_setting.remove_small_rectangles.tooltip": "検知した矩形の面積が「最小面積 < 矩形の面積 < 最大面積」の場合、小さい矩形とみなして除去する。",
    "form_setting.remove_rectangle_min_area_threshold": "除去する小さい矩形の最小面積",
    "form_setting.remove_rectangle_max_area_threshold": "除去する小さい矩形の最大面積",
    "form_setting.remove_diagonal_lines": "斜め線を除去",
    "form_setting.remove_diagonal_lines.tooltip": "表に含まれる斜めの線を除去する。",

    "form_setting.specifying_form_settings.page_title": "帳票設定の指定",
    "form_setting.specifying_form_settings.form_name": "指定する帳票設定名",
    "form_setting.specifying_form_settings.update_confirm": "変更の確認",
    "form_setting.specifying_form_settings.change_information": "変更内容を保存しますか？",
    "form_setting.specifying_form_settings.save": "保存",
    "form_setting.specifying_form_settings.cancel": "キャンセル",

    // 事前構築済みモデル 基本設定
    "form_setting.prebuilt.enable_empty_output": "取得できなかった項目は空で出力",

    // 事前構築済みモデル 項目名設定
    "form_setting.prebuilt.type.header": "ヘッダ",
    "form_setting.prebuilt.type.items": "明細",
    "form_setting.prebuilt.type.tax_details": "税詳細",
    "form_setting.prebuilt.invoice.customer_name": "顧客名",
    "form_setting.prebuilt.invoice.customer_id": "顧客番号",
    "form_setting.prebuilt.invoice.purchase_order": "注文書参照番号",
    "form_setting.prebuilt.invoice.invoice_id": "請求書番号",
    "form_setting.prebuilt.invoice.invoice_date": "請求書の発行日",
    "form_setting.prebuilt.invoice.due_date": "支払い期限日",
    "form_setting.prebuilt.invoice.vendor_name": "ベンダー名",
    "form_setting.prebuilt.invoice.vendor_tax_id": "ベンダーの納税者番号",
    "form_setting.prebuilt.invoice.vendor_address": "ベンダーの住所",
    "form_setting.prebuilt.invoice.vendor_address_recipient": "ベンダーの郵送先住所受領者",
    "form_setting.prebuilt.invoice.customer_address": "顧客の住所",
    "form_setting.prebuilt.invoice.customer_tax_id": "顧客の納税者番号",
    "form_setting.prebuilt.invoice.customer_address_recipient": "顧客の郵送先住所受領者",
    "form_setting.prebuilt.invoice.billing_address": "請求先住所",
    "form_setting.prebuilt.invoice.billing_address_recipient": "請求先住所受領者",
    "form_setting.prebuilt.invoice.shipping_address": "配送先住所",
    "form_setting.prebuilt.invoice.shipping_address_recipient": "配送先住所受領者",
    "form_setting.prebuilt.invoice.payment_term": "請求書の支払い条件",
    "form_setting.prebuilt.invoice.sub_total": "小計",
    "form_setting.prebuilt.invoice.total_tax": "合計税額",
    "form_setting.prebuilt.invoice.invoice_total": "合計請求金額",
    "form_setting.prebuilt.invoice.amount_due": "ベンダーに対する合計金額",
    "form_setting.prebuilt.invoice.service_address": "顧客のサービス住所または物件所在地",
    "form_setting.prebuilt.invoice.service_address_recipient": "顧客のサービス住所受領者",
    "form_setting.prebuilt.invoice.remittance_address": "支払い先住所",
    "form_setting.prebuilt.invoice.remittance_address_recipient": "支払い先住所受領者",
    "form_setting.prebuilt.invoice.service_start_date": "サービス期間開始日",
    "form_setting.prebuilt.invoice.service_end_date": "サービス期間終了日",
    "form_setting.prebuilt.invoice.previous_unpaid_balance": "以前の未払い残高",
    "form_setting.prebuilt.invoice.currency_code": "通貨コード",
    "form_setting.prebuilt.invoice.payment_details": "支払いオプション詳細",
    "form_setting.prebuilt.invoice.total_discount": "割引額合計",
    "form_setting.prebuilt.invoice.items": "各項目の文字列テキスト行全文",
    "form_setting.prebuilt.invoice.amount": "各項目の価格",
    "form_setting.prebuilt.invoice.description": "各項目の説明",
    "form_setting.prebuilt.invoice.quantity": "各項目の数量",
    "form_setting.prebuilt.invoice.unit_price": "各項目の単価",
    "form_setting.prebuilt.invoice.product_code": "各項目の製品番号",
    "form_setting.prebuilt.invoice.unit": "各項目の数量単位",
    "form_setting.prebuilt.invoice.date": "各項目の日付",
    "form_setting.prebuilt.invoice.tax": "各項目の税額",
    "form_setting.prebuilt.invoice.tax_rate": "各項目の税率",
    "form_setting.prebuilt.receipt.merchant_name": "販売者名",
    "form_setting.prebuilt.receipt.merchant_phone_number": "販売者の電話番号",
    "form_setting.prebuilt.receipt.merchant_address": "販売者の住所",
    "form_setting.prebuilt.receipt.total": "取引合計額",
    "form_setting.prebuilt.receipt.transaction_date": "レシートの発行日",
    "form_setting.prebuilt.receipt.transaction_time": "レシートの発行時刻",
    "form_setting.prebuilt.receipt.subtotal": "小計",
    "form_setting.prebuilt.receipt.total_tax": "合計税額",
    "form_setting.prebuilt.receipt.tip": "チップ",
    "form_setting.prebuilt.receipt.arrival_date": "ホテル到着日",
    "form_setting.prebuilt.receipt.departure_date": "ホテル出発日",
    "form_setting.prebuilt.receipt.currency": "入金金額の通貨単位",
    "form_setting.prebuilt.receipt.merchant_aliases": "販売者の別名",
    "form_setting.prebuilt.receipt.total_price": "各項目の合計価格",
    "form_setting.prebuilt.receipt.description": "各項目の説明",
    "form_setting.prebuilt.receipt.quantity": "各項目の数量",
    "form_setting.prebuilt.receipt.price": "各項目単位の個別価格",
    "form_setting.prebuilt.receipt.product_code": "各項目の製品番号",
    "form_setting.prebuilt.receipt.quantity_unit": "各項目の数量単位",
    "form_setting.prebuilt.receipt.date": "各項目の日付",
    "form_setting.prebuilt.receipt.category": "各項目のカテゴリー",
    "form_setting.prebuilt.receipt.amount": "税額詳細",

    "team.page_title": "チーム管理",
    "team.page_edit_team_title": "チーム編集",
    "team.no": "No.",
    "team.team_id": "チームID",
    "team.contractor": "ご契約者",
    "team.contractor_name": "アライズイノベーション株式会社 製品開発チーム2",
    "team.create_new_team": "新規チームの作成",
    "team.team_name": "チーム名",
    "team.created_date": "作成日",
    "team.updated_date": "更新日",
    "team.remove": "削除",
    "team.remove_team": "チームを削除",
    "team.change": "変更",
    "team.add_user": "ユーザを追加",
    "team.user_id": "ユーザID",
    "team.user_name": "ユーザ名",
    "team.user_level": "ユーザレベル",
    //"team.user_level": "ユーザレベル",
    "team.modal_team_management_title": "チーム管理[詳細]",
    "team.modal_add_user_title": "チーム編集[ユーザ追加]",
    "team.filter_condition": "絞り込み条件",
    "team.save": "保存",
    "team.update": "更新",
    "team.remove_confirm_msg": "このチームを削除してもよろしいですか？\r\n削除されたチームのファイルは\r\nCSVダウンロード画面に表示されなくなります。",
    "team.remove_user_confirm_msg": "このユーザを削除してもよろしいですか？",
    "team.add_user_confirm_msg": "作成したチームに、チーム編集画面から\r\nユーザを追加してください",

    "post_processing.page_title": "後処理設定管理（読取結果変換リスト）",
    "post_processing.definition_name": "定義名",
    "post_processing.created_by": "登録者",
    "post_processing.remove_post_processing_confirm_msg": "この読取結果変換リストを削除してもよろしいですか？",

    "template_sorting.common.setting_name": "仕分け定義名",
    "template_sorting.common.classification_type": "仕分け種別",
    "template_sorting.common.classification_type.image": "画像仕分け",
    "template_sorting.common.classification_type.keyword": "キーワード仕分け",
    "template_sorting.common.max_features": "特徴点の抽出数",
    "template_sorting.common.match_ct_th": "特徴点の最小数",
    "template_sorting.common.size_diff_th": "画像比較スキップの閾値",
    "template_sorting.common.keyword_rect": "仕分けで読取をしたい範囲",
    "template_sorting.mng.page_title": "仕分け管理",
    "template_sorting.mng.id": "仕分け定義ID",
    "template_sorting.mng.create": "仕分け定義の作成",
    "template_sorting.mng.remove_confirm_msg": "この項目を削除してもよろしいですか？",
    "template_sorting.mng.modal.dialog_title": "仕分け管理[詳細]",
    "template_sorting.mng.modal.max_features.tooltip": "画像の比較に使用する特徴的な点を検出する数。\r\n1以上の整数値を指定可能。",
    "template_sorting.mng.modal.match_ct_th.tooltip": "入力画像とテンプレート画像が似ていると判断するのに\r\n最低限必要な特徴点のマッチ数。\r\n0以上の整数値を指定可能。",
    "template_sorting.mng.modal.size_diff_th.tooltip": "テンプレート画像と入力画像のピクセルでの面積を比較し、\r\nその差がこの値より大きい場合そのテンプレート画像との比較をスキップする。\r\n-1を指定した場合、スキップ処理は行われない。\r\n-1以上の整数値を指定可能。",
    "template_sorting.mng.modal.keyword_rect.tooltip": "方向（T:上、B:下、R:右、L:左）と割合で指定。\r\n例）T,20,L,50　→　上から20%、かつ左から50%の範囲\r\n範囲を絞ることで処理時間を削減できる。",
    "template_sorting.mng.modal.add_form_confirm_msg": "仕分け編集画面から、作成した仕分け定義の\r\n編集を行ってください",
    "template_sorting.edit.page_title": "仕分け編集",
    "template_sorting.edit.change": "変更",
    "template_sorting.edit.formGroup": "【グループ】",
    "template_sorting.edit.formGroup_default": "【選択してください】",
    "template_sorting.edit.edition": "Edition",
    "template_sorting.edit.edition.manual": "座標",
    "template_sorting.edit.edition.rule": "ルール v2",
    "template_sorting.edit.form_group_name": "Group Name",
    "template_sorting.edit.form_id": "Form ID",
    "template_sorting.edit.config_name": "定義名",
    "template_sorting.edit.status": "テンプレート画像",
    "template_sorting.edit.regist": "テンプレート画像登録",
    "template_sorting.edit.keyword": "キーワード",
    "template_sorting.edit.keyword.tooltip": "半角スペースで区切るとOR条件、セミコロン(;)で区切るとAND条件でキーワードを検索して識別します。",
    "template_sorting.edit.status_0": "未登録",
    "template_sorting.edit.status_1": "登録済み",
    "template_sorting.edit.regist_btn": "登録",
    "template_sorting.edit.back": "戻る",
    "template_sorting.edit.save": "保存",
    "template_sorting.edit.modal.change_group": "変更の確認",
    "template_sorting.edit.modal.change_information": "テンプレートの割り当てが解除されます。\r\nよろしいですか？",
    "template_sorting.regist.page_title": "テンプレート画像登録",
    "template_sorting.upload.file_size_over_input": "ファイルサイズは10MB以内でお願いします。(JPG, TIFF, PNG形式、5ファイルまで）",
    "template_sorting.upload.file_size_over": "ファイルサイズは10MB以内でお願いします。(JPG, TIFF, PNG形式)",
    "template_sorting.upload.file_type_not_allow": "アップロードされたファイルの形式が不正です",
    "template_sorting.upload.cant_upload_warning": "Warning",
    "template_sorting.upload.cant_upload_5": "登録できるファイルは5ファイルまでです",
    "template_sorting.upload.exists_same_file_name": "同名のファイルが存在します",
    "template_sorting.upload.close": "終了",
    "template_sorting.upload.no": "No.",
    "template_sorting.upload.filename": "名称",
    "template_sorting.upload.format": "形式",
    "template_sorting.upload.size": "サイズ",
    "template_sorting.upload.regist_status": "ステータス",
    "template_sorting.upload.status": "状態",
    "template_sorting.upload.action": "解除",
    "template_sorting.upload.back": "戻る",
    "template_sorting.upload.regist": "登録",
    "template_sorting.upload.cancel": "キャンセル",
    "template_sorting.upload.confirmation_delete": "解除の確認",
    "template_sorting.upload.confirm_delete_desc": "テンプレート画像を解除しますか？",
    "template_sorting.upload.remove": "解除",
    "template_sorting.upload.confirmation": "確認",
    "template_sorting.upload.confirm_desc": "テンプレート画像を登録します。\r\nよろしいですか？",
    "template_sorting.upload.regist": "登録",
    "template_sorting.regist.imagetype_1": "JPG",
    "template_sorting.regist.imagetype_2": "PNG",
    "template_sorting.regist.imagetype_3": "TIFF",
    "template_sorting.regist.status_0": "未登録",
    "template_sorting.regist.status_1": "登録済み",

    // For log
    "log.title": "ログ",
    "log.download": "CSVダウンロード",
    "log.no": "No.",
    "log.action": "アクション",
    "log.action_detail": "アクション詳細",
    "log.user_id": "ユーザID",
    "log.executed_at": "実行日時",
    "log.download": "CSVダウンロード",
    "log.action_record_upload": "帳票アップロード",
    "log.action_csv_download": "CSVダウンロード",
    "log.action_form_definition_upload": "帳票定義アップロード",
    "log.action_correct_reading_result": "読取結果修正",
    "log.action_login": "ログイン",
    "log.action_logout": "ログアウト",
    "log.action_delete_document": "読取結果削除",
    "log.action_debug_download": "デバッグ情報ダウンロード",
    "log.action_record_download": "帳票ダウンロード",
    "log.btn_update": "更新",

    "log_search_box.narrowing_conditions": "絞り込み条件",
    "log_search_box.executed_date": "【実行日時】",
    "log_search_box.to_date": "【現在まで】",
    "log_search_box.user_id": "【ユーザID】",
    "log_search_box.all_definition_file": "【選択してください】",
    "log_search_box.action": "【アクション】",
    "log_search_box.action_login": "ログイン",
    "log_search_box.action_logout": "ログアウト",
    "log_search_box.action_record_upload": "帳票アップロード",
    "log_search_box.action_csv_download": "CSVダウンロード",
    "log_search_box.action_form_definition_upload": "帳票定義アップロード",
    "log_search_box.action_correct_reading_result": "読取結果修正",
    "log_search_box.action_delete_document": "読取結果削除",
    "log_search_box.action_debug_download": "デバッグ情報ダウンロード",
    "log_search_box.action_record_download": "帳票ダウンロード",

    // For download
    "documents_download.no": "No.",
    "documents_download.title": "帳票ダウンロード",
    "documents_download.file_name": "ファイル名",
    "documents_download.definition_file": "定義ファイル",
    "documents_download.registered_date": "登録日時",
    "documents_download.team_name": "チーム名",
    "documents_download.user_id": "作成者",
    "documents_download.status": "ステータス",
    "documents_download.download": "ダウンロード",
    "documents_download.form_group": "グループ",
    "documents_download.select_to_download": "ダウンロードするデータを選択してください",
    'documents_download.brand_name': "AIRead on Cloud",
    "documents_download.status_0": "未ダウンロード",
    "documents_download.status_1": "ダウンロード済",
    "documents_download.btn_update": "更新",
    "documents_download.selected_item": "選択した数: ",
    "documents_download.prebuilt": "事前構築済みモデル",
    "documents_download.prebuilt_invoice": "請求書",
    "documents_download.prebuilt_receipt": "レシート",

    // For download search box
    "download_search_box.file_name": "【ファイル名】",
    "download_search_box.file_name_place_holder": "ファイル名",
    "download_search_box.formGroup": "【グループ】",
    "download_search_box.definition_file": "【定義ファイル】",
    "download_search_box.narrowing_conditions": "絞り込み条件",
    "download_search_box.registered_date": "【登録日時】",
    "download_search_box.to_date": "【現在まで】",
    "download_search_box.status": "【ステータス】",
    "download_search_box.not_downloaded": "未ダウンロード",
    "download_search_box.downloaded": "ダウンロード済",
    "download_search_box.search": "検索",
    "download_search_box.ok": "OK",
    "download_search_box.brand_name": "AIRead on Cloud",
    "download_search_box.from_time_greater_to_time": "終了日が開始日より大きいです",
    "download_search_box.all_definition_file": "【選択してください】",
    "download_search_box.team_name": "【チーム名】",
    "download_search_box.user_id": "【作成者】"
  }
}