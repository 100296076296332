import { ApiService } from "core/services";

class IndividualSettingApi extends ApiService {
    // Get individual setting
    getSetting() {
        return this.get("individual-setting");
    }

    // Update individual setting
    setSetting(payload) {
        return this.put("individual-setting", payload);
    }
}

export default IndividualSettingApi;