import React, { Component } from "react";
import classnames from "classnames";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import menus from "./menus";
import { IconFa } from "components";
import URL_CONFIG from '../../core/services/config'
import "./style.scss";

@inject("commonStore")
@observer
class MainSidebar extends Component {
  componentDidMount() {
    const { history, location, commonStore: {
      hasPermission
    }, } = this.props;
    if (menus.filter(e => e.path === location.pathname)[0] && !hasPermission(menus.filter(e => e.path === location.pathname)[0]?.permission)) {
      history.push("/");
    }
  }
  onClickMenu = (local, path) => {
    this.setState({
      active: local
    });
  };
  render() {
    const { t, location, commonStore: {
      hasPermission,
      userStore: { userInfo }
    }, } = this.props;
    return (
      <aside className="main-sidebar">
        <section className="sidebar">
          <div className="user-panel">
            <div className="pull-left image">
              <img
                src={userInfo?.avatar ? userInfo?.avatar : URL_CONFIG.default_avatar}
                className="img-circle"
                alt="User"
              />
            </div>
            <div className="pull-left info">
              <p className="user-name">{userInfo?.userName}</p>
            </div>
          </div>
        </section>
        <ul className="sidebar-menu tree" data-widget="tree">
          {menus.map(menu => {
            const { key, path, name, permission } = menu;
            return (hasPermission(permission) &&
              <li className={classnames({ active: location.pathname === path })} key={key}>
                <Link to={path} onClick={() => this.onClickMenu(key, path)}><IconFa type={key} /><span>{t(name, name)}</span></Link>
              </li>
            );
          })}
        </ul>
      </aside>
    );
  }
}

export default withRouter(withTranslation()(MainSidebar));
