import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { SwitchLanguages } from "../../components";
import classnames from "classnames";
import "./style.scss";

/**
 * Component for showing login page.
 * 
 * @component
 */
@inject("authStore")
@observer
class ScreenLogin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      password: "",
      errors: {
        userId: false,
        password: false,
        hasError: () => {
          return (this.state?.errors?.userId || this.state?.errors?.password)
        }
      },
    };
  }
  /**
   * onSubmit
   * handle login button
   * 
   * @returns null
   */
  onSubmit = () => {
    const { userId, password, errors } = this.state;
    this.checkValidateRequired("userId", userId)
    this.checkValidateRequired("password", password)
    if (userId && password && !errors.hasError()) {
      const { authStore, history, t } = this.props;
      authStore.signIn({ userId, password }, t).then(success => {
        if (success) {
          const fromPath = this.props.location.state?.from;
          if (fromPath && fromPath !== "/" ) {
            history.push(fromPath);
          } else {
            history.push("/");
          }
        }
      });
    }
  };

  /**
   * onChange
   * handle user/ password changed and auto run validation
   * 
   * @param e {Object} : change event
   * @returns  null
   */
  onChange = e => {
    e.persist();
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value?.trim()
      },
      this.checkValidateRequired(name, value?.trim())
    );
  };

  /**
   * checkValidateRequired
   * validation for userid and password
   * 
   * @param {string} name: name of the field
   * @param {string} value: value of the field
   * @returns  null
   */
  checkValidateRequired = (name, value) => {
    let errors = this.state.errors;
    if (!value && name) {
      errors[name] = true;

    } else {
      errors[name] = false;
    }
    this.setState({
      errors
    });
  };

  componentDidMount() {
    const { authStore } = this.props;
    authStore.getSystemStatus();
  }

  onMyFrameLoad = (e) => {
    var iframe = document.getElementById('my-iframe');
    var loginFrame = document.getElementsByClassName('login-page')[0];
    if (iframe.innerHTML) {
      // get and check the Title (and H tags if you want)
      var ifTitle = iframe.contentDocument.title;
      if (ifTitle.indexOf("404") >= 0) {
        // we have a winner! probably a 404 page!
      }
    } else {
      iframe.className = "frame-visible";
      loginFrame.className = "login-page";
    }
  }
  onMyFrameError = (e) => {
    console.log("error");
  }

  /**
   * render
   * 
   * @return  {Component} 
   */
  render() {
    const { t, authStore: { dataUrl } } = this.props;
    const { userId, password, errors } = this.state;
    const readyUrl = dataUrl && (dataUrl.indexOf("http://") > -1 || dataUrl.indexOf("https://") > -1);
    return <div className="login-page-wrapper">
      <div className={"login-page full-width"}>
        <div className="login-box">
          <div className="login-logo">
            <img style={{width: "100%"}} src="/logo.png" alt="logo"></img>
          </div>
          <div className="login-box-body">
            <p style={{color: "#3C3C3C"}} className="login-box-msg">
              {t("login.title")}
            </p>
            <div
              className={classnames("form-group has-feedback", {
                error: errors["userId"]
              })}
            >
              <input
                name="userId"
                type="avatar"
                className="form-control"
                placeholder={t('login.userId.placeHolder')}
                value={userId}
                onChange={this.onChange}
              />
              {/* <span className="glyphicon glyphicon-user form-control-feedback"></span> */}
            </div>
            <div
              className={classnames("form-group has-feedback", {
                error: errors["password"]
              })}
            >
              <input
                name="password"
                type="password"
                className="form-control"
                placeholder={t("login.password.placeHolder")}
                value={password}
                onChange={this.onChange}
              />
              {/* <span className="glyphicon glyphicon-lock form-control-feedback"></span> */}
            </div>
            <div
              className={classnames("color-red", {
                hidden: !errors.hasError()
              })}
            >
              {t("login.fields.required")}
            </div>
            <div className="row text-center">
              <button
                className="btn btn-primary"
                onClick={this.onSubmit}
                disabled={errors.hasError()}
              >
                {t("login.signin")}
              </button>
            </div>
          </div>
        </div>
        <div className="main-footer lang-switch-footer">
          <SwitchLanguages mode={0} />
        </div>
      </div>
      {readyUrl && <iframe id="my-iframe" title="pdf-frame" src={dataUrl} onLoad={this.onMyFrameLoad} onError={this.onMyFrameError} className="frame-none"></iframe>}
    </div>
  }
}

export default withTranslation()(ScreenLogin);
