const getYears = initYear => {
  const now = initYear || new Date().getUTCFullYear();
  // return Array(now - (now - 6)).fill('').map((v, idx) => now + idx);
  return [2020, 2021, 2022, 2023, 2024, 2025];
};

const getMonths = () => {
  return Array(12).fill('').map((v, idx) => idx + 1);
};

const calcConfigLevel = (threshold_high, threshold_low, value) => {
  let level = "";
  if (value < threshold_low) {
    level = "low"
  }
  if (threshold_low <= value && value < threshold_high) {
    level = "mid"
  }
  if (value >= threshold_high) {
    level = "high"
  }
  if (threshold_high === value && value === threshold_low) {
    level = "high"
  }
  return level;
}

export default { getYears, getMonths, calcConfigLevel };