import { ApiService } from "core/services";

class FileApi extends ApiService {


  /**
   * upload
   * upload a file to server
   * 
   * @param {File} file : upload file
   * @returns  boolean
   */
  uploadFile(file, id) {
    //convert file to form data
    let formData = new FormData();
    formData.append("file", file);
    formData.append("upload_id", 1);
    return this.upload("file-upload", formData);
  }

  /**
   * deleteFile
   * delete document
   * 
   * @returns  boolean
   */
  deleteFile() {
    return this.delete("file-upload");
  }
  /**
   * execute
   * execute document
   * 
   * @param {Object} payload : execute data
   * @returns  boolean
   */
  executeFile(payload = null) {
    return this.post("execute-airead", payload);
  }

  deleteUploadedFile(payload) {
    return this.delete("file-upload/" + payload.filename + "/" + payload.timestamp);
  }
}

export default FileApi
