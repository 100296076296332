import { ApiService } from "core/services";

class DocumentsAPI extends ApiService {
  constructor() {
    super("documents");
  }

  /**
   * getDocuments
   * prepare to search
   *
   * @param {Object} payload : filter conditions
   * @returns  boolean
   */
  getDocuments(payload = null) {
    return this.get("", payload);
  }

  /**
   * update
   * update document
   *
   * @param {String} id :id of document
   * @param {Object} payload : udpate data
   * @returns  boolean
   */
  update(id, payload = null) {
    return this.put(id, payload);
  }

  /**
   * confirm
   * save and confirm document
   *
   * @param {String} id :id of document
   * @param {Object} payload : udpate data
   * @returns  boolean
   */
  confirm(id, payload = null) {
    return this.put(`confirm/${id}`, payload);
  }

  /**
   * unconfirm
   * unconfirm document
   *
   * @param {String} id :id of document
   * @returns  boolean
   */
  unconfirm(id) {
    return this.put(`unconfirm/${id}`);
  }

  /**
   * getOneDocument
   *
   * @param {String} id : filter conditions
   * @returns  boolean
   */
  getOneDocument(id) {
    return this.get(id);
  }

  /**
   * downloadDocuments
   * prepare to download
   *
   * @param {Object} payload : conditions
   * @returns  boolean
   */
  downloadDocuments(payload) {
    return this.get("download", payload);
  }

  /**
 * downloadReadingResults
 *
 * @param {Object} payload : conditions
 * @returns  boolean
 */
  downloadReadingResults(payload) {
    return this.get("download/blob", payload);
  }

  /**
   * deleteDocuments
   * prepare to delete
   *
   * @param {Object} payload : conditions
   * @returns  boolean
   */
  deleteDocuments(payload) {
    let queryString = this.buildQueryString(payload);
    return this.delete("?" + queryString);
  }
}

export default DocumentsAPI;
