import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { DATE_FORMAT } from "../../core/utils/constants"
import "./style.scss";
@inject("commonStore")
@observer
class SearchBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            registeredDate: moment().format(DATE_FORMAT.frontend_minus),
            toDate: moment().format(DATE_FORMAT.frontend_minus)
        };
    }
    onChange = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    /**
     * render
     * render search box
     * 
     */
    render() {
        const {
            t,
            openModal,
            openUserModal,
            commonStore: {
                userStore: { userInfo }
            }
        } = this.props;
        return (
            <div className="admin-header row">
                {/* Information */}
                <div className="col-sm-9">
                    <div className="row">
                        <div className="col-sm-4 font-bold">{t("admin.contractor")}</div>
                        <div className="col-sm-8">{userInfo.tenantName}</div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 font-bold">{t("search_box.num_contract_account")}</div>
                        <div className="col-sm-8">
                            {userInfo.contractAccount}{" "}
                            <span>
                                {userInfo &&
                                userInfo.contractAccount &&
                                userInfo.contractAccount > 1
                                    ? t("search_box.accounts")
                                    : t("search_box.account")}
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 font-bold">
                            {t("search_box.number_of_contracts")}
                        </div>
                        <div className="col-sm-8">
                            {userInfo.contractAmount}{" "}

                            <span>
                                {userInfo &&
                                userInfo.contractAmount &&
                                userInfo.contractAmount > 1
                                    ? t("search_box.sheets")
                                    : t("search_box.sheet")}
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 font-bold">
                            {t("search_box.use_this_month")}
                        </div>
                        <div className="col-sm-8">
                            {userInfo.useAmount}{" "}
                            <span>
                                {userInfo &&
                                userInfo.useAmount &&
                                userInfo.useAmount > 1
                                    ? t("search_box.sheets")
                                    : t("search_box.sheet")}
                            </span>
                        </div>
                    </div>
                </div>

                {/* Action button */}
                <div className="col-sm-3">
                    <div className="row">
                        <button
                            className="btn btn-primary f-r mb-3 mr-3"
                            type="button"
                            onClick={() => openUserModal("add")}
                        >
                            {t("search_box.create_user_btn")}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(SearchBox);
