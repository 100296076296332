import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

class PageNotFound extends Component {

    render() {
        const { t } = this.props;
        return (
            <section className="content">
                <div className="error-page">
                    <h2 className="headline text-yellow"> 404</h2>
                    <div className="error-content">
                        <h3><i className="fa fa-warning text-yellow"></i> {t('staticpage.notfound.title')}</h3>
                        <p>{t('staticpage.notfound.description')}
                            <a href="/">{t('staticpage.notfound.return')}</a>
                        </p>

                    </div>
                </div>
            </section>
        );
    }
}

export default withRouter(withTranslation()(PageNotFound));
