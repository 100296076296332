import { observable, runInAction, configure } from "mobx";
import { SystemSettingApi } from "apis";
import ApiStore from "./ApiStore";
import BaseStore from "./BaseStore";

configure({ enforceActions: "observed" });

class SystemStore extends BaseStore {
    constructor(props) {
        super(props);
        this.api = new SystemSettingApi();
    }

    getSettingOverviewMng = async () => {
        let response = await ApiStore.call(this.api, this.api.getSettingOverviewMng);
        return response;
    }

    setSettingOverviewMng = async (payload) => {
        let result = await ApiStore.call(this.api, this.api.setSettingOverviewMng, payload);
        return result;
    }
}

export default SystemStore;