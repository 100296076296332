import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { InputField, ModalFooter } from "components";
import { Scrollbars } from "react-custom-scrollbars";
import { Validate } from "core/utils";
import "./style.scss";

@inject("modalStore")
@observer
class SettingAdmin extends Component {
  constructor(props) {
    super(props);
    const { adminDetail } = this.props;
    this.state = {
      editablePass: false,
      adminDetail,
      error: {}
    };
  }

  /**
   * onChange
   * listen change
   * 
   */
  onChange = (name, value, messageError) => {
    const { adminDetail, error } = this.state;
    adminDetail[name] = value;
    if (!messageError || error[name] !== messageError) {
      error[name] = "";
    }
    this.setState({ adminDetail, error });
    const { onChange } = this.props;
    onChange(adminDetail);
  };

  /**
   * onConfirm
   * confirm edit admin user information
   * 
   */
  onConfirm = () => {
    const { adminDetail, editablePass } = this.state;
    let err = true;
    const { t } = this.props;
    let error = {};
    const checkTenant = Validate.validateNotNull(adminDetail.tenantName, t);
    const checkEmail = Validate.validateEmail(adminDetail.email, t);
    if (checkTenant) {
      err = false;
      error.tenantName = checkTenant;
    }
    if (adminDetail && adminDetail.email && checkEmail) {
      err = false;
      error.email = checkEmail;
    }
    if (editablePass) {
      const checkPassword = Validate.validatePassword(
        adminDetail.password,
        t
      );
      const checkConfirmPassword = Validate.validateConfirmPass(
        adminDetail.confirmPassword,
        t,
        adminDetail.password
      );
      if (checkPassword) {
        err = false;
        error.password = checkPassword;
      }
      if (checkConfirmPassword) {
        err = false;
        error.confirmPassword = checkConfirmPassword;
      }
    }
    if (err) {
      this.openComfirmModal();
    }
    this.setState({ error });
  };

  onEditablePass = (value) => {
    const { adminDetail } = this.state;
    delete adminDetail["password"];
    delete adminDetail["confirmPassword"];
    this.setState({
      editablePass: value,
      error: {}
    });
    const { onChange } = this.props;
    onChange(adminDetail);
  };

  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hide();
  };

  /**
   * openComfirmModal
   * show confirmation modal
   * 
   */
  openComfirmModal = () => {
    const { modalStore, onConfirm, t } = this.props;
    const { adminDetail } = this.state;
    modalStore.show({
      id: "confirm",
      isOpen: true,
      header: t("admin.update_confirm"),
      onCancel: () => this.closeModal("confirm"),
      children: <div className="text-c">
        <div key={"modal-body"} className="modal-body">
          <span>
            {t("admin.change_information")}
          </span>
        </div>
        <ModalFooter key={"modal-footer"}
          saveButtonText={t("admin.save")}
          onConfirm={() => {
            this.closeModal();
            onConfirm(adminDetail);
          }}
          cancelButtonText={t("admin.cancel")}
          onCancel={this.closeModal}
        />
      </div>,
      type: "small"
    });
  };

  /**
   * render
   * render admin edit screen
   * 
   */
  render() {
    const { t, saveButtonText } = this.props;
    const { adminDetail, error } = this.state;

    return <div className="setting-admin">
      <Scrollbars key={"modal-body"} className="modal-body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-4 font-bold">
            <span className="color-red">*</span>
            {t("admin.required")}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-4 font-bold">
            {`${t("admin.admin")} ID`}
          </div>
          <div className="col-sm-6">
            <span>{adminDetail.userId}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-1 p-0"></div>
          <div className="col-sm-4 font-bold">
            {t("admin.contractor_name")}
            <span className="color-red">*</span>
          </div>
          <div className="col-sm-6">
            <InputField
              name="userName"
              value={adminDetail.userName}
              onChange={this.onChange}
              type="text"
              placeholder={t("admin.contractor_name")}
              maxLength={255}
              validate={Validate.validateNotNull}
              error={error.userName}
            />
          </div>
        </div>
      </Scrollbars>
      <ModalFooter key={"modal-footer"}
        saveButtonText={saveButtonText}
        onConfirm={this.onConfirm}
      />
    </div>
  }
}

export default withTranslation()(SettingAdmin);
