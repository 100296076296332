import { action, observable, runInAction, configure } from "mobx";
import { CommonApi, TemplateSortingApi, FileApi } from "apis";
import { ReactNotifications } from "core/logic";
import { SYSTEM_PATH, TEMPLATE_SORTING_CLASSIFICATION_TYPE } from "../core/utils/constants";
import i18n from "../i18n";
import ApiStore from "./ApiStore";
import CONFIG from "../core/services/config";
import BaseStore from "./BaseStore";

configure({ enforceActions: "observed" });

class TemplateSortingStore extends BaseStore {
  @observable templateSortings = [];
  //@observable templateSortingsForUpload = [];
  @observable templateSorting = {};
  @observable templateSortingFormMap = {};
  @observable listGroupDefinitions = [];
  @observable selectedGroupId = -1;
  @observable currentConfigFile = null;
  @observable currentUploadFile = null;
  @observable uploadingFiles = [];
  @observable fileIndex = 0;

  constructor(props) {
    super(props);
    this.api = new TemplateSortingApi();
    this.commonApi = new CommonApi();
    this.fileApi = new FileApi();
  }

  @action
  async getTemplateSortings() {
    const { size, page, sortDir, sortKey } = this.paging;
    let payload = { size, page, sortDir, sortKey };
    let response = await ApiStore.call(this.api, this.api.getAll, payload);
    if (response) {
      runInAction(() => {
        this.updatePagingFiler(response.paging);
        this.templateSortings = response.elements;
        if (this.paging?.totalRecord && this.paging?.totalRecord !== 0) {
          if (this.paging?.page > 1 && (!response.elements || response.elements.length === 0)) {
            this.updatePagingFiler({ page: this.paging.page - 1 });
            this.getTemplateSortings();
          }
        }
      })
    }
  }

  /*
  @action
  async getTemplateSortingsForUpload() {
    const { size, page, sortDir, sortKey } = this.paging;
    let payload = { size, page, sortDir, sortKey };
    let response = await ApiStore.call(this.api, this.api.getAll, payload);
    if (response) {
      runInAction(() => {
        this.templateSortingsForUpload = response.elements;
      })
    }
  }
  */

  @action
  async addTemplateSorting(payload, cb) {
    let response = await ApiStore.call(this.api, this.api.addTemplateSorting, payload);
    if (response) {
      runInAction(() => {
        cb && cb(response.id);
      })
    }
  }

  @action
  async deleteTemplateSorting(id, cb) {
    let resp = await ApiStore.call(this.api, this.api.deleteTemplateSorting, id);
    if (resp) {
      runInAction(() => {
        cb && cb();
      });
    }
  }

  @action
  async getTemplateSortingById(id) {
    const { size, page, sortDir, sortKey } = this.paging;
    let payload = {};
    payload["pageInfo"] = { size, page, sortDir, sortKey };
    payload["id"] = id;
    let response = await ApiStore.call(this.api, this.api.getTemplateSortingById, payload);
    if (response) {
      runInAction(() => {
        //this.updatePagingFiler(response.pages.paging);
        this.templateSorting.id = response.id;
        this.templateSorting.settingName = response.settingName;
        this.templateSorting.classificationType = response.classificationType;
        this.templateSorting.maxFeatures = response.maxFeatures;
        this.templateSorting.matchCtTh = response.matchCtTh;
        this.templateSorting.sizeDiffTh = response.sizeDiffTh;
        this.templateSorting.keywordRect = response.keywordRect;
        this.templateSortingFormMap = response.formMap;
        /*if (this.paging?.totalRecord && this.paging?.totalRecord !== 0) {
          if (this.paging?.page > 1 && (!response.pages.elements || response.pages.elements.length === 0)) {
            this.updatePagingFiler({ page: this.paging.page - 1 });
            this.getTemplateSortingById(id);
          }
        }*/
      })
    }
  }

  @action
  async updateTemplateSortingSetting(payload, cb) {
    let response = await ApiStore.call(this.api, this.api.updateTemplateSorting, payload);
    if (response) {
      runInAction(() => {
        this.templateSorting.id = response.id;
        this.templateSorting.settingName = response.settingName;
        this.templateSorting.classificationType = response.classificationType;
        this.templateSorting.maxFeatures = response.maxFeatures;
        this.templateSorting.matchCtTh = response.matchCtTh;
        this.templateSorting.sizeDiffTh = response.sizeDiffTh;
        this.templateSorting.keywordRect = response.keywordRect;
        cb && cb();
      });
    }
  }

  @action
  async getDefinitationsGroup() {
    let groups = await ApiStore.newCall(
      this.commonApi,
      this.commonApi.getDefinitationsGroup
    );
    if (groups) {
      runInAction(() => {
        if (groups) {
          this.listGroupDefinitions = groups.filter(f => f.edition == 'Manual' || f.edition == 'Rule');
        }
      });
    }
  }

  @action
  async getTemplateSortingFormGroupById(id, cb) {
    this.selectedGroupId = -1;
    let response = await ApiStore.call(this.api, this.api.getTemplateSortingFormGroupById, id);
    if (response) {
      runInAction(() => {
        if (0 < response.length) {
          this.selectedGroupId = parseInt(response[0].formGroupId);
        }
        cb && cb(id);
      });
    }
  }

  @action
  async setSelectedGroupId(newValue) {
    this.selectedGroupId = newValue;
  }

  @action
  async setCurrentConfigFile(configfilesListsId) {
    let formList = this.templateSortingFormMap[this.selectedGroupId];
    if (formList) {
      for (let i = 0; i < formList.length; i++) {
        let elm = formList[i];
        if (elm && elm.configFile.id === configfilesListsId) {
          this.currentConfigFile = elm;
          break;
        }
      }
      /*formList.forEach((element) => {
        if (element && element.configFile.id === configfilesListsId) {
          this.currentConfigFile = element;
        }
      });*/
    }
  }

  @action
  async getTemplateSortingTemplatesById(configfilesListsId, cb) {
    let response = await ApiStore.call(this.api, this.api.getTemplateSortingTemplatesById, configfilesListsId);
    if (response) {
      runInAction(() => {
        //this.templateSortingTemplates = response;
        this.uploadingFiles = response;
        this.uploadingFiles.forEach(e => {
          this.fileIndex = e.id;
        });
        cb && cb(configfilesListsId);
      });
    }
  }

  @action setCurrentUploadFile = (id) => {
    let file = null;
    this.uploadingFiles.forEach(e => {
      if (e.id === id) file = e;
    });
    this.currentUploadFile = {
      ...this.currentUploadFile,
      id: file?.id,
      configfilesListsId: file?.configfilesListsId,
      templateDirPath: file?.templateDirPath,
      imageType: -1,
      fileType: file?.fileType,
      fileSize: file?.fileSize,
      templateOriginalFileName: file?.templateOriginalFileName,
      templateDisplayFileName: file?.templateDisplayFileName,
      templateCacheFileName: file?.templateCacheFileName,
      updatedJuserSequence: file?.updatedJuserSequence,
      createdAt: file?.createdAt,
      updatedAt: file?.updatedAt,
      timestamp: file?.timestamp,
      status: file?.status,
      strFileSize: file?.strFileSize,
      link: CONFIG.file_domain + encodeURIComponent(file?.templateDisplayFileName)
    };
    this.checkReadyExecute();
  }

  @action checkReadyExecute = () => {
    let canExecute = true;
    this.uploadingFiles.forEach(e => {
      if (e.status === -1) canExecute = false;
    });
    if (this.uploadingFiles.length === 0) canExecute = false;
    this.currentUploadFile = {
      ...this.currentUploadFile,
      readyToExecute: canExecute,
    };
  }

  @action
  async uploadDocument(file) {
    this.checkReadyExecute();
    file.forEach(e => {
      if (!e.existsSameFileName) {
        let fileIndex = e.upload_id;
        ApiStore.call(
          this.fileApi,
          this.fileApi.uploadFile,
          [e.data, e.upload_id],
          true,
          null,
          true
        ).then(response => {
          if (response.fileName) {
            runInAction(() => {
              const completedFile = response;
              this.updateFileComplete(completedFile, fileIndex);
              this.setCurrentUploadFile(fileIndex);
              this.checkReadyExecute();
            });
          } else {
            ReactNotifications("error", i18n.t("api.response.no_network"), "");
            this.removeFileByIndex(fileIndex);
          }
        }).catch((err) => {
          if (err?.status) {
            if (err?.status === 413) {
              ReactNotifications("error", i18n.t("upload.file_size_over"), "");
            } else {
              let exception = err.json();
              try {
                exception.then((error) => {
                  if (error?.message) {
                    ReactNotifications("error", error?.message, "");
                  } else {
                    ReactNotifications(
                      "error",
                      i18n.t("api.response.no_message"),
                      ""
                    );
                  }
                });
              } catch {
                ReactNotifications("error", i18n.t("api.response.no_network"), "");
              }
            }
            this.removeFileByIndex(fileIndex);
          } else {
            ReactNotifications("error", i18n.t("api.response.no_network"), "");
            this.removeFileByIndex(fileIndex);
          }
        })
      }
    });
  }

  @action checkExistsSameFileName = (fileName) => {
    let existsSameFileName = false;
    this.uploadingFiles.forEach(e => {
      if (e.templateOriginalFileName === fileName) existsSameFileName = true;
    });
    if (existsSameFileName) {
      ReactNotifications("error", i18n.t("template_sorting.upload.exists_same_file_name"), "");
    }
    return existsSameFileName;
  }

  @action addUpdateFile = (fileData) => {
    let newFile = {
      id: fileData?.upload_id,
      configfilesListsId: -1,
      templateDirPath: null,
      imageType: null,
      fileType: null,
      fileSize: fileData?.data?.size,
      templateOriginalFileName: fileData?.data?.name,
      templateDisplayFileName: null,
      templateCacheFileName: null,
      updatedJuserSequence: -1,
      createdAt: null,
      updatedAt: null,
      timestamp: null,
      status: -1,
      strFileSize: null,
    }
    this.uploadingFiles.push(newFile);
  }

  @action updateFileComplete = (fileData, index) => {
    const newFiles = this.uploadingFiles.map(e => {
      if (e.id === index) {
        return {
          id: e?.id,
          configfilesListsId: -1,
          templateDirPath: null,
          imageType: null,
          fileType: fileData?.fileType,
          fileSize: e?.fileSize,
          templateOriginalFileName: e?.templateOriginalFileName,
          templateDisplayFileName: fileData?.previewFile + fileData?.previewFilename,
          templateCacheFileName: null,
          updatedJuserSequence: -1,
          createdAt: null,
          updatedAt: null,
          timestamp: fileData?.timestamp,
          status: 0,
          strFileSize: fileData?.fileSize,
        }
      } else {
        return e;
      }
    });
    this.uploadingFiles = newFiles;

    /*
    let newFile = {
      id: fileData?.upload_id,
      configfilesListsId: -1,
      templateDirPath: null,
      imageType: fileData?.fileType,
      fileType: fileData?.fileType,
      fileSize: -1,
      templateOriginalFileName: fileData?.fileName,
      templateDisplayFileName: fileData?.previewFile + fileData?.previewFilename,
      templateCacheFileName: null,
      updatedJuserSequence: -1,
      createdAt: null,
      updatedAt: null,
      timestamp: fileData?.timestamp,
      status: 0,
      strFileSize: fileData?.fileSize,
    }
    this.uploadingFiles.push(newFile);
    */
  }

  @action removeFileByIndex = (index) => {
    const newFiles = this.uploadingFiles.filter(e => {
      return (e.upload_id !== index);
    });
    this.uploadingFiles = newFiles;
    this.checkReadyExecute();
  }

  @action
  cleanCurrentFile() {
    this.currentUploadFile = null;
    this.uploadingFiles = [];
    this.fileIndex = 0;
  }

  @action
  cleanCurrentForm() {
    this.currentConfigFile = null;
    this.templateSortingFormMap = {};
    this.listGroupDefinitions = [];
    this.selectedGroupId = -1;
  }

  @action
  cleanAll() {
    this.cleanCurrentFile();
    this.cleanCurrentForm();
    this.templateSortings = [];
    this.templateSorting = {};
  }

  async deleteUploadFile() {
    if (this.uploadingFiles && 0 < this.uploadingFiles.length) {
      let response = await ApiStore.call(this.fileApi, this.fileApi.deleteFile);
      if (response) {
        this.cleanCurrentFile();
      }
    }
  }

  //  async deleteUploadedFile(fileName, timestamp) {
  async deleteUploadedFile(fileData) {
    const fileName = encodeURIComponent(fileData.templateOriginalFileName);
    const timestamp = fileData.timestamp;
    if (fileData.status === 0) {
      await ApiStore.call(this.fileApi, this.fileApi.deleteUploadedFile, { filename: fileName, timestamp: timestamp });
    } else if (fileData.status === 1) {
      await ApiStore.call(this.api, this.api.deleteTemplates, fileData.id);
    }
    runInAction(() => {
      this.uploadingFiles = this.uploadingFiles.filter(e => !(encodeURIComponent(e.templateOriginalFileName) === fileName && e.timestamp === timestamp));
      if (encodeURIComponent(this.currentUploadFile?.templateOriginalFileName) === fileName && this.currentUploadFile?.timestamp === timestamp) this.currentUploadFile = null;
      this.checkReadyExecute();
    });
  }

  @action
  updateFileContent(content) {
    if ((this.uploadingFiles.length + content.length) <= 5) {
      runInAction(() => {
        const fileArray = content.map((e) => {
          this.fileIndex++;
          return { data: e, upload_id: this.fileIndex, existsSameFileName: this.checkExistsSameFileName(e.name) };
        });
        fileArray.forEach(e => {
          if (!e.existsSameFileName) this.addUpdateFile(e);
        });
        this.uploadDocument(fileArray);
        //this.uploadingFiles = [...this.uploadingFiles, ...fileArray];
        /*this.currentUploadFile = {
          ...this.currentUploadFile,
          content: fileArray,
        };*/
      });
      return true;
    } else {
      ReactNotifications("error", i18n.t("template_sorting.upload.cant_upload_5"), "Upload");
      return false
    }
  }

  @action
  async registTemplates(configfilesListsId, cb) {
    const payload = {
      configfilesListsId: configfilesListsId,
      files: this.uploadingFiles.map(e => {
        return {
          "id": e.id,
          "fileSize": e.fileSize,
          "templateOriginalFileName": e.templateOriginalFileName,
          "templateDisplayFileName": e.templateDisplayFileName,
          "timestamp": e.timestamp,
          "status": e.status,
        }
      }),
    };
    let response = await ApiStore.call(this.api, this.api.executeRegistTemplates, payload);
    if (response) {
      runInAction(() => {
        this.cleanCurrentFile();
        cb && cb(configfilesListsId);
      });
    }
  }

  @action
  async updateTemplateSortingForms(id, checkedItemsMap, keywords, cb) {
    var forms = new Array();
    checkedItemsMap.forEach((value, key) => {
      forms.push(key);
    });
    var keywordsList = new Array();
    forms.forEach((id) => {
      keywordsList.push(keywords.get(id));
    });
    let selectedEdition = "";
    this.listGroupDefinitions.map((group) => {
      if (parseInt(group.id) === this.selectedGroupId) {
        selectedEdition = group.edition;
      }
    });
    const payload = {
      templateSortingId: id,
      formGroupId: this.selectedGroupId,
      edition: selectedEdition,
      configfilesListsIdList: forms,
      keywordsList: keywordsList,
    };
    let response = await ApiStore.call(this.api, this.api.updateTemplateSortingFormGroup, payload);
    if (response) {
      runInAction(() => {
        cb && cb(response);
      });
    }
  }


















  @observable templateSortingFormGroups = [];

  //@observable templateSorting = {};
  /*@observable templateSorting = {
    id: -1,
    settingName: "",
    settingBody: {
      maxFeatures: 2000,
      matchCtTh: 50,
      sizeDiffTh: -1,
    }
  };*/
  //@observable templateSortingDetail = {
  //  id: -1,
  //  settingName: "",
  //  settingBody: {
  //    maxFeatures: 2000,
  //    matchCtTh: 50,
  //    sizeDiffTh: -1,
  //  }
  //};
  //@observable templateSortingName = "";
  //@observable teamUsers = [];
  //@observable usersNotInTeam = [];
  //@observable teamsOfUser = [];
  @observable keepFilterState = false;

  @observable templateSortingSettingDefault = {
    id: -1,
    settingName: "",
    settingBody: {
      classificationType: TEMPLATE_SORTING_CLASSIFICATION_TYPE.IMAGE,
      maxFeatures: 2000,
      matchCtTh: 50,
      sizeDiffTh: -1,
      keywordRect: "",
    }
  }

  /*
  @action
  async getTemplateSortingById(id) {
    const { size, page, sortDir, sortKey } = this.paging;
    let payload = {};
    payload["pageInfo"] = { size, page, sortDir, sortKey };
    payload["id"] = id;
    let response = await ApiStore.call(this.api, this.api.getTemplateSortingById, payload);
    if (response) {
      runInAction(() => {
        //this.updatePagingFiler(response.pages.paging);
        response.settingBody = JSON.parse(response.settingBody);
        //this.templateSortingDetail = response;
        this.templateSorting = response;
      })
    }
  }
  */

  /*
  @action
  async getTemplateSortingFormGroupById(id, cb) {
    let resp = await ApiStore.call(this.api, this.api.getTemplateSortingFormGroupById, id);
    if (resp) {
      runInAction(() => {
        this.templateSortingFormGroups = resp;
        cb && cb(id);
      });
    }
  }
  */

  @action
  async getTemplateSortingTemplatesByFormGoupId(id, cb) {
    let resp = await ApiStore.call(this.api, this.api.getTemplateSortingTemplatesByFormGoupId, id);
    if (resp) {
      runInAction(() => {
        this.templateSortingTemplates = new Map(Object.entries(resp));
        cb && cb(id);
      });
    }
  }

  @action
  async updateTemplateSorting(payload, cb) {
    let response = await ApiStore.call(this.api, this.api.updateTemplateSorting, payload);
    if (response) {
      runInAction(() => {
        response.setting_body = JSON.parse(response.setting_body);
        //this.templateSortingDetail = response;
        this.templateSorting.id = response.id;
        this.templateSorting.settingName = response.setting_name;
        this.templateSorting.settingBody = response.setting_body;
        cb && cb(this.templateSorting);
      });
    }
  }

  /*   async getUsersNotInTeam(payload) {
    const { size, page, sortDir, sortKey } = this.paging;
    const { userId, userName } = payload;
    payload["pageInfo"] = { size, page, sortDir, sortKey, userId, userName };
    let response = await ApiStore.call(this.api, this.api.getUsersNotInTeam, payload);
    if (response) {
      runInAction(() => {
        this.updatePagingFiler(response.pages.paging);
        this.usersNotInTeam = response.pages.elements;
        if (this.paging?.totalRecord && this.paging?.totalRecord !== 0) {
          if (this.paging?.page > 1 && (!response.pages.elements || response.pages.elements.length === 0)) {
            this.updatePagingFiler({ page: this.paging.page - 1 });
            this.getUsersNotInTeam(payload);
          }
        }
      })
    }
  }
 */
  /*   async addUsersForTeam(payload, cb) {
      let resp = await ApiStore.call(this.api, this.api.addUsersForTeam, payload);
      if (resp) {
        runInAction(() => {
          cb && cb();
        });
      }
    }
   */
  /*   async deleteUserForTeam(payload, cb) {
      let resp = await ApiStore.call(this.api, this.api.deleteUserForTeam, payload);
      if (resp) {
        runInAction(() => {
          cb && cb();
        });
      }
    }
   */
  /*   @action
    async getTeamOfUser() {
      let response = await ApiStore.call(this.api, this.api.getTeamOfUser);
      if (response) {
        runInAction(() => {
          response.sort((a, b) => (a.id > b.id) ? 1 : -1);
          this.teamsOfUser = response;
        })
      }
    }
   */
  @action
  setKeepFilterState = (value) => {
    this.keepFilterState = value;
  }

  @action
  clean() {
    if (!this.keepFilterState) {
      console.log("TemplateSortingStore -> clean -> this.keepFilterState", this.keepFilterState)
      super.clean();
    }
  }

  @action
  cleanTemplateSorting() {
    if (!this.keepFilterState) {
      this.templateSortings = [];
      this.templateSortingFormGroups = [];
      //this.templateSortingTemplates = [];
      this.templateSorting = {
        id: -1,
        settingName: "",
        settingBody: {
          classificationType: TEMPLATE_SORTING_CLASSIFICATION_TYPE.IMAGE,
          maxFeatures: 2000,
          matchCtTh: 50,
          sizeDiffTh: -1,
          keywordRect: "",
        }
      };
    }
  }

  @action
  convertTemplateSortingSetting = (settingBody) => {
    if (settingBody) {
      settingBody.classificationType = !this.isBlank(settingBody.classificationType) ? parseInt(settingBody.classificationType) : TEMPLATE_SORTING_CLASSIFICATION_TYPE.IMAGE;
      settingBody.maxFeatures = !this.isBlank(settingBody.maxFeatures) ? parseInt(settingBody.maxFeatures) : '';
      settingBody.matchCtTh = !this.isBlank(settingBody.matchCtTh) ? parseInt(settingBody.matchCtTh) : '';
      settingBody.sizeDiffTh = !this.isBlank(settingBody.sizeDiffTh) ? parseInt(settingBody.sizeDiffTh) : '';
      settingBody.keywordRect = !this.isBlank(settingBody.keywordRect) ? parseInt(settingBody.keywordRect) : '';
    }
    return settingBody;
  }

  @action
  async executeRegist(documentsStore, history, id, configId) {
    const payload = {
      templateSortingId: id,
      configId: configId,
      inputFiles: documentsStore.uploadingFiles.map(e => {
        return {
          "inputFileName": e.name,
          "type": e.type,
          "previewFilename": e.previewFilename,
          "previewFile": e.previewFile,
          "timestamp": e.timestamp,
        }
      }),
    };
    let response = await ApiStore.call(this.api, this.api.executeRegist, payload);
    if (response) {
    }
  }

  isBlank = (value) => {
    if (typeof (value) === "string") {
      value = value.trim();
    }
    if (value === null || value === undefined || value === "") {
      return true;
    }
    return false;
  }
}

export default TemplateSortingStore;