import { action, configure } from "mobx";
import BaseStore from "./BaseStore";
import i18n from "../i18n";
import { LANGUAGE_CODE } from "../core/utils/constants";

configure({ enforceActions: "observed" });

class SwitchLanguagesStore extends BaseStore {

    // Display language in the web page for user
    @action
    onSwitch = (key) => {
        switch(key) {
            case LANGUAGE_CODE.JP:
                key = 'jp';
                break;
            case LANGUAGE_CODE.EN:
                key = 'en';
                break;
            default:
                key = 'jp';
                break;
        }

        i18n.changeLanguage(key);
        localStorage.setItem("language", key);
    };
}

export default SwitchLanguagesStore;
