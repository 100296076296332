import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { DATE_FORMAT } from "../../core/utils/constants";
import { Helper } from "../../core/utils";
import "./style.scss";

@inject("commonStore")
@observer
class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registeredDate: moment().format(DATE_FORMAT.frontend_minus),
      toDate: moment().format(DATE_FORMAT.frontend_minus)
    };
  }

  onChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleKeyPressForNumber = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^\d*$/.test(keyValue)) {
      event.preventDefault();
    }
  }

  handleChangeYear = (e) => {
    const { commonStore: { userStore: { setYear } } } = this.props;
    const re = /^\d*$/;
    const val = e.currentTarget.value;
    if (re.test(val) && (val === "" || parseInt(val) <= 9999)) {
      setYear(val)
    }
  }

  handleUpdate = () => {
    const { commonStore: { userStore } } = this.props;
    userStore.getMonthlyAmount();
  }

  render() {
    const { t, commonStore: { userStore: { year, month, setMonth } }, className } = this.props;
    return <div className={className}>
      <label>{t("profile.period_of_use")}</label>
      <input className="input-number" type="number"
        max="9999"
        value={year}
        onKeyPress={this.handleKeyPressForNumber}
        onChange={(e) => { this.handleChangeYear(e) }} />
      <label>{t("profile.period_of_use_year")}</label>
      <select className="slct-month" onChange={(e) => setMonth(e.currentTarget.value)} value={month}>
        {Helper.getMonths().map(e => <option key={e} value={e}>{e}</option>)}
      </select>
      <label>{t("profile.period_of_use_month")}</label>
      <button
        className="download-btn-style btn btn-primary mb-3"
        type="button"
        onClick={this.handleUpdate} >
        {t("profile.period_of_use_update")}
      </button>
    </div>
  }
}

export default withTranslation()(SearchBox);
