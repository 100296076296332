import { ApiService } from "core/services";

class ActionLogsAPI extends ApiService {
  constructor() {
    super("actionlogs");
  }

  /**
   * getActionLogs
   * prepare to search
   *
   * @param {Object} payload : filter conditions
   * @returns  boolean
   */
  getActionLogs(payload = null) {
    return this.get("", payload);
  }

  /**
   * downloadActionLogs
   * prepare to download
   *
   * @param {Object} payload : filter conditions
   * @returns  boolean
   */
  downloadActionLogs(payload = null) {
    return this.get("download", payload);
  }
}

export default ActionLogsAPI;
