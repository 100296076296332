import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { ContainerBox, Table, ModalFooter } from 'components';
import { Validate } from "core/utils";
import { ReactNotifications } from "core/logic";
import BaseComponent from '../BaseComponent';
import Switch from 'react-switch';
import './style.scss';
import moment from 'moment';
import { MODAL_ID, DATE_FORMAT, NO_DATE } from '../../core/utils/constants';

const TABLE_ROW_WIDTH_IPADDRESS = {
  NO: "6%",
  IP_ADDRESS: "20%",
  COMMENT: "30%",
  CREATED_DATE: "18%",
  UPDATED_DATE: "18%"
};
@inject('commonStore', 'modalStore')
@observer
class GeneralSetting extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      threshold1: 0,
      threshold2: 0,
      apiKey: null,
      dataRetentionPeriod: 0,
      downloadFolderName: "<date><time>",
      downloadFileName: "<date><time>",
      restrictionIpAddress: 1,
      ipadresses: [],
    };
    this.restrictionIpAddress = {
      ipAddress: '',
      comment: '',
    };
    this.deletedIpAddresses = [];
  }

  executeLogicAfterRender = async () => {
    const { commonStore: { settingStore, settingStore: { getSetting } } } = this.props;
    await settingStore.cleanIpaddress();
    await settingStore.getIpAddresses();
    const data = await getSetting();
    this.setState({
      threshold1: data.confidence_threshold_1,
      threshold2: data.confidence_threshold_2,
      apiKey: data.apiToken,
      dataRetentionPeriod: data.data_retention_period || 0,
      downloadFolderName: data.download_folder_name,
      downloadFileName: data.download_file_name,
      restrictionIpAddress: data.restriction_ip_address,
      ipadresses: settingStore.ipadresses,
    });
  }

  executeOnDidUpdate = () => {
    // Attach tooltip
    window.$('[data-toggle="tooltip"]').tooltip('destroy');
    window.$('[data-toggle="tooltip"]').tooltip({ container: '.screen-general-setting' });
  }

  onChangeTheshold1 = (value) => {
    this.setState({ threshold1: value });
  }

  onChangeTheshold2 = (value) => {
    this.setState({ threshold2: value });
  }

  handleGenerate = async () => {
    const { commonStore: { settingStore: { generateKey } } } = this.props;
    const data = await generateKey();
    this.setState({
      apiKey: data.apiToken
    });
  }

  handleRemoveKey = () => {
    this.setState({ apiKey: "" });
  }

  onChangeDataRetentionPeriod = (value) => {
    this.setState({ dataRetentionPeriod: value });
  }

  onChangeDownloadFolderName = (value) => {
    this.setState({ downloadFolderName: value });
  }

  onChangeDownloadFileName = (value) => {
    this.setState({ downloadFileName: value });
  }

  onChangeRestrictionIpAddress = (value) => {
    if (value) {
      this.setState({ restrictionIpAddress: 0 });
    } else {
      this.setState({ restrictionIpAddress: 1 });
    }
  }

  handleSubmit = () => {
    const { t, commonStore: { settingStore, settingStore: { setSetting } } } = this.props;
    const { threshold1, threshold2, apiKey, dataRetentionPeriod, downloadFolderName, downloadFileName,
      restrictionIpAddress, ipadresses } = this.state;
    const payload = {
      confidence_threshold_1: threshold1,
      confidence_threshold_2: threshold2,
      apiToken: apiKey,
      data_retention_period: dataRetentionPeriod,
      download_folder_name: downloadFolderName,
      download_file_name: downloadFileName,
      restriction_ip_address: restrictionIpAddress
    };
    if (threshold2 && threshold2
      && !Validate.validateThreshold(threshold1, t)
      && !Validate.validateThreshold(threshold2, t)
      && threshold1 >= threshold2
      && !Validate.validateDataRetentionPeriod(dataRetentionPeriod, t)
      && !Validate.validateDownloadFolderName(downloadFolderName, t)
      && !Validate.validateDownloadFileName(downloadFileName, t)) {
      setSetting(payload);
    }

    settingStore.saveIpAddress(this.deletedIpAddresses, ipadresses, this.reflashRestrictionIpAddressTable);
  }

  handleCopy = (str) => {
    const { t } = this.props;
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    ReactNotifications("info", t("upload.copy"), "");
  }

  renderThresholdErr = (error) => {
    return <div className="row">
      <div className="col-sm-3"></div>
      <div className="col-sm-9 color-red" style={{ height: "40px", lineHeight: "40px" }}>{error}</div>
    </div>
  }

  handleKeyPressForNumber = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^\d*$/.test(keyValue)) {
      event.preventDefault();
    }
  }

  onFetch = (tableData) => {
    const { commonStore: { settingStore } } = this.props;
    settingStore.updatePagingFiler(tableData);
    this.state.ipadresses = this.sortIpAddresses(tableData);
    this.setState({
      ipadresses: this.state.ipadresses,
    });
  };

  sortIpAddresses = (tableData) => {
    let sortedIpAdresses = [];
    if (tableData.sortKey && tableData.sortKey != 'undefined') {
      switch (tableData.sortKey) {
        case 'ipAddress':
          if (tableData.sortDir == "ASC") {
            sortedIpAdresses = this.state.ipadresses.sort(function (a, b) {
              return (a.ip_address < b.ip_address) ? -1 : 1;
            });
          } else {
            sortedIpAdresses = this.state.ipadresses.sort(function (a, b) {
              return (a.ip_address > b.ip_address) ? -1 : 1;
            });
          }
          break;

        case 'comment':
          if (tableData.sortDir == "ASC") {
            sortedIpAdresses = this.state.ipadresses.sort(function (a, b) {
              return (a.comment < b.comment) ? -1 : 1;
            });
          } else {
            sortedIpAdresses = this.state.ipadresses.sort(function (a, b) {
              return (a.comment > b.comment) ? -1 : 1;
            });
          }
          break;

        case 'createdAt':
          if (tableData.sortDir == "ASC") {
            sortedIpAdresses = this.state.ipadresses.sort(function (a, b) {
              return (a.created_at < b.created_at) ? -1 : 1;
            });
          } else {
            sortedIpAdresses = this.state.ipadresses.sort(function (a, b) {
              return (a.created_at > b.created_at) ? -1 : 1;
            });
          }
          break;

        case 'updatedAt':
          if (tableData.sortDir == "ASC") {
            sortedIpAdresses = this.state.ipadresses.sort(function (a, b) {
              return (a.updated_at < b.updated_at) ? -1 : 1;
            });
          } else {
            sortedIpAdresses = this.state.ipadresses.sort(function (a, b) {
              return (a.updated_at > b.updated_at) ? -1 : 1;
            });
          }
          break;
      }
    } else {
      sortedIpAdresses = this.state.ipadresses.sort(function (a, b) {
        if (a.created_at && b.created_at) {
          return (a.created_at > b.created_at) ? -1 : 1;
        } else if (!a.created_at && !b.created_at) {
          return (a.created_at_temp < b.created_at_temp) ? -1 : 1;
        } else if (!a.created_at) {
          return 1;
        } else if (!b.created_at) {
          return -1;
        }
      });
    }

    return sortedIpAdresses;
  };

  reflashRestrictionIpAddressTable = (tableData) => {
    this.deletedIpAddresses = [];
    this.setState({
      ipadresses: tableData,
    });
  }

  onChangeIpAddress = (value) => {
    document.getElementById("required_msg_ipaddress").textContent = '';
    this.restrictionIpAddress.ipAddress = value;
  }

  onChangeComment = (value) => {
    this.restrictionIpAddress.comment = value;
  }

  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hideAll();
  };

  openAddModal = () => {
    const { modalStore, t } = this.props;
    this.restrictionIpAddress.ipAddress = "";
    this.restrictionIpAddress.comment = "";

    modalStore.show({
      id: MODAL_ID.CREATE_TEAM,
      isOpen: true,
      header: t('general_setting.restriction_ip_address.detail.page_title'),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body modal-add-item">
            <div className="row">
              <div className="col-sm-1" />
              <div className="col-sm-4 font-bold">
                <span className="color-red">*</span>
                {t("setting_definition.required")}
              </div>
            </div>
            <div className="row" style={{ paddingTop: "15px" }}>
              <div className="col-sm-1" />
              <div className="col-sm-4 font-bold">{t("general_setting.restriction_ip_address.ip")}<span className="color-red">*</span></div>
              <div className="col-sm-6" id="div_item_name">
                <input name="ip_address"
                  type="text"
                  maxLength={18}
                  onChange={(value) => this.onChangeIpAddress(value.target.value)}
                />
                <span className="color-red" id="required_msg_ipaddress"></span>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-1" />
              <div className="col-sm-6" style={{ marginLeft: "33%" }}>
                {t("general_setting.restriction_ip_address.detail.ip.desc_1")}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-1" />
              <div className="col-sm-6" style={{ marginLeft: "33%" }}>
                {t("general_setting.restriction_ip_address.detail.ip.desc_2")}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-1" />
              <div className="col-sm-6" style={{ marginLeft: "43%" }}>
                {t("general_setting.restriction_ip_address.detail.ip.desc_3")}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-1" />
              <div className="col-sm-6" style={{ marginLeft: "43%" }}>
                {t("general_setting.restriction_ip_address.detail.ip.desc_4")}
              </div>
            </div>
            <div className="row"><div className="col-sm-1" /></div>
            <div className="row"><div className="col-sm-1" /></div>
            <div className="row" style={{ paddingTop: "15px" }}>
              <div className="col-sm-1" />
              <div className="col-sm-4 font-bold">{t("general_setting.restriction_ip_address.comment")}</div>
              <div className="col-sm-6" id="div_item_name">
                <input name="ip_address"
                  type="text"
                  maxLength={255}
                  onChange={(value) => this.onChangeComment(value.target.value)}
                />
              </div>
            </div>
          </div>

          <ModalFooter key={"modal-footer"}
            saveButtonText={t('common.create')}
            onConfirm={() => this.onConfirmAdd()}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: 'medium'
    });
  };

  openEditModal = (id, ipaddress, comment) => {
    const { modalStore, t } = this.props;
    this.restrictionIpAddress.ipAddress = ipaddress;
    this.restrictionIpAddress.comment = comment;

    modalStore.show({
      id: MODAL_ID.CREATE_TEAM,
      isOpen: true,
      header: t('general_setting.restriction_ip_address.detail.page_title'),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body modal-add-item">
            <div className="row">
              <div className="col-sm-1" />
              <div className="col-sm-4 font-bold">
                <span className="color-red">*</span>
                {t("setting_definition.required")}
              </div>
            </div>
            <div className="row" style={{ paddingTop: "15px" }}>
              <div className="col-sm-1" />
              <div className="col-sm-4 font-bold">{t("general_setting.restriction_ip_address.ip")}<span className="color-red">*</span></div>
              <div className="col-sm-6" id="div_item_name">
                <input name="ip_address"
                  type="text"
                  maxLength={18}
                  onChange={(value) => this.onChangeIpAddress(value.target.value)}
                  defaultValue={ipaddress}
                />
                <span className="color-red" id="required_msg_ipaddress"></span>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-1" />
              <div className="col-sm-6" style={{ marginLeft: "33%" }}>
                {t("general_setting.restriction_ip_address.detail.ip.desc_1")}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-1" />
              <div className="col-sm-6" style={{ marginLeft: "33%" }}>
                {t("general_setting.restriction_ip_address.detail.ip.desc_2")}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-1" />
              <div className="col-sm-6" style={{ marginLeft: "43%" }}>
                {t("general_setting.restriction_ip_address.detail.ip.desc_3")}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-1" />
              <div className="col-sm-6" style={{ marginLeft: "43%" }}>
                {t("general_setting.restriction_ip_address.detail.ip.desc_4")}
              </div>
            </div>
            <div className="row"><div className="col-sm-1" /></div>
            <div className="row"><div className="col-sm-1" /></div>
            <div className="row" style={{ paddingTop: "15px" }}>
              <div className="col-sm-1" />
              <div className="col-sm-4 font-bold">{t("general_setting.restriction_ip_address.comment")}</div>
              <div className="col-sm-6" id="div_item_name">
                <input name="ip_address"
                  type="text"
                  maxLength={255}
                  onChange={(value) => this.onChangeComment(value.target.value)}
                  defaultValue={comment}
                />
              </div>
            </div>
          </div>

          <ModalFooter key={"modal-footer"}
            saveButtonText={t('common.save')}
            onConfirm={() => this.onConfirmEdit(id)}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: 'medium'
    });
  };

  openComfirmModal = (id) => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.REMOVE,
      isOpen: true,
      header: t('general_setting.restriction_ip_address.remove_confirm'),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>{t('general_setting.restriction_ip_address.remove_confirm_msg')}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t('individual_model_mng.remove')}
            onConfirm={() => this.onConfirmDeleteById(id)}
            cancelButtonText={t('definition.cancel')}
            onCancel={this.closeModal}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: 'small'
    });
  };

  onConfirmDeleteById = (id) => {
    const { ipadresses } = this.state;
    let newIpAdresses = [];
    for (const elem of ipadresses) {
      if (elem.id == id) {
        if ((!elem.status || elem.status == 'undefined') ||
          (elem.status && elem.status == 2)) {
          this.deletedIpAddresses.length += 1;
          this.deletedIpAddresses[this.deletedIpAddresses.length - 1] = elem;
        }
      } else {
        newIpAdresses.length += 1;
        newIpAdresses[newIpAdresses.length - 1] = elem;
      }
    }
    this.setState({ ipadresses: newIpAdresses });
    this.closeModal();
  }

  onConfirmAdd = () => {
    const { t, commonStore: { settingStore } } = this.props;
    const { ipadresses } = this.state;
    let trimedIpAddress = this.restrictionIpAddress.ipAddress.trim();
    let trimedComment = this.restrictionIpAddress.comment.trim();
    if (trimedIpAddress == "") {
      document.getElementById("required_msg_ipaddress").textContent = t("validate.field_is_empty");
      return;
    }
    let errmsg = Validate.validateIpAddress(trimedIpAddress, t);
    if (errmsg !== "") {
      document.getElementById("required_msg_ipaddress").textContent = errmsg;
      return;
    }
    if (!this.isUniqueIpAddress(trimedIpAddress)) {
      document.getElementById("required_msg_ipaddress").textContent = t("general_setting.restriction_ip_address.detail.ip_duplicated");
      return;
    }
    ipadresses.length += 1;
    let newElement = {
      id: this.uniqueId(5),
      ip_address: trimedIpAddress,
      comment: trimedComment,
      created_at: null,
      updated_at: null,
      created_at_temp: new Date().getTime(),
      status: 1,
    }
    ipadresses[ipadresses.length - 1] = newElement;
    this.setState({ ipadresses });

    this.closeModal();
    this.state.ipAddress = "";
    this.state.comment = "";
  }

  isUniqueIpAddress = (newIpAddress) => {
    const { ipadresses } = this.state;
    for (const elem of ipadresses) {
      if (elem.ip_address == newIpAddress) {
        return false;
      }
    }
    return true;
  }

  uniqueId = (digits) => {
    var strong = typeof digits !== 'undefined' ? digits : 1000;
    return Date.now().toString(16) + Math.floor(strong * Math.random()).toString(16);
  };

  onConfirmEdit = (id) => {
    const { t, commonStore: { settingStore } } = this.props;
    const { ipadresses } = this.state;
    let trimedIpAddress = this.restrictionIpAddress.ipAddress.trim();
    let trimedComment = this.restrictionIpAddress.comment.trim();
    if (trimedIpAddress == "") {
      document.getElementById("required_msg_ipaddress").textContent = t("validate.field_is_empty");
    } else {
      let errmsg = Validate.validateIpAddress(trimedIpAddress, t);
      if (errmsg !== "") {
        document.getElementById("required_msg_ipaddress").textContent = errmsg;
      } else {
        for (const elem of ipadresses) {
          if (elem.id == id) {
            elem.ip_address = trimedIpAddress;
            elem.comment = trimedComment;
            if (!elem.status || elem.status == 'undefined') {
              elem.status = 2;
            }
            break;
          }
        }
        this.setState({ ipadresses });
        this.closeModal();
        this.restrictionIpAddress.ipAddress = "";
        this.restrictionIpAddress.comment = "";
      }
    }
  }

  render() {
    const { t } = this.props;
    const { commonStore: { settingStore: { paging } } } = this.props;
    const { threshold1, threshold2, apiKey, dataRetentionPeriod, downloadFolderName, downloadFileName, restrictionIpAddress, ipadresses } = this.state;
    paging.size = 1000;
    const columnsIpAddress = [
      {
        Header: t("common.no"),
        disableSortBy: true,
        width: TABLE_ROW_WIDTH_IPADDRESS.NO,
        Cell: ({ initialState, row }) => <div style={{ textAlign: "center" }}>{
          initialState?.initialTableState?.pageSize *
          initialState?.initialTableState?.pageIndex +
          row.index +
          1
        }</div>
        ,
      },
      {
        Header: t('general_setting.restriction_ip_address.ip'),
        accessor: 'ipAddress',
        Cell: ({ row: { original } }) => {
          return (
            <div className="color-theme cursor-p"
              onClick={() => this.openEditModal(original.id, original.ip_address, original.comment)}>
              {original.ip_address}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_IPADDRESS.IP_ADDRESS
      },
      {
        Header: t('general_setting.restriction_ip_address.comment'),
        accessor: 'comment',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.comment}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_IPADDRESS.COMMENT
      },
      {
        Header: t('common.created_date'),
        accessor: 'createdAt',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.created_at ? moment(original.created_at).format(DATE_FORMAT.full_time) : NO_DATE}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_IPADDRESS.CREATED_DATE
      },
      {
        Header: t('common.updated_date'),
        accessor: 'updatedAt',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.updated_at ? moment(original.updated_at).format(DATE_FORMAT.full_time) : NO_DATE}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_IPADDRESS.UPDATED_DATE
      },
      {
        Header: t("common.remove"),
        id: 'action',
        className: 'admin-action',
        Cell: ({ row: { original: { id } } }) => {
          return <div>
            <button className="btn btn-danger" type="button" onClick={() => this.openComfirmModal(id)} style={{ fontSize: "12px", minWidth: "50px" }}>
              {t('common.remove')}
            </button>
          </div>
        },
        width: 100,
      }
    ];

    return <div className="screen-general-setting">
      <ContainerBox headerTitle={t('menu.general_setting')} className="admin-sb-container">
        <div className="box-body">
          <div className="row">
            <div className="col-sm-4 font-bold">
              <span className="color-red">*</span>
              {t("setting_definition.required")}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 font-bold" style={{ lineHeight: "34px" }}>
              {t("general_setting.threshold_title")}
              <span className="color-red">*</span>
            </div>
            <div className="area col-sm-1">
              <div className="high-area">
                {t("general_setting.high")}
              </div>
            </div>
            <div className="col-sm-2" style={{ textAlign: "center" }}>
              <input style={{ maxWidth: "80px" }} type="number" value={threshold1} onChange={(value) => this.onChangeTheshold1(value.target.value)} ></input>
            </div>
            <div className="area col-sm-1">
              <div className="mid-area">
                {t("general_setting.mid")}
              </div>
            </div>
            <div className="col-sm-2" style={{ textAlign: "center" }}>
              <input style={{ maxWidth: "80px" }} type="number" value={threshold2} onChange={(value) => this.onChangeTheshold2(value.target.value)} ></input>
            </div>
            <div className="area col-sm-1">
              <div className="low-area">
                {t("general_setting.low")}
              </div>
            </div>
          </div>
          {(!threshold1 || !threshold2)
            ? this.renderThresholdErr(t("validate.field_is_empty"))
            : (Validate.validateThreshold(threshold1, t) || Validate.validateThreshold(threshold2, t))
              ? this.renderThresholdErr(Validate.validateThreshold(threshold1, t) || Validate.validateThreshold(threshold2, t))
              : (threshold1 < threshold2) ? this.renderThresholdErr(t("validate.threshold_invalid")) : " "}
          <div className="row">
            <div className="col-sm-3 font-bold" style={{ lineHeight: "34px" }}>
              {t("general_setting.api_token")}
            </div>
            <div style={{ padding: "0px", display: "flex", flexDirection: "row" }} className="col-sm-7">
              {/* <button onClick={this.handleGenerate} style={{ backgroundColor: "#999", borderWidth: "1px", color: "#fff", borderRadius: "4px" }}>{t("general_setting.generate")}</button> */}
              <button className="btn btn-primary" onClick={this.handleGenerate} style={{ height: "32px", minWidth: "80px" }}>{t("general_setting.generate")}</button>
              <input value={apiKey} disabled style={{ textAlign: "left", marginLeft: "40px", width: "70%" }}></input>
              {apiKey && <button style={{ marginLeft: "12px" }} onClick={() => this.handleCopy(apiKey)}><i className="fa fa-files-o"></i></button>}
              {apiKey && <i onClick={this.handleRemoveKey} className="fa fa-times"></i>}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 font-bold" style={{ lineHeight: "34px" }}>
              {t("general_setting.data_retention_period")}
              <span className="color-red">*</span>
              <i className="fa fa-info-circle ar-tooltip"
                data-toggle="tooltip"
                data-placement="right"
                title={t("general_setting.data_retention_period.tooltip")}>
              </i>
            </div>
            {/* <div className="col-sm-1"></div> */}
            <div style={{ padding: "0px", display: "flex", flexDirection: "row" }} className="col-sm-7">
              <div style={{ marginLeft: "125px" }}>
                <input style={{ maxWidth: "80px" }} type="number"
                  min="0"
                  onKeyPress={this.handleKeyPressForNumber}
                  value={dataRetentionPeriod}
                  onChange={(value) => this.onChangeDataRetentionPeriod(value.target.value)} >
                </input>
                <span style={{ marginLeft: "10px" }}>{t("general_setting.day")}</span>
                {Validate.validateDataRetentionPeriod(dataRetentionPeriod, t) !== ""
                  && <div className="err-msg">{Validate.validateDataRetentionPeriod(dataRetentionPeriod, t)}</div>
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 font-bold" style={{ lineHeight: "34px" }}>
              {t("general_setting.document_download")}
            </div>
            <div style={{ padding: "0px", display: "flex", flexDirection: "row" }} className="col-sm-9">
              <div className="col-sm-6" style={{ padding: "0px", lineHeight: "34px" }}>
                <span className="font-bold" style={{ paddingRight: "15px" }}>
                  {t("general_setting.document_download_folder")}
                </span>
                <input style={{ width: "60%", textAlign: "left", marginRight: "15px" }} type="text"
                  maxLength="255" value={downloadFolderName}
                  onChange={(value) => this.onChangeDownloadFolderName(value.target.value)} >
                </input>
                {Validate.validateDownloadFolderName(downloadFolderName, t) !== ""
                  && <div className="err-msg">{Validate.validateDownloadFolderName(downloadFolderName, t)}</div>
                }
              </div>
              <div className="col-sm-6" style={{ padding: "0px", lineHeight: "34px" }}>
                <span className="font-bold" style={{ paddingRight: "15px" }}>
                  {t("general_setting.document_download_file")}
                </span>
                <input style={{ width: "60%", textAlign: "left", marginRight: "15px" }} type="text"
                  maxLength="255" value={downloadFileName}
                  onChange={(value) => this.onChangeDownloadFileName(value.target.value)} >
                </input>
                {Validate.validateDownloadFileName(downloadFileName, t) !== ""
                  && <div className="err-msg">{Validate.validateDownloadFileName(downloadFileName, t)}</div>
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 font-bold" style={{ lineHeight: "34px" }}></div>
            <div style={{ padding: "0px" }} className="col-sm-9">
              {t("general_setting.document_download_description_before_link")}
              <a target="_blank" href="https://manual.airead.ai/usersmanual/oncloud/latest/">
                {t("general_setting.document_download_description_link")}
              </a>
              {t("general_setting.document_download_description_after_link")}
            </div>
          </div>
          <div className="row" style={{ margin: "0px", padding: "0px" }}>
            <div className="col-sm-3 font-bold" style={{ lineHeight: "34px" }}>
              {t("general_setting.restriction_ip_address")}
            </div>
            <div style={{ padding: "0px", display: "flex", flexDirection: "row" }} className="col-sm-9">
              <Switch
                onChange={(value) => this.onChangeRestrictionIpAddress(value)}
                checked={restrictionIpAddress === 0}
                height={25}
                width={65}
              />
              <div style={{ marginLeft: "auto" }} className="col-sm-9">
                <button
                  className="ip-address-btn-style btn btn-primary f-r mb-3 btn-ip-addresss-man"
                  type="button"
                  onClick={this.openAddModal}
                  style={{ fontSize: "12px" }}
                >
                  {t("general_setting.restriction_ip_address.create")}
                </button>
              </div>
            </div>
          </div>
          <div className="row" style={{ margin: "0px", padding: "0px" }} >
            <div className="col-sm-3 font-bold" />
            <div className="col-sm-9" style={{ fontSize: "12px" }}>
              <div style={{ height: "25vh", overflow: "auto", overflowX: "hidden" }}>
                <Table
                  columns={columnsIpAddress}
                  data={ipadresses}
                  disablePaging={true}
                  disableSelectionPageSize={true}
                  onFetch={this.onFetch}
                  enableServerSidePaging={true}
                  initialTableState={paging}
                  hideTableFooter={false}
                  style={{ height: "25vh", overflow: "auto", overflowX: "hidden" }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12" style={{ textAlign: "center" }}>
              <button className="btn btn-primary" onClick={this.handleSubmit}>{t("general_setting.save")}</button>
            </div>
          </div>
        </div>
      </ContainerBox>
    </div >
  }
}

export default withRouter(withTranslation()(GeneralSetting));
