import { action, observable, runInAction, configure } from "mobx";
import { TeamApi } from "apis";
import ApiStore from "./ApiStore";
import BaseStore from "./BaseStore";

configure({ enforceActions: "observed" });

class TeamStore extends BaseStore {
  @observable teams = [];
  @observable team = {};
  @observable teamName = "";
  @observable teamUsers = [];
  @observable usersNotInTeam = [];
  @observable teamsOfUser = [];
  @observable keepFilterState = false;
  @observable teamsAll = [];
  @observable usersInSameTeams = [];
  @observable usersInSameTeamsCache = [];

  constructor(props) {
    super(props);
    this.api = new TeamApi();
  }

  @action
  async getTeams() {
    const { size, page, sortDir, sortKey } = this.paging;
    let payload = { size, page, sortDir, sortKey };
    let response = await ApiStore.call(this.api, this.api.getAll, payload);
    if (response) {
      runInAction(() => {
        this.updatePagingFiler(response.paging);
        this.teams = response.elements;
        if (this.paging?.totalRecord && this.paging?.totalRecord !== 0) {
          if (this.paging?.page > 1 && (!response.elements || response.elements.length === 0)) {
            this.updatePagingFiler({ page: this.paging.page - 1 });
            this.getTeams();
          }
        }
      })
    }
  }

  @action
  async getTeamById(id) {
    const { size, page, sortDir, sortKey } = this.paging;
    let payload = {};
    payload["pageInfo"] = { size, page, sortDir, sortKey };
    payload["id"] = id;
    let response = await ApiStore.call(this.api, this.api.getTeamById, payload);
    if (response) {
      runInAction(() => {
        this.updatePagingFiler(response.pages.paging);
        this.teamName = response.name;
        this.teamUsers = response.pages.elements;
        if (this.paging?.totalRecord && this.paging?.totalRecord !== 0) {
          if (this.paging?.page > 1 && (!response.pages.elements || response.pages.elements.length === 0)) {
            this.updatePagingFiler({ page: this.paging.page - 1 });
            this.getTeamById(id);
          }
        }
      })
    }
  }

  async addTeam(payload, cb) {
    let response = await ApiStore.call(this.api, this.api.addTeam, payload);
    if (response) {
      runInAction(() => {
        // this.getTeams();
        cb && cb(response.id);
      })
    }
  }

  async deleteTeam(id, cb) {
    let resp = await ApiStore.call(this.api, this.api.deleteTeam, id);
    if (resp) {
      runInAction(() => {
        cb && cb();
      });
    }
  }

  async updateTeamName(payload, cb) {
    let resp = await ApiStore.call(this.api, this.api.updateTeamName, payload);
    if (resp) {
      runInAction(() => {
        cb && cb();
      });
    }
  }

  async getUsersNotInTeam(payload) {
    const { size, page, sortDir, sortKey } = this.paging;
    const { userId, userName } = payload;
    payload["pageInfo"] = { size, page, sortDir, sortKey, userId, userName };
    let response = await ApiStore.call(this.api, this.api.getUsersNotInTeam, payload);
    if (response) {
      runInAction(() => {
        this.updatePagingFiler(response.pages.paging);
        this.usersNotInTeam = response.pages.elements;
        if (this.paging?.totalRecord && this.paging?.totalRecord !== 0) {
          if (this.paging?.page > 1 && (!response.pages.elements || response.pages.elements.length === 0)) {
            this.updatePagingFiler({ page: this.paging.page - 1 });
            this.getUsersNotInTeam(payload);
          }
        }
      })
    }
  }

  async addUsersForTeam(payload, cb) {
    let resp = await ApiStore.call(this.api, this.api.addUsersForTeam, payload);
    if (resp) {
      runInAction(() => {
        cb && cb();
      });
    }
  }

  async deleteUserForTeam(payload, cb) {
    let resp = await ApiStore.call(this.api, this.api.deleteUserForTeam, payload);
    if (resp) {
      runInAction(() => {
        cb && cb();
      });
    }
  }

  @action
  async getTeamOfUser() {
    let response = await ApiStore.call(this.api, this.api.getTeamOfUser);
    if (response) {
      runInAction(() => {
        response.sort((a, b) => (a.id > b.id) ? 1 : -1);
        this.teamsOfUser = response;
      })
    }
  }

  @action
  async getTeamsAll() {
    let response = await ApiStore.call(this.api, this.api.getTeamsAll);
    if (response) {
      runInAction(() => {
        response.sort((a, b) => (a.id > b.id) ? 1 : -1);
        this.teamsAll = response;
      })
    }
  }

  // Execute after getTeamOfUser()
  @action
  async getUsersInSameTeams() {
    let response = await ApiStore.call(this.api, this.api.getUsersInSameTeams);
    if (response) {
      runInAction(() => {
        response.sort((a, b) => (a.id > b.id) ? 1 : -1);
        this.usersInSameTeams = response;
        this.usersInSameTeamsCache = this.usersInSameTeams;
      })
    }
  }

  @action
  getUsersByTeamId(teamId) {
    if (!teamId || teamId == "null") {
      this.usersInSameTeams = this.usersInSameTeamsCache;
    }
    else {
      this.usersInSameTeams = this.usersInSameTeamsCache.filter(x => x.teamsId.includes(parseInt(teamId)));
    }
    return this.usersInSameTeams;
  }

  @action
  setKeepFilterState = (value) => {
    this.keepFilterState = value;
  }

  @action
  clean() {
    if (!this.keepFilterState) {
      console.log("TeamStore -> clean -> this.keepFilterState", this.keepFilterState)
      super.clean();
    }
  }
}

export default TeamStore;