import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { ProfileUser } from "components";
@inject("commonStore",)
@observer
class ScreensHome extends Component {

  componentWillMount = () => {
    const {
      commonStore: { userStore, settingStore }
    } = this.props;
    userStore.getUserInfo();
    settingStore.getSetting();
  }

  render() {
    const {
      commonStore: { userStore }
    } = this.props;
    return (
      <ProfileUser userInfo={userStore.userInfo} />
    );
  }
}

export default withRouter(ScreensHome);
