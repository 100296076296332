import { action, observable, runInAction, configure } from "mobx";
import { DefinitionApi } from "apis";
import ApiStore from "./ApiStore";
import BaseStore from "./BaseStore";

configure({ enforceActions: "observed" });

class FormGroupStore extends BaseStore {
  @observable keepFilterState = false;
  @observable listGroupDefinitions = [];

  constructor(props) {
    super(props);
    this.api = new DefinitionApi();
  }

  @action
  async getFormGroups() {
    const { size, page, sortDir, sortKey } = this.paging;
    let payload = { size, page, sortDir, sortKey };
    let response = await ApiStore.call(this.api, this.api.getFormGroupsAll, payload);
    if (response) {
      runInAction(() => {
        this.updatePagingFiler(response.paging);
        this.listGroupDefinitions = response.elements;
        if (this.paging?.totalRecord && this.paging?.totalRecord !== 0) {
          if (this.paging?.page > 1 && (!response.elements || response.elements.length === 0)) {
            this.updatePagingFiler({ page: this.paging.page - 1 });
            this.getFormGroups();
          }
        }
      })
    }
  }

  @action
  async deleteFormGroup(id) {
    let resp = await ApiStore.call(this.api, this.api.deleteFormGroup, id);
    if (resp) {
      runInAction(() => {
        this.getFormGroups();
      })
    }
  }

  @action
  async updateFormGroupSetting(payload, cb) {
    let resp = await ApiStore.call(this.api, this.api.updateFormGroupSetting, payload);
    if (resp) {
      runInAction(() => {
        cb && cb();
      });
    }
  }

  @action
  clean() {
    if (!this.keepFilterState) {
      console.log("FormGroupStore -> clean -> this.keepFilterState", this.keepFilterState)
      super.clean();
    }
  }

}

export default FormGroupStore;