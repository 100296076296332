import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { ContainerBox, Table, ModalFooter } from 'components';
import { MODE, MODAL_ID, DATE_FORMAT, NO_DATE, EMPTY_FORMAT_CELL } from '../../core/utils/constants';
import moment from 'moment';
import BaseComponent from '../BaseComponent';
import './style.scss';

const TABLE_ROW_WIDTH_MODEL = {
  NO: "6%",
  DEFINITION_NAME: "40%",
  CREATED_BY: "20%",
  CREATED_DATE: "15%",
  UPDATED_DATE: "15%"
};

@inject('commonStore', 'modalStore')
@observer
class ScreenPostProcessing extends BaseComponent {
  executeLogicAfterRender = () => {
    this.props.commonStore.postProcessingStore.clean();
    this.props.commonStore.postProcessingStore.getPostProcesses();
  }

  executeLogicBeforeOutComponent = () => {
    this.props.commonStore.postProcessingStore.clean();
  }

  onCancelUpdate = (userId, userStatus) => {
    this.closeModal();
  };

  onConfirmDeleteById = (id) => {
    this.props.commonStore.postProcessingStore.deletePostProcess(id);
    this.closeModal();
  }

  openComfirmModal = (id) => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.REMOVE,
      isOpen: true,
      header: t('definition.remove_confirm'),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>{t('post_processing.remove_post_processing_confirm_msg')}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t('individual_model_mng.remove')}
            onConfirm={() => this.onConfirmDeleteById(id)}
            cancelButtonText={t('definition.cancel')}
            onCancel={this.closeModal}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: 'small'
    });
  };

  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hideAll();
  };

  onFetch = (tableData) => {
    this.props.commonStore.postProcessingStore.updatePagingFiler(tableData);
    this.props.commonStore.postProcessingStore.getPostProcesses();
  };

  render() {
    const { t, commonStore: { postProcessingStore: { postProcesses, paging } } } = this.props;
    const columns = [
      {
        Header: t("common.no"),
        disableSortBy: true,
        width: TABLE_ROW_WIDTH_MODEL.NO,
        Cell: ({ initialState, row }) => <div style={{ textAlign: "center" }}>{
          initialState?.initialTableState?.pageSize *
          initialState?.initialTableState?.pageIndex +
          row.index +
          1
        }</div>
        ,
      },
      {
        Header: t('post_processing.definition_name'),
        accessor: 'settingName',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.setting_name}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_MODEL.DEFINITION_NAME
      },
      {
        Header: t('post_processing.created_by'),
        accessor: 'updatedUser',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.updated_user}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_MODEL.CREATED_BY
      },
      {
        Header: t('common.created_date'),
        accessor: 'createdAt',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.created_at ? moment(original.created_at).format(DATE_FORMAT.full_time) : NO_DATE}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_MODEL.CREATED_DATE
      },
      {
        Header: t('common.updated_date'),
        accessor: 'updatedAt',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.updated_at ? moment(original.updated_at).format(DATE_FORMAT.full_time) : NO_DATE}
            </div>
          );
        },
        width: TABLE_ROW_WIDTH_MODEL.UPDATED_DATE
      },
      {
        Header: t("individual_model_mng.remove"),
        id: 'action',
        className: 'admin-action',
        Cell: ({ row: { original: { id } } }) => {
          return <div>
            <button className="btn btn-danger" type="button" onClick={() => this.openComfirmModal(id)}>
              {t('individual_model_mng.remove')}
            </button>
          </div>
        },
        width: 100,
      }
    ];
    return (
      <div className="screen-post-processing">
        <ContainerBox headerTitle={`${t('post_processing.page_title')}`} className="admin-body-container table-container">
          <div className="box-body document-item-info">
            <Table
              columns={columns}
              data={postProcesses}
              disablePaging={false}
              onFetch={this.onFetch}
              enableServerSidePaging={true}
              initialTableState={paging}
            />
          </div>
        </ContainerBox>
      </div>
    );
  }

}

export default withRouter(withTranslation()(ScreenPostProcessing));
