import { action, observable, runInAction, configure } from "mobx";
import { TenantApi, SystemSettingApi } from "apis";
import ApiStore from "./ApiStore";
import BaseStore from "./BaseStore";
import { LANGUAGE_CODE_DEFAULT, RENEWAL_SPAN_CODE_DEFAULT } from '../core/utils/constants';

configure({ enforceActions: "observed" });

const defaultTenantFormData = {
  name: "",
  language: LANGUAGE_CODE_DEFAULT,
  startDate: null,
  endDate: null,
  total: null,
  userActive: null,
  renewalSpan: RENEWAL_SPAN_CODE_DEFAULT,
  adminId: "",
  adminAccount: "",
  adminPass: "",
  confirmPassword: "",
  teamName: "",
};

class TenantStore extends BaseStore {
  @observable tenants = [];
  @observable month = null;
  @observable year = null;
  @observable total = {};
  @observable active = 'true';
  @observable keepFilterState = false;
  @observable tenantFormData = defaultTenantFormData;

  constructor(props) {
    super(props);
    this.api = new TenantApi();
    this.month = (new Date()).getMonth() + 1;
    this.year = (new Date()).getFullYear();
  }

  @action
  setTenantFormData = (data) => {
    this.tenantFormData = data;
  }

  @action
  loadDefaultTenantFormData = () => {
    this.tenantFormData = defaultTenantFormData;
  }

  @action
  setMonth = (value) => {
    this.month = value;
  }

  @action
  setYear = (value) => {
    this.year = value;
  }

  @action
  setActive = (value) => {
    this.active = value;
  }

  @action
  async getTenants() {
    const { size, page, sortDir, sortKey } = this.paging;
    let payload = { size, page, sortDir, sortKey, year: this.year, month: this.month, active: this.active };
    let response = await ApiStore.call(this.api, this.api.getAll, payload);
    if (response) {
      runInAction(() => {
        this.updatePagingFiler(response.paging);
        this.tenants = response.elements;
        this.total = response.total;
        if (this.paging?.totalRecord && this.paging?.totalRecord !== 0) {
          if (this.paging?.page > 1 && (!response.elements || response.elements.length === 0)) {
            this.updatePagingFiler({ page: this.paging.page - 1 });
            this.getTenants();
          }
        }
      })
    }
  }

  @action
  async createTenant(payload, rb = null) {
    let resp = await ApiStore.call(this.api, this.api.createTenant, payload);
    if (resp) {
      return resp
    } else {
      rb && rb();
      return false;
    }
  }

  @action
  async updateTenant(payload, rb = null) {
    let resp = await ApiStore.call(this.api, this.api.updateTenant, payload);
    if (resp) {
      return resp
    } else {
      rb && rb();
      return false;
    }
  }

  @action
  clean() {
    if (!this.keepFilterState) {
      super.clean();
      this.month = (new Date()).getMonth() + 1;
      this.year = (new Date()).getFullYear();
      this.active = 'true';
    }
  }
}

export default TenantStore;
