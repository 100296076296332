import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { ModalFooter } from "components";
import { Scrollbars } from "react-custom-scrollbars";
import BaseComponent from '../BaseComponent';
import "./style.scss";

@inject("modalStore", "commonStore")
@observer
class ScreenChangeTeamName extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      teamName: this.props.teamName || ""
    };
  }

  onChange = (value) => {
    this.setState({
      teamName: value
    });
  };

  onSave = (forceCreate = false) => {
    const { teamName } = this.state;
    const { teamId, commonStore: { teamStore } } = this.props;
    let payload = {
      id: teamId,
      name: teamName
    }
    teamStore.updateTeamName(
      payload,
      () => {
        teamStore.getTeamById(teamId);
        this.closeAllModal();
      }
    );
  };

  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hide();
  };

  closeAllModal = () => {
    const { modalStore } = this.props;
    modalStore.hideAll();
  };

  render() {
    const { t, saveButtonText } = this.props;
    return (
      <div className="team-name-modal">
        <Scrollbars key={"modal-body"} className="modal-body">
          <div className="row">
            <div className="col-sm-1" />
            <div className="col-sm-4 font-bold">
              <span className="color-red">*</span>
              {t("setting_definition.required")}
            </div>
          </div>
          <div className="row" style={{ paddingTop: "15px" }}>
            <div className="col-sm-1" />
            <div className="col-sm-4 font-bold">{t("team.team_name")}<span className="color-red">*</span></div>
            <div className="col-sm-6">
              <input
                type="text"
                value={this.state.teamName}
                maxLength={255}
                onChange={e => this.onChange(e.target.value)}
              />
              <span className="color-red" id="required_msg"></span>
            </div>
          </div>
        </Scrollbars>
        <ModalFooter key={"modal-footer"}
            saveButtonText={saveButtonText}
            onConfirm={() => {
              this.closeModal();
              this.onSave();
            }}
          />
      </div>
    );
  }
}

export default withTranslation()(ScreenChangeTeamName);
