import React, { Component, Suspense } from "react";
import i18next from "i18next";
import Root from "./screens/Root";
import ScreenLogin from "./screens/Login/ScreenLogin";
import moment from "moment";
import { NotificationContainer } from "react-notifications";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { Spinner, ReactModal } from "components";
import { Browser } from "core/utils";
import URL_CONFIG from './core/services/config'
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import "app.scss";
import "react-datepicker/dist/react-datepicker.css";
import "react-notifications/lib/notifications.css";
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");
window.moment = moment;

//#region Application insights
if (URL_CONFIG.is_connect_to_azure_app_insight) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: URL_CONFIG.app_insight_instrumentation_key
    }
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}
//#endregion Application insights

class App extends Component {
  render() {
    const language = Browser.getSavedInfo("language");
    // change the language
    i18next.changeLanguage(language || "jp");
    return (
        <Suspense fallback="">
          <input type="hidden" value="prayer" />
          <BrowserRouter basename={URL_CONFIG.prefix_url}>
            <Switch>
              {/* public route - with out authenticate */}
              <Route exact={true} path="/login" component={ScreenLogin} />
              {/* private route - with authenticate and base layout*/}
              <Route path="/" component={Root} />
              <Redirect to={"/"} />
            </Switch>
          </BrowserRouter>
          <NotificationContainer />
          <Spinner />
          <ReactModal />
        </Suspense>
    );
  }
}

export default App;
