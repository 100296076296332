import React from "react";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import { PAGE_SIZE_OPTIONS, CONFIG_FILE_EDITION } from "../../core/utils/constants";
import PagingInfo from "./PagingInfo";
import ReactPaging from "./ReactPaging";
import SelectPageSize from "./SelectPageSize";
import "./style.scss";
import { TABLE_CODE } from "../../core/utils/constants"

const IndeterminateCheckbox = React.forwardRef(({ indeterminate }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} />
    </>
  );
});
function isOrderByStateChange(newState, OldState) {
  return (
    newState.sortBy[0]?.id !== OldState?.sortBy[0]?.id ||
    newState.sortBy[0]?.desc !== OldState?.sortBy[0]?.desc
  );
}
function ReactTable({
  tblCode,
  columns,
  data,
  enableSelectRow,
  disableSelectionPageSize,
  disablePaging,
  disableField,
  disableFieldValue,
  t,
  element,
  customSelectedBox,
  pageIndexProp,
  changePageIndexProp,
  enableServerSidePaging,
  onFetch,
  rowClass,
  initialTableState,
  hideTableFooter,
  className,
  tableFooter,
  useCustomSortProcess,
  customSortProcess,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    rows,
    pageOptions,
    gotoPage,
    setPageSize,
    // state: { pageIndex, pageSize },
    state,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { initialTableState },
      manualSortBy: enableServerSidePaging,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {

      hooks.flatColumns.push((columns) => {
        return enableSelectRow
          ? [
            // Let's make a column for selection
            {
              id: "selection",
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox
                    {...getToggleAllRowsSelectedProps()}
                  />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              ),
            },
            ...columns,
          ]
          : columns;
      });
    }
  );

  React.useEffect(() => {
    if (
      enableServerSidePaging &&
      isOrderByStateChange(state, initialTableState)
    ) {
      onFetch(state);
    }
  }, [
    onFetch,
    state,
    initialTableState,
    enableServerSidePaging,
  ]);

  React.useEffect(() => {
    if (initialTableState) state.pageSize = Number(initialTableState.pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTableState]);

  React.useEffect(() => {
    if (useCustomSortProcess) {
      customSortProcess();
    }
  }, [sortBy]);

  // Render the UI for your table
  let renderITems = disablePaging ? rows : page;
  if (data.length > 0) {
    if (pageIndexProp + 1 <= pageOptions.length) {
      if (pageIndexProp !== state.pageIndex) {
        gotoPage(pageIndexProp);
      }
    } else {
      changePageIndexProp(0);
      return "";
    }
  }
  disablePaging = disablePaging && data.length > 0;
  // Listen for changes in pagination and use the state to fetch our new data
  return (
    <div className={"react-table " + className}>
      {!disablePaging && (
        <div className="selection-header" style={{ display: disableSelectionPageSize ? "none" : "" }}>
          <SelectPageSize
            value={state.pageSize}
            onChange={(e) => {
              if (enableServerSidePaging) {
                state.pageIndex = 0;
                state.pageSize = Number(e.target.value);
                onFetch(state);
              } else {
                changePageIndexProp(0);
                setPageSize(Number(e.target.value));
              }
            }}
            values={customSelectedBox ? customSelectedBox : PAGE_SIZE_OPTIONS}
          />
          {element}
        </div>
      )}
      <table
        {...getTableProps()}
        className="table table-bordered table-striped"
      >
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, k) => (
                <th
                  {...column.getHeaderProps(
                    column.canSort ? column.getSortByToggleProps({
                      title: t("table_rearrange_order"),
                    }) : ""
                  )}
                  width={column.width}
                  key={"row_" + i + "_" + k}
                  className={
                    "cansort_" + column.canSort + "_sorted_" + column.isSorted + " " + column?.headerClassName
                  }
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted &&
                      (column.isSortedDesc ? (
                        <i className="fa fa-sort-desc"></i>
                      ) : (
                        <i className="fa fa-sort-asc"></i>
                      ))}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {renderITems.length > 0 &&
            renderITems.map((row, i) => {
              prepareRow(row);
              let customRowClass = "";
              if (rowClass) customRowClass = rowClass(row);
              return (
                <tr
                  key={i}
                  className={
                    // disableFieldValue &&
                    //   row.original[disableField] !== disableFieldValue
                    //   ? "disable " + customRowClass
                    //   : customRowClass
                    getRowClass(tblCode, row, customRowClass, disableField, disableFieldValue)
                  }
                >
                  {row.cells.map((cell, k) => {
                    return (
                      <td
                        width={cell?.column?.width}
                        className={cell?.column?.className}
                        {...cell.getCellProps()}
                        key={i + "_" + k}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
        <tfoot>
          {tableFooter && tableFooter}
        </tfoot>
      </table>
      {!hideTableFooter &&
        <div className="row table-footer">
          {data.length !== 0 ? (
            !disablePaging && (
              <div>
                <PagingInfo
                  tableState={enableServerSidePaging ? initialTableState : state}
                />
                <ReactPaging
                  forcePage={enableServerSidePaging ? initialTableState.pageIndex : state.pageIndex}
                  pageCount={Math.ceil(
                    (enableServerSidePaging
                      ? initialTableState?.totalRecord
                      : rows.length) / state.pageSize
                  )}
                  onPageChange={({ selected }) => {
                    if (enableServerSidePaging) {
                      state.pageIndex = selected;
                      onFetch(state);
                    } else {
                      gotoPage(selected);
                      changePageIndexProp(selected);
                    }
                  }}
                />
              </div>
            )
          ) : (
            <div className="table-no-data">
              <p>{t("table_no_data")}</p>
            </div>
          )}
        </div>
      }
    </div>
  );
}

function getRowClass(tblCode, row, customRowClass, disableField, disableFieldValue) {
  if (tblCode === TABLE_CODE.DOCUMENT) {
    const obj = disableField;
    if (obj.documentId != null) {
      if (obj.configFileEdition !== row.original.configFileEdition) {
        return "disable " + customRowClass;
      }
      if (row.original.configFileEdition === CONFIG_FILE_EDITION.ITEM || row.original.configFileEdition === CONFIG_FILE_EDITION.RULE) {
        if (obj.formGroupId !== row.original.formGroupId) {
          return "disable " + customRowClass;
        }
      }
      else if (obj.configFileCode !== row.original.configFileCode) {
        return "disable " + customRowClass;
      }
    }
  }
  else {
    if (disableFieldValue && row.original[disableField] !== disableFieldValue) {
      return "disable " + customRowClass;
    }
    return customRowClass;
  }
  return customRowClass;
}

export default ReactTable;
