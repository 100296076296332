import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import "./i18n";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { Provider } from "mobx-react";
import { AuthStore, CommonStore, ModalStore, ApiStore } from "stores";

import "./index.css";
import DocumentsStore from "./stores/DocumentsStore";
import DocumentsDownloadStore from "./stores/DocumentsDownloadStore";
import ActionLogsStore from "./stores/ActionLogsStore";
i18next.init({
    interpolation: { escapeValue: false } // React already does escaping
});


ReactDOM.render(
    <Provider
        actionLogsStore={new ActionLogsStore()}
        authStore={AuthStore}
        commonStore={new CommonStore()}
        documentsStore={new DocumentsStore()}
        documentsDownloadStore={new DocumentsDownloadStore()}
        modalStore={ModalStore}
        apiStore={ApiStore}
    >
        <I18nextProvider i18n={i18next}>
            <App />
        </I18nextProvider>
    </Provider>,
    document.getElementById("root")
);
