import { action, observable, runInAction, configure } from "mobx";
import { UserApi } from "apis";
import ApiStore from "./ApiStore";
import BaseStore from "./BaseStore";
import i18n from "../i18n";
import { LANGUAGE_CODE } from "../core/utils/constants";

configure({ enforceActions: "observed" });
const USER_DELETED = 'USER_DELETED'

class UserStore extends BaseStore {
  @observable users = [];
  @observable userInfo = {};
  @observable month = null;
  @observable year = null;

  selectedUser = null;

  constructor(props) {
    super(props);
    this.api = new UserApi();
    this.month = (new Date()).getMonth() + 1;
    this.year = (new Date()).getFullYear();
  }

  /**
   * getUserInfo
   * handle sign in action
   * 
   * @param {Object} payload : username / password object
   * @returns  boolean
   */
  @action
  async getUserInfo() {
    let user = await ApiStore.call(this.api, this.api.getCurrentUserInfo);
    if (user) {
      runInAction(() => {
        this.userInfo = user;
        this.saveUserInfo(user);
        // Set language for web page
        this.switchLanguage(user.language);
      })
    }
  }

  /**
   * getUsers
   * Get all users in admin management screen.
   * 
   * @returns  boolean
   */
  @action
  async getUsers() {
    const { size, page, sortDir, sortKey } = this.paging;

    let payload = { size, page, sortDir, sortKey };

    let response = await ApiStore.call(this.api, this.api.getAll, payload);
    if (response) {
      runInAction(() => {
        this.updatePagingFiler(response.paging);
        this.users = response.elements;

        if (this.paging?.totalRecord && this.paging?.totalRecord !== 0) {
          if (this.paging?.page > 1 && (!response.elements || response.elements.length === 0)) {
            this.updatePagingFiler({ page: this.paging.page - 1 });
            this.getUsers();
          }
        }
      })
    }
  }

  /**
   * getUsersContainAdmin
   * Get all users in admin management screen.
   * 
   * @returns  boolean
   */
  @action
  async getUsersContainAdmin() {
    const { size, page, sortDir, sortKey } = this.paging;

    let payload = { size, page, sortDir, sortKey };

    let response = await ApiStore.call(this.api, this.api.getAllContainAdmin, payload);
    if (response) {
      runInAction(() => {
        this.updatePagingFiler(response.paging);
        this.users = response.elements;

        if (this.paging?.totalRecord && this.paging?.totalRecord !== 0) {
          if (this.paging?.page > 1 && (!response.elements || response.elements.length === 0)) {
            this.updatePagingFiler({ page: this.paging.page - 1 });
            this.getUsersContainAdmin();
          }
        }
      })
    }
  }

  /**
   * getUserById
   * Get all users in admin management screen.
   * 
   * @returns  boolean
   */
  @action
  async getUserById(userId, cb) {
    // Get admin
    let user = await ApiStore.call(this.api, this.api.getUser, userId);
    if (user) {
      runInAction(() => {
        this.selectedUser = user;
        cb && cb();
      })
    }
  }

  /**
   * createUser
   * Create new user
   * 
   * @returns  null
   */
  @action
  async createUser(payload, cb = null, rb = null) {
    let resp = await ApiStore.call(this.api, this.api.createUser, payload);
    if (resp) {
      runInAction(() => {
        let isDeleted = resp?.statusCode === USER_DELETED ? true : false;
        if (!isDeleted) {
          this.users.push(resp);
        }
        cb && cb(isDeleted);
      })
    } else {
      rb && rb();
    }
  }

  /**
   * updateUser
   * Update user information
   * 
   * @returns  null
   */
  @action
  async updateUser(payload, cb, rb, t) {
    let resp = await ApiStore.call(this.api, this.api.updateUser, payload);
    if (resp) {
      runInAction(() => {
        const userDetail = resp;
        const index = this.users.findIndex(
          o => o.userId === payload.userId
        );
        this.users[index] = userDetail;
        cb && cb();
      });
    } else {
      rb && rb();
    }
  }

  /**
   * updateUserStatus
   * Update user status
   * 
   * @returns  null
   */
  @action
  async updateUserStatus(payload) {
    let resp = await ApiStore.call(this.api, this.api.updateUserStatus, payload);
    if (resp) {
      runInAction(() => {
        this.getUsersContainAdmin();
      })
    }
  }


  /**
   * updateAdmin
   * Update admin information
   * 
   * @returns  null
   */
  @action
  async updateAdmin(payload, cb) {
    let resp = await ApiStore.call(this.api, this.api.updateAdmin, payload);
    if (resp) {
      runInAction(() => {
        this.userInfo = Object.assign(this.userInfo, resp);
        console.log("UserStore -> updateAdmin -> resp", this.userInfo, resp)
        this.userInfo.userName = resp.userName;
        cb && cb();
      });
    }
  }

  @action
  resetUserInfo() {
    this.userInfo = {};
  }

  /**
   * deleteUser
   * delete user
   * 
   * @returns  null
   */
  @action
  async deleteUser(userId) {
    let resp = await ApiStore.call(this.api, this.api.deleteUser, userId);
    if (resp) {
      runInAction(() => {
        this.getUsersContainAdmin();
      })
    }
  }


  /**
   * saveUserInfo
   * save user infomation to local storage
   * 
   * @param {Object} data : user object
   * @param {boolean} rememberMe : rememberMe
   * 
   * @returns  null
   */
  saveUserInfo = (data) => {
    localStorage.setItem("userInfo", JSON.stringify(data));
  };

  showEditModal = (userId, cb) => {
    this.getUserById(userId, cb);
  }

  // Display language in the web page for user
  switchLanguage = (key) => {
    switch (key) {
      case LANGUAGE_CODE.JP:
        key = 'jp';
        break;
      case LANGUAGE_CODE.EN:
        key = 'en';
        break;
      default:
        key = 'jp';
        break;
    }

    i18n.changeLanguage(key);
    localStorage.setItem("language", key);
  };

  @action
  setMonth = (value) => {
    this.month = value;
  }

  @action
  setYear = (value) => {
    this.year = value;
  }

  @action
  async getMonthlyAmount() {
    let payload = {
      year: this.year,
      month: this.month,
    };
    let resp = await ApiStore.call(this.api, this.api.getMonthlyAmount, payload);
    if (resp) {
      runInAction(() => {
        if (resp.data != null) {
          this.userInfo.useAmount = resp.data;
        } else {
          this.userInfo.useAmount = resp;
        }
      })
    }
  }

  /**
   * unlockUser
   * unlock user
   * 
   * @returns  null
   */
  @action
  async unlockUser(userId) {
    let resp = await ApiStore.call(this.api, this.api.unlockUser, userId);
    if (resp) {
      runInAction(() => {
        this.getUsersContainAdmin();
      })
    }
  }

}
export default UserStore;
