import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { InputWrap, InputField, InputCheckbox } from "components";
import { observer, inject } from 'mobx-react';
import { DOWNLOAD_ACCESSOR_SEARCH_BOX, DATE_FORMAT, DATE_PLACHOLDER_FORMAT, DATE_SEPARATE, CONFIG_FILE_EDITION, EMPTY_FORMAT_CELL, PREBUILT_MODELS } from '../../core/utils/constants';
import DateComponent from '../../components/DateComponent'
import "./style.scss";
import moment from 'moment'
@inject('commonStore', 'documentsDownloadStore', 'modalStore')
@observer
class SearchBox extends Component {
    tableOriginalSize = 0;
    constructor(props) {
        super(props);
        const { savedData } = this.props;
        this.state = {
            filterData: {},
            errors: {
                fromTime: "",
                toTime: "",
                hasError: () => {
                    return (this.state?.errors?.fromTime !== "" || this.state?.errors?.toTime !== "")
                }
            },
            isCollapseOut: (savedData?.isCollapseOut === true) ? savedData?.isCollapseOut : false,
        };
    }
    /**
     * handleFilterDataChange
     * handle all filter data change
     * update current state
     * 
     * @param {string} name name of the field
     * @param {string} value value of the field
     * 
     * @returns Null setState and rerender component
     */
    handleFilterDataChange = (name, value) => {
        const { documentsDownloadStore } = this.props;
        if ([DOWNLOAD_ACCESSOR_SEARCH_BOX.TO_TIME, DOWNLOAD_ACCESSOR_SEARCH_BOX.FROM_TIME].includes(name)) {
            if (value) {
                let timeStamp = moment(value).format(DATE_FORMAT.time_stamp)
                this.validationDateData(name, timeStamp)
            }
        }
        if ([DOWNLOAD_ACCESSOR_SEARCH_BOX.FORM_GROUP_ID].includes(name)) {
            this.props.documentsDownloadStore.getDefinitationsByGroup(value);
            this.props.documentsDownloadStore.resetConfigFileId();
        }
        if ([DOWNLOAD_ACCESSOR_SEARCH_BOX.TEAM_ID].includes(name)) {
            this.props.commonStore.teamStore.getUsersByTeamId(value);
            this.props.documentsDownloadStore.resetUserId();
        }
        let data = {}
        data[name] = value;
        documentsDownloadStore.updateTempSearchBoxFiler(data);
    }

    /**
     * componentWillMount
     * get definitions of user
     * 
     */
    componentWillMount() {
        this.props.documentsDownloadStore.getDefinitationsInTeam();
        this.props.documentsDownloadStore.getAllDefinitationsGroupInTeam();
        this.props.commonStore.teamStore.getTeamOfUser();
        this.props.commonStore.teamStore.getUsersInSameTeams();
    }

    componentDidMount() {
        const { isCollapseOut } = this.state;
        document.getElementById("collapsible").checked = isCollapseOut;
        this.documentHandleCollapse(!isCollapseOut);
        let tableContainerHeight = document.getElementsByClassName("table-container");
        if (tableContainerHeight && tableContainerHeight[0]) {
            this.tableOriginalSize = tableContainerHeight[0].offsetHeight;
        }

        this.tableHandler();
    }

    /**
     * validationDateData
     * 
     * rules:
     * fromTime is required when toTime has value
     * toTime is required when fromTime has value
     * fromTime is smaller than fromTime
     * 
     * @param {string} currentField name of the field
     * @param {string} newValue value of the field
     * 
     * @returns set errors setState and rerender component
     */
    validationDateData = (currentField, newValue) => {
        let filterData = this.state.filterData
        let errors = this.state.errors;

        errors.fromTime = "";
        errors.toTime = "";
        if (newValue) {
            if (currentField === DOWNLOAD_ACCESSOR_SEARCH_BOX.FROM_TIME && filterData.toTime && newValue > filterData.toTime) {
                errors.fromTime = 'search_box.from_time_greater_to_time';
            }
            if (currentField === DOWNLOAD_ACCESSOR_SEARCH_BOX.TO_TIME && filterData.fromTime && filterData.fromTime > newValue) {
                errors.toTime = 'search_box.from_time_greater_to_time';
            }
        }
        this.setState({ errors: { ...this.state.errors, ...errors } })
    }

    documentHandleCollapse(isCollapseOut) {
        let element = document.getElementById("collapse-header");
        if (isCollapseOut && element) {
            element.classList.add("collapse_in");
            element.classList.remove("collapse_out");
        } else {
            element.classList.remove("collapse_in");
            element.classList.add("collapse_out");
        }
    }

    collapseHander = (e) => {
        const { isCollapseOut } = this.state;
        const { documentsDownloadStore } = this.props;

        documentsDownloadStore.updateSearchBoxCollapse(!isCollapseOut);
        this.documentHandleCollapse(isCollapseOut);
        document.getElementById("collapsible").checked = !isCollapseOut
        this.setState({ isCollapseOut: !isCollapseOut });
        this.tableHandler();
    }

    tableHandler = () => {
        // compute table.
        // search box height
        let collapsibleContentHeight = document.getElementsByClassName("collapsible-content");
        if (collapsibleContentHeight && collapsibleContentHeight[0]) {
            let tableContainerHeight = document.getElementsByClassName("table-container");
            if (tableContainerHeight && tableContainerHeight[0]) {
                let tableHeight = tableContainerHeight[0].offsetHeight;
                if (collapsibleContentHeight[0].offsetHeight === 0) {
                    tableContainerHeight[0].style.height = this.tableOriginalSize - 2 + "px";
                } else {
                    tableContainerHeight[0].style.height = tableHeight - collapsibleContentHeight[0].offsetHeight + 10.7 + "px";
                }

                // compute for table body
                let table = document.getElementsByTagName("table");
                if (table && table[0]) {
                    let tableHead = document.getElementsByTagName("thead");
                    if (tableHead && tableHead[0]) {
                        let tableBody = document.getElementsByTagName("tbody");
                        if (tableBody && tableBody[0]) {
                            let tableBodyHeight = table[0].offsetHeight - tableHead[0].offsetHeight;
                            tableBody[0].style.height = tableBodyHeight + "px";
                        }
                    }
                }
            }
        }
    }

    /**
     * render
     * render search box
     * 
     */
    render() {
        const { t, commonStore: { teamStore } } = this.props;
        return (
            <div className="search-form">
                <div id="collapse-header" className="collapse-header" onClick={() => this.collapseHander()}>
                    <h4 className="sb-form-title">{t("download_search_box.narrowing_conditions")}</h4>
                </div>
                <input id="collapsible" className="toggle" type="checkbox"></input>

                <div className="collapsible-content row document-search-pannel">
                    <div className="row row-1st" style={{ display: "flex" }}>
                        <div style={{ display: "flex", lineHeight: "30px" }}>
                            <label>{t("download_search_box.file_name")}</label>
                            <InputField
                                type="text"
                                name={DOWNLOAD_ACCESSOR_SEARCH_BOX.FILE_NAME_INPUT}
                                placeholder={t("download_search_box.file_name_place_holder")}
                                onChange={this.handleFilterDataChange}
                                value={this.props.documentsDownloadStore.tempFilterData?.filenameOriginal}
                            />
                        </div>

                        <div className="l20">
                            <label>{t("download_search_box.formGroup")}</label>
                            <select className="select download-select"
                                onChange={(e) => this.handleFilterDataChange(DOWNLOAD_ACCESSOR_SEARCH_BOX.FORM_GROUP_ID, e?.target?.value)}
                                value={this.props.documentsDownloadStore.tempFilterData?.formGroupId ? this.props.documentsDownloadStore.tempFilterData?.formGroupId : ""}>
                                <option key={"null"} value={"null"}>{t("download_search_box.all_definition_file")}</option>
                                {
                                    this.props?.documentsDownloadStore?.listGroupDefinitions.map((item) => {
                                        if (item?.edition == CONFIG_FILE_EDITION.PREBUILT) {
                                            return <option key={item?.id} value={item?.id}>{t("documents_download.prebuilt")}</option>
                                        }
                                        return <option key={item?.id} value={item?.id}>{item?.groupName}</option>
                                    })
                                }
                            </select>
                        </div>

                        <div className="l20">
                            <label>{t("download_search_box.definition_file")}</label>
                            <select className="select download-select"
                                onChange={(e) => this.handleFilterDataChange(DOWNLOAD_ACCESSOR_SEARCH_BOX.CONFIG_FILE_ID, e?.target?.value)}
                                value={this.props.documentsDownloadStore.tempFilterData?.configFileId ? this.props.documentsDownloadStore.tempFilterData?.configFileId : ""}>
                                <option key={"null"} value={"null"}>{t("download_search_box.all_definition_file")}</option>
                                {
                                    this.props?.documentsDownloadStore?.listAllDefinitions.map((item) => {
                                        if (item?.edition == CONFIG_FILE_EDITION.PREBUILT) {
                                            switch (item?.configName) {
                                                case PREBUILT_MODELS.INVOICE:
                                                    return <option key={item?.id} value={item?.id}>{t("documents_download.prebuilt_invoice")}</option>
                                                case PREBUILT_MODELS.RECEIPT:
                                                    return <option key={item?.id} value={item?.id}>{t("documents_download.prebuilt_receipt")}</option>
                                                default:
                                                    return EMPTY_FORMAT_CELL;
                                            }
                                        }
                                        return <option key={item?.id} value={item?.id}>{item?.configName}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <div className="row row-2nd">
                        <div style={{ display: "flex" }}>
                            <div style={{ lineHeight: "30px" }}>
                                <label>{t("download_search_box.registered_date")}</label>
                            </div>
                            <div className={"searchbox-dp " + (this.state.errors[DOWNLOAD_ACCESSOR_SEARCH_BOX.FROM_TIME] !== "" ? "error" : "")}>
                                <InputWrap customClass="registered-date-input-wrap">
                                    <div className="input-group date">
                                        <label className="date-label">
                                            <div className="input-group-addon">
                                                <i className="fa fa-calendar"></i>
                                            </div>
                                            <DateComponent placeholder={DATE_PLACHOLDER_FORMAT} name={DOWNLOAD_ACCESSOR_SEARCH_BOX.FROM_TIME} onChange={this.handleFilterDataChange}
                                                value={this.props.documentsDownloadStore?.tempFilterData?.fromTime} maxDate={new Date()} />
                                        </label>
                                    </div>
                                    <span className="color-red">{t(this.state.errors[DOWNLOAD_ACCESSOR_SEARCH_BOX.FROM_TIME])}</span>
                                </InputWrap>
                            </div>
                            <div className="sb-dp-separate">
                                <label>{DATE_SEPARATE}</label>
                            </div>
                            <div className={"searchbox-dp " + (this.state.errors[DOWNLOAD_ACCESSOR_SEARCH_BOX.TO_TIME] !== "" ? "error" : "")}>
                                <InputWrap>
                                    <label className="hideLabel">{t("download_search_box.to_date")}</label>
                                    <div className="input-group date ">
                                        <label className="date-label">
                                            <div className="input-group-addon">
                                                <i className="fa fa-calendar"></i>
                                            </div>
                                            <DateComponent placeholder={DATE_PLACHOLDER_FORMAT} name={DOWNLOAD_ACCESSOR_SEARCH_BOX.TO_TIME} onChange={this.handleFilterDataChange}
                                                value={this.props.documentsDownloadStore?.tempFilterData?.toTime} maxDate={new Date()} />
                                        </label>
                                    </div>
                                </InputWrap>
                                <span className="color-red">{t(this.state.errors[DOWNLOAD_ACCESSOR_SEARCH_BOX.TO_TIME])}</span>
                            </div>
                        </div>

                        <div className="l10">
                            <label>{t("download_search_box.team_name")}</label>
                            <select className="select download-select"
                                onChange={(e) => this.handleFilterDataChange(DOWNLOAD_ACCESSOR_SEARCH_BOX.TEAM_ID, e?.target?.value)}
                                value={this.props.documentsDownloadStore.tempFilterData?.teamId ? this.props.documentsDownloadStore.tempFilterData?.teamId : ""}>
                                <option key={"null"} value={"null"}>{t("download_search_box.all_definition_file")}</option>
                                {
                                    teamStore.teamsOfUser?.map((team) => {
                                        return <option key={team?.id} value={team?.id}>{team?.name}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="l10">
                            <label>{t("download_search_box.user_id")}</label>
                            <select className="select download-select"
                                onChange={(e) => this.handleFilterDataChange(DOWNLOAD_ACCESSOR_SEARCH_BOX.USER_ID, e?.target?.value)}
                                value={this.props.documentsDownloadStore.tempFilterData?.userId ? this.props.documentsDownloadStore.tempFilterData?.userId : ""}>
                                <option key={"null"} value={"null"}>{t("download_search_box.all_definition_file")}</option>
                                {
                                    teamStore.usersInSameTeams?.map((user) => {
                                        return <option key={user?.userId} value={user?.userId}>{user?.userId}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <div className="row row-3rd">
                        <div>
                            <label className="lbl-status">{t("download_search_box.status")}</label>
                        </div>
                        <div>
                            <InputCheckbox
                                id={DOWNLOAD_ACCESSOR_SEARCH_BOX.STATUS_NOT_DOWNLOADED}
                                type="checkbox"
                                label={t("download_search_box.not_downloaded")}
                                onChange={(e) => this.handleFilterDataChange(DOWNLOAD_ACCESSOR_SEARCH_BOX.STATUS_NOT_DOWNLOADED, e?.target?.checked)}
                                checked={this.props.documentsDownloadStore?.tempFilterData?.notDownloaded ? true : false}
                            />
                            <InputCheckbox
                                id={DOWNLOAD_ACCESSOR_SEARCH_BOX.STATUS_DOWNLOADED}
                                type="checkbox"
                                label={t("download_search_box.downloaded")}
                                onChange={(e) => this.handleFilterDataChange(DOWNLOAD_ACCESSOR_SEARCH_BOX.STATUS_DOWNLOADED, e?.target?.checked)}
                                checked={this.props.documentsDownloadStore?.tempFilterData?.downloaded ? true : false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(SearchBox);
