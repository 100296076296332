import { observable, action, configure } from "mobx";
import { ReactNotifications } from "core/logic";

import { Browser } from "core/utils";
import i18n from "../i18n";
// don't allow state modifications outside actions
configure({
  enforceActions: "observed"
});
class ApiStore {

  requestCount = 0;
  isRes202 = false;
  @observable loading = false;

  @action showLoading = () => {
    this.loading = true;
  };
  @action hideLoading = () => {
    this.loading = false;
  };
  @action setLoading(loading) {
    this.loading = loading;
  }

  handlerGeneralResponse = (response) => {
    if (response?.status === 401) {
      const sessionId = Browser.getSavedInfo("sessionId");
      localStorage.removeItem("sessionId");
      sessionStorage.removeItem("sessionId");
      if (sessionId && sessionId !== "undefined" && window.location.href !== "/login") {
        window.location.href = "/login";
      }
    }
    if (response?.status === 200) {
      if (response?.headers?.get("Content-Type") === "application/json") {
        return response?.json();
      } else {
        return response;
      }
    }
    if (response?.status === 202) {
      this.isRes202 = true;
      if (response?.headers?.get("Content-Type") === "application/json") {
        return response?.json();
      } else {
        return response;
      }
    }
    throw response;
  }

  handlerGeneralResponseError = (response, handleErrorByChild, fb = null) => {
    if (handleErrorByChild) {
      throw response;
    }

    try {
      if (response?.status >= 500) {
        if (response?.message) {
          ReactNotifications("error", response?.message, "");
        } else {
          ReactNotifications("error", i18n.t("api.response.server_error"), "");
        }
        return;
      } else if (response?.status) {
        response = response.json();
      }
      response.then(error => {
        if (error?.message) {
          ReactNotifications("error", error?.message, "");
        }
        else {
          ReactNotifications("error", i18n.t("api.response.no_message"), "");
        }
        fb && fb();
      })
    } catch {
      ReactNotifications("error", i18n.t("api.response.no_network"), "");
    }
  }

  call = async (context, request, payload = null, handleErrorByChild = false, fb = null, disableLoading = false) => {
    this.requestCount += 1;
    // Loading
    if (!disableLoading) this.showLoading();
    return request.apply(context, Object.prototype.toString.call(payload) === '[object Array]' ? payload : [payload])
      .then(this.handlerGeneralResponse)
      .then(response => {
        if (response) {
          if (this.isRes202 && response?.message) {
            ReactNotifications("error", response?.message, "");
          }
          if (response.data) {
            return response.data
          }
          return response;
        } else {
          ReactNotifications("error", i18n.t("api.response.worng_format"), "");
        }
      })
      .catch((repsonse) => this.handlerGeneralResponseError(repsonse, handleErrorByChild, fb))
      .finally(() => {
        this.isRes202 = false;
        this.requestCount -= 1;
        if (this.requestCount === 0) {
          this.hideLoading();
        }
      });
  };

  newCall = async (context, request, payload = null) => {
    // Loading
    return request.apply(context, [payload])
      .then(this.handlerGeneralResponse)
      .then(response => {
        if (response) {
          if (response.data) {
            return response.data
          }
          return response;
        } else {
          ReactNotifications("error", i18n.t("api.response.worng_format"), "");
        }
      }).catch(this.handlerGeneralResponseError)
  };
}
export default new ApiStore();
