import React, { Component } from "react";
import { observer } from "mobx-react";
import { Route, Switch } from "react-router-dom";
import ScreenHome from "./Home/ScreenHome";
import ScreenUpload from "./Upload/ScreenUpload";
import Documents from "./Documents/Documents";
import ScreenEdit from "./Documents/Edit/ScreenEdit";
import ScreenAdmin from "./Admin/ScreenAdmin";
import ScreenTenant from "./Tenant/ScreenTenant";
import GeneralSetting from "./GeneralSetting/GeneralSetting";
import IndividualSetting from "./IndividualSetting/ScreenIndividualSetting";
import IndividualModel from "./IndividualModel/ScreenIndividualModel";
import ScreenFormSetting from "./FormSetting/ScreenFormSetting";
import ScreenTeam from "./Team/ScreenTeam";
import ScreenEditTeam from "./Team/ScreenEditTeam";
import PageNotFound from "./404/404"
import { ContainerBody } from "../components";
import { secured } from "core/logic";
import { SYSTEM_PATH } from "../core/utils/constants";
import ScreenDefinition from "./FormDefinition/ScreenDefinition";
import ScreenFormGroup from "./FormDefinition/ScreenFormGroup";
import ScreenPostProcessing from "./PostProcessing/ScreenPostProcessing";
import ScreenTemplateSortingSetting from "./TemplateSorting/ScreenTemplateSortingSetting";
import ScreenEditTemplateSorting from "./TemplateSorting/ScreenEditTemplateSorting";
import ScreenRegistTemplates from "./TemplateSorting/ScreenRegistTemplates";
import ActionLogs from "./ActionLogs/ActionLogs";
import ScreenDownload from "./DocumentDownload/ScreenDownload";
@secured
@observer
class ScreensRoot extends Component {
  render() {
    return (

      <ContainerBody disableTrackScrollBar={true}>
        <Switch>
          {/* <Route exact path="/" component={ScreenHome} />
                <Route path="/home" component={ScreenHome} />
                <Route path="/login" component={ScreenLogin} />
                <Route path="/upload" component={ScreenUpload} />
                <Route
                    path="/download"
                    component={ScreenDownload}
                />
                <Route path="/edit/:id" component={ScreenEdit} />
                <Route path="/admin" component={ScreenAdmin} />
                <Redirect to={"/"} /> */}
          <Route exact path={SYSTEM_PATH.HOME} component={ScreenHome} />
          <Route path={SYSTEM_PATH.ADMIN} component={ScreenAdmin} />
          <Route exact path={SYSTEM_PATH.DOCUMENT} component={Documents} />
          <Route path={SYSTEM_PATH.EDIT_DOCUMENT} component={ScreenEdit} />
          <Route path={SYSTEM_PATH.UPLOAD} component={ScreenUpload} />
          <Route path={SYSTEM_PATH.DOCUMENT_DOWNLOAD} component={ScreenDownload} />
          <Route exact path={SYSTEM_PATH.FORM_DEFINITION} component={ScreenDefinition} />
          <Route path={SYSTEM_PATH.FORM_GROUP} component={ScreenFormGroup} />
          <Route path={SYSTEM_PATH.TENANT} component={ScreenTenant} />
          <Route path={SYSTEM_PATH.GENERAL_SETTING} component={GeneralSetting} />
          <Route path={SYSTEM_PATH.INDIVIDUAL_SETTING} component={IndividualSetting} />
          <Route path={SYSTEM_PATH.MODEL_MNG} component={IndividualModel} />
          <Route path={SYSTEM_PATH.FORM_SETTING} component={ScreenFormSetting} />
          <Route exact path={SYSTEM_PATH.TEAM} component={ScreenTeam} />
          <Route path={SYSTEM_PATH.EDIT_TEAM} component={ScreenEditTeam} />
          <Route path={SYSTEM_PATH.POST_PROCESSING} component={ScreenPostProcessing} />
          <Route exact path={SYSTEM_PATH.TEMPLATE_SORTING} component={ScreenTemplateSortingSetting} />
          <Route path={SYSTEM_PATH.EDIT_TEMPLATE_SORTING} component={ScreenEditTemplateSorting} />
          <Route path={SYSTEM_PATH.REGIST_TEMPLATES} component={ScreenRegistTemplates} />
          <Route path={SYSTEM_PATH.ACTION_LOG} component={ActionLogs} />
          <Route>
            <PageNotFound />
          </Route>
        </Switch>
      </ContainerBody >
    );
  }
}

export default ScreensRoot;
