import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer, MobXProviderContext } from 'mobx-react';
import { useForm } from "react-hook-form";
import { Scrollbars } from "react-custom-scrollbars";
import { ModalFooter } from "components";
import { Validate } from "core/utils";
import "./style.scss";

function useStores() {
  return React.useContext(MobXProviderContext)
}

const ChangePassword = observer((props) => {

  const { modalStore, authStore, authStore: { resetPasswordFormData } } = useStores();

  const { t } = useTranslation();

  const { register, errors, setError, handleSubmit } = useForm({
    defaultValues: resetPasswordFormData
  });

  const closeModal = () => {
    modalStore.hide();
  }

  const openComfirmModal = (data) => {
    modalStore.show({
      id: "confirm",
      isOpen: true,
      header: t("admin.update_confirm"),
      onCancel: () => closeModal(),
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>
              {t("admin.change_information")}
            </span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t("admin.save")}
            onConfirm={async () => {
              const response = await authStore.changePassword({
                "old_password": data.oldPassword,
                "new_password": data.newPassword
              });
              if (response) {
                modalStore.hideAll();
                authStore.loadDefaultResetPasswordFormData();
              } else {
                modalStore.hide();
              }
            }}
            cancelButtonText={t("admin.cancel")}
            onCancel={() => closeModal()}
          />
        </div>
      ),
      type: "small"
    });
  };

  const onSubmit = data => {
    if (Validate.validateConfirmPass(data.newPassword, t, data.confirmPassword)) {
      setError("confirmPassword", {
        type: "confirm_not_match",
        message: Validate.validateConfirmPass(data.newPassword, t, data.confirmPassword)
      });
    } else if (Validate.validatePassword(data.newPassword, t)) {
      setError("newPassword", {
        type: "password_condition",
        message: Validate.validatePassword(data.newPassword, t)
      });
    } else {
      authStore.setResetPasswordFormData(data);
      openComfirmModal(data);
    }
  }

  useEffect(() => {
    console.log("didmount");
  }, []);

  return <div className="wrap-change-password">
    <Scrollbars key={"modal-body"} className="modal-body">
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          <span className="color-red">*</span>
          {t("admin.required")}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          {t("change_password.old_password")}
          <span className="color-red">*</span>
        </div>
        <div className="col-sm-6">
          <input
            className={errors["oldPassword"] && "error-input"}
            name="oldPassword"
            autoComplete="new-password"
            type="password"
            ref={register({ required: t("validate.field_is_empty") })}
          />
          {errors["oldPassword"] && <div className="color-red">{errors["oldPassword"]?.message}</div>}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          {t("change_password.new_password")}
          <span className="color-red">*</span>
        </div>
        <div className="col-sm-6">
          <input maxLength={255} className={errors["newPassword"] && "error-input"} name="newPassword" autoComplete="new-password" type="password" ref={register({ required: t("validate.field_is_empty") })} />
          {errors["newPassword"] && <div className="color-red">{errors["newPassword"]?.message}</div>}
        </div>
      </div>
      {errors["newPassword"]?.type !== "password_condition" && <div className="row">
        <div className="col-sm-5"></div>
        <div className="col-sm-6">{t("validate.password_condition")}</div>
      </div>}
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          {t("change_password.confirm_password")}
          <span className="color-red">*</span>
        </div>
        <div className="col-sm-6">
          <input maxLength={255} className={errors["confirmPassword"] && "error-input"} name="confirmPassword" autoComplete="new-password" type="password" ref={register({ required: t("validate.field_is_empty") })} />
          {errors["confirmPassword"] && <div className="color-red">{errors["confirmPassword"]?.message}</div>}
        </div>
      </div>
    </Scrollbars>
    <ModalFooter key={"modal-footer"}
      saveButtonText={t('change_password.submit')}
      onConfirm={handleSubmit(onSubmit)}
      cancelButtonText={t('admin.cancel')}
      onCancel={() => {
        authStore.loadDefaultResetPasswordFormData();
        modalStore.hideAll();
      }}
      saveButtonClass="btn-primary"
    />
  </div>
});

export default ChangePassword;