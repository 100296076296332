import { DEFAULT_PAGE_SIZE } from "../core/utils/constants";

class BaseStore {
  paging = {
    page: 1,
    totalPage: 0,
    totalRecord: 0,
    size: DEFAULT_PAGE_SIZE,
    sortKey: null,
    sortDir: null,
  };

  updatePagingFiler(value = null) {
    this.paging = { ...this.paging, ...value };
  }

  clean() {
    this.paging = {
      page: 1,
      totalPage: 0,
      totalRecord: 0,
      size: DEFAULT_PAGE_SIZE,
      sortKey: null,
      sortDir: null,
    };
  }
}
export default BaseStore;
