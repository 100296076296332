import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer, MobXProviderContext } from 'mobx-react';
import { useForm, Controller } from "react-hook-form";
import { Scrollbars } from "react-custom-scrollbars";
import { ModalFooter } from "components";
import { Validate } from "core/utils";
import DateComponent from '../../components/DateComponent';
import { DATE_FORMAT, LANGUAGE_CODE_DEFAULT, RENEWAL_SPAN_CODE_DEFAULT } from "../../core/utils/constants";
import moment from "moment";
import "./style.scss";

function useStores() {
  return React.useContext(MobXProviderContext)
}

const CreateTenant = observer((props) => {

  const [passwordType, setPasswordType] = useState("text");
  
  const [showPass, setShowPass] = useState(true);

  const { modalStore, commonStore, commonStore: { tenantStore } } = useStores();

  const { t } = useTranslation();

  const { control, register, errors, setError, handleSubmit, setValue } = useForm({
    defaultValues: tenantStore.tenantFormData
  });

  const closeModal = () => {
    modalStore.hide();
  }

  const openComfirmModal = (data) => {
    modalStore.show({
      id: "confirm",
      isOpen: true,
      type: "small",
      header: t("tenant.create_tenant"),
      onCancel: () => closeModal(),
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>
              {t("tenant.create_tenant_confirm")}
            </span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t("tenant.submit")}
            onConfirm={async () => {
              const response = await commonStore.tenantStore.createTenant(data);
              if (response) {
                commonStore.tenantStore.getTenants();
                commonStore.tenantStore.loadDefaultTenantFormData();
                modalStore.hideAll();
              } else {
                closeModal();
              }
            }}
            cancelButtonText={t("tenant.cancel")}
            onCancel={() => closeModal()}
          />
        </div>
      ),
    });
  };

  const handleMaxNumber = (value, field) => {
    if (value > 1000000000) {
      setValue(field, 1000000000)
    }
  }

  const handleChangeShowPassword = (value) => {
    if (value) setPasswordType("text");
    else setPasswordType("password");
    setShowPass(value);
  }

  const onSubmit = data => {
    if (Validate.validateConfirmPass(data.adminPass, t, data.confirmPassword)) {
      setError("confirmPassword", {
        type: "confirm_not_match",
        message: Validate.validateConfirmPass(data.adminPass, t, data.confirmPassword)
      });
    } else if (Validate.validatePassword(data.adminPass, t)) {
      setError("adminPass", {
        type: "password_condition",
        message: Validate.validatePassword(data.adminPass, t)
      });
    } else if (Validate.validateEmailAdmin(data.adminId, t)) {
      setError("adminId", {
        type: "password_condition",
        message: Validate.validateEmailAdmin(data.adminId, t)
      });
    } else if (Validate.validateContractNumber(data.total, t)) {
      setError("total", {
        type: "password_condition",
        message: Validate.validateContractNumber(data.total, t)
      });
    } else if (Validate.validateAccountNumber(data.userActive, t)) {
      setError("userActive", {
        type: "password_condition",
        message: Validate.validateAccountNumber(data.userActive, t)
      });
    } else if (data.startDate && data.endDate && Validate.validateDateRange(moment(data.startDate).format(DATE_FORMAT.time_stamp), moment(data.endDate).format(DATE_FORMAT.time_stamp), t)) {
      setError("startDate", {
        type: "password_condition",
        message: Validate.validateDateRange(moment(data.startDate).format(DATE_FORMAT.time_stamp), moment(data.endDate).format(DATE_FORMAT.time_stamp), t)
      });
    } else {
      const payload = {
        "tenantName": data.name,
        "contractAmount": data.total,
        "contractAccount": data.userActive,
        "contractRenewalSpan": data.renewalSpan ? parseInt(data.renewalSpan) : RENEWAL_SPAN_CODE_DEFAULT,
        "teamName": data.teamName,
        "admin": {
          "userId": data.adminId,
          "userName": data.adminAccount,
          "password": data.adminPass,
          "languageSetting": data.language ? parseInt(data.language) : LANGUAGE_CODE_DEFAULT
        },
      };
      if (data.startDate) payload.startDate = moment(data.startDate).format(DATE_FORMAT.time_stamp);
      if (data.endDate) payload.endDate = moment(data.endDate).format(DATE_FORMAT.time_stamp);
      commonStore.tenantStore.setTenantFormData(data);
      openComfirmModal(payload);
    }
  }

  return <div className="wrap-create-tenant">
    <Scrollbars key={"modal-body"} className="modal-body">
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          <span className="color-red">*</span>
          {t("tenant.required")}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          {t("tenant.name")}
          <span className="color-red">*</span>
        </div>
        <div className="col-sm-6">
          <input
            placeholder={t("tenant.name_place_holder")}
            className={errors["name"] && "error-input"}
            name="name"
            type="text"
            maxLength={255}
            ref={register({ required: t("validate.field_is_empty") })}
          />
          {errors["name"] && <div className="color-red">{errors["name"]?.message}</div>}
        </div>
      </div>
      <div className="row">
      <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          {t("tenant.language")}
          <span className="color-red">*</span>
        </div>
        <div className="col-sm-6">
          <select className="slct" name="language" ref={register}>
              <option value="0">{t("tenant.language.japan")}</option>
              <option value="1">{t("tenant.language.english")}</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          {t("tenant.date_range")}
          {/* <span className="color-red">*</span> */}
        </div>
        <div className="col-sm-3 input-date-range">
          <Controller
            render={({ onChange, onBlur, value }) => <div className="input-group date">
              <label className="date-label">
                <div className="input-group-addon">
                  <i className="fa fa-calendar"></i>
                </div>
                <DateComponent className={errors["startDate"] && "error-input"} value={value} onChange={(e, dateValue) => onChange(dateValue)} />
              </label>
            </div>
            }
            name="startDate"
            control={control}
          // rules={{ required: t("validate.field_is_empty") }}
          />
          {/* {errors["startDate"] && <div className="color-red">{errors["startDate"]?.message}</div>} */}
        </div>
        <div className="col-sm-3 input-date-range">
          <Controller
            render={({ onChange, onBlur, value }) => <div className="input-group date">
              <label className="date-label">
                <div className="input-group-addon">
                  <i className="fa fa-calendar"></i>
                </div>
                <DateComponent className={errors["endDate"] && "error-input"} value={value} onChange={(e, dateValue) => onChange(dateValue)} />
              </label>
            </div>}
            name="endDate"
            control={control}
          // rules={{ required: t("validate.field_is_empty") }}
          />
          {/* {errors["endDate"] && <div className="color-red">{errors["endDate"]?.message}</div>} */}
        </div>
        {errors["startDate"] && <div className="col-sm-5"></div>}
        {errors["startDate"] && <div className="col-sm-7"><div className="color-red">{errors["startDate"]?.message}</div></div>}
      </div>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          {t("tenant.total")}
          <span className="color-red">*</span>
        </div>
        <div className="col-sm-6">
          <input
            min={100}
            placeholder={t("tenant.total_place_holder")}
            className={errors["total"] && "error-input"}
            name="total"
            type="number"
            onChange={e => handleMaxNumber(e.target.value, "total")}
            ref={register({ required: t("validate.field_is_empty") })}
          />
          {errors["total"] && <div className="color-red">{errors["total"]?.message}</div>}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          {t("tenant.renewal_span")}
          <span className="color-red">*</span>
        </div>
        <div className="col-sm-6">
          <select className="slct" name="renewalSpan" ref={register}>
              <option value="0">{t("tenant.renewal_span_month")}</option>
              <option value="1">{t("tenant.renewal_span_year")}</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          {t("tenant.active_user")}
          <span className="color-red">*</span>
        </div>
        <div className="col-sm-6">
          <input
            min={1}
            onChange={e => handleMaxNumber(e.target.value, "userActive")}
            onPaste={e => e.preventDefault()}
            placeholder={t("tenant.active_user_place_holder")}
            className={errors["userActive"] && "error-input"}
            name="userActive"
            type="number"
            ref={register({ required: t("validate.field_is_empty") })}
          />
          {errors["userActive"] && <div className="color-red">{errors["userActive"]?.message}</div>}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          {t("tenant.admin_id")}
          <span className="color-red">*</span>
        </div>
        <div className="col-sm-6">
          <input maxLength={255} placeholder={t("tenant.admin_id_place_holder")} className={errors["adminId"] && "error-input"} name="adminId" type="text" ref={register({ required: t("validate.field_is_empty") })} />
          {errors["adminId"] && <div className="color-red">{errors["adminId"]?.message}</div>}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          {t("tenant.admin_name")}
          <span className="color-red">*</span>
        </div>
        <div className="col-sm-6">
          <input maxLength={255} placeholder={t("tenant.admin_name_place_holder")} className={errors["adminAccount"] && "error-input"} name="adminAccount" autoComplete="new-password" type="text" ref={register({ required: t("validate.field_is_empty") })} />
          {errors["adminAccount"] && <div className="color-red">{errors["adminAccount"]?.message}</div>}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          {t("tenant.team_name")}
          <span className="color-red">*</span>
        </div>
        <div className="col-sm-6">
          <input maxLength={255} placeholder={t("tenant.team_name")} className={errors["teamName"] && "error-input"} name="teamName" type="text" ref={register({ required: t("validate.field_is_empty") })} />
          {errors["teamName"] && <div className="color-red">{errors["teamName"]?.message}</div>}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-5"></div>
        <div className="col-sm-6" style={{ display: "flex", flexDirection: "row", justifyContent: "start" }}>
          <input checked={showPass} onChange={e => handleChangeShowPassword(e.currentTarget.checked)} type="checkbox" className="ck-show-pass" />
          <label for="vehicle1">{t("tenant.show_password")}</label>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          {t("tenant.admin_password")}
          <span className="color-red">*</span>
        </div>
        <div className="col-sm-6">
          <input maxLength={255} placeholder={t("tenant.admin_password")} className={errors["adminPass"] && "error-input"} name="adminPass" autoComplete="new-password" type={passwordType} ref={register({ required: t("validate.field_is_empty") })} />
          {errors["adminPass"] && <div className="color-red">{errors["adminPass"]?.message}</div>}
        </div>
      </div>
      {errors["adminPass"]?.type !== "password_condition" && <div className="row">
        <div className="col-sm-5"></div>
        <div className="col-sm-6">{t("validate.password_condition")}</div>
      </div>}
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-4 font-bold">
          {t("tenant.confirm_password")}
          <span className="color-red">*</span>
        </div>
        <div className="col-sm-6">
          <input maxLength={255} placeholder={t("tenant.confirm_password")} className={errors["confirmPassword"] && "error-input"} name="confirmPassword" type={passwordType} ref={register({ required: t("validate.field_is_empty") })} />
          {errors["confirmPassword"] && <div className="color-red">{errors["confirmPassword"]?.message}</div>}
        </div>
      </div>
    </Scrollbars>
    <ModalFooter key={"modal-footer"}
      saveButtonText={t('tenant.submit')}
      onConfirm={handleSubmit(onSubmit)}
      cancelButtonText={t('tenant.cancel')}
      onCancel={() => { modalStore.hideAll(); commonStore.tenantStore.loadDefaultTenantFormData(); }}
      saveButtonClass="btn-primary"
    />
  </div>
});

export default CreateTenant;