import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import Modal from "react-modal";
import ModalContent from "./ModalContent";

import "./style.scss";
// for pdf files
@inject("modalStore")
@observer
class ReactModal extends Component {
  renderInstance = (instance, index) => {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)"
      }
    };
    const {
      isOpen,
      onAfterOpen,
      onRequestClose,
      contentLabel,
      header,
      saveButtonText,
      cancelButtonText,
      saveButtonClass,
      onConfirm,
      onCancel,
      children,
      type,
      notButtonX
    } = toJS(instance);
    const classN = "modal-" + type ?? "modal-content";
    return (
      <Modal
        key={index || "modal"}
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
        contentLabel={contentLabel}
      >
        <input type="hidden" value="prayer" />
        <ModalContent
          header={header}
          saveButtonText={saveButtonText}
          cancelButtonText={cancelButtonText}
          saveButtonClass={saveButtonClass}
          onConfirm={onConfirm}
          onCancel={onCancel}
          classN={classN}
          notButtonX={notButtonX}
        >
          {children}
        </ModalContent>
      </Modal>
    );
  };

  render() {
    const { instances } = this.props.modalStore;
    return (
      <div>
        {toJS(instances).map((item, index) => {
          return this.renderInstance(item, index);
        })}
      </div>
    );
  }
}

export default ReactModal;
