import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { MainSidebar, SwitchLanguages } from "components";
import { Scrollbars } from "react-custom-scrollbars";
import URL_CONFIG from '../../core/services/config';
import { MODAL_ID } from '../../core/utils/constants';
import { Browser } from '../../core/utils';
import "./style.scss";
import ChangePassword from "../ChangePassword";
@inject("authStore", "commonStore", "documentsStore", "modalStore")
@observer
class ContainerBody extends Component {

  onChangePassword = () => {
    const { modalStore, authStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.REMOVE,
      isOpen: true,
      header: t('change_password.title'),
      onCancel: () => {
        authStore.loadDefaultResetPasswordFormData();
        modalStore.hideAll();
      },
      children: <div key={"modal-body"} className="modal-body">
        <ChangePassword></ChangePassword>
      </div>,
      type: 'medium'
    });
  };

  onLogout = () => {
    const {
      authStore,
      history,
    } = this.props;
    authStore.logoff().finally(() => {
      history.push("/login");
    });
  };

  componentDidMount = () => {
    let sessionId = Browser.getSavedInfo("sessionId");
    const { commonStore: { userStore }, history } = this.props;
    if (sessionId && sessionId !== "undefined") {
      setTimeout(() => { userStore.getUserInfo(); }, 200);
    } else {
      //history.push("/login")
      history.push("/login", {from: this.props.location?.pathname});
    }

    let isCollapse = localStorage.getItem("isCollapse");
    if (isCollapse === "true") {
      let elements = document.getElementsByClassName("sidebar-toggle");
      if (elements && elements[0]) {
        elements[0].click();
      }
    }
    this.collapseHandler();
  }

  disableTrackScrollBar = (disabled) => {
    if (disabled) {
      return props => <div {...props} style={{ display: 'none' }} />
    }
  }

  renderCustomView = () => {
    return props => <div {...props} style={{}} className="scroll-bar-custom" />
  }

  collapseHandler = () => {
    let elements = document.getElementsByClassName("sidebar-collapse");
    if (elements && elements[0]) {
      localStorage.setItem("isCollapse", true);
    } else {
      localStorage.setItem("isCollapse", false);
    }
  }

  render() {
    const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
    const {
      commonStore: {
        userStore: { userInfo }
      },
      children,
      t,
      disableTrackScrollBar
    } = this.props;
    return (
      <div className="wrapper">
        <header className="main-header">
          <Link to="/" className="logo">
            <span className="logo-mini">
              <img src={"/favicon.ico"} alt="logo" />
            </span>
            <span className="logo-lg">
              <img src={"/favicon_full.png"} alt="logo" />
            </span>
          </Link>
          <nav className="navbar navbar-static-top">
            <span className="sidebar-toggle" data-toggle="push-menu" onClick={this.collapseHandler}></span>
            <div className="navbar-custom-menu">
              <ul className="nav navbar-nav">
                <li className="dropdown user user-menu">
                  <a
                    href="#section"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <img
                      src={userInfo.avatar ? userInfo.avatar : URL_CONFIG.default_avatar}
                      className="user-image"
                      alt="User"
                    />
                    <span className="hidden-xs">
                      {userInfo?.userId}
                    </span>
                  </a>
                  <ul className="dropdown-menu">
                    {/* The iframe below is used to fix bug in the browser IE11.
                    In the screen [Upload], the pdf iframe is overlap all elements. */}
                    {isIE && <iframe class="iframe-cover-ar" src="about:blank"></iframe>}
                    <li className="user-header">
                      <img
                        src={userInfo.avatar ? userInfo.avatar : URL_CONFIG.default_avatar}
                        className="img-circle"
                        alt="User"
                      />
                      <p>
                        {userInfo?.userId}
                      </p>
                      <p>
                        {userInfo.tenantName}
                      </p>
                    </li>
                    <li className="user-footer">
                      <div className="pull-left">
                        <button
                          className="btn btn-default btn-flat"
                          onClick={this.onChangePassword}
                        >
                          {t("change_password.menu_button")}
                        </button>
                      </div>
                      <div className="pull-right">
                        <button
                          className="btn btn-default btn-flat"
                          onClick={this.onLogout}
                        >
                          {t("header.sign_out")}
                        </button>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </nav>
        </header>
        <MainSidebar />
        <Scrollbars renderTrackHorizontal={this.disableTrackScrollBar(disableTrackScrollBar)}
          renderTrackVertical={this.disableTrackScrollBar(disableTrackScrollBar)}
          renderView={this.renderCustomView()}
          className="content-wrapper">{children}</Scrollbars>
        <footer className="main-footer">
          <SwitchLanguages t={t} />
        </footer>
      </div>
    );
  }
}

export default withRouter(withTranslation()(ContainerBody));
