import React from "react";
import Dropzone from "react-dropzone";
import { withTranslation } from "react-i18next";
import "./style.scss";
// for pdf files
class UploadFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      warningMsgKey: ""
    };
  }

  onDrop = (accepted, rejected) => {

    const { maxFileSize, types } = this.props;
    let warningMsgKey = ""
    if (Object.keys(rejected).length !== 0) {
      warningMsgKey = "upload.file_erorrs"
    } else {
      warningMsgKey = ""
      if (Object.prototype.toString.call(accepted) === '[object Array]') {
        accepted.forEach(e => {
          let file = e;
          //vallidation fileSize and fileType
          if (maxFileSize && file.size > maxFileSize) {
            warningMsgKey = "upload.file_size_over"
          } else if (types && !types.includes(file.type)) {
            warningMsgKey = "upload.file_type_not_allow"
          }
        });
        if (warningMsgKey === "") {
          this.props.addFile(accepted);
        };
      } else {
        warningMsgKey = "upload.file_erorrs"
      }
    }
    this.setState({ warningMsgKey })
  };

  render() {
    const { t, mime } = this.props;
    const render = ({ getRootProps, getInputProps }) => (
      <section>
        <div {...getRootProps({ className: "upload-file" })}>
          <div>
            <input id="upload-selector" {...getInputProps()} accept={mime ? mime : "*"} />
            <p>{t("upload.drag_drop_desc")}</p>
          </div>
          <div>
            <p className="color-red">{t("upload.file_size_over_input")}</p>
          </div>
        </div>
      </section>
    );

    return (
      <div className="error">
        <Dropzone onDrop={this.onDrop} >{render}</Dropzone>
      </div>
    );
  }
}

export default withTranslation()(UploadFile);
