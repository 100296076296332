// UncontrolledLottie.jsx
import React from 'react';
import Lottie from 'react-lottie';
import animationData from './7861-loading-animation.json';
import animationDataPdf from './5257-loading.json';
import loadingUploadData from './2044-loading-dots-3.json';

const Loading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return <Lottie isClickToPauseDisabled={true} options={defaultOptions}
    height={200}
    width={200}
  />
}
const LoadingPdf = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationDataPdf,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return <Lottie isClickToPauseDisabled={true} options={defaultOptions}
    height={200}
    width={200}
  />
}

const LoadingUpload = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingUploadData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return <Lottie isClickToPauseDisabled={true} options={defaultOptions}
    width={60}
    height={20}
    style={{lineHeight:"0.5"}}
  />
}

export { Loading, LoadingUpload, LoadingPdf }